import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import {Box} from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel'

const ToastContent = ({content, status}: { content: string, status: boolean }) => {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      {
        status ?
          <CheckCircleIcon fontSize={'small'} htmlColor={'#0AC571'}/> :
          <CancelIcon fontSize={'small'} htmlColor={'#f44336'}/>
      }
      <Box>
        {content}
      </Box>
    </Box>
  )
}

export default ToastContent