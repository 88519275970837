export const ORIGIN_TOKENS = [
    {
        symbol: 'BTC'
    },
    {
        symbol: 'LTC'
    },
    {
        symbol: 'DOGE'
    },
    {
        symbol: 'oBTC'
    },
    {
        symbol: 'oLTC'
    },
    {
        symbol: 'oDOGE'
    },
]

export const CHAIN_BTC = 1991
export const CHAIN_LTC = 1992
export const CHAIN_DOGE = 1993
