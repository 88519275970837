import React from 'react'
import { Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import { Chains, CHAIN_POLYGON } from '@w3u/chains'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import { Flex, Text } from 'components/Style'
import { getIcon, getChainIcon } from 'utils/icons'
import BigNumber from 'bignumber.js'
import { getBalanceNumber } from 'utils/formatBalance'
import { LockBalanceInterface } from 'modal/twoway'
import Balance from 'components/Balance'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { setupNetwork } from 'utils/network'

import { TokenImg, ListText, ChainList } from './style'

const WrapperContanter = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
`

interface Items {
    totalLiquidity: BigNumber;
    userBalance: string | undefined;
    decimals: number | undefined;
    tokenName: string;
    balances: LockBalanceInterface[];
    farmApr: string
}
interface LiquidityProps {
    datas: Items[]
}
const SkeletonList = () => {
    return (
        <>
            {[1, 2, 3, 4].map(d => (
                <Skeleton key={d} width="100%" variant="text" height={80} />
            ))}
        </>
    )
}
const MobileLiquidity:React.FC<LiquidityProps> = ({ datas }) => {
    const { t } = useTranslation()
    const { chainId } = useWeb3React()
    const changeChain = async (chainId: number) => {
        await setupNetwork(chainId)
            .catch((e: any) => console.error('Setup network: ', e))
    }
    return (
        <WrapperContanter>
            {!!datas.length ? datas.map(token => (
                    <Accordion
                        sx={{
                            borderRadius: '20px !important',
                            marginBottom: '15px',
                            padding: '25px 15px 10px',
                            background: '#00000066',
                        }}
                    >
                        <AccordionSummary>
                            <Flex width="100%" flexDirection="column" justifyContent="space-between">
                                <Flex mb="20px" alignItems="center" justifyContent="space-between">
                                    <TokenImg>
                                        <img src={getIcon(token.tokenName)} width="20px" height="20px" alt={token.tokenName} />
                                        <img src={getChainIcon(CHAIN_POLYGON)} width="16px" height="16px" alt={Chains[CHAIN_POLYGON].displayName} />
                                        <ListText ml="10px">{token.tokenName}</ListText>
                                    </TokenImg>
                                    <ChainList>
                                        {token.balances.map(chain => (<img src={getChainIcon(chain.chainID)} width="16px" height="16px" alt="BOR" />))}
                                    </ChainList>
                                </Flex>
                                <Flex justifyContent="space-between" mb="12px">
                                    <ListText>{t('total_liquidity')}</ListText>
                                    <Balance color="#ffffff" value={getBalanceNumber(token.totalLiquidity, token.decimals)} />
                                </Flex>
                                <Flex justifyContent="space-between" mb="12px">
                                    <ListText>{t('your_liquidity')}</ListText>
                                    <Balance color="#ffffff" value={getBalanceNumber(new BigNumber(token.userBalance ?? '0'), token.decimals)} />
                                </Flex>
                                <Flex justifyContent="space-between">
                                    <ListText>{t('farm_apy')}</ListText>
                                    <Text color="#ffffff">{token.farmApr}%</Text>
                                </Flex>
                                <Divider sx={{background: '#000000', margin: '15px 0'}}/>
                                <Flex justifyContent="center"><KeyboardArrowDownIcon/></Flex>
                            </Flex>
                        </AccordionSummary>
                        <AccordionDetails sx={{padding: '0'}}>
                            {token.balances.map(d => (
                                <Flex flexDirection="column" padding="15px 0">
                                    <Flex mb='15px' alignItems="center" sx={{gridColumnStart: 2}}>
                                        <img src={getChainIcon(d.chainID)} width="20px" height="20px" alt={Chains[d.chainID].displayName} />
                                        <ListText ml="8px">{Chains[d.chainID].displayName}</ListText>
                                    </Flex>
                                    <Flex mb='15px' justifyContent="space-between">
                                        <ListText>{t('total_liquidity')}</ListText>
                                        <Balance color="#ffffff" value={getBalanceNumber(new BigNumber(d.balance), token.decimals)} />
                                    </Flex>
                                    <Flex justifyContent="space-between" mb='15px'>
                                        <ListText>{t('your_liquidity')}</ListText>
                                        <Balance color="#ffffff" value={getBalanceNumber(new BigNumber(d.balance), token.decimals)} />
                                    </Flex>
                                    {d.chainID === chainId ?
                                        <Grid>
                                            <Link to='/liquidity/add'><Button fullWidth size='small'>{t('add')}</Button></Link>
                                            <Link to='/liquidity/remove'><Button fullWidth size='small'>{t('remove')}</Button></Link>
                                            <Link to='/farm'><Button fullWidth size='small'>{t('go_farm')}</Button></Link>
                                        </Grid> :
                                        <Flex justifyContent="center">
                                            <Button onClick={() => changeChain(d.chainID)} fullWidth size='small' variant="outlined">
                                                {t('switch')}
                                            </Button>
                                        </Flex>
                                    }
                                </Flex>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                )) : <SkeletonList />
            }
        </WrapperContanter>
    )
}

export default MobileLiquidity
