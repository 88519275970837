import axios from 'axios'

const request = axios.create({
  baseURL: process.env.REACT_APP_API_HOST ?? "https://oportal.boringdao-api.com", // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 * 5, // request timeout
})

// response
// request.interceptors.response.use(
//   (response) => {
//     const res = response.data
//     if (!res) {
//       return Promise.reject(new Error(res.message || 'Error'))
//     }
//     console.log("response", response.data)
//     return response
//   },
//   (error) => {
//     return Promise.reject(error)
//   },
// )

export default request
