import {useCallback, useEffect, useState, useMemo} from 'react'
import {useWeb3React} from '@web3-react/core'
import {CHAIN_ETHER} from '@w3u/chains'
import {ethers} from 'ethers'
import {isTestnet, isOriginToken} from 'utils/providers'
import multicall, {Call} from 'utils/muticall'
import {
  TWOWAY_CHAIN_IDS,
  PRODUCTION_TWOWAY_CHAIN_IDS,
  TWOWAY_TOKENS,
  OUSDT_TOKENS,
  FilterTokens,
} from 'config/constants/twoway'
import {LockBalancesInterface} from 'modal/twoway'
import {TwowayToken, TwowayOToken, TwowayOTokenBalcnce} from 'modal/token'
import BigNumber from 'bignumber.js'
import {getTwowayFeeStatus, getTwowayChains} from 'utils'
import {BIG_TEN} from 'utils/bigNumber'
import useRefresh from './useRefresh'
import {useRelayChainID, useRelayTwowayAddress} from 'hooks'
import {useRouterContract, useTwowayContract} from 'hooks/useContract'
import TwowayABI from 'config/abi/TwoWay.json'
import TwowayCenterABI from 'config/abi/TwowayCenter.json'
import ERC20ABI from 'config/abi/ERC20.json'
import {BridgeType} from 'modal/types'

//** Twoway Common */
export const useTwowayOTokens = (): TwowayOToken[] => {
  const relayChainID = useRelayChainID()
  const tokens = useMemo(
    () => OUSDT_TOKENS.filter((token: TwowayOToken) => token.chainID === relayChainID),
    [OUSDT_TOKENS, relayChainID]
  )

  if (FilterTokens.length === 0) {
    return tokens
  }

  const tokenNames = useMemo(() => FilterTokens.map((tokenName) => 'o' + tokenName), [FilterTokens])

  return useMemo(() => tokens.filter((token: TwowayToken) => !tokenNames.includes(token.symbol)), [tokens])
}

export const useTwowayTokens = (chainId: number | undefined): TwowayToken[] => {
  const chainID = chainId ?? CHAIN_ETHER
  const tokens = useMemo(() => TWOWAY_TOKENS.filter((token) => token.chainID === chainID), [TWOWAY_TOKENS, chainId])
  if (FilterTokens.length === 0) {
    return tokens
  }

  return useMemo(() => tokens.filter((token) => !FilterTokens.includes(token.symbol)), [tokens])
}

export const useCommonTwowayTokens = (
  fromChainID: number | undefined,
  toChainID: number | undefined
): TwowayToken[] => {
  const fromChainId = fromChainID ?? CHAIN_ETHER
  const from = useTwowayTokens(fromChainId)
  const to = useTwowayTokens(toChainID)

  const tokens = useMemo(
    () => from.filter((token) => to.some((t) => t.symbol === token.symbol)),
    [fromChainID, toChainID]
  )

  if (FilterTokens.length === 0) {
    return tokens
  }

  return useMemo(() => tokens.filter((token) => !FilterTokens.includes(token.symbol)), [tokens])
}
export const useTwowayChainIds = () => {
  const {chainId} = useWeb3React()
  return !isTestnet(chainId) ? PRODUCTION_TWOWAY_CHAIN_IDS : TWOWAY_CHAIN_IDS
}

//** Twoway */
export const useTwowayLockBalance = (tokenName: string | undefined, chainID: number | undefined) => {
  const {lockBalances, loading, fetchLockLiquidity} = useTwowayLockBalances()
  const results = lockBalances.find((lock) => lock.tokenName === `o${tokenName}`)
  const balance = results && results.balances.find((result) => result.chainID === chainID)
  return useMemo(() => {
    return {
      lockBalance: balance?.balance || '0',
      loading,
      fetchLockLiquidity,
    }
  }, [chainID, tokenName, lockBalances, loading])
}

/**
 * Get Twoway Lock Balance
 * @returns
 */
export const useTwowayLockBalances = () => {
  const [loading, setLoading] = useState(false)
  const [lockBalances, setLockBalances] = useState<LockBalancesInterface[]>([])
  const {slowRefresh} = useRefresh()
  const relayChainId = useRelayChainID()
  const tokens = useTwowayOTokens()
  const ids = useMemo(() => getTwowayChains(), [])
  const relayAddress = useRelayTwowayAddress()
  const fetchLockLiquidity = useCallback(async () => {
    const calls: Call[] = []
    tokens.map((token) => {
      calls.push(
        ...ids.map((id) => ({
          address: relayAddress,
          name: 'lockBalances',
          params: [token.address, id],
        }))
      )
    })
    try {
      setLoading(true)
      const balances = await multicall(TwowayCenterABI, calls, relayChainId)
      const result: LockBalancesInterface[] = []
      if (balances && balances.length > 0) {
        tokens.map((token, i) => {
          const b = ids.map((id, j) => {
            // @ts-ignore
            const balance = balances[j + ids.length * i] || '0'
            return {chainID: id, balance: balance.toString()}
          })
          result.push({tokenName: token.symbol, balances: b})
        })
        setLockBalances(result)
      }
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      // setLoading(false)
    }
  }, [relayChainId, tokens, ids, relayAddress])

  useEffect(() => {
    fetchLockLiquidity()
  }, [fetchLockLiquidity, slowRefresh])

  return {lockBalances, loading, fetchLockLiquidity}
}

export const useUserLiquidity = () => {
  const [loading, setLoading] = useState(false)
  const [oTokenBalanes, setOTokenBlances] = useState<TwowayOTokenBalcnce[]>([])
  const {account} = useWeb3React()
  const relayChainId = useRelayChainID()
  const tokens = useTwowayOTokens()
  const fetchUserLiquidity = useCallback(async () => {
    if (account) {
      setLoading(true)
      const calls = tokens.map((token) => ({
        address: token.address,
        name: 'balanceOf',
        params: [account],
      }))
      const resultBalances = await multicall(ERC20ABI, calls, relayChainId)
      const userBalance = tokens.map((d, i) => ({
        ...d,
        balance: resultBalances[i].toString(),
      }))
      setLoading(false)
      setOTokenBlances(userBalance)
    }
  }, [account, relayChainId])

  useEffect(() => {
    fetchUserLiquidity()
  }, [fetchUserLiquidity])

  return {oTokenBalanes, loading, fetchUserLiquidity}
}

export const useTwowayCalculateFee = (
  token: TwowayToken,
  fromAddress: string | undefined | null,
  toID: number,
  amount: string,
  lockBalance: string,
  bridgeType: BridgeType,
  fixFee: string
) => {
  const [fee, setFee] = useState('0')
  const [calcFee, setCalcFee] = useState('')
  const [ratioFees, setRatioFees] = useState(['', '', ''])
  const [remainAmount, setRemainAmount] = useState('')
  const [loading, setLoading] = useState(false)
  token.decimals

  const relayChainId = useRelayChainID()
  const otokens = useTwowayOTokens()
  const relayAddress = useRelayTwowayAddress()

  const fetchCalculateFee = useCallback(async () => {
    try {
      const isCanCalc = new BigNumber(amount).gte(new BigNumber(fixFee).div(BIG_TEN.pow(18)))
      if (
        isCanCalc &&
        amount !== '' &&
        new BigNumber(amount).gt(0) &&
        fromAddress &&
        bridgeType === BridgeType.oportal
      ) {
        setLoading(true)
        const otoken = otokens.find((otoken) => otoken.symbol === 'o' + token?.symbol)
        const calls = [
          {
            address: relayAddress,
            name: 'calculateFee',
            params: [fromAddress, otoken?.address, toID, ethers.utils.parseEther(amount)],
          },
          {
            address: relayAddress,
            name: 'ratioFeesHigh',
            params: [otoken?.address, toID],
          },
          {
            address: relayAddress,
            name: 'ratioFeesMedium',
            params: [otoken?.address, toID],
          },
          {
            address: relayAddress,
            name: 'ratioFeesLow',
            params: [otoken?.address, toID],
          },
        ]
        const [feeResult, ...rest] = await multicall(TwowayABI, calls, relayChainId)
        const fixAmount = feeResult[0].toString()
        const ratioAmount = feeResult[1].toString()
        const remainAmount = feeResult[2].toString()
        const feeAmount = new BigNumber(fixAmount).plus(ratioAmount).toString()
        const calcFee = new BigNumber(ratioAmount)
          .div(new BigNumber(remainAmount).plus(ratioAmount))
          .times(100)
          .toString()
        const ratioFeesAmount = [rest[0].toString(), rest[1].toString(), rest[2].toString()]
        setFee(feeAmount)
        setRemainAmount(remainAmount)
        setRatioFees(ratioFeesAmount)
        setCalcFee(Number(remainAmount) === 0 ? `--` : `${calcFee}%`)
        setLoading(false)
      } else {
        setFee('0')
        setRemainAmount('')
        setRatioFees(['', '', ''])
        setCalcFee('--')
      }
    } catch (error) {
      console.log('fetchCalculateFee-error', error)
    }
  }, [relayChainId, otokens, amount, fromAddress, fixFee])

  useEffect(() => {
    fetchCalculateFee()
  }, [fetchCalculateFee])

  return {fee, calcFee, ratioFees, remainAmount, loading}
}

/**
 * get Twoway fix fee
 * @param toID
 * @param token
 * @returns
 */
export const useTwowayFixFees = (fromID: number, toID: number, token: TwowayToken) => {
  const [fixFee, setFixFee] = useState<string>('')
  const oTokens = useTwowayOTokens()
  const relayChainID = useRelayChainID()
  const relayAddress = useRelayTwowayAddress()
  const fetchFixFees = useCallback(async () => {
    try {
      const calls = oTokens.map((token) => ({
        address: relayAddress,
        name: 'fixFees2',
        params: [token.address, fromID, toID],
      }))
      const values = await multicall(TwowayCenterABI, calls, relayChainID)
      const oTokenIndex = oTokens.findIndex((otoken) => otoken.symbol === 'o' + token?.symbol)
      setFixFee(values[oTokenIndex].toString())
    } catch (error) {
      setFixFee('')
    }
  }, [relayChainID, oTokens, toID, token, relayAddress])

  useEffect(() => {
    fetchFixFees()
  }, [fetchFixFees])

  return {fixFee, fetchFixFees}
}

export const useRouterCrossOut = () => {
  const {chainId, account} = useWeb3React()
  try {
    const twowayCenterContract = useRouterContract()
    const handleCrossOut = useCallback(
      async (token: TwowayToken, toID: number, amount: string, toAddress?: string | null | undefined) => {
        let args = {}
        if (isOriginToken(chainId, token.symbol)) {
          args = {
            value: ethers.utils.parseUnits(amount, token?.decimals),
          }
        }
        const tx = await twowayCenterContract.crossOut(
          token.address,
          toID,
          toAddress || account,
          ethers.utils.parseUnits(amount, token.decimals),
          args
        )
        console.log('fuck', tx)
        return tx
      },
      [twowayCenterContract, account, chainId]
    )

    return {onCrossOut: handleCrossOut}
  } catch (error) {
    console.error(error)
    return {onCrossOut: async () => {}}
  }
}

export const useCrossOut = () => {
  const {chainId, account} = useWeb3React()
  try {
    const twowayCenterContract = useTwowayContract()
    const handleCrossOut = useCallback(
      async (token: TwowayToken, toID: number, amount: string, toAddress?: string | null | undefined) => {
        let args = {}
        if (isOriginToken(chainId, token.symbol)) {
          args = {
            value: ethers.utils.parseUnits(amount, token?.decimals),
          }
        }
        const tx = await twowayCenterContract.crossOut(
          token.address,
          toID,
          toAddress || account,
          ethers.utils.parseUnits(amount, token.decimals),
          args
        )
        return tx
      },
      [twowayCenterContract, account, chainId]
    )

    return {onCrossOut: handleCrossOut}
  } catch (error) {
    return {onCrossOut: async () => {}}
  }
}

interface TwowayLiquidityReward {
  chainID: number
  reward: string
  liquidity: string
}

export const useAddLiquidityRewards = (oTokenAddress: string, amount: string, decimals: number) => {
  const twowayChains = useTwowayChainIds()
  const relayChainID = useRelayChainID()
  const relayAddress = useRelayTwowayAddress()
  const [loading, setLoading] = useState(false)
  const [liquidityRewads, setLiquidityRewads] = useState<TwowayLiquidityReward[]>()

  const fetchLiqidityRewards = useCallback(async () => {
    const calls = twowayChains.map((id) => ({
      address: relayAddress,
      name: 'calculateReward',
      params: [oTokenAddress, id, ethers.utils.parseEther(amount || '0')],
    }))
    const lockCalls = twowayChains.map((id) => ({
      address: relayAddress,
      name: 'lockBalances',
      params: [oTokenAddress, id],
    }))
    setLoading(true)
    try {
      const rewards = await multicall(TwowayCenterABI, calls, relayChainID)
      const liquiditys = await multicall(TwowayCenterABI, lockCalls, relayChainID)
      const lRs: TwowayLiquidityReward[] = twowayChains.map((d, i) => ({
        chainID: d,
        reward: rewards[i].toString(),
        liquidity: liquiditys[i].toString(),
      }))
      setLiquidityRewads(lRs)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [twowayChains, relayAddress, relayChainID, oTokenAddress, amount])

  useEffect(() => {
    if (oTokenAddress && amount) {
      fetchLiqidityRewards()
    }
  }, [fetchLiqidityRewards])

  return {liquidityRewads, loading, fetchLiqidityRewards}
}

export const useDeposit = () => {
  const {chainId} = useWeb3React()
  const twowayContract = useTwowayContract()
  const handleDeposit = useCallback(
    async (amount: string, token: TwowayToken) => {
      const value = ethers.utils.parseUnits(amount, token?.decimals || 18)
      let args = {}
      if (isOriginToken(chainId, token.symbol)) {
        args = {
          value: value,
        }
      }
      const tx = await twowayContract?.deposit(token?.address, value, args)
      // const receipt = await tx.wait()
      return tx
    },
    [twowayContract, chainId]
  )

  return {onDeposit: handleDeposit}
}
export const useWithdraw = () => {
  const {chainId} = useWeb3React()
  const twowayContract = useTwowayContract()
  const handleWithdraw = useCallback(
    async (amount: string, token: TwowayToken, toID: number, account: string) => {
      const tx = await twowayContract?.withdraw(token?.address, toID, account, ethers.utils.parseEther(amount))
      // const receipt = await tx.wait()
      return tx
    },
    [twowayContract, chainId]
  )

  return {onWithdraw: handleWithdraw}
}
