import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const OtherIcon:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M0.159381 9.58193C0.913964 13.3131 4.19553 15.9942 8.00227 15.9897C9.0691 15.9923 10.1255 15.7799 11.1085 15.3653C15.1775 13.6498 17.0854 8.96049 15.3699 4.89147C13.6543 0.822451 8.96503 -1.08542 4.896 0.630119C1.38667 2.10518 -0.595201 5.85073 0.159381 9.58193ZM1.25902 7.99773C1.25902 4.28271 4.28724 1.25449 8.00227 1.25449C11.7173 1.25449 14.7455 4.28271 14.7455 7.99773C14.7455 11.7128 11.7173 14.741 8.00227 14.741C4.28724 14.741 1.25902 11.7128 1.25902 7.99773Z" />
            <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M10.4217 8.46973C10.6126 8.93052 11.0622 9.23096 11.561 9.23096C12.242 9.23096 12.7941 8.67887 12.7941 7.99782C12.7941 7.49907 12.4937 7.04942 12.0329 6.85855C11.5721 6.66769 11.0417 6.77319 10.689 7.12586C10.3363 7.47854 10.2308 8.00893 10.4217 8.46973ZM8.00203 9.23096C7.50327 9.23096 7.05363 8.93052 6.86276 8.46973C6.67189 8.00893 6.7774 7.47854 7.13007 7.12586C7.48275 6.77319 8.01314 6.66769 8.47394 6.85855C8.93473 7.04942 9.23517 7.49907 9.23517 7.99782C9.23517 8.67887 8.68308 9.23096 8.00203 9.23096ZM4.4431 9.23096C3.76206 9.23096 3.20996 8.67887 3.20996 7.99782C3.20996 7.31678 3.76206 6.76468 4.4431 6.76468C5.12414 6.76468 5.67624 7.31678 5.67624 7.99782C5.67624 8.67887 5.12414 9.23096 4.4431 9.23096Z" />
        </SvgIcon>
    )
}

export default OtherIcon