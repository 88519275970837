import { AxiosResponse } from 'axios';
import request from 'utils/request'

const HOST = process.env.REACT_APP_API_HOST
const HOST_POW = process.env.REACT_APP_API_HOST_POW

export const getRouterSteps = (address: string | undefined | null) => {
  return request({
    url: `https://router.boringdao-api.com/user_records?addr=${address}`,
    method: 'GET',
  })
}

export const getTwowaySteps = (params: any) => {
  return request({
    url: `${HOST}/twoway/api/v1/steps`,
    method: 'GET',
    params
  })
}

export const getNativeSteps = (params: any) => {
  return request({
    url: `${HOST}/native/api/v1/steps`,
    method: 'GET',
    params
  })
}

export const getPowChainTxInfo = (type: string, txID: string) => {
  return request({
    url: `${HOST_POW}/api/v2/tx/${type}/${txID}`,
    method: 'GET'
  })
}

export const getPowChainReceived = (type: string, address: string, txID: string) => {
  return request({
    url: `${HOST_POW}/api/v2/get_tx_received/${type}/${address}/${txID}`,
    method: 'GET'
  })
}

export const getTwowayHistory = (params: any) => {
  return request({
    url: `${HOST}/twoway/api/v1/history`,
    method: 'GET',
    params
  })
}
export const getNativeHistory = (params: any) => {
  return request({
    url: `${HOST}/native/api/v1/history`,
    method: 'GET',
    params
  })
}

