import gql from 'graphql-tag'

export const boringFarm = (account) => {
  const queryString = `query MyQuery {
    boringFarmEntities(
      first: 10
      where: {user: "${account}"}
      orderBy: timestamp
      orderDirection: desc
    ) {
      symbol
      timestamp
      to
      user
      id
      optName
      from
      eventName
      amount
    }
  }`
  return gql(queryString)
}
export const oportalFarm = (account) => {
  const queryString = `query MyQuery {
    oportalFarmEntities(
      first: 10
      where: {user: "${account}"}
      orderBy: timestamp
      orderDirection: desc
    ) {
      eventName
      amount
      id
      from
      optName
      symbol
      timestamp
      to
      user
    }
  }`
  return gql(queryString)
}
