import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const HomeIcon:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path fill={color} d="M8.90512 0.332451C8.41544 -0.110817 7.66972 -0.110817 7.18005 0.332451L0.20777 6.95428C-0.0514701 7.19271 -0.070673 7.59597 0.164563 7.85842C0.4014 8.12086 0.804662 8.14326 1.0687 7.90962L1.17912 7.80401V14.7139C1.17912 15.0547 1.31354 15.3828 1.55518 15.6244C1.79682 15.8661 2.12327 16.0021 2.46572 16.0021H6.2087V12.1295C6.2087 11.8894 6.30311 11.659 6.47434 11.4878C6.64397 11.3182 6.8744 11.2221 7.11604 11.2221H8.87791C9.11795 11.2221 9.34838 11.3182 9.51961 11.4878C9.68924 11.6574 9.78525 11.8894 9.78525 12.1295V16.0021H13.6178C13.9587 16.0021 14.2867 15.8661 14.5284 15.6244C14.77 15.3828 14.9044 15.0547 14.9044 14.7139V7.88882C15.1909 8.14806 15.5957 8.12726 15.8342 7.86322C15.9478 7.7368 16.0086 7.57037 15.999 7.39914C15.991 7.22952 15.9142 7.06949 15.7878 6.95428L8.90512 0.332451Z"/>
        </SvgIcon>
    )
}

export default HomeIcon