import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

// For Polygon Chain
export const oPortalClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/burlin-star/oportal-farm',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

// For Ethereum Chain
export const boringClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/burlin-star/boring-farm'
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})