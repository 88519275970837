import {useMemo} from 'react'
import {useWeb3React} from '@web3-react/core'
import {CHAIN_ETHER, CHAIN_POLYGON, CHAIN_POLYGON_MUMBAI} from '@w3u/chains'
import {FilterTokens, OUSDT_TOKENS, TWOWAY_TOKENS} from 'config/constants/twoway'
import {generateTopTokenFromNative, generateTopTokenFromTwoway, TopToken, TwowayOToken, TwowayToken} from 'modal/token'
import {isTestnet} from 'utils/providers'
import {NATIVE_BRIDGE_TOKENS} from "../config/constants/native_bridge";
import {CHAIN_BTC, ORIGIN_TOKENS} from "../config/constants/origin";

export const useRelayChainID = () => {
  const {chainId} = useWeb3React()
  const requestChainID = isTestnet(chainId) ? CHAIN_POLYGON_MUMBAI : CHAIN_POLYGON
  return useMemo(() => requestChainID, [chainId])
}

export const useTwowayOTokens = (): TwowayOToken[] => {
  const relayChainID = useRelayChainID()
  const tokens = useMemo(() => OUSDT_TOKENS.filter((token: TwowayOToken) => token.chainID === relayChainID), [
    OUSDT_TOKENS,
    relayChainID,
  ])

  if (FilterTokens.length === 0) {
    return tokens
  }

  const tokenNames = useMemo(() => FilterTokens.map((tokenName) => 'o' + tokenName), [FilterTokens])

  return useMemo(() => tokens.filter((token: TwowayToken) => !tokenNames.includes(token.symbol)), [tokens])
}

export const useTwowayTokens = (chainId: number | undefined): TwowayToken[] => {
  const chainID = chainId ?? CHAIN_ETHER
  const tokens = useMemo(() => TWOWAY_TOKENS.filter((token) => token.chainID === chainID), [TWOWAY_TOKENS, chainId])
  if (FilterTokens.length === 0) {
    return tokens
  }

  return useMemo(() => tokens.filter((token) => !FilterTokens.includes(token.symbol)), [tokens])
}

export const useAllTokens = (chainId: number | undefined): TopToken[] => {
  const chainID = chainId ?? CHAIN_ETHER
  const tokens1 = useMemo(() => TWOWAY_TOKENS.filter((token) => token.chainID === chainID), [TWOWAY_TOKENS, chainId])
  const tokens2 = useMemo(() => NATIVE_BRIDGE_TOKENS.filter((token) => token.chainID === chainID), [chainId])
  const t1 = tokens1.map((token) => generateTopTokenFromTwoway(token))
  const t2 = tokens2.map((token) => generateTopTokenFromNative(token))
  return t1.concat(t2)
}

export const useCommonTwowayTokens = (
    fromChainID: number | undefined,
    toChainID: number | undefined
  ): TwowayToken[] => {
    const fromChainId = fromChainID ?? CHAIN_ETHER
    const from = useTwowayTokens(fromChainId)
    const to = useTwowayTokens(toChainID)

    const tokens = useMemo(() => from.filter((token) => to.some((t) => t.symbol === token.symbol)), [
      fromChainID,
      toChainID,
    ])

    if (FilterTokens.length === 0) {
      return tokens
    }

    return useMemo(() => tokens.filter((token) => !FilterTokens.includes(token.symbol)), [tokens])
}


export const useCommonTokens = (
    fromChainID: number | undefined,
    toChainID: number | undefined
): TopToken[] => {
  const fromChainId = fromChainID ?? CHAIN_ETHER
  const from = useAllTokens(fromChainId)
  const to = useAllTokens(toChainID)

  const tokens = useMemo(() => from.filter((token) => to.some((t) => t.symbol === token.symbol)), [
    fromChainID,
    toChainID,
  ])

  if (FilterTokens.length === 0) {
    return tokens
  }

  return useMemo(() => tokens.filter((token) => !FilterTokens.includes(token.symbol)), [tokens])
}

export const useRelatedChains = (symbol: string): number[] => {
  // if (isOOriginToken(symbol)) t= [CHAIN_ETHER]
  console.log(symbol)
  if (symbol === 'BTC') return useMemo(() => [CHAIN_BTC], [symbol])
  console.log(symbol)
  // if (symbol === 'LTC') t= [CHAIN_LTC]
  // if (symbol === 'DOGE') t=  [CHAIN_DOGE]

  const t1 = useMemo(() => TWOWAY_TOKENS.filter(t => t.symbol === symbol), [symbol])
  if (t1 && t1.length > 0) {
    return useMemo(() => t1.map(t => t.chainID), [t1])
  }

  const t2 = useMemo(() => NATIVE_BRIDGE_TOKENS.filter(t => t.symbol === symbol), [symbol])

  return t2.map(t => t.chainID)
}

export const useAllTokenSymbols = (): string[] => {
  const s1 = useMemo(() => TWOWAY_TOKENS.map(t => t.symbol), [TWOWAY_TOKENS])
  const s2 = useMemo(() => NATIVE_BRIDGE_TOKENS.map(t => t.symbol), [NATIVE_BRIDGE_TOKENS])
  const s3 = useMemo(() => ORIGIN_TOKENS.map(t => t.symbol), [ORIGIN_TOKENS])

  const s = s1.concat(s2).concat(s3)

  const t = new Set(s)

  return useMemo(() => [...Array.from(t.values())], [s1, s2, s3])
}
