import React, {useMemo, useContext, useState, useEffect} from 'react'
import {Dialog, Box} from '@mui/material'
import styled from '@emotion/styled'
import useMediaQuery from '@mui/material/useMediaQuery'
import {Chains, CHAIN_ETHER, CHAIN_POLYGON} from '@w3u/chains'
import {Text, Flex} from 'components/Style'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {useWeb3React} from '@web3-react/core'
import {useTranslation} from 'react-i18next'
import {TransactionContext} from 'contexts/TransactionContext'
import {injectedConnector, walletConnect, walletLink} from 'config/connectors'
import Copy from 'components/Copy'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import {CrossIcon, ErrorIcon, LoadingIcon, ViewIcon, ShareIcon} from 'components/Svg'
import {getOTokenByAddress, getTwowayTokenByAddress, getNativeTokenByAddress} from 'utils/addressHelpers'
import {formatNumber} from 'utils'
import BigNumber from 'bignumber.js'
import {IHistory} from 'modal/types'
import {useBoringFarm, useOportalFarm} from 'hooks/useGraph'
import {BridgeType} from 'modal/types'

import WalletConnectIcon from 'assets/images/wallet/walletconnect.png'
import WalletConnectSolidIcon from 'assets/images/wallet/walletconnect_solid.png'
import MetaMaskIcon from 'assets/images/wallet/metamask.png'
import MetaMaskSolidIcon from 'assets/images/wallet/metamask_solid.png'
import CoinbaseIcon from 'assets/images/wallet/coinbase.png'
import CoinbaseSolidIcon from 'assets/images/wallet/coinbase_solid.png'
import {useRouterTransactions} from '../../hooks/useRouterTransactions'
import {RouterStepInterface} from '../../interfaces/router'
import {useModal} from 'mui-modal-provider'
import {RouterTransaction} from './RouterTransaction'

const WalletData = [
  {
    name: 'Metamask',
    icon: MetaMaskIcon,
    solidIcon: MetaMaskSolidIcon,
    bgColor: 'linear-gradient(to right, #FF8901, #FF6900)',
  },
  {
    name: 'WalletConnect',
    icon: WalletConnectIcon,
    solidIcon: WalletConnectSolidIcon,
    bgColor: 'linear-gradient(to right, #00A1FF, #0068FF)',
  },
  {
    name: 'Coinbase Wallet',
    icon: CoinbaseIcon,
    solidIcon: CoinbaseSolidIcon,
    bgColor: 'linear-gradient(to right, #315DF5, #002BD3)',
  },
]

const DialogTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  font-family: 'Lexend-Regular, Lexend';
  font-size: 18px;
`

const WalletList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 37px;
  @media screen and (max-width: 600px) {
    margin-top: 15px;
  }
`

const WalletItem = styled.div<{bgColor: string}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({bgColor}) => bgColor};
  cursor: pointer;
  color: #ffffff;
  padding: 0 20px;
  border-radius: 14px;
  width: 512px;
  height: 56px;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  > img:first-of-type {
    width: 56px;
    height: 56px;
  }
  > img:last-of-type {
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`
const AccountBox = styled.div`
  padding: 20px;
  border-radius: 12px;
  background-color: #00000099;
  width: 100%;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin: 0 auto;
    padding: 20px 10px;
  }
`
const Account = styled.div`
  padding: 16px;
  color: #ffffff;
  background: linear-gradient(90deg, #000000 0%, rgba(0, 35, 55, 0.22) 50%, rgba(0, 161, 255, 0.18) 100%);
  border-radius: 16px;
  border: 1px solid rgba(0, 104, 255, 0.22);
  margin-top: 13px;
`

const HistoryList = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  padding: 20px 0 0;
`
const TxText = styled(Flex)`
  align-items: center;
  svg {
    cursor: pointer;
    width: 12px;
    height: 12px;
  }
`
const ChangeButton = styled.div`
  border: 1px solid #0068ff;
  border-radius: 15px;
  padding: 4px 14px;
  cursor: pointer;
  color: #0068ff;
  :hover {
    opacity: 0.8;
  }
`
interface ModalProps {
  open: boolean
  onClose: () => void
}

export interface HistoryInfo {
  txHash: string
  message: string
  timestamp: number
  status: number // -1 error 0 laoding 1 success
  farmAction: boolean
  chainId: number
  bridgeType?: string
}

const findMax = (arry: IHistory[]): IHistory | undefined => {
  if (arry.length) {
    let current = arry[0].step
    let maxIndex = 0
    arry.forEach((f, i) => {
      if (f.step > current) {
        current = f.step
        maxIndex = i
      }
    })
    return arry[maxIndex]
  }
  return undefined
}
const ConnectWalletModal: React.FC<ModalProps> = ({open, onClose}) => {
  const {t} = useTranslation()
  const [change, setChange] = useState(false)
  const {activate, account, chainId} = useWeb3React()
  const {data, targetOpen, changeTxHash} = useContext(TransactionContext)
  const {data: boringFarmData} = useBoringFarm()
  const {data: oportalFarmData} = useOportalFarm()
  const rtxs = useRouterTransactions()
  const routerTxs = useMemo(() => {
    const ret: HistoryInfo[] = rtxs.map((d: RouterStepInterface[]) => {
      const count = d.length
      const shouldCount = d[0].total_step
      return {
        txHash: d[0].origin_txid,
        message: `Transfer ${d[0].amount} ${d[0].token_symbol} from ${
          Chains[d[0].origin_chain_id ?? CHAIN_ETHER].displayName
        } to ${Chains[d[0].target_chain_id ?? CHAIN_ETHER].displayName}`,
        timestamp: new Date(d[0].block_time).getTime(),
        status: count === shouldCount ? 1 : 0,
        farmAction: false,
        chainId: d[0].origin_chain_id,
        bridgeType: BridgeType.router,
      }
    })
    return ret
  }, [rtxs])
  const accountLink = useMemo(() => `${Chains[chainId ?? CHAIN_ETHER].explorer}/address/${account}`, [account, chainId])
  const listHistroy = useMemo(() => {
    let list: HistoryInfo[] = []
    Object.keys(data).forEach((d) => {
      const txs = data[d]
      const firstStep = txs.filter((f) => f.step === 1)
      if (!firstStep) return null
      let m = ''
      let s = 0
      const lastStep = findMax(txs)
      if (lastStep && lastStep?.event?.indexOf('Failed') > -1) {
        s = -1
      } else if (
        lastStep?.bridgeType === BridgeType.oportal &&
        lastStep &&
        lastStep.event_chain_id === lastStep.to_chain_id &&
        lastStep.status === 1
      ) {
        s = 1
      } else if (lastStep?.bridgeType === BridgeType.native && lastStep && lastStep.status === 1) {
        s = 1
      }
      if (txs[0].bridgeType === BridgeType.oportal) {
        if (firstStep.some((s) => s.event.indexOf('Cross') > -1)) {
          const tT = getTwowayTokenByAddress(txs[0].from_token_address)
          const amount = formatNumber(new BigNumber(txs[0].amount).toFixed(4))
          m = `Transfer ${amount} ${tT?.symbol} From ${Chains[txs[0].from_chain_id].displayName} to ${
            Chains[txs[0].to_chain_id].displayName
          }`
        } else if (firstStep.some((s) => s.event.indexOf('Deposited') > -1)) {
          const tT = getTwowayTokenByAddress(txs[0].from_token_address)
          const amount = formatNumber(new BigNumber(txs[0].amount).toFixed(4))
          m = `Add Liquidity ${amount} ${tT?.symbol}`
        } else if (firstStep.some((s) => s.event.indexOf('Withdrawed') > -1)) {
          const oT = getOTokenByAddress(txs[0].from_token_address)
          const amount = formatNumber(new BigNumber(txs[0].amount).toFixed(4))
          m = `Remove Liquidity ${amount} ${oT?.symbol}`
        }
      } else if (txs[0].bridgeType === BridgeType.native) {
        const nT = getNativeTokenByAddress(txs[0].origin_token_address)
        const amount = formatNumber(new BigNumber(txs[0].amount).toFixed(4))
        m = `Transfer ${amount} ${nT?.symbol} from ${Chains[txs[0].from_chain_id].displayName} to ${
          Chains[txs[0].to_chain_id].displayName
        }`
      }
      if (txs[0].bridgeType === BridgeType.native) {
        list.push({
          txHash: d,
          message: m,
          status: s,
          farmAction: false,
          chainId: txs[0].event_chain_id,
          timestamp: new Date(txs[0].block_time).valueOf() / 1000,
        })
      }
    })
    return list.filter((f) => !!f.message)
  }, [data])
  const boringFarmHistory: HistoryInfo[] = useMemo(() => {
    if (boringFarmData.length) {
      return boringFarmData.map((d) => {
        return {
          txHash: d.id,
          message: `${d.optName} ${d.amount} ${d.symbol}`,
          status: 1,
          timestamp: Number(d.timestamp),
          farmAction: true,
          chainId: CHAIN_ETHER,
        }
      })
    }
    return []
  }, [boringFarmData])

  const oportalFarmHistory: HistoryInfo[] = useMemo(() => {
    if (oportalFarmData.length) {
      return oportalFarmData.map((d) => {
        return {
          txHash: d.id,
          message: `${d.optName} ${d.amount} ${d.symbol}`,
          status: 1,
          timestamp: Number(d.timestamp),
          farmAction: true,
          chainId: CHAIN_POLYGON,
        }
      })
    }
    return []
  }, [oportalFarmData])

  const actionHistory = useMemo(() => {
    return [...listHistroy, ...boringFarmHistory, ...oportalFarmHistory, ...routerTxs].sort(
      (a, b) => b.timestamp - a.timestamp
    )
  }, [listHistroy, boringFarmHistory, oportalFarmHistory, routerTxs])

  const walletAddress = useMemo(() => {
    return account && `${account.substring(0, 8)}...${account.substring(account.length - 8)}`
  }, [account])

  const handleConnectWallet = (d: any) => {
    switch (d.name) {
      case 'Metamask':
        activate(injectedConnector, (e) => console.error(e), true)
        break
      case 'WalletConnect':
        activate(walletConnect, (e) => console.error(e), true)
        break
      case 'Coinbase Wallet':
        activate(walletLink, (e) => console.error(e), true)
        break
    }
    activate(injectedConnector, (e) => console.error(e), true)
    setChange(false)
    onClose()
  }
  const handleView = (tx: string) => {
    changeTxHash && changeTxHash(tx)
    targetOpen && targetOpen()
    onClose()
  }

  const {showModal} = useModal()

  return (
    <Dialog
      sx={(theme) => ({
        '.MuiPaper-root': {
          padding: '30px',
          [theme.breakpoints.down('md')]: {
            padding: '0 20px',
            margin: '0 auto',
            width: 'calc(100% - 40px)',
          },
        },
      })}
      fullWidth
      open={open}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>
        {account ? (
          <Text fontFamily='Lexend' fontSize='18px'>
            {t('Account')}
          </Text>
        ) : (
          <Text fontSize='18px'>{t('Connect Wallet')}</Text>
        )}
        <Text sx={{cursor: 'pointer'}} onClick={onClose}>
          <CloseOutlinedIcon />
        </Text>
      </DialogTitle>
      {account && !change ? (
        <AccountBox>
          <Flex alignItems='center' justifyContent='space-between'>
            <Text color='#ffffff'>{t('Connected with MetaMask')}</Text>
            <ChangeButton onClick={() => setChange(true)}>{t('Change')}</ChangeButton>
          </Flex>
          <Account>{walletAddress}</Account>
          <Flex mt='20px'>
            <Copy name={t('Copy Address')} value={account} />
            <a target='_blank' href={accountLink} style={{marginLeft: '16px'}}>
              <Flex alignItems='center'>
                <Text mr='10px' fontSize='14px' color='#0068FF'>
                  {t('View on explorer')}
                </Text>
                <OpenInNewOutlinedIcon style={{color: '#0068FF'}} />
              </Flex>
            </a>
          </Flex>
          <HistoryList>
            <Text fontSize='14px' fontWeight='500'>
              {t('Recent Transactions')}
            </Text>
            {actionHistory && actionHistory.length ? (
              actionHistory.slice(0, 10).map((d, i) => (
                <Flex key={i} alignItems='center' justifyContent='space-between' pt='10px'>
                  <TxText>
                    {d.farmAction ? (
                      <a target='_blank' href={`${Chains[d.chainId].explorer}/tx/${d.txHash}`}>
                        <Text mr='10px' color='#ffffff99'>
                          {d.message} &nbsp;
                          <ShareIcon color='#ffffff99' />
                        </Text>
                      </a>
                    ) : (
                      <Text
                        mr='10px'
                        color='#ffffff99'
                        onClick={() => {
                          console.log(d.bridgeType)
                          if (d.bridgeType === BridgeType.router) {
                            showModal(RouterTransaction, {txHash: d.txHash})
                            return
                          }
                          handleView(d.txHash)
                        }}
                      >
                        {d.message} &nbsp;
                        {d.farmAction ? <ShareIcon color='#ffffff99' /> : <ViewIcon color='#0068FF' />}
                      </Text>
                    )}
                  </TxText>
                  {d.status === -1 && <ErrorIcon color='#FF0000' />}
                  {d.status === 0 && <LoadingIcon color='#FFFFFF' />}
                  {d.status === 1 && <CrossIcon color='#38C776' />}
                </Flex>
              ))
            ) : (
              <Text fontSize='14px' fontWeight='500' mt='8px' color='#ffffff'>
                {t('Your transactions will appear here…')}
              </Text>
            )}
          </HistoryList>
        </AccountBox>
      ) : (
        <WalletList>
          {WalletData.map((d) => (
            <WalletItem key={d.name} bgColor={d.bgColor} onClick={() => handleConnectWallet(d)}>
              <Flex alignItems='center'>
                {d.name === 'WalletConnect' ? (
                  <img width={56} height={40} src={d.icon} alt={d.name} />
                ) : (
                  <img width={56} height={56} src={d.icon} alt={d.name} />
                )}
                <Text fontSize='18px' sx={{ml: {xs: '10px', lg: '12px'}}}>
                  {d.name}
                </Text>
              </Flex>
              <Flex alignItems='center'>
                <img width={40} height={40} src={d.solidIcon} alt={d.name} />
                <ArrowForwardIosOutlinedIcon sx={{width: '12px', marginLeft: {xs: '12px', lg: '20px'}}} />
              </Flex>
            </WalletItem>
          ))}
        </WalletList>
      )}
    </Dialog>
  )
}

export default ConnectWalletModal
