import {
    CHAIN_ARBITRUM,
    CHAIN_AVALANCHE,
    CHAIN_BOBA,
    CHAIN_BSC,
    CHAIN_BSC_TESTNET,
    CHAIN_ETHER,
    CHAIN_ETHER_KOVAN,
    CHAIN_FANTOM,
    CHAIN_HARMONY_0,
    CHAIN_HECO,
    CHAIN_OK,
    CHAIN_OPTIMISM,
    CHAIN_POLYGON,
    CHAIN_XDAI,
  } from '@w3u/chains'
  
  import {ALL_CHAINS} from './native_bridge'
  
  export const PRODUCTION_CHEF_CHAINS = ALL_CHAINS
  
  export const CHEF_CHAINS = [...ALL_CHAINS]
  
  export const CHEF_ADDRESSES: {[chainID: number]: string} = {
    [CHAIN_ETHER_KOVAN]: '0x9F7Ccd966d0ea6858Ada8b383A44c2678B76c231',
    [CHAIN_BSC_TESTNET]: '0xD6cc9F28f869e535aB31E8607866a7EC0d7f4E85',
    // [CHAIN_ETHER]: '0x204c87CDA5DAAC87b2Fc562bFb5371a0B066229C',
    [CHAIN_ETHER]: '0xDBECe03cbB33160cA62107017fF45265343f5F10',
    [CHAIN_BSC]: '0xa056c100cEB4111899916Cf2d361152eE9147aDF',
    [CHAIN_OK]: '0xAd2F796199ff714AfE4f6716C50fF6dc431322C8',
    [CHAIN_POLYGON]: '0x9159b63bFD1566ab4c99Ee1Ff21575C86d2cEBB9',
    [CHAIN_XDAI]: '0x0Bf101ab5CC8C6Be93D9fb6742e1e4d724aFa414',
    [CHAIN_HECO]: '0x0Bf101ab5CC8C6Be93D9fb6742e1e4d724aFa414',
    [CHAIN_FANTOM]: '0x6D6D851bE4Ae838f32B0694a2561FDe3a18765e6',
    [CHAIN_AVALANCHE]: '0xAf9f062613dF193dCF4d4008ACC3bb9280c87323',
    [CHAIN_HARMONY_0]: '0x39c412fa81dAe9673A3E00e83B2F58a08DD8Fbc9',
    [CHAIN_ARBITRUM]: '0x216f332D17145871D1d5ff5fEB4b08513Ef7Cc21',
    [CHAIN_OPTIMISM]: '0x77B3aa18D89ea24e4AF7BB9BC221D49FD5534c19',
    [CHAIN_BOBA]: '0xB238a595e9797E009eFD5f938C9918181662066C',
  }
  
  export const OLD_ETH_CHEF_ADDRESS = '0x204c87CDA5DAAC87b2Fc562bFb5371a0B066229C'
  