import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const ViewIcon:React.FC<IconProps> = ({ color = "#ffffff", ...props }) => {
    return (
        <SvgIcon viewBox="0 0 10 10" {...props}>
            <g opacity="0.6">
                <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M10 2.9115V0.375C10 0.167893 9.83211 0 9.625 0H7.089C6.88189 -0.0001379 6.71389 0.167643 6.71375 0.37475C6.71361 0.581857 6.88139 0.749862 7.0885 0.75H8.7195L5.821 3.6485C5.75062 3.71883 5.71108 3.81425 5.71108 3.91375C5.71108 4.01325 5.75062 4.10867 5.821 4.179C5.89118 4.24947 5.98654 4.28909 6.086 4.28909C6.18546 4.28909 6.28082 4.24947 6.351 4.179L9.25 1.28V2.9115C9.25004 3.11858 9.41792 3.28644 9.625 3.28644C9.83208 3.28644 9.99996 3.11858 10 2.9115ZM0.75 8.7195L3.6495 5.8205C3.74361 5.72297 3.88301 5.68381 4.01414 5.71807C4.14527 5.75233 4.24771 5.85467 4.28209 5.98576C4.31647 6.11686 4.27744 6.2563 4.18 6.3505L1.2805 9.25H2.912C3.11908 9.25004 3.28694 9.41792 3.28694 9.625C3.28694 9.83208 3.11908 9.99996 2.912 10H0.375C0.167893 10 0 9.83211 0 9.625V7.088C-0.0001379 6.88089 0.167643 6.71289 0.37475 6.71275C0.581857 6.71261 0.749862 6.88039 0.75 7.0875V8.7195ZM1.2805 0.75H2.9125C3.04647 0.749911 3.17022 0.678354 3.23713 0.562284C3.30404 0.446214 3.30395 0.303264 3.23688 0.187284C3.16982 0.0713029 3.04597 -8.91453e-05 2.912 0H0.375C0.167893 0 0 0.167893 0 0.375V2.9115C3.5219e-05 3.11858 0.167918 3.28644 0.375 3.28644C0.582082 3.28644 0.749965 3.11858 0.75 2.9115V1.28L3.6495 4.179C3.71968 4.24947 3.81504 4.28909 3.9145 4.28909C4.01396 4.28909 4.10932 4.24947 4.1795 4.179C4.24988 4.10867 4.28942 4.01325 4.28942 3.91375C4.28942 3.81425 4.24988 3.71883 4.1795 3.6485L1.2805 0.75ZM9.25 7.088C9.25 6.88089 9.41789 6.713 9.625 6.713C9.83211 6.713 10 6.88089 10 7.088V9.625C10 9.83211 9.83211 10 9.625 10H7.089C6.95185 10.0051 6.82288 9.93488 6.75274 9.81691C6.6826 9.69895 6.6825 9.55209 6.75248 9.43404C6.82246 9.31598 6.95135 9.24558 7.0885 9.2505H8.7195L5.8205 6.351C5.67401 6.20451 5.67401 5.96699 5.8205 5.8205C5.96699 5.67401 6.20451 5.67401 6.351 5.8205L9.25 8.72V7.088Z" />
            </g>
        </SvgIcon>
    )
}

export default ViewIcon
