import React, { useState, useMemo, useEffect } from 'react'
import { Flex, Text, BoringInput, Center } from 'components/Style'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import styled from '@emotion/styled'
import useQuery from 'hooks/useQuery'
import { BackIcon } from 'components/Svg'

import { WidthButton } from './styles'

const StyleBox = styled(Box)`
    background: #00000066;
    padding: 30px;
    border-radius: 18px;
    min-height: 186px;
`

const StyledInput = styled(BoringInput)`
    border: 1px solid #0068ff66;
    height: 60px;
    padding: 0 12px;
`

const StepGrid = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 1.8fr 1.5fr 1.8fr 1.5fr 1.8fr 1.5fr;
`
const SepBox = styled(Flex)`
    position: relative;
    justify-content: center;
`
const NumberIcon = styled.div<{actived?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 24px;
    background: ${({ actived }) => actived ? '#0068FF' : '#ffffff66'};
`
const StepText = styled(Text)`
    position: absolute;
    top: calc(100% + 10px);
    white-space: nowrap;
`
const Line = styled.div`
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ffffff;
`
interface Props {
    txID: string
    oToken: string
    multiAddres: string
    onNext: (step: number) => void
}
const GuideQuery:React.FC<Props> = ({ txID, oToken, multiAddres, onNext }) => {
    const { t } = useTranslation()
    const [txId, setTxId] = useState(txID)
    const [query, setQuery]= useState(false)
    const token = useMemo(() => oToken.slice(1) ?? 'BTC', [oToken])
    const { confirmation, step, error, fetchCheck } = useQuery(token, multiAddres)

    const handleNext = (index: number) => {
        onNext && onNext(index)
    }
    const changeValue = (e: any) => setTxId(e.target.value)
    const handleQuery = () => {
        fetchCheck(txId)
        setQuery(true)
    }
    useEffect(() => {
        if (txId) { handleQuery() }
    }, [])

    return (
        <>
            {!query ?
                <StyleBox mb="30px">
                    <Flex flexDirection="column" alignItems="center" mb="30px">
                        <StyledInput value={txId} onChange={changeValue} fullWidth placeholder={t('enter_TXID')} />
                    </Flex>
                    <Flex justifyContent="center">
                        <WidthButton size='large' disabled={!txId} onClick={handleQuery}>{t('view_progress')}</WidthButton>
                    </Flex>
                </StyleBox> :
                <StyleBox mb="30px">
                    <Flex alignItems="center" style={{cursor: 'pointer'}} mb="30px" onClick={() => handleNext(2)}>
                        <BackIcon/>
                        <Text ml="7px">{t('back')}</Text>
                    </Flex>
                    <StepGrid>
                        <SepBox>
                            <NumberIcon actived={step >= 0}>1</NumberIcon>
                            <StepText>{t('confirmations(0/6)', {number:  confirmation >= 6 ? 6 : confirmation})}</StepText>
                        </SepBox>
                        <Center><Line /></Center>
                        <SepBox>
                            <NumberIcon actived={step >= 1}>2</NumberIcon>
                            <StepText>
                                {/* {t('Correct OP_RETURN')} */}
                                {error ? t('mint_step_7_2_wrong') : t('mint_step_7_2_right')}
                            </StepText>
                        </SepBox>
                        <Center><Line /></Center>
                        <SepBox>
                            <NumberIcon actived={step >= 2}>3</NumberIcon>
                            <StepText>
                                {/* {t('minting')} */}
                                {error ? t('mint_step_7_3_wrong', {type: token}) : t('mint_step_7_3_right')}
                            </StepText>
                        </SepBox>
                        <Center><Line /></Center>
                        <SepBox>
                            <NumberIcon actived={step >= 3}>4</NumberIcon>
                            <StepText>
                                {/* {t('minting_completed')} */}
                                {error ? t('mint_step_7_4_wrong') : t('mint_step_7_4_right')}
                            </StepText>
                        </SepBox>
                    </StepGrid>
                </StyleBox>
            }
            <StyleBox>
                <Text mb="10px">{t('mint_query_0')}</Text>
                <Box lineHeight="26px">
                    <Flex >
                        <Text>1、</Text>
                        <Text>
                            {t('mint_query_1_1')}
                            {t('mint_query_1_2')}
                        </Text>
                    </Flex>
                    <Flex>
                        <Text>2、</Text>
                        <Text>{t('mint_query_2')}</Text>
                    </Flex>
                    <Flex>
                        <Text>3、</Text>
                        <Text>{t('mint_query_3')}</Text>
                    </Flex>
                    <Flex>
                        <Text>4、</Text>
                        <Text>
                            {t('mint_query_4_1')}
                            {t('mint_query_4_2')}
                        </Text>
                    </Flex>
                </Box>
            </StyleBox>
        </>
        
    )
}

export default GuideQuery
