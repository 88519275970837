import React, {useEffect, useState, useContext} from 'react'
import { Box } from '@mui/material'
import { CHAIN_POLYGON, Chains} from '@w3u/chains'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { getScanTxLink } from 'utils'
import { getTwowaySteps } from 'utils/api'
import { TransactionContext } from 'contexts/TransactionContext'
import { TransactionAllData } from 'modal/types'
import { BridgeType } from 'modal/types'
import { CrossNoticeStatus, CrossNoticeEdge, Flex, Text } from 'components/Style'
import { ViewIcon } from 'components/Svg'
import useRefresh from 'hooks/useRefresh'
import Arrow from 'assets/images/arrow.svg'
import Right from 'assets/images/right.svg'
import Time from 'assets/images/time.svg'
import Wrong from 'assets/images/wrong.svg'

const ViewBox = styled.div`
  margin-left: 8px;
  > svg {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
`

const BridgeNotice = ({
  fromID,
  toID,
  txHash,
  amount,
  symbol,
  tokenAddress,
  receivedAmount,
}: {
  fromID: number
  toID: number
  txHash: string
  amount: string
  symbol: string
  tokenAddress: string
  receivedAmount: string
}) => {
  const { t } = useTranslation()
  const { targetOpen, changeTxHash, updateData } = useContext(TransactionContext)
  const [firstOpen, setFirstOpen] = useState(true)
  const [status, setStatus] = useState(0)
  const [secondStatus, setSecondStatus] = useState(0)
  const [secondHash, setSecondHash] = useState('')
  const [thirdHash, setThirdHash] = useState('')
  const { fastRefresh } = useRefresh()

  const handleViewDetail = () => {
    if (txHash) {
      changeTxHash && changeTxHash(txHash, true)
      targetOpen && targetOpen()
    }
  }

  const fetchSteps = async () => {
    const res = await getTwowaySteps({tx_id: txHash})
    const data = res.data
    if (txHash && data) {
     const events = data.events
      let history:any [] = []
      if (fromID === CHAIN_POLYGON || toID === CHAIN_POLYGON) {
        if (events.length === 0) {
          const step1 = {
            transaction: txHash,
            event: 'CrossIned',
            event_chain_id: fromID,
            event_chain_name: Chains[fromID].displayName,
            from: '',
            from_chain_id: fromID,
            from_chain_name: Chains[fromID].displayName,
            from_token_address: tokenAddress,
            to: '',
            to_chain_id: toID,
            to_chain_name: Chains[toID].displayName,
            to_token_address: '',
            amount,
            block_time: '',
            status: 0,
            tx_id: txHash,
            step: 1,
            first_step_transaction: txHash,
            bridgeType: BridgeType.oportal
          }
          const step2 = {
            ...step1,
            transaction: '',
            event: 'ForwardCrossOuted',
            event_chain_id: toID,
            event_chain_name: Chains[toID].displayName,
            tx_id: '',
            step: 2,
          }
          history =[step1, step2]
        } else if (events.length === 1) {
          const step1 = { ...events[0], bridgeType: BridgeType.oportal }
          const step2 = {
            ...events[0],
            event: 'ForwardCrossOuted',
            event_chain_id: toID,
            event_chain_name: Chains[toID].displayName,
            status: 0,
            step: 2,
            transaction: '',
            bridgeType: BridgeType.oportal
          }
          history = [step1, step2]
        } else if (events.length === 2) {
          const step1 = { ...events[0], bridgeType: BridgeType.oportal }
          const step2 = { ...events[1], bridgeType: BridgeType.oportal }
          history = [step1, step2]
        }
      } else {
        if (events.length === 0) {
          const step1 = {
            transaction: txHash,
            event: 'CrossOut',
            event_chain_id: fromID,
            event_chain_name: Chains[fromID].displayName,
            from: '',
            from_chain_id: fromID,
            from_chain_name: Chains[fromID].displayName,
            from_token_address: tokenAddress,
            to: '',
            to_chain_id: toID,
            to_chain_name: Chains[toID].displayName,
            to_token_address: '',
            amount,
            block_time: '',
            status: 0,
            tx_id: txHash,
            step: 1,
            first_step_transaction: txHash,
            bridgeType: BridgeType.oportal
          }
          const step2 = {
            ...step1,
            transaction: '',
            event: 'ForwardCrossOuted',
            event_chain_id: CHAIN_POLYGON,
            event_chain_name: Chains[CHAIN_POLYGON].displayName,
            tx_id: '',
            step: 2,
          }
          const step3 = {
            ...step1,
            transaction: '',
            event: 'CrossIned',
            event_chain_id: toID,
            event_chain_name: Chains[toID].displayName,
            tx_id: '',
            step: 3,
          }
          history = [step1, step2, step3]
        } else if (events.length === 1) {
          const step2 = {
            ...events[0],
            transaction: '',
            event: 'ForwardCrossOuted',
            event_chain_id: CHAIN_POLYGON,
            event_chain_name: Chains[CHAIN_POLYGON].displayName,
            status: 0,
            step: 2,
            bridgeType: BridgeType.oportal
          }
          const step3 = {
            ...events[1],
            transaction: '',
            event: 'CrossIned',
            event_chain_id: toID,
            event_chain_name: Chains[toID].displayName,
            status: 0,
            step: 3,
            bridgeType: BridgeType.oportal
          }
          history = [{...events[0], bridgeType: BridgeType.oportal}, step2, step3]
        } else if (events.length === 2) {
          const step1 = { ...events[0], bridgeType: BridgeType.oportal }
          const step2 = { ...events[1], bridgeType: BridgeType.oportal }
          const step3 = {
            ...events[1],
            transaction: '',
            event: 'CrossIned',
            event_chain_id: toID,
            event_chain_name: Chains[toID].displayName,
            status: 0,
            step: 3,
            bridgeType: BridgeType.oportal
          }
          history = [step1, step2, step3]
        } else if (events.length === 3) {
          const step1 = { ...events[0], bridgeType: BridgeType.oportal }
          const step2 = { ...events[1], bridgeType: BridgeType.oportal }
          const step3 = { ...events[2], bridgeType: BridgeType.oportal }
          history = [step1, step2, step3]
        }
      }
      const tsd: TransactionAllData = {
        [txHash]: history
      }
      setFirstOpen(false)
      updateData && updateData(tsd)
      if (firstOpen) {
        changeTxHash && changeTxHash(txHash, true)
        targetOpen && targetOpen()
      }
    }
    if (fromID === CHAIN_POLYGON || toID === CHAIN_POLYGON) {
      if (data.total_steps !== 0 && data.events.length === data.total_steps) {
        setStatus(data.events[0].status)
        setSecondHash(data.events[data.total_steps - 1].transaction)
      }
    } else {
      if (data.total_steps !== 0 && data.events.length === 2) {
        setStatus(data.events[0].status)
        setSecondHash(data.events[1].transaction)
      }
      if (data.total_steps !== 0 && data.events.length === 3) {
        setStatus(data.events[0].status)
        setSecondStatus(data.events[1].status)
        setSecondHash(data.events[1].transaction)
        setThirdHash(data.events[2].transaction)
      }
    }
  }

  useEffect(() => {
    if (txHash) {
      fetchSteps()
    }
  }, [fastRefresh, txHash])

  return (
    <>
      <Box sx={{display: 'flex'}}>
        {fromID === CHAIN_POLYGON || toID === CHAIN_POLYGON ? (
          <>
            <CrossNoticeEdge
              chainID={fromID}
              amount={amount}
              symbol={`${symbol}`}
              href={getScanTxLink(fromID, txHash)}
              desc={`From ${Chains[fromID].displayName}`}
            />
            <CrossNoticeStatus mx={2} width={46}>
              {status === 0 && <img src={Time} alt='Loading' />}
              {status === 1 && <img src={Right} alt='Done' />}
              {status === 2 && <img src={Wrong} alt='Wrong' />}
              <img src={Arrow} alt='arrow' />
            </CrossNoticeStatus>
            <CrossNoticeEdge
              chainID={toID}
              amount={receivedAmount}
              symbol={`${symbol}`}
              href={getScanTxLink(toID, secondHash)}
              desc={`To ${Chains[toID].displayName}`}
            />
          </>
        ) : (
          <>
            <CrossNoticeEdge
              chainID={fromID}
              amount={amount}
              symbol={symbol}
              // href={getScanTxLink(fromID, txHash)}
              desc={`From ${Chains[fromID].displayName}`}
            />
            <CrossNoticeStatus mx={2} width={46}>
              {status === 0 && <img src={Time} alt='time' />}
              {status === 1 && <img src={Right} alt='time' />}
              {status === 2 && <img src={Wrong} alt='time' />}
              <img src={Arrow} alt='arrow' />
            </CrossNoticeStatus>
            <CrossNoticeEdge
              chainID={CHAIN_POLYGON}
              amount={receivedAmount}
              symbol={`o${symbol}`}
              // href={getScanTxLink(CHAIN_POLYGON, secondHash)}
              desc={'On Polygon'}
            />
            <CrossNoticeStatus mx={2} width={46}>
              {secondStatus === 0 && <img src={Time} alt='time' />}
              {secondStatus === 1 && <img src={Right} alt='time' />}
              {secondStatus === 2 && <img src={Wrong} alt='time' />}
              <img src={Arrow} alt='arrow' />
            </CrossNoticeStatus>
            <CrossNoticeEdge
              chainID={toID}
              amount={receivedAmount}
              symbol={`${symbol}`}
              // href={getScanTxLink(toID, thirdHash)}
              desc={`To ${Chains[toID].displayName}`}
            />
          </>
        )}
      </Box>
      <Flex alignItems="center" mt="8px" onClick={handleViewDetail}>
        <Text color="#0068FF">{t('View detail')}</Text>
        <ViewBox><ViewIcon color="#0068FF" /></ViewBox>
      </Flex>
    </>
  )
}

export default BridgeNotice
