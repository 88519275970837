import {useEffect, useMemo, useState} from 'react'
import {useSearchParams, useNavigate} from 'react-router-dom'
import {useWeb3React} from '@web3-react/core'
import {CHAIN_ETHER} from '@w3u/chains'
import {ethers} from 'ethers'
import {Typography, Box, Skeleton, Button} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {Flex, Text, BoringInput} from 'components/Style'
import Mybutton, {OutlineButton} from 'components/Button'
import {getIcon} from 'utils/icons'
import {toast} from 'react-toastify'
import ChainSwitcher from 'components/ChainSwitcher'
import TokenSwitcher from 'components/TokenSwitcher'
import ConnectWallet from 'components/ConnectWallet'
import FreeTooltips from 'components/Tooltip'
import NativeBridgeNotice from 'components/Notice/NativeBridgeNotice'
import BridgeNotice from 'components/Notice/BridgeNotice'
import {DownarrowIcon} from 'components/Svg'
import AlertModal from 'components/Modal/Alert'
import {NativeBridgeToken, TwowayOToken} from 'modal/token'
import {getTwowayAddress, getNativeBridgeAddress} from 'utils/addressHelpers'
import {getAllSupportChains, formatNumber, get2WayTokenFixed} from 'utils'
import {displayBalance, getFullDisplayBalanceString} from 'utils/formatBalance'
import {TWOWAY_TOKENS} from 'config/constants/twoway'
import {NATIVE_BRIDGE_TOKENS} from 'config/constants/native_bridge'
import BigNumber from 'bignumber.js'
import {useTokensBalance} from 'hooks/useTokenBalance'
import {useApprove} from 'hooks/useApprove'
import {
  useCommonTwowayTokens,
  useTwowayLockBalance,
  useTwowayCalculateFee,
  useTwowayFixFees,
  useCrossOut,
} from 'hooks/useTwoway'
import {BIG_TEN} from 'utils/bigNumber'
// Native Bridge
import {
  useNativeLock,
  useCommonNativeBridgeTokens,
  useNativeCalculateFee,
  userNativeFixFee,
} from 'hooks/useNativeBridge'
import {BridgeType, BridgeStatus} from 'modal/types'
import {BurnWrapper, BurnBox, BoringInfo} from './style'

const Twoway = () => {
  const {t} = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const tokenByUrl = searchParams.get('token')
  const fromChainIdByUrl = searchParams.get('from_chain_id')
  const toChainIdByUrl = searchParams.get('to_chain_id')
  const [alertOpen, setAlertOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [toAddress, setToAddress] = useState('')
  // const [bridgeStatus, setBridgeStatus] = useState(BridgeStatus.support)
  const [showAddress, setShowAddress] = useState(false)
  const {account, chainId} = useWeb3React()
  const [amount, setAmount] = useState('')
  const [bridgeType, setBridgeType] = useState<BridgeType>(BridgeType.oportal)
  // const onlySupportNativeChains = useMemo(() => getOnlySupportNativeChains(), [])
  const fromChainIDs = useMemo(() => getAllSupportChains(), [])
  const _chainId = useMemo(
    () => (fromChainIDs.some((s) => s === chainId) ? chainId || CHAIN_ETHER : CHAIN_ETHER),
    [chainId]
  )
  useEffect(() => setAlertOpen(!fromChainIDs.some((s) => s === chainId)), [chainId, fromChainIDs])
  // useEffect(() => {
  //     const bridgeStatus = onlySupportNativeChains.some(s => s === _chainId) ? BridgeStatus.notsupport : BridgeStatus.support
  //     setBridgeStatus(bridgeStatus)
  // }, [onlySupportNativeChains, _chainId])
  const {onCrossOut} = useCrossOut()
  const {onNativeLock} = useNativeLock()

  const twowayAddress = getTwowayAddress(_chainId)
  const nativeBridgeAddress = getNativeBridgeAddress(_chainId)
  const toChainIDs = useMemo(() => {
    const tTokens = TWOWAY_TOKENS.filter((f) => f.chainID === _chainId)
    const nTokens = NATIVE_BRIDGE_TOKENS.filter((f) => f.chainID === _chainId)
    const ts = TWOWAY_TOKENS.filter((f) => tTokens.some((s) => s.symbol === f.symbol)).map((d) => d.chainID)
    const ns = NATIVE_BRIDGE_TOKENS.filter((f) => nTokens.some((s) => s.symbol === f.symbol)).map((d) => d.chainID)
    const allToChains = [...ts, ...ns]
    return allToChains.filter((f, i) => allToChains.indexOf(f) === i).filter((f) => f !== _chainId)
  }, [_chainId])
  const [toID, setToID] = useState(toChainIdByUrl ? Number(toChainIdByUrl) : toChainIDs[0])
  const [fromChainID, setFromChainID] = useState(fromChainIdByUrl ? Number(fromChainIdByUrl) : _chainId)
  const nativeTokens = useCommonNativeBridgeTokens(fromChainID, toID)
  const twowayTokens = useCommonTwowayTokens(fromChainID, toID)
  // const defaultTokens =  useCommonTwowayTokens(CHAIN_ETHER, toID)
  const tokens = useMemo(() => {
    return [...twowayTokens, ...nativeTokens]
  }, [twowayTokens, nativeTokens])
  const defaultToken =
    tokens.find((f) => f.address.toLocaleLowerCase() === tokenByUrl?.toLocaleLowerCase()) ?? tokens[0]
  const [token, setToken] = useState<NativeBridgeToken | TwowayOToken>(defaultToken)
  const {
    balances,
    loading: balanceLoading,
    fetchBalances,
  } = useTokensBalance(tokens.map((d) => ({address: d.address, symbol: d.symbol})))
  // Twoway
  const tokenAddress = useMemo(() => token?.address, [token])
  const {
    allowance,
    loading: approveLoading,
    onApprove,
  } = useApprove(tokenAddress, twowayAddress, _chainId, BridgeType.oportal, bridgeType)
  const {lockBalance, loading: lcokLoading, fetchLockLiquidity} = useTwowayLockBalance(token.symbol, toID)
  const {fixFee} = useTwowayFixFees(fromChainID, toID, token)
  const {
    fee,
    calcFee,
    ratioFees,
    remainAmount,
    loading: feeLoading,
  } = useTwowayCalculateFee(token, account, toID, amount, lockBalance, bridgeType, fixFee)
  // Native Bridge
  const {allowance: nativeAllowance, onApprove: onApproveNative} = useApprove(
    tokenAddress,
    nativeBridgeAddress,
    _chainId,
    BridgeType.native,
    bridgeType
  )
  const {nativeFixFee} = userNativeFixFee(tokenAddress, toID.toString(), bridgeType)
  const {nativeFee} = useNativeCalculateFee(tokenAddress, toID.toString(), amount, token, bridgeType, nativeFixFee)

  const balance = useMemo(() => {
    const b = balances.find((f) => f.address.toLocaleLowerCase() === tokenAddress.toLocaleLowerCase())
    return b ? b.balance : '0'
  }, [token, balances])
  const maxInput = useMemo(
    () =>
      BigNumber.minimum(
        new BigNumber(balance).div(BIG_TEN.pow(token.decimals)),
        new BigNumber(lockBalance).div(BIG_TEN.pow(18))
      ).toString(),
    [balance, lockBalance, token]
  )

  const amountError = useMemo(() => {
    if (amount === '') return
    const balanceN = new BigNumber(balance).div(BIG_TEN.pow(token.decimals))
    const amountN = new BigNumber(amount)
    const fixFeeStr =
      bridgeType === BridgeType.oportal
        ? new BigNumber(fixFee).div(BIG_TEN.pow(18)).toFixed()
        : new BigNumber(nativeFixFee).div(BIG_TEN.pow(token.decimals)).toFixed()
    if (balanceN.lte(0)) {
      return t('balance_is_0')
    }
    if (amountN.gt(balanceN)) {
      return t('balance_is_insufficient')
    }
    if (amountN.gt(maxInput) && bridgeType === BridgeType.oportal) {
      return t('cross_maximum_limit_notice', {amount: `${formatNumber(maxInput)} ${token.symbol}`})
    }
    // // TDOO Modal
    // if (amountN.gt(new BigNumber(lockBalance).multipliedBy(0.8))) {
    //     return t('twoway_cross_limit_notice')
    // }
    if (amountN.lt(fixFeeStr)) {
      return t('cross_minimum_limit_notice', {amount: `${fixFeeStr} ${token.symbol}`})
    }
    return null
  }, [balance, amount, token, nativeFixFee, bridgeType, toID, remainAmount])

  // change chain update token & balance & approve
  useEffect(() => {
    if (tokens.some((s) => s.address.toLocaleLowerCase() === token.address.toLocaleLowerCase())) return
    const changeToken =
      tokens.find((f) => f.address.toLocaleLowerCase() === tokenByUrl?.toLocaleLowerCase()) ?? tokens[0]
    setToken(changeToken)
  }, [chainId, tokens, tokenByUrl])

  useEffect(() => {
    if (token) {
      const tTs = twowayTokens.find(
        (f) =>
          f.address.toLocaleLowerCase() === token.address.toLocaleLowerCase() ||
          (tokenByUrl && f.address.toLocaleLowerCase() === tokenByUrl.toLocaleLowerCase())
      )
      setBridgeType(tTs ? BridgeType.oportal : BridgeType.native)
    }
  }, [token, tokenByUrl])

  useEffect(() => {
    if (tokenByUrl && fromChainIdByUrl && toChainIdByUrl && token) {
      const link = `/twoway?token=${token.address}&from_chain_id=${fromChainID}&to_chain_id=${toID}`
      navigate(link)
    }
  }, [token, fromChainID, toID])
  useEffect(() => {
    setAmount('')
    setToAddress('')
  }, [chainId])

  const handleTokenChange = (address: string) => {
    const nTs = nativeTokens.find((f) => f.address.toLocaleLowerCase() == address.toLocaleLowerCase())
    const tTs = twowayTokens.find((f) => f.address.toLocaleLowerCase() === address.toLocaleLowerCase())
    setToken(tTs ? tTs : nTs ?? twowayTokens[0])
    setBridgeType(tTs ? BridgeType.oportal : BridgeType.native)
  }
  const handleMax = () => {
    setAmount(getFullDisplayBalanceString(balance, token.decimals, get2WayTokenFixed(token?.symbol)))
  }
  const handleApprove = async () => {
    if (bridgeType === BridgeType.oportal) {
      setLoading(true)
      const tx = await onApprove()
      setLoading(false)
    } else if (bridgeType === BridgeType.native) {
      setLoading(true)
      await onApproveNative()
      setLoading(false)
    }
  }
  const handleConfirm = async () => {
    try {
      if (bridgeType === BridgeType.oportal && account) {
        setLoading(true)
        const toAccount = ethers.utils.isAddress(toAddress) ? toAddress : account
        const tx = await onCrossOut(token, toID, amount, toAccount)
        toast(
          <BridgeNotice
            fromID={fromChainID}
            toID={toID}
            txHash={tx.hash}
            amount={amount}
            tokenAddress={token.address}
            symbol={token?.symbol || 'USDT'}
            receivedAmount={displayBalance(remainAmount, 18, get2WayTokenFixed(token?.symbol))}
          />
        )
        setAmount('')
        setToAddress('')
        setLoading(false)
        fetchBalances()
        fetchLockLiquidity()
      } else if (bridgeType == BridgeType.native && account) {
        setLoading(true)
        const toAccount = ethers.utils.isAddress(toAddress) ? toAddress : account
        const tx = await onNativeLock(token, toAccount, toID, amount)
        toast(
          <NativeBridgeNotice
            fromID={fromChainID}
            toID={toID}
            txHash={tx.hash}
            amount={amount}
            tokenAddress={token.address}
            symbol={token?.symbol || 'BORING'}
            receivedAmount={displayBalance(nativeFee[1], 18, get2WayTokenFixed(token?.symbol))}
          />
        )
        setAmount('')
        setToAddress('')
        setLoading(false)
        fetchBalances()
        fetchLockLiquidity()
      }
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Flex flexDirection='column' alignItems='center'>
      <BurnWrapper>
        <Typography mb='19px' fontSize='18px'>
          {t('bridge')}
        </Typography>
        <Flex alignItems='center' mb='18px'>
          <Text minWidth='40px'>{t('from')}</Text>
          <ChainSwitcher
            border
            chainIDs={fromChainIDs}
            changeNetwork
            selectCurrentChain
            defaultChain={Number(fromChainIdByUrl)}
            onChange={(id: number) => setFromChainID(id)}
          />
        </Flex>
        <BurnBox>
          <Flex mb='10px' justifyContent='space-between'>
            <Text fontSize='12px'>{t('send')}</Text>
            {balanceLoading ? (
              <Skeleton width={80} height={15} />
            ) : (
              <Text>
                {t('balance')}: {displayBalance(balance, token.decimals, get2WayTokenFixed(token.symbol))}
              </Text>
            )}
          </Flex>
          <Flex justifyContent='space-between' alignItems='center'>
            <Flex>
              <TokenSwitcher
                defaultTokenAddress={tokenAddress}
                border={false}
                tokens={tokens}
                onChange={handleTokenChange}
              />
              <BoringInput value={amount} onChange={(e) => setAmount(e.target.value)} />
            </Flex>
            <OutlineButton style={{height: '26px'}} onClick={handleMax}>
              {t('MAX')}
            </OutlineButton>
          </Flex>
        </BurnBox>
        <Flex justifyContent='center' m='15px 0'>
          <DownarrowIcon />
        </Flex>
        <Flex alignItems='center' justifyContent='space-between' mb='15px'>
          <Flex alignItems='center'>
            <Text minWidth='40px'>{t('to')}</Text>
            <ChainSwitcher border defaultChain={toID} chainIDs={toChainIDs} onChange={(id: number) => setToID(id)} />
          </Flex>
          <Text onClick={() => setShowAddress(!showAddress)} style={{cursor: 'pointer'}}>
            {showAddress ? '-' : '+'}&nbsp;&nbsp;{t('send_to')}
          </Text>
        </Flex>
        {showAddress && (
          <BurnBox mb='15px'>
            <Text mb='10px' fontSize='12px'>
              {t('address')}
            </Text>
            <Flex justifyContent='space-between'>
              <BoringInput
                defaultValue={account}
                value={toAddress}
                onChange={(e) => setToAddress(e.target.value)}
                style={{flex: 'auto'}}
              />
              <OutlineButton style={{height: '26px'}} onClick={() => setToAddress(account ?? '')}>
                {t('default')}
              </OutlineButton>
            </Flex>
          </BurnBox>
        )}
        <BurnBox mb='15px' noBorder>
          <Text mb='18px' fontSize='12px'>
            {t('estimated_received')}
          </Text>
          <Flex alignItems='center'>
            <img src={getIcon(token.symbol)} width='20px' height='20px' alt={token.symbol} />
            <Text m='0 10px' fontSize='12px'>
              {token.symbol}
            </Text>
            {feeLoading ? (
              <Skeleton width={60} height={15} />
            ) : bridgeType === BridgeType.oportal ? (
              <Text>{displayBalance(remainAmount, 18, get2WayTokenFixed(token.symbol))}</Text>
            ) : (
              <Text>{displayBalance(nativeFee[1], 18, get2WayTokenFixed(token.symbol))}</Text>
            )}
          </Flex>
        </BurnBox>
        <Flex justifyContent='center'>
          <Box sx={{width: {md: '60%', xs: '100%'}}}>
            {account ? (
              (bridgeType === BridgeType.oportal && allowance) ||
              (bridgeType === BridgeType.native && nativeAllowance) ? (
                <Mybutton
                  fullWidth
                  loading={feeLoading || loading}
                  disabled={new BigNumber(amount).lte(0) || !amount || !!amountError}
                  onClick={handleConfirm}
                >
                  {!amountError ? (
                    t('confirm')
                  ) : (
                    <Text fontSize='18px' color='#ff0000'>
                      {amountError}
                    </Text>
                  )}
                </Mybutton>
              ) : (
                <Mybutton loading={loading} fullWidth onClick={handleApprove}>
                  {t('approve')}
                </Mybutton>
              )
            ) : (
              <ConnectWallet />
            )}
          </Box>
        </Flex>
      </BurnWrapper>
      <BoringInfo>
        <Flex justifyContent='space-between'>
          <Flex>
            <Text mr='12px'>{t('fee')}</Text>
            {bridgeType === BridgeType.oportal && (
              <FreeTooltips fixFee={fixFee} calcFee={calcFee} token={token} ratioFees={ratioFees} />
            )}
          </Flex>
          {bridgeType === BridgeType.oportal ? (
            <Text>
              {displayBalance(fee, 18, get2WayTokenFixed(token.symbol))} {token.symbol}
            </Text>
          ) : (
            <Text>
              {displayBalance(nativeFee[0], 18, get2WayTokenFixed(token.symbol))} {token.symbol}
            </Text>
          )}
        </Flex>
        {bridgeType === BridgeType.oportal && (
          <Flex mt='10px' justifyContent='space-between' alignItems='center'>
            <Text>{t('total_liquidity')}</Text>
            {lcokLoading ? (
              <Skeleton width={80} height={20} />
            ) : (
              <Link to='/liquidity'>
                <Text color='#0068FF'>
                  {displayBalance(lockBalance)} {token.symbol}
                </Text>
              </Link>
            )}
          </Flex>
        )}
      </BoringInfo>
      <AlertModal
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        title={t('Wrong Network')}
        context={t('Please switch to support network')}
      />
    </Flex>
  )
}

export default Twoway
