import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Flex } from 'components/Style'

export const BurnWrapper = styled.div`
    background: #00000066;
    padding: 30px;
    border-radius: 20px;
    width: 580px;
    @media screen and (max-width: 600px) {
        width: 100%;
        padding: 20px 15px;
    }
`

export const BurnBox = styled(Box)<{noBorder?: boolean}>`
    background: #00000066;  
    border-radius: 12px;
    border: ${({ noBorder }) => noBorder ? '0' : '1px'} solid #0A214D;
    padding: 17px;
    width: 100%;
`

export const BoringInfo = styled(Flex)`
    flex-direction: column;
    background: #0000008c;
    border-radius: 0 0 30px 30px;
    padding: 30px 20px;
    width: 520px;
    @media screen and (max-width: 600px) {
        width: 90%;
        padding: 15px;
    }
` 