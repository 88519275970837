import {createStyles, InputBase, MenuItem, Select, useMediaQuery, useTheme} from '@mui/material'
import {withStyles} from '@material-ui/styles'
import i18n from 'i18next'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import LanguageIcon from '@mui/icons-material/Language'
import { Text } from 'components/Style'

export const BoringInput = withStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '6px',
      color: '#FFFFFF',
      // paddingLeft: '12px 0',
      '& svg': {
        color: '#FFFFFF',
      }
    },
  })
)(InputBase)

const LanguageSelect = (): JSX.Element => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Select
      autoWidth
      IconComponent={ExpandMoreOutlinedIcon}
      MenuProps={{
        style: {
          marginBottom: '20px',
        },
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        elevation: 1,
      }}
      onChange={(e) => {
        return i18n.changeLanguage(e.target.value as string)
      }}
      value={i18n.language ?? 'en-US'}
      input={<BoringInput />}
    >
      <MenuItem value={'zh-CN'}>
        <LanguageIcon sx={{width: '20px', height: '20px'}} />
        <Text m='0 8px'>{isMobile ? 'CN' : '简体中文'}</Text>
      </MenuItem>
      <MenuItem value={'en-US'}>
        <LanguageIcon sx={{width: '20px', height: '20px'}} />
        <Text m='0 8px'>{isMobile ? 'EN' : 'English'}</Text>
      </MenuItem>
    </Select>
  )
}

export default LanguageSelect
