import {
    CHAIN_ARBITRUM,
    CHAIN_ARBITRUM_TESTNET,
    CHAIN_AURORA,
    CHAIN_AURORA_TESTNET,
    CHAIN_AVALANCHE,
    CHAIN_AVALANCHE_TESTNET,
    CHAIN_BOBA,
    CHAIN_BSC,
    CHAIN_BSC_TESTNET,
    CHAIN_ETHER,
    CHAIN_ETHER_GOERLI,
    CHAIN_ETHER_KOVAN,
    CHAIN_ETHER_RINKEBY,
    CHAIN_ETHER_ROPSTEN,
    CHAIN_FANTOM,
    CHAIN_FANTOM_TESTNET,
    CHAIN_HARMONY_0,
    CHAIN_HARMONY_0_TESTNET,
    CHAIN_HECO,
    CHAIN_HECO_TESTNET,
    CHAIN_METIS,
    CHAIN_OK,
    CHAIN_OK_TESTNET,
    CHAIN_OPTIMISM,
    CHAIN_POLYGON,
    CHAIN_POLYGON_MUMBAI,
    CHAIN_XDAI,
    CHAIN_XDAI_TESTNET,
} from '@w3u/chains'

export const TOKEN_BORING: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0xBC19712FEB3a26080eBf6f2F7849b417FdD792CA',
    [CHAIN_ETHER_KOVAN]: '0x55FB3193F6e42D6753691c8aA38800B2753FB7d0',
    [CHAIN_BSC]: '0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F',
    [CHAIN_BSC_TESTNET]: '0xd5fb474DCd065d243C37F52Ad3815e0d8bc906ED'
}

export const TOKEN_BOR: {[chainID: number]: string} = {
    [CHAIN_ETHER]: '0x3c9d6c1C73b31c837832c72E04D3152f051fc1A9',
    [CHAIN_ETHER_ROPSTEN]: '0xC877Ec4f22317A30BA04A17796d4497490A76e22',
    [CHAIN_BSC]: '0x92D7756c60dcfD4c689290E8A9F4d263b3b32241',
    [CHAIN_BSC_TESTNET]: '0x4261bb282EB6bb678A693B4684DBdD07a0d3D245',
    [CHAIN_HECO_TESTNET]: '0xbf49c0ffDEEC5bF1731674841B60E4B0855FE6ED',
    [CHAIN_ETHER_KOVAN]: '0x257c512f2532dE45C1B88c9E6ceE12d3bF943596',
    [CHAIN_OK_TESTNET]: '0x4ee9Ef559D9219c54b0Bc06f3B0aB17a52ef936e',
    [CHAIN_POLYGON]: '0x7d8c139d5bfBafdfD1D2D0284e7fC862babE4137',
    [CHAIN_POLYGON_MUMBAI]: '0xbf49c0ffDEEC5bF1731674841B60E4B0855FE6ED'
}

export const BoringTunnel_BTC: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x6ADff7bedD9D7BF4794421D078CCED17AA4EC39c'
}
export const BoringTunnel_LTC: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x457EacbD56eB29f8Ec75c22fCC549e6b2D8cCde9'
}
export const BoringTunnel_DOGE: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0xd0c4b4fBF2a076A9d936F34809E445eBe926Fbee'
}

export const BoringTunnel_TOKEN = (token: string) => {
    let tokens = BoringTunnel_BTC
    switch (token) {
        case 'BTC':
            tokens = BoringTunnel_BTC
            break;
        case 'LTC':
            tokens = BoringTunnel_LTC
            break;
        case 'DOGE':
            tokens = BoringTunnel_DOGE
            break;
        default:
            tokens = BoringTunnel_BTC
    }
    return tokens
}

export const BoringFeePool_BTC: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x2b781634e4cb0b5236cC957DABA88F911FD66fCD'
}
export const BoringFeePool_LTC: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0xC23F4d5AC44A7d8e56c70741e6205dAF3F10f998'
}
export const BoringFeePool_DOGE: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x0e2d371F2A9925219d4e936e8F227F0Ab9D4dBDe'
}

export const BoringFeePool_TOKEN = (token: string) => {
    let tokens = BoringFeePool_BTC
    switch (token) {
        case 'BTC':
            tokens = BoringFeePool_BTC
            break;
        case 'LTC':
            tokens = BoringFeePool_LTC
            break;
        case 'DOGE':
            tokens = BoringFeePool_DOGE
            break;
        default:
            tokens = BoringFeePool_BTC
    }
    return tokens
}

export const BoringBoringDAO_BTC: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x77F79FEa3d135847098Adb1fdc6B10A0218823F5'
}
export const BoringBoringDAO_LTC: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x77F79FEa3d135847098Adb1fdc6B10A0218823F5'
}
export const BoringBoringDAO_DOGE: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x77F79FEa3d135847098Adb1fdc6B10A0218823F5'
}
export const BoringBoringDAO_TOKEN = (token: string) => {
    let tokens = BoringBoringDAO_BTC
    switch (token) {
        case 'BTC':
            tokens = BoringBoringDAO_BTC
            break;
        case 'LTC':
            tokens = BoringBoringDAO_LTC
            break;
        case 'DOGE':
            tokens = BoringBoringDAO_DOGE
            break;
        default:
            tokens = BoringBoringDAO_BTC
    }
    return tokens
}