import {BscConnector} from '@binance-chain/bsc-connector'
import {
  CHAIN_ARBITRUM,
  CHAIN_ARBITRUM_TESTNET,
  CHAIN_AURORA,
  CHAIN_AURORA_TESTNET,
  CHAIN_BOBA,
  CHAIN_FANTOM,
  CHAIN_FANTOM_TESTNET,
  CHAIN_HARMONY_0,
  CHAIN_HARMONY_0_TESTNET,
  CHAIN_HECO,
  CHAIN_METIS,
  CHAIN_OPTIMISM,
  CHAIN_POLYGON,
  CHAIN_POLYGON_MUMBAI,
  CHAIN_XDAI,
  CHAIN_XDAI_TESTNET,
  CHAIN_KCC,
  CHAIN_IOTEX,
  CHAIN_ETHER,
  CHAIN_BLAST,
} from '@w3u/chains'
import {getUrlProvider} from 'utils/providers'
import {InjectedConnector} from '@web3-react/injected-connector'
import {WalletConnectConnector} from '@web3-react/walletconnect-connector'
import {WalletLinkConnector} from '@web3-react/walletlink-connector'

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [
    1,
    3,
    4,
    5,
    42,
    56,
    97,
    256,
    65,
    66,
    43113,
    43114,
    CHAIN_HARMONY_0,
    CHAIN_HARMONY_0_TESTNET,
    CHAIN_HECO,
    CHAIN_POLYGON,
    CHAIN_POLYGON_MUMBAI,
    CHAIN_XDAI_TESTNET,
    CHAIN_XDAI,
    CHAIN_FANTOM,
    CHAIN_FANTOM_TESTNET,
    CHAIN_ARBITRUM,
    CHAIN_ARBITRUM_TESTNET,
    CHAIN_OPTIMISM,
    CHAIN_BOBA,
    CHAIN_METIS,
    CHAIN_AURORA,
    CHAIN_AURORA_TESTNET,
    CHAIN_KCC,
    CHAIN_IOTEX,
    CHAIN_BLAST,
  ],
})

export const bscConnector = new BscConnector({supportedChainIds: [56, 97]})

// MainNet only
// export const walletConnect = new WalletConnectConnector({
//   rpc: {
//     1: 'https://mainnet.infura.io/v3/f05d1f443cdc4cd089c073969678e26a'
//   },
//   bridge:  'https://a.bridge.walletconnect.org/',
//   qrcode: true,
// })
export const walletConnect = new WalletConnectConnector({
  rpc: {1: `https://mainnet.infura.io/v3/2543dbcf4d8c4ed29a8cf6a159f2700e`},
  bridge: 'https://e.bridge.walletconnect.org',
  qrcode: true,
})

// MainNet only
export const walletLink = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/2543dbcf4d8c4ed29a8cf6a159f2700e`,
  appName: 'BoringDAO',
  appLogoUrl: 'https://app.boringdao.com/static/media/bor.0a18b81a.png',
})
