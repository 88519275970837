import * as React from 'react'
import {styled} from '@mui/material/styles'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {Box, Typography} from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip'
import {useTranslation} from 'react-i18next'
import {displayBalance} from 'utils/formatBalance'
import {TwowayToken} from 'modal/token'
import {getTwoWayLimit, get2WayTokenFixed} from 'utils'
import {ethers} from 'ethers'

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  cursor: 'pointer',
}))

interface Props {
  ratioFees: string[]
  calcFee: string
  fixFee: string
  token: TwowayToken
}

const FreeTooltips: React.FC<Props> = ({ratioFees, calcFee, fixFee, token}) => {
  const {t} = useTranslation()
  const [open, setOpen] = React.useState(false)
  const ratioFeeList = React.useMemo(() => {
    try {
      return ratioFees.map((d) => ethers.utils.parseEther(d))
    } catch (error) {
      return ['0.05', '0.15', '0.50']
    }
  }, [])
  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }
  return (
    // Network fee: 0.00 USDT, covers the gas cost for your cross-chain transfer.
    // Transaction fee rate:0%-0.5%, fluctuates with the remaining liquidity on the destination chain:
    // ≥ 500k USDT, fee rate 0.05%;
    // ≥ 100k USDT, fee rate 0.15%;
    // < 100k USDT, fee rate 0.50%.
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Box>{t('router_fee_notice')}</Box>
            </React.Fragment>
          }
        >
          <HelpOutlineIcon onClick={handleTooltipOpen} />
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  )
}

export default FreeTooltips
