import {CHAIN_ETHER, CHAIN_OK, CHAIN_BSC, CHAIN_POLYGON, CHAIN_METIS, CHAIN_KCC, CHAIN_BOBA} from '@w3u/chains'

export const Swaps = [
  {
    chain: CHAIN_ETHER,
    chainBackground: '#3E5BF214',
    projects: [
      {
        type: {
          icons: ['BORING', 'ETH'],
          name: 'BORING/ETH',
        },
        metas: [
          {
            name: 'Uniswap(UNI)',
            icon: 'uniswap',
            background: 'linear-gradient(to right, #ec227700, #ec227759)',
            borderColor: 'rgba(255, 191, 218, 0.284173)',
            buttonColor: '#EC2277',
            color: '#ffffff',
            link: 'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xbc19712feb3a26080ebf6f2f7849b417fdd792ca&chain=mainnet',
          },
          {
            name: 'Bancor',
            icon: 'bancor',
            background: 'linear-gradient(270deg, rgba(255, 255, 255, 0.144067) 0%, rgba(0, 0, 0, 0.0001) 100%)',
            borderColor: 'rgba(93, 93, 93, 0.574582);',
            buttonColor: '#ffffff',
            color: '#0A2540',
            link: 'https://app.bancor.network/swap?from=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&to=0xBC19712FEB3a26080eBf6f2F7849b417FdD792CA',
          },
        ],
      },
      {
        type: {
          icons: ['BOR', 'ETH'],
          name: 'BOR/ETH',
          chainBackground: '',
        },
        metas: [
          {
            name: 'Sushi',
            icon: 'sushi',
            background: 'linear-gradient(270deg, rgba(236, 34, 119, 0.353196) 0%, rgba(40, 172, 228, 0.213472) 100%)',
            borderColor: 'rgba(255, 191, 218, 0.284173)',
            buttonColor: '#EC2277',
            color: '#ffffff',
            link: 'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x3c9d6c1C73b31c837832c72E04D3152f051fc1A9&chainId=1',
          },
        ],
      },
    ],
  },
  {
    chain: CHAIN_BSC,
    chainBackground: '#AB830313',
    projects: [
      {
        type: {
          icons: ['BORING', 'BNB'],
          name: 'BORING/BNB',
        },
        metas: [
          {
            name: 'Pancake',
            icon: 'pancake',
            background: 'linear-gradient(270deg, rgba(178, 137, 105, 0.465335) 0%, rgba(209, 136, 79, 0.0001) 100%)',
            borderColor: '#948273',
            buttonColor: '#D1884F',
            color: '#ffffff',
            link: 'https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xffeecbf8d7267757c2dc3d13d730e97e15bfdf7f',
          },
          {
            name: 'Wault',
            icon: 'wault',
            background: 'linear-gradient(270deg, rgba(57, 174, 169, 0.414827) 0%, rgba(0, 59, 57, 0.0001) 100%)',
            borderColor: 'rgba(57, 174, 169, 0.540838);',
            buttonColor: '#39AEA9',
            color: '#ffffff',
            link: 'https://swap.wault.finance/bsc/#/swap?inputCurrency=BNB&outputCurrency=0xffeecbf8d7267757c2dc3d13d730e97e15bfdf7f',
          },
        ],
      },
      {
        type: {
          icons: ['BORING', 'BOR'],
          name: 'BORING/BOR',
        },
        metas: [
          {
            name: 'DODO',
            icon: 'dodo',
            background: 'linear-gradient(270deg, rgba(255, 247, 6, 0.262948) -8.23%, rgba(255, 247, 6, 0.0001) 100%)',
            borderColor: 'rgba(255, 247, 6, 0.31067)',
            buttonColor: '#FFF706',
            color: '#000000',
            link: 'https://app.dodoex.io/?network=bsc-mainnet&from=BORING&to=BOR',
          },
        ],
      },
    ],
  },
  {
    chain: CHAIN_POLYGON,
    chainBackground: '#7B3FE414',
    projects: [
      {
        type: {
          icons: ['BORING', 'ETH'],
          name: 'BORING/ETH',
        },
        metas: [
          {
            name: 'Uniswap',
            icon: 'uniswap',
            background: 'linear-gradient(270deg, rgba(236, 34, 119, 0.353196) 0%, rgba(236, 34, 119, 0.0001) 100%)',
            borderColor: 'rgba(255, 191, 218, 0.284173)',
            buttonColor: '#EC2277',
            color: '#ffffff',
            link: 'https://app.uniswap.org/#/swap?inputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619&outputCurrency=0x50D0799ba241E10A6E9bAcE2936129f117Cb32af&chain=polygon',
          },
        ],
      },
      {
        type: {
          icons: ['BORING', 'BORING'],
          name: 'BORING/BORING Pos',
        },
        metas: [
          {
            name: 'DODO',
            icon: 'dodo',
            background: 'linear-gradient(270deg, rgba(255, 247, 6, 0.262948) -8.23%, rgba(255, 247, 6, 0.0001) 100%)',
            borderColor: 'rgba(255, 247, 6, 0.31067)',
            buttonColor: '#FFF706',
            color: '#000000',
            link: 'https://app.dodoex.io/?network=polygon&from=0xff88434e29d1e2333ad6baa08d358b436196da6b&to=0x50d0799ba241e10a6e9bace2936129f117cb32af',
          },
        ],
      },
    ],
  },
  {
    chain: CHAIN_METIS,
    chainBackground: '#00DACC14',
    projects: [
      {
        type: {
          icons: ['BORING', 'USDC'],
          name: 'BORING/USDC',
        },
        metas: [
          {
            name: 'Hermes',
            icon: 'hermes',
            background: 'linear-gradient(270deg, rgba(45, 148, 160, 0.637019) 0%, rgba(162, 219, 217, 0.06138) 100%)',
            borderColor: 'rgba(62, 120, 126, 0.941106)',
            buttonColor: '#2D94A0',
            color: '#FFFFFF',
            link: 'https://hermes.maiadao.xyz/#/swap?inputCurrency=0xbB06DCA3AE6887fAbF931640f67cab3e3a16F4dC&outputCurrency=0x226D8BfB4da78Ddc5bd8Fd6C1532c58e88F9Fd34',
          },
        ],
      },
      {
        type: {
          icons: ['BORING', 'METIS'],
          name: 'BORING/METIS',
        },
        metas: [
          {
            name: 'Hermes',
            icon: 'hermes',
            background: 'linear-gradient(270deg, rgba(45, 148, 160, 0.637019) 0%, rgba(162, 219, 217, 0.06138) 100%)',
            borderColor: 'rgba(62, 120, 126, 0.941106)',
            buttonColor: '#2D94A0',
            color: '#FFFFFF',
            link: 'https://hermes.maiadao.xyz/#/swap?inputCurrency=Metis&outputCurrency=0x226D8BfB4da78Ddc5bd8Fd6C1532c58e88F9Fd34',
          },
        ],
      },
    ],
  },
  // {
  //     chain: CHAIN_KCC,
  //     chainBackground: '#00eeac33',
  //     projects: [
  //         {
  //             type: {
  //                 icons: ['BORING', 'USDT'],
  //                 name: 'BORING/USDT'
  //             },
  //             metas: [
  //                 {
  //                     name: 'Hermes',
  //                     icon: 'hermes',
  //                     background: 'linear-gradient(270deg, rgba(18, 208, 179, 0.2385) 0%, rgba(14, 180, 196, 0.125) 99.91%)',
  //                     borderColor: 'rgba(18, 208, 179, 0.395979)',
  //                     buttonColor: '#12D0B3',
  //                     color: '#FFFFFF',
  //                     link: ''
  //                 },
  //             ]
  //         }
  //     ]

  // },
  {
    chain: CHAIN_OK,
    chainBackground: '#FFFFFF33',
    projects: [
      {
        type: {
          icons: ['BORING', 'USDT'],
          name: 'BORING/USDT',
        },
        metas: [
          {
            name: 'Kswap',
            icon: 'kswap',
            background: 'linear-gradient(270deg, rgba(45, 96, 251, 0.319602) 0%, rgba(25, 53, 85, 0.161768) 100%);',
            borderColor: 'rgba(40, 87, 226, 0.639969)',
            buttonColor: '#2D60FB',
            color: '#FFFFFF',
            link: 'https://app.kswap.finance/#/swap?inputCurrency=OKT&outputCurrency=0x5ce01aa1528a6a0c8edecc4cee909f0a24cf54de',
          },
        ],
      },
    ],
  },
  {
    chain: CHAIN_BOBA,
    chainBackground: '#00000033',
    projects: [
      {
        type: {
          icons: ['BORING', 'USDT'],
          name: 'BORING/USDT',
        },
        metas: [
          {
            name: 'Oolongswap',
            icon: 'oolongswap',
            background: 'linear-gradient(270deg, rgba(178, 137, 105, 0.465335) 0%, rgba(209, 136, 79, 0.0001) 100%)',
            borderColor: 'rgba(40, 87, 226, 0.639969)',
            buttonColor: '#F5A14C',
            color: '#FFFFFF',
            link: 'https://oolongswap.com/#/swap?inputCurrency=ETH&outputCurrency=0x017Ff87AB312301aDE54f7cf9Cc5AEA28C9De024',
          },
        ],
      },
    ],
  },
  {
    chain: -1,
    chainBackground: '#00000033',
    projects: [
      {
        type: {
          icons: ['BORING', 'USDT'],
          name: 'BORING/USDT',
        },
        metas: [
          {
            name: 'OKX',
            icon: 'okc',
            background: 'linear-gradient(270deg, rgba(255, 255, 255, 0.144067) 0%, rgba(0, 0, 0, 0.0001) 100%)',
            borderColor: 'rgba(93, 93, 93, 0.574582)',
            buttonColor: '#FFFFFF',
            color: '#000000',
            link: 'https://www.okx.com/trade-spot/boring-usdt',
          },
          {
            name: 'Huobi',
            icon: 'huobi',
            background:
              'linear-gradient(270deg, rgba(5, 155, 220, 0.533271) -0.63%, rgba(5, 155, 220, 0.0653136) 100%)',
            borderColor: '#06415E',
            buttonColor: '#059BDC',
            color: '#FFFFFF',
            link: 'https://www.huobi.com/en-us/exchange/boring_usdt',
          },
          {
            name: 'Bitmart',
            icon: 'bitmart',
            background: 'linear-gradient(270deg, rgba(19, 194, 194, 0.545017) 0%, rgba(19, 194, 194, 0.0568455) 100%)',
            borderColor: 'rgba(19, 194, 194, 0.720116)',
            buttonColor: '#13C2C2',
            color: '#FFFFFF',
            link: 'https://www.bitmart.com/trade/en?symbol=BORING_USDT&layout=basic',
          },
          {
            name: 'Gate',
            icon: 'gate',
            background: 'linear-gradient(270deg, rgba(216, 89, 88, 0.704272) 0%, rgba(209, 79, 79, 0.0001) 100%)',
            borderColor: 'rgba(216, 89, 88, 0.574628)',
            buttonColor: '#D85958',
            color: '#FFFFFF',
            link: 'https://www.gate.io/trade/BORING_USDT',
          },
          {
            name: 'Biture',
            icon: 'biture',
            background: 'linear-gradient(270deg, rgba(243, 189, 42, 0.592603) 0%, rgba(243, 189, 42, 0.0001) 100%)',
            borderColor: 'rgba(243, 189, 42, 0.761664)',
            buttonColor: '#F3BD2A',
            color: '#FFFFFF',
            link: 'https://www.bitrue.com/trade/boring_usdt',
          },
          {
            name: 'Mexc',
            icon: 'mexc',
            background: 'linear-gradient(270deg, rgba(0, 184, 151, 0.768985) 0%, rgba(81, 226, 149, 0.0001) 100%)',
            borderColor: 'rgba(81, 226, 149, 0.714762)',
            buttonColor: '#51E295',
            color: '#FFFFFF',
            link: 'https://www.mexc.com/exchange/BORING_USDT',
          },
        ],
      },
    ],
  },
]

export const Farms = [
  {
    chain: CHAIN_ETHER,
    chainBackground: '#00000028',
    projects: [
      {
        metas: [
          {
            name: 'Curve',
            icon: 'curve',
            background: 'linear-gradient(270deg, rgba(236, 34, 119, 0.353196) 0%, rgba(236, 34, 119, 0.0001) 100%);',
            borderColor: 'rgba(255, 191, 218, 0.717274);',
            buttonColor: '#EC2277',
            color: '#FFFFFF',
            actionText: 'Deposit',
            orderText: 'to earn',
            deposit: ['oBTC'],
            earn: ['CRV', 'BORING'],
            link: 'https://curve.fi/obtc',
          },
          {
            name: 'Convex',
            icon: 'convex',
            background: 'linear-gradient(270deg, rgba(255, 207, 87, 0.6) 0%, rgba(255, 207, 87, 0.1) 100%);',
            borderColor: '#FFCF57',
            buttonColor: '#FFCF57',
            color: '#FFFFFF',
            actionText: 'Deposit',
            orderText: 'to earn',
            deposit: ['oBTC'],
            earn: ['CRV', 'CVX', 'BORING'],
            link: 'https://www.convexfinance.com/stake',
          },
          {
            name: 'Yearn',
            icon: 'yearn',
            background: 'linear-gradient(270deg, rgba(0, 106, 227, 0.6) 0%, rgba(0, 106, 227, 0.1) 100%)',
            borderColor: '#006AE3',
            buttonColor: '#006AE3',
            color: '#FFFFFF',
            actionText: 'Deposit',
            orderText: 'to earn',
            deposit: ['oBTC'],
            earn: ['oBTC'],
            link: 'https://yearn.finance/#/vault/0xe9Dc63083c464d6EDcCFf23444fF3CFc6886f6FB',
          },
          // {
          //   name: 'Fortube',
          //   icon: 'fortube',
          //   background: 'linear-gradient(270deg, rgba(71, 197, 173, 0.698727) 0%, rgba(71, 197, 173, 0.0448263) 100%)',
          //   borderColor: '#47C5AD',
          //   buttonColor: '#47C5AD',
          //   color: '#FFFFFF',
          //   actionText: 'Deposit',
          //   orderText: 'to borrowe other asset',
          //   deposit: ['oBTC'],
          //   earn: [],
          //   link: 'https://for.tube/unfixed-deposit/details/oBTC',
          // },
          {
            name: 'Uniswap',
            icon: 'uniswap',
            background: 'linear-gradient(270deg, rgba(236, 34, 119, 0.353196) 0%, rgba(236, 34, 119, 0.0001) 100%)',
            borderColor: '#ffbfdab8',
            buttonColor: '#EC2277',
            color: '#FFFFFF',
            actionText: 'Add liqudity',
            orderText: 'for',
            deposit: [],
            earn: ['oBTC', 'ETH'],
            link: 'https://app.uniswap.org/#/add/ETH/0x8064d9Ae6cDf087b1bcd5BDf3531bD5d8C537a68/3000?chain=mainnet&minPrice=0.037485&maxPrice=0.149941',
          },
          {
            name: 'Uniswap',
            icon: 'uniswap',
            background: 'linear-gradient(270deg, rgba(236, 34, 119, 0.353196) 0%, rgba(236, 34, 119, 0.0001) 100%)',
            borderColor: '#ffbfdab8',
            buttonColor: '#EC2277',
            color: '#FFFFFF',
            actionText: 'Add liqudity',
            orderText: 'for',
            deposit: [],
            earn: ['oLTC', 'ETH'],
            link: 'https://app.uniswap.org/#/add/ETH/0x07C44B5Ac257C2255AA0933112c3b75A6BFf3Cb1/3000?chain=mainnet&minPrice=12.362799&maxPrice=49.451196',
          },
          {
            name: 'Uniswap',
            icon: 'uniswap',
            background: 'linear-gradient(270deg, rgba(236, 34, 119, 0.353196) 0%, rgba(236, 34, 119, 0.0001) 100%)',
            borderColor: '#ffbfdab8',
            buttonColor: '#EC2277',
            color: '#FFFFFF',
            actionText: 'Add liqudity',
            orderText: 'for',
            deposit: [],
            earn: ['oDOGE', 'ETH'],
            link: 'https://app.uniswap.org/#/add/ETH/0x9c306A78b1a904e83115c05Ac67c1Ef07C653651/3000?chain=mainnet',
          },
        ],
      },
    ],
  },
  // {
  //     chain: CHAIN_KCC,
  //     chainBackground: '#00000033',
  //     projects: [
  //         {
  //             metas: [
  //                 {
  //                     project: 'Mojitoswap',
  //                     icon: 'mojitoswap',
  //                     name: 'Mojitoswap',
  //                     background: 'linear-gradient(270deg, rgba(20, 212, 167, 0.465172) 0%, rgba(12, 200, 212, 0.0644668) 100%)',
  //                     borderColor: 'rgba(20, 212, 167, 0.613472)',
  //                     buttonColor: '#14D4A7',
  //                     color: '#FFFFFF',
  //                     actionText: 'Add',
  //                     orderText: 'liquidity to earn',
  //                     deposit: ['BORING', 'USDT'],
  //                     earn: ['MJT', 'BORING'],
  //                     link: 'https://app.mojitoswap.finance/add/0x0039f574eE5cC39bdD162E9A88e3EB1f111bAF48/0x5D78bF8f79A66e43f5932c1Ae0b8fA6563F97f74'
  //                 }
  //             ]
  //         }
  //     ]
  // }
]
