import { useMemo, useState, useEffect } from 'react'
import { CHAIN_ETHER, CHAIN_POLYGON_MUMBAI, CHAIN_POLYGON } from '@w3u/chains'
import { useWeb3React } from '@web3-react/core'
import { injectedConnector } from 'config/connectors'
import {isMobile} from 'react-device-detect'
import { isTestnet } from 'utils/providers'
import { getUrlProvider } from 'utils/providers'
import { TWOWAY_ADDRESSES, TWOWAY_CHEF_ADDRESSES } from 'config/constants/twoway'

export const useRelayChainProvider = () => {
  const {library, chainId} = useWeb3React()
  const relayChainID = useRelayChainID()
  const provider = relayChainID === chainId ? library : getUrlProvider(relayChainID)

  return useMemo(() => provider, [chainId])
}

export const useRelayChainID = () => {
  // const {chainId} = useWeb3React()
  // const requestChainID = isTestnet(chainId) ? CHAIN_POLYGON_MUMBAI : CHAIN_POLYGON
  return useMemo(() => CHAIN_POLYGON, [])
}
export const useRelayTwowayAddress = () => {
  const relayChainID = useRelayChainID()
  return useMemo(() => TWOWAY_ADDRESSES[relayChainID], [])
}
export const useTwowayChefAddress = () => {
  const relayChainID = useRelayChainID()
  return useMemo(() => TWOWAY_CHEF_ADDRESSES[relayChainID], [])
}

export const useTwowayAddress = () => {
  const {chainId} = useWeb3React()
  return useMemo(() => TWOWAY_ADDRESSES[chainId ?? CHAIN_ETHER], [])
}

export const useEagerConnect = (): boolean => {
  const {activate, active} = useWeb3React()
  const [tried, setTried] = useState(false)

  useEffect(() => {
    injectedConnector.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injectedConnector, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        if (isMobile && window.ethereum) {
          activate(injectedConnector, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          setTried(true)
        }
      }
    })
  }, [activate])

  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

// T is a generic type for value parameter, our case this will be string
export function useDebounce<T>(value: T, delay: number): T {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}
