import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Flex, Text } from 'components/Style'
import { useWeb3React } from '@web3-react/core'
import { CHAIN_ETHER } from '@w3u/chains'
import { OLD_ETH_CHEF_ADDRESS } from 'config/constants/chef' 
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import { changeChain } from 'utils'
import { getIcon } from 'utils/icons'
import { BoringChefInfo } from 'modal/twoway'
import { displayBalance } from 'utils/formatBalance'
import { useApprove } from 'hooks/useApprove'
import { useDeposit, useWithdraw, useClaim } from 'hooks/useBoringFarm'
// import { ShareIcon } from 'components/Svg'

import Deposit from './components/Deposit'
import Withdraw from './components/Withdraw'

const FarmWrapper = styled.div`
    width: 100%;
    border-radius: 20px;
    padding: 20px 15px;
    background-color: #00000066;
    margin-bottom: 15px;
`
const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr) 2fr;
    padding: 20px 0;
    align-items: center;
`
interface FarmItemProps {
    farm: BoringChefInfo
    boringPrice: string
    onRefresh: () => void
}
interface Props {
    farmName: string
    farms: BoringChefInfo[]
    boringPrice: string
    onRefresh: () => void
}
const SkeletonList = () => {
    return (
        <>
            {[1, 2, 3, 4].map(d => (
                <Grid key={d}>
                    <Skeleton variant="text" width="300px" height={40} />
                </Grid>
            ))}
        </>
    )
}
const FarmItem:React.FC<FarmItemProps> = ({ farm, boringPrice, onRefresh }) => {
    const { t } = useTranslation()
    const { chainId } = useWeb3React()
    const [open, setOpen] = useState(false)
    const [openWithdraw, setOpenWithdraw] = useState(false)
    const chefAddress = OLD_ETH_CHEF_ADDRESS
    const { allowance, loading: apvLoading, onApprove } = useApprove(farm.token.address, chefAddress, CHAIN_ETHER)

    const [loading, setLoading] = useState(apvLoading)
    const { onDeposit } = useDeposit()
    const { onWithdraw } = useWithdraw()
    const { onClaim } = useClaim()
    const handleDeposit = async (amount: string) => {
        try {
            setLoading(true)
            await onDeposit(farm.token.pid, amount, farm.token.decimals)
            setOpen(false)
            onRefresh()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    const handleWithdraw = async (amount: string) => {
        try {
            setLoading(true)
            await onWithdraw(farm.token.pid, amount, farm.token.decimals)
            setOpenWithdraw(false)
            onRefresh()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    const handleClaim = async (pid: number) => {
        try {
            setLoading(true)
            await onClaim(pid)
            onRefresh()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    const renderAction = () => {
        return allowance ?
        <Flex justifyContent="space-evenly">
            <Button onClick={() => setOpen(true)}>{t('stake')}</Button>
            <Button onClick={() => setOpenWithdraw(true)}>{t('withdraw')}</Button>
        </Flex> :
        <Button disabled={loading} fullWidth 
            onClick={async () => {
                try {
                    setLoading(true)
                    await onApprove()
                    onRefresh()
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                }
            }
        }>{t('Approve')}</Button>
    }
    return (
        <>
            <Accordion
                sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none'
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        width: '100%',
                        margin: 0,
                        padding: '15px 0',
                        border: 'none',
                        boxShadow: 'none',
                        borderTop: '1px solid #000000'
                    }}
                >
                    <Flex>
                        <img src={getIcon(farm.token.symbol)} width="20px" height="20px" alt={farm.token.symbol} />
                        <Typography ml="10px" color="#ffffff">{farm.token.name}</Typography>
                    </Flex>
                </AccordionSummary>
                <AccordionDetails sx={{padding: '0'}}>
                    <Flex justifyContent="space-between" p="20px 0">
                        <Text color="#ffffffb3" fontSize="12px">{t('APR')}</Text>
                        <Text color="#ffffffb3" fontSize="12px">{farm.apr}%</Text>
                    </Flex>
                    <Flex justifyContent="space-between" p="20px 0">
                        <Text color="#ffffffb3" fontSize="12px">{t('total_staked')}</Text>
                        <Text color="#ffffffb3" fontSize="12px">{displayBalance(farm.totalStaked)}</Text>
                    </Flex>
                    <Flex justifyContent="space-between" p="20px 0">
                        <Text color="#ffffffb3" fontSize="12px">{t('balance')}</Text>
                        <Text color="#ffffffb3" fontSize="12px">{displayBalance(farm.balance)}</Text>
                    </Flex>
                    {/* <Grid>
                        <Text color="#ffffffb3" fontSize="12px">{t('Balance')}</Text>
                        <Text color="#ffffffb3" fontSize="12px">{displayBalance(farm.balance)}</Text>
                        <Flex justifyContent="flex-end"><ShareIcon color='#0068FF'/></Flex>
                    </Grid> */}
                    <Grid>
                        <Text color="#ffffffb3" fontSize="12px">{t('your_staked')}</Text>
                        <Text color="#ffffffb3" fontSize="12px">{displayBalance(farm.yourStaked)}</Text>
                        {chainId === CHAIN_ETHER ? renderAction() :
                            <Button onClick={() => changeChain(CHAIN_ETHER)} fullWidth size='small' variant="outlined">
                                {t('switch_to_ethereum')}
                            </Button>
                        }
                    </Grid>
                    <Grid>
                        <Text color="#ffffffb3" fontSize="12px">{t('reward')}</Text>
                        <Text color="#ffffffb3" fontSize="12px">{displayBalance(farm.reward)}</Text>
                        {chainId === CHAIN_ETHER ? 
                            <Flex justifyContent="flex-end">
                                <Button fullWidth onClick={() => handleClaim(farm.token.pid)}>{t('claim')}</Button>
                            </Flex> :
                            <Button onClick={() => changeChain(CHAIN_ETHER)} fullWidth size='small' variant="outlined">
                                {t('switch_to_ethereum')}
                            </Button>
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Deposit 
                farmInfo={farm}
                open={open}
                onClose={() => setOpen(false)}
                onConfrim={handleDeposit}
            />
            <Withdraw 
                farmInfo={farm}
                open={openWithdraw}
                onClose={() => setOpenWithdraw(false)}
                onConfrim={handleWithdraw}
            />
        </>
    )
}
const MobileFarm:React.FC<Props> = ({ farmName, farms, boringPrice, onRefresh }) => {
    const { t } = useTranslation()
    return (
        <FarmWrapper>
            <Flex flexDirection="column" mb="15px">
                <Typography>{farmName}</Typography>
                <Typography color="#ffffff80" fontSize="12px">{t('Stake PPToken and earn BORING rewards')}</Typography>
            </Flex>
            {farms.length ? farms.filter(f => f.token.type === farmName).map((farm, i) => (
                    <FarmItem key={i} farm={farm} boringPrice={boringPrice} onRefresh={onRefresh}  />
                )) : <SkeletonList />
            }
        </FarmWrapper>
    )
}

export default MobileFarm