import { Flex, Text } from 'components/Style'
import styled from '@emotion/styled'
import { getIcon, getChainIcon, getEcoIcon } from 'utils/icons'
import { Button, Box } from '@mui/material'
import { Swaps } from './config'

const Grid = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    margin-bottom: 20px;
    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 15px;
        margin-bottom: 15px;
    }
`

const EcoWrapper = styled.div`
    width: 100%;
    background: #00000066;
    border-radius: 30px;
    padding: 30px;
    > div:not(:last-child) {
        margin-bottom: 30px;
    }
    @media screen and (max-width: 600px) {
        padding: 15px 0;
        border-radius: 20px;
        background: transparent;
        > div:not(:last-child) {
            margin-bottom: 15px;
        }
    }
`
const FarmBox = styled(Flex)`
    @media screen and (max-width: 600px) {
        padding: 15px;
        background: #00000066;
        border-radius: 20px;
    }
`

const ChainName = styled.div<{chainBackground: string}>`
    background: ${({ chainBackground }) => chainBackground};
    padding: 16px;
    width: 60px;
    height: 60px;
    border-radius: 12px 0 0 12px;
    @media screen and (max-width: 600px) {
        display: none;
    }
`
const ChainNameInner = styled(Box)`
    display: none;
    margin-right: 12px;
    @media screen and (max-width: 600px) {
        display: inline-flex;
        align-items: center;
        padding: 6px;
        border-radius: 10px;
        background-color: #3e5bf24d;
    }
`
const ChainToken = styled(Flex)`
    align-items: center;
    margin-bottom: 22px;
`
const ChainList = styled.div`
    flex: 1 1 auto;
    background: #00000066;
    padding: 20px;
    border-radius: 0 12px 12px 12px;
    @media screen and (max-width: 600px) {
        background: none;
        padding: 0;
    }
`

const ChainSwap = styled(Flex)<{borderColor: string, background: string}>`
    justify-content: space-between;
    border: 1px solid ${({ borderColor }) => borderColor};
    padding: 17px 14px;
    border-radius: 12px;
    background: ${({ background }) => background};
`

const Swap = () => {
    // const { t } = useTranslation()
    return (
        <EcoWrapper>
            {Swaps.map((swap, i) => (
                <FarmBox key={i}>
                    <ChainName chainBackground={swap.chainBackground}>
                        <img src={getChainIcon(swap.chain)} width="28px"  height="28px" alt="BOR" />
                    </ChainName>
                    <ChainList>
                        {swap.projects.map((project, j) => (
                            <>
                                <ChainToken key={`token${j}`}>
                                    <Flex alignItems="center">
                                        <ChainNameInner><img src={getChainIcon(swap.chain)} width="28px"  height="28px" alt="BOR" /></ChainNameInner>
                                        <img src={getIcon(project.type.icons[0])} width="20px"  height="20px" alt={project.type.icons[0]} />
                                        <img src={getIcon(project.type.icons[1])} width="20px"  height="20px" alt={project.type.icons[1]} />
                                    </Flex>
                                    <Text ml="10px">{project.type.name}</Text>
                                </ChainToken>
                                <Grid key={`grid${j}`}>
                                    {project.metas.map(meta => (
                                        <ChainSwap borderColor={meta.borderColor} background={meta.background}>
                                            <Flex alignItems="center">
                                                <img src={getEcoIcon(meta.icon)} width="30px" height="30px" alt="BOR" />
                                                <Text fontSize='12px' ml="10px">{meta.name}</Text>
                                            </Flex>
                                            <a target="_blank" href={meta.link}>
                                                <Button style={{background: meta.buttonColor}} size='small' sx={{borderRadius: '24px'}}>
                                                    <Text style={{color: meta.color}}>Go</Text>
                                                </Button>
                                            </a>
                                        </ChainSwap>
                                    ))}
                                </Grid>
                            </>
                        ))}
                    </ChainList>
                </FarmBox>
            ))}
        </EcoWrapper>
    )
}

export default Swap
