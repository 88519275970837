import { useState } from 'react'
import { Flex, Text } from 'components/Style'
import styled from '@emotion/styled'
import useMediaQuery from '@mui/material/useMediaQuery'
import { getIcon } from 'utils/icons'
import { POW_TOKEN } from 'config/constants/pow_bridge'
import { useTranslation } from 'react-i18next'

const TabWrapper = styled(Flex)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background: #0068ff33;
    padding: 5px;
    margin-bottom: 15px;
    border-radius: 10px;
`

const TabButton = styled.div<{actived: boolean}>`
    display: flex;
    justify-content: center;
    background: ${({ actived }) => actived ? 'linear-gradient(90deg, #0068FF 0%, #00A1FF 100%);' : '' } ;
    border-radius: 8px;
    padding: 10px 0;
    white-space: nowrap;
    letter-spacing: 0.3px;
    cursor: pointer;
    text-align: center;
    &:hover {
        opacity: 0.8;
        /* background: linear-gradient(90deg, #0068FF 0%, #00A1FF 100%); */
    }
`
interface Props {
    defaultToken: string
    onToken: (token: string) => void
}

const Tab: React.FC<Props> = ({ onToken, defaultToken }) => {
    const { t } = useTranslation()
    const matches = useMediaQuery('(max-width:600px)')
    const [token, setToken] = useState(defaultToken || 'BTC')
    const handleClick = (t: string) => {
        setToken(t)
        onToken(t)
    }

    return (
        <TabWrapper>
            {POW_TOKEN.map(d => (
                <TabButton key={d} onClick={() => handleClick(d)} actived={token === d}>
                    <img src={getIcon(d)} width="20px" height="20px" alt="Token" />
                    <Text fontSize="14px" ml="10px">o{d} {matches ? '' : t('Tunnel')}</Text>
                </TabButton>
            ))}
        </TabWrapper>
    )
}

export default Tab
