import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Flex, Text } from 'components/Style'

export const WrapperLiquity = styled(Flex)`
    flex-direction: column;
    background: #00000066;
    border-radius: 30px;
    padding: 30px;
    width: 100%;
`
export const Gride = styled(Box)`
    display: grid;
    grid-template-columns: 0.8fr 2.5fr repeat(3, 0.8fr) 1.6fr;
    width: 100%;
`
export const GrideSkeleton = styled(Box)`
    display: grid;
    grid-template-columns: 0.8fr 2.5fr repeat(3, 0.8fr) 1.6fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
`
export const SubGride = styled(Box)`
    display: grid;
    grid-template-columns: 0.8fr 2.5fr repeat(3, 0.8fr) 1.6fr;
    grid-column-start: 2;
    width: 100%;
    padding: 8px 0;
    margin-bottom: 4px;
    > div {
        padding-bottom: 8px;
        border-bottom: 1px solid #000000;
    }
`
export const TokenImg = styled(Flex)`
    align-items: center;
    > img:nth-child(2){
        margin: 2px 0 0 -5px;
    }
`
export const ChainList = styled(Flex)`
    justify-content: flex-start;
    img {
        margin-right: 6px;
    }
    @media screen and (max-width: 600px) {
        display: none;
    }
`
export const ListText = styled(Text)`
    color: #ffffff;
`