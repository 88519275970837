import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import { MenuIcon } from 'components/Svg'
import Menu from './Menu'

const MenuIconBox = styled.div`
    padding-left: 15px;
    margin: 14px auto 20px;
`
interface Props {
    open: boolean
    onClose: () => void
}
const MobileMenu:React.FC<Props> = ({ open, onClose }) => {

  return (
    <React.Fragment>
        <Drawer anchor='left' open={open} onClose={onClose}
            sx={{
                height: '100%',
                '.MuiPaper-root': {
                    background: '#00000091',
                    backdropFilter: 'blur(7px)',
                    paddingLeft: '20px'
                }
            }}
        >
            <Box height="100%" sx={{minWidth: '220px'}}>
                <MenuIconBox onClick={onClose}><MenuIcon /></MenuIconBox>
                <Menu hideborder onClose={onClose} />
            </Box>
        </Drawer>
    </React.Fragment>
  )
}
export default MobileMenu
