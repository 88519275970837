import { useMemo, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Flex, Text } from 'components/Style'
import useMediaQuery from '@mui/material/useMediaQuery'
import styled from '@emotion/styled'
import { useWeb3React } from '@web3-react/core'
import { CHAIN_ETHER } from '@w3u/chains'
import { getIcon } from 'utils/icons'
import { Button, Skeleton } from '@mui/material'
import { SmallButton } from 'components/Button'
import { useTranslation } from 'react-i18next'
import { useBoringChef } from 'hooks/useBoringFarm'
import { displayBalance } from 'utils/formatBalance'
import { OLD_ETH_CHEF_ADDRESS } from 'config/constants/chef'
import { ethers } from 'ethers'
import { usePrice } from 'hooks/usePrice'
import { useApprove } from 'hooks/useApprove'
import { useDeposit, useWithdraw, useClaim } from 'hooks/useBoringFarm'
import { usePrices } from 'hooks/usePrice'
import { BoringChefInfo } from 'modal/twoway'
import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
import { changeChain } from 'utils'
import { BoringFarmName } from 'config/constants/twoway'
import Deposit from './components/Deposit'
import Withdraw from './components/Withdraw'
import Mobile from './mobile'

const Grid = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1.3fr 0.5fr repeat(4, 1fr) 2.5fr;
    grid-row-gap: 20px;
    height: 40px;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

const EcoWrapper = styled.div`
    width: 100%;
    background: #00000066;
    border-radius: 30px;
    padding: 30px;
`
const MobileWrapper =  styled.div`
    width: 100%;
`

const TitleName = styled.div`
    background: #00000066;
    padding: 21px 16px;
    border-radius: 12px 0 0 12px;
    height: 60px;
    min-width: 130px;
`
const FarmBox = styled.div`
    flex: 1 1 auto;
    background: #00000066;
    padding: 20px;
    border-radius: 0 12px 12px 12px;
`
const ButtonGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    width: 100%;
`
interface FarmItemProps {
    farm: BoringChefInfo
    href: string
    onRefresh: () => void
}
const SkeletonList = () => {
    return (
        <>
            {[1, 2, 3, 4].map(d => (
                <Grid key={d}>
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                </Grid>
            ))}
        </>
    )
}
const FarmItem:React.FC<FarmItemProps> = ({ farm, href, onRefresh }) => {
    const { t } = useTranslation()
    const { chainId } = useWeb3React()
    const [open, setOpen] = useState(false)
    const [openWithdraw, setOpenWithdraw] = useState(false)
    const chefAddress = OLD_ETH_CHEF_ADDRESS
    const { allowance, loading: apvLoading, onApprove } = useApprove(farm.token.address, chefAddress, CHAIN_ETHER)

    const [loading, setLoading] = useState(apvLoading)
    const [depositLoading, setDepositLoading] = useState(apvLoading)
    const [widthDrawLoading, setWidthDrawLoading] = useState(apvLoading)
    const [calimLoading, setClaimLoading] = useState(apvLoading)
    const { onDeposit } = useDeposit()
    const { onWithdraw } = useWithdraw()
    const { onClaim } = useClaim()
    const handleDeposit = async (amount: string) => {
        try {
            setDepositLoading(true)
            await onDeposit(farm.token.pid, amount, farm.token.decimals)
            onRefresh()
            setDepositLoading(false)
            setOpen(false)
        } catch (error) {
            console.error(error)
            setDepositLoading(false)
        }
    }
    const handleWithdraw = async (amount: string) => {
        try {
            setWidthDrawLoading(true)
            await onWithdraw(farm.token.pid, amount, farm.token.decimals)
            onRefresh()
            setWidthDrawLoading(false)
            setOpenWithdraw(false)
        } catch (error) {
            setWidthDrawLoading(false)
        }
    }
    const handleClaim = async () => {
        try {
            setClaimLoading(true)
            await onClaim(farm.token.pid)
            onRefresh()
            setClaimLoading(false)
        } catch (error) {
            setClaimLoading(false)
        }
    }
    const handleApprove = async () => {
        try {
            setLoading(true)
            await onApprove()
            onRefresh()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    const renderAction = () => {
        const canClaim = new BigNumber(farm.reward).gt(0)

        return allowance ?
        <ButtonGrid>
            <SmallButton loading={depositLoading} onClick={() => setOpen(true)}>{t('stake')}</SmallButton>
            <SmallButton disabled={new BigNumber(farm.yourStaked).eq(0)} loading={widthDrawLoading}  onClick={() => setOpenWithdraw(true)}>{t('withdraw')}</SmallButton>
            <SmallButton disabled={!canClaim} loading={calimLoading}  onClick={handleClaim}>{t('claim')}</SmallButton>
        </ButtonGrid> :
        <SmallButton fullWidth loading={loading} onClick={handleApprove}>{t('approve')}</SmallButton>
    }
    return (
        <>
            <Grid key={farm.token.pid}>
                <Flex alignItems="center">
                    <img src={getIcon(farm.token.symbol)} width="20px" height="20px" alt={farm.token.symbol} />
                    <Text ml="6px">{farm.token.name}</Text>
                </Flex>
                <Flex flexDirection="column" justifyContent="center">
                    <Flex>
                        {/* <img src={getIcon('BORING')} alt='Boring' width={18} height={18} /> &nbsp; */}
                        {/* <Text>{displayTwowayBoringAPY(farm, boringPrice)}%</Text> */}
                        <Text>{farm.apr}%</Text>
                    </Flex>
                </Flex>
                <Flex alignItems="center" justifyContent="flex-end">
                    <Text>{displayBalance(farm.totalStaked, farm.token.decimals)}</Text>
                </Flex>
                <Flex alignItems="center" justifyContent="flex-end">
                    <Text mr="6px">{displayBalance(farm.balance, farm.token.decimals)}</Text>
                    {!!href ? <Link to={`${href}?token=${farm.token.subSymbol || farm.token.symbol}`}><Text color="#0068FF" style={{cursor: 'pointer'}}>{t('Get')}</Text></Link> :
                        <Text style={{visibility: 'hidden'}}>{t('Get')}</Text>
                    }
                </Flex>
                <Flex alignItems="center" justifyContent="flex-end">
                    <Text>
                        {displayBalance(farm.yourStaked, farm.token.decimals)}
                    </Text>
                </Flex>
                <Flex flexDirection="column" justifyContent="center">
                    <Flex justifyContent="flex-end">
                        <img src={getIcon('BORING')} alt='Boring' width={18} height={18} /> &nbsp;
                        <Text>{displayBalance(farm.reward)}</Text>
                    </Flex>
                </Flex>
                <Flex justifyContent="space-evenly" width="100%">
                  {chainId === CHAIN_ETHER ? renderAction() :
                    <Button onClick={() => changeChain(CHAIN_ETHER)} fullWidth size='small' variant="outlined">
                        {t('switch_to_ethereum')}
                    </Button>
                  }
                </Flex>
            </Grid>
            <Deposit
                farmInfo={farm}
                open={open}
                onClose={() => setOpen(false)}
                onConfrim={handleDeposit}
            />
            <Withdraw
                farmInfo={farm}
                open={openWithdraw}
                onClose={() => setOpenWithdraw(false)}
                onConfrim={handleWithdraw}
            />
        </>
    )
}

const FarmBoring = () => {
    const { t } = useTranslation()
    const matches = useMediaQuery('(max-width:600px)')
    const boringPrice = usePrice('BORING')
    const tokens = ['BORING', 'BTC', 'LTC', 'DOGE', 'ETH', 'USDT', 'USDC', 'DAI', 'SUNDER']
    const prices = usePrices(tokens)
    const { chefs: farms, loading, fetchFarmChef } = useBoringChef()
    const getPrice = (tokenName: string) => {
        if (!prices) return  '0.00'
        if (tokenName === 'oBTC' || tokenName === 'oLTC' || tokenName === 'oDoge') {
            return prices[`${tokenName.substring(1).toUpperCase()}`]
        }
        if (tokenName === 'WETH') {
            return prices['ETH']
        }
        return prices[tokenName]
    }
    const calcAPY = useCallback((rate: string, lockValue: BigNumber, boringPrice: string) => {
        const bp = new BigNumber(boringPrice)
        const br = new BigNumber(ethers.utils.formatEther(rate))
        const a = bp.multipliedBy(br).multipliedBy(365 * 3600 * 24)
        const lockBN = new BigNumber(lockValue)
        if (lockBN.eq(new BigNumber('0'))) {
          return '0.00'
        }
        const result = a.div(lockBN).multipliedBy(100)
        return result.toFixed(2)
      }, [])
    const formatFarms = useMemo(() => {
        try {
            return farms.map(d => {
                const tokenPrice = getPrice(d.token.symbol) ?? '0'
                const lockValue = new BigNumber(d.totalStaked)
                  .multipliedBy(tokenPrice)
                  .dividedBy(Math.pow(10, d.token.decimals))
                const rate = Number(d.totalPoint) !== 0 ? new BigNumber(d.point)
                    .dividedBy(d.totalPoint)
                    .multipliedBy(d.boringPerBlock)
                    .dividedBy(15) : BIG_ZERO
                    // .dividedBy(chainId === CHAIN_ETHER ? 15 : 3) // TODO ?
                const apr = calcAPY(rate.toFixed(0), lockValue, boringPrice)
                return {
                    ...d,
                    tokenPrice,
                    apr
                }
            })
        } catch (error) {
            return []
        }

    }, [farms, prices])
    return (
        <>
        {matches ?
            <MobileWrapper>
                <Text mb="15px" fontSize="16px" color="#ffffff">{t('oTunnel_farm')}</Text>
                {BoringFarmName.map((d, i) => (<Mobile key={i} farmName={d.name} farms={formatFarms} boringPrice={boringPrice} onRefresh={fetchFarmChef} />))}
            </MobileWrapper> :
            <EcoWrapper>
                <Text mb="20px" fontSize="18px">{t('oTunnel_farm')}</Text>
                <Flex alignItems="center">
                    <TitleName style={{visibility: 'hidden'}}>
                        <Text>{BoringFarmName[0].name}</Text>
                    </TitleName>
                    <Grid style={{width: '100%', padding: '0 20px'}}>
                        <Flex alignItems="center"><Text>{t('pool')}</Text></Flex>
                        <Flex alignItems="center"><Text>{t('APR')}</Text></Flex>
                        <Flex alignItems="center" justifyContent="flex-end"><Text>{t('total_staked')}</Text></Flex>
                        <Flex alignItems="center" justifyContent="flex-end"><Text>{t('balance')}</Text></Flex>
                        <Flex alignItems="center" justifyContent="flex-end"><Text>{t('your_staked')}</Text></Flex>
                        <Flex alignItems="center" justifyContent="flex-end"><Text>{t('reward')}</Text></Flex>
                    </Grid>
                </Flex>
                {BoringFarmName.map((d, j) => (
                    <Flex key={j} mb="30px">
                        <TitleName>
                            <Text>{t(d.name)}</Text>
                        </TitleName>
                        <FarmBox>
                            {formatFarms.length && !loading ?
                                formatFarms.filter(f => f.token.type === d.name).map((farm, i) => (<FarmItem key={i} farm={farm} href={d.href} onRefresh={fetchFarmChef} />)) :
                                <SkeletonList />
                            }
                        </FarmBox>
                    </Flex>
                ))}
            </EcoWrapper>
        }
        </>
    )
}

export default FarmBoring
