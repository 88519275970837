import { Flex } from 'components/Style'
import Link from '@mui/material/Link'
import Backed from 'components/Backed'
import { IconProps } from 'components/Svg/type'
import * as IconModule from 'components/Svg'

// import { useTranslation } from 'react-i18next'
import { socials } from '../Layout/config'

const Icons = (IconModule as unknown) as { [key: string]: React.FC<IconProps> };

const Footer = () => {
    return (
        <Flex flexDirection="column">
            <Backed />
            <Flex
                sx={{
                    justifyContent: 'center',
                    padding: "12px 0 30px",
                }}
            >
                <Flex
                    sx={{
                        paddingTop: '6px',
                        paddingRight: '12px',
                    }}
                >
                    {socials.map(d => {
                        const Icon = Icons[d.icon] ?? Icons['HomeIcon']
                        const iconElement = <Icon />
                        return (
                            <Link target="_blank" key={d.label} p="0 8px" href={d.href} color="inherit" underline="none">
                                {d.icon.indexOf('png') > -1 ? <img src={`/images/${d.icon}`} width="18px" height="18px"  /> : iconElement}
                            </Link>
                        )}
                    )}
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Footer