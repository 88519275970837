import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const MediunIcon:React.FC<IconProps> = () => {
    return (
        <SvgIcon viewBox="0 0 40 35">
            <g id="1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="2" transform="translate(-40.000000, -46.000000)" fill="#FFFFFF" fill-rule="nonzero">
                    <g id="3" transform="translate(40.000000, 46.000000)">
                        <path d="M0,0 L40,0 L40,5 L0,5 L0,0 Z M0,30 L40,30 L40,35 L0,35 L0,30 Z M0,15 L40,15 L40,20 L0,20 L0,15 L0,15 Z" id="4"></path>
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}

export default MediunIcon
