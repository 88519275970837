import {Box, Button, Dialog, DialogProps} from '@mui/material'
import {DialogTitle, Grid, TokenBalance} from '../../views/Transaction/style'
import {Flex, Text} from '../Style'
import {getIcon} from '../../utils/icons'
import {get2WayTokenFixed} from '../../utils'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useRouterTransactions} from '../../hooks/useRouterTransactions'
import {RouterStepInterface} from '../../interfaces/router'
import RouterChainItem from './RouterChainItem'

interface RouterTransactionProps extends DialogProps {
  txHash: string
}

export const RouterTransaction = ({txHash, ...props}: RouterTransactionProps) => {
  const {t} = useTranslation()

  const routerTxs = useRouterTransactions()

  const txs = routerTxs.find((txs: RouterStepInterface[]) => txs[0].origin_txid === txHash)

  const tokenSymbol = txs && txs[0].token_symbol
  const amount = (txs && txs[0].amount) ?? 0

  console.log(txs)

  return (
    <>
      <Dialog
        fullWidth
        maxWidth='md'
        keepMounted
        {...props}
        sx={(theme) => ({
          '.MuiPaper-root': {
            padding: '30px',
            [theme.breakpoints.down('md')]: {
              padding: '10px',
              margin: '0 4px',
              width: 'calc(100% - 8px)',
            },
          },
        })}
      >
        <DialogTitle>{t('Transaction Status')}</DialogTitle>
        <TokenBalance>
          <Flex justifyContent='space-between'>
            <Text fontSize='14px' color='#ffffff'>
              {t('Token')}
            </Text>
            <Flex flexDirection='column'>
              <Flex alignItems='center' justifyContent='flex-end' mb='5px'>
                <img src={getIcon(tokenSymbol)} width='38px' height='38px' alt={tokenSymbol} />
                <Text fontSize='14px' ml='5px'>
                  {tokenSymbol}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent='space-between'>
            <Text fontSize='14px'>{t('Amount')}</Text>
            <Flex>
              <Text>
                {amount.toFixed(get2WayTokenFixed(tokenSymbol))} {tokenSymbol}
              </Text>
            </Flex>
          </Flex>
        </TokenBalance>
        <Grid>
          <Text fontSize='16px'>{t('Network')}</Text>
          <Text style={{visibility: 'hidden'}}>{t('Chain')}</Text>
          {/* <Text textAlign="center" fontSize="16px">{t('Timer')}</Text> */}
          <Text textAlign='right' fontSize='16px'>
            {t('Gas Fee')}
          </Text>
          <Text style={{visibility: 'hidden'}}>-</Text>
        </Grid>
        {txs && txs.length ? (
          <RouterChainItem steps={txs} />
        ) : (
          <Flex width='100%' justifyContent='center' padding='30px 0 40px'>
            <CircularProgress sx={{color: '#ffffff'}} size='18px' />
          </Flex>
        )}
        <Flex justifyContent='center'>
          <Box width='50%'>
            <Button
              onClick={() => {
                // @ts-ignore
                props.onClose()
              }}
              fullWidth
              size='large'
            >
              {t('Close')}
            </Button>
          </Box>
        </Flex>
      </Dialog>
    </>
  )
}
