import React, { useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { Typography, Box, Skeleton } from '@mui/material'
import styled from '@emotion/styled'
import { Chains, CHAIN_ETHER } from '@w3u/chains'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Flex, Text, BoringInput } from 'components/Style'
import TokenSwitcher from 'components/TokenSwitcher'
import ConnectWallet from 'components/ConnectWallet'
import AddLiquidityNotice from 'components/Notice/AddLiquidityNotice'
import MyButton, { OutlineButton } from 'components/Button'
import { getIcon, getChainIcon } from 'utils/icons'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useTwowayAddress, useRelayChainID } from 'hooks'
import { useTwowayOTokens, useTwowayTokens, useAddLiquidityRewards, useDeposit } from 'hooks/useTwoway'
import { useTokensBalance } from 'hooks/useTokenBalance'
import { useApprove } from  'hooks/useApprove'
import { TwowayOToken, TwowayToken } from 'modal/token'
import { getTwoWayLimit, get2WayTokenFixed } from 'utils'
import { displayBalance, getFullDisplayBalanceString } from 'utils/formatBalance'
import BigNumber from 'bignumber.js'
import { BIG_TEN } from 'utils/bigNumber'

const Wrapper = styled.div`
    background: #00000066;
    padding: 30px 20px;
    border-radius: 30px;
    max-width: 580px;
    @media screen and (max-width: 600px) {
        padding: 20px 15px;
    }
`
const InputBox = styled(Box)`
    background: #00000066;  
    border-radius: 12px;
    border: 1px solid #0A214D;
    padding: 17px;
    width: 100%;
`
const InputBoxText = styled(Box)`
    background: #00000066;  
    border-radius: 12px;
    padding: 17px;
    width: 100%;
`
const TipBox = styled(Box)`
    background: #0068ff1a;
    padding: 16px;
    border-radius: 12px;
    margin-bottom: 30px;
`
const LiquidityBox = styled(Flex)`
    margin-top: 30px;
    flex-direction: column;
    background: #00000066;
    border-radius: 30px;
    padding: 30px;
    max-width: 580px;
    @media screen and (max-width: 600px) {
        padding: 20px 15px;
        margin-top: 15px;
    }
`
const Grid = styled(Box)`
    display: grid;
    grid-template-columns: 1.2fr 1fr 0.6fr;
    grid-column-gap: 20px;
`
const LiquidityBoxTitle = styled(Text)`
    /* background: #00000066; */
    padding: 8px 0;
`

const AddLiquity = () => {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const defaultSymbol = searchParams.get('token')
    const history = useNavigate()
    const [loading, setLoading] = useState(false)
    const { chainId, account } = useWeb3React()
    const [amount, setAmount] = useState<string>('')
    const relayChainID = useRelayChainID()
    const twowayAddress = useTwowayAddress()
    const tokens = useTwowayTokens(chainId)
    const oTokens = useTwowayOTokens()
    const queryToken = tokens.find(f => defaultSymbol && f.symbol === defaultSymbol.slice(1))
    const queryOToken = oTokens.find(f => f.symbol === defaultSymbol)
    const defaultToken = defaultSymbol && queryToken ? queryToken : tokens[0]
    const defaultOToken = defaultSymbol && queryOToken ? queryOToken : oTokens[0]
    const [token, setToken] = useState<TwowayToken>(defaultToken)
    const [otoken, setOToken] = useState<TwowayOToken>(defaultOToken)
    const { balances, loading:balanceLoading } = useTokensBalance(tokens.map(d => ({address: d.address, symbol: d.symbol})))
    const { liquidityRewads, loading: ldrLoading, fetchLiqidityRewards } = useAddLiquidityRewards(otoken.address, amount, otoken.decimals)
    const { allowance, loading: apvLoading, fetchApprove, onApprove } = useApprove(token.address, twowayAddress, chainId ?? CHAIN_ETHER)
    const { onDeposit } = useDeposit()

    // change chain update token & balance & approve
    useEffect(() => {
        if (tokens.some(s => s.address.toLocaleLowerCase() === token.address.toLocaleLowerCase())) return
        setToken(tokens[0])
    }, [chainId, tokens])

    const tokenRewad = useMemo(() => {
        return liquidityRewads ? liquidityRewads.find(f => f.chainID === chainId)?.reward ?? '0' : '0'
    }, [liquidityRewads, chainId])
    const balance = useMemo(() => balances.find(f => f.address === token?.address)?.balance ?? '0', [balances, token])
    const amountError = useMemo(() => {
        const blanceBN = new BigNumber(balance).div(BIG_TEN.pow(token.decimals))
        const amountBN = new BigNumber(amount)
        if (amountBN.gt(blanceBN.toString())) {
            return t('balance_is_0')
        }
        return null
    }, [token, balance, amount])
    const handleTokenChange = (address: string) => {
        const token = tokens.find(f => f.address === address) ?? tokens[0]
        setToken(token)
        setOToken(oTokens.find(f => f.symbol === `o${token.symbol}`) ?? oTokens[0])
    }
    const handleMax = () => setAmount(getFullDisplayBalanceString(balance, token.decimals))
    const getBack = () => history(-1)
    const handleDepoist = async () => {
        try {
            setLoading(true)
            const tx = await onDeposit(amount, token)
            // const tx = "0x59157ba4071d080fe0c2145a24f0cf7f645eade5e3b051c723c7de8c622d218b"
            toast(
                <AddLiquidityNotice
                    fromID={chainId ?? CHAIN_ETHER}
                    txHash={tx.hash}
                    amount={amount}
                    symbol={token.symbol}
                    tokenAddress={token.address}
                    receivedAmount={amount}
                />
            )
            fetchLiqidityRewards()
            setAmount('')
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    const handleApprove = async () => {
        try {
            setLoading(true)
            await onApprove()
            fetchApprove()
            setLoading(false)
       } catch (error) {
            setLoading(false)
       }
    }
    useEffect(() => setAmount(''), [token])
    const renderAction = () => {
        return allowance ?
        <MyButton fullWidth loading={loading} disabled={!!amountError || !amount} onClick={handleDepoist}>
            {!amountError ? t('confirm') :
                <Text fontSize="18px" color="#ff0000">{amountError}</Text>
            }
        </MyButton> :
        <MyButton fullWidth loading={loading} onClick={handleApprove}>{t('approve')}</MyButton>
    }

    return (
        <Flex flexDirection="column" alignItems="center">
            <Wrapper>
                <Typography mb="15px" fontSize="18px">{t('add_liquidity')}</Typography>
                <Flex onClick={getBack} sx={{cursor: 'pointer', mb: {md: '30px', xs: '15px'}}}>
                    <KeyboardBackspaceIcon sx={{fontSize: '24px'}} />
                </Flex>
                <Typography variant='subtitle2' mb="6px">
                    {t('twoway_add_notice_1')}
                </Typography>
                 <Typography variant='body2' mb={6}>
                    {t('twoway_add_notice_2')}
                    <strong>{t('twoway_add_notice_3')}</strong>
                </Typography>
                <InputBox sx={{mb: {md: '30px', xs: '15px'}}}>
                    <Flex mb="10px" justifyContent="space-between">
                        <Text fontSize="12px">{t('add')}</Text>
                        {balanceLoading ? <Skeleton width={80} height={15} /> :
                            <Text fontSize="12px">{t('balance')}: {displayBalance(balance, token?.decimals)}</Text>
                        }
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Flex>
                            <TokenSwitcher defaultTokenAddress={defaultToken.address} border={false} tokens={tokens} onChange={handleTokenChange} />
                            <BoringInput value={amount} onChange={e => setAmount(e.target.value)} />
                        </Flex>
                        <OutlineButton style={{height: '26px'}} onClick={handleMax}>MAX</OutlineButton>
                    </Flex>
                </InputBox>
                <Flex justifyContent="center" sx={{cursor: 'pointer', mb: {md: '30px', xs: '15px'}}}>
                    <KeyboardBackspaceIcon sx={{fontSize: '24px', transform: 'rotate(-90deg)'}} />
                </Flex>
                <InputBoxText mb="15px">
                    <Text  mb="18px" fontSize="12px">{t('receive_on_polygon')}</Text>
                    <Flex>
                        <Flex alignItems="center">
                            <img src={getIcon(otoken.symbol)} width="20px" height="20px" alt={otoken.symbol} />
                            <img style={{marginLeft: '-6px', marginTop: '2px'}} src={getChainIcon(relayChainID)} width={16} height={16} alt={Chains[relayChainID].displayName} />
                        </Flex>
                        <Text m="0 10px" fontSize="12px">{otoken.symbol}</Text>
                        <Text fontSize="12px">{amount}</Text>
                    </Flex>
                </InputBoxText>
                <TipBox>
                    <Text mb="15px">
                        {t('twoway_reward_notice', {number: getTwoWayLimit(token?.symbol, 0) + ' ' + token?.symbol})}
                        {/* Bonus (only available when depositing to chains with less than 500,000 USDT/USDC remaining liquidity) */}
                        </Text>
                    <Flex>
                        <Flex alignItems="center">
                            <img src={getIcon(otoken.symbol)} width="20px" height="20px" alt={otoken.symbol} />
                            <img style={{marginLeft: '-6px', marginTop: '2px'}} src={getChainIcon(relayChainID)} width={16} height={16} alt={Chains[relayChainID].displayName} />
                        </Flex>
                        <Text m="0 10px" fontSize="12px">{otoken.symbol}</Text>
                        <Text fontSize="12px">{displayBalance(tokenRewad, otoken.decimals)}</Text>
                    </Flex>
                </TipBox>
                <Flex justifyContent="center">
                    <Box sx={{width: {md: '60%', xs: '100%'}}}>
                        {account ? renderAction() : <ConnectWallet />}
                    </Box>
                </Flex>
            </Wrapper>
            {liquidityRewads &&
                <LiquidityBox>
                    <Text mb="20px">{t('bonus_calculation')}</Text>
                    <Grid mb="2px">
                        <LiquidityBoxTitle>{t('chain')}</LiquidityBoxTitle>
                        <LiquidityBoxTitle>{t('liquidity')}</LiquidityBoxTitle>
                        <LiquidityBoxTitle>{t('estimated_bonus')}</LiquidityBoxTitle>
                    </Grid>
                    {liquidityRewads.map(d => (
                        <Grid padding="18px 0">
                            <Flex>
                                <img src={getChainIcon(d.chainID)} width="20px" height="20px" alt={Chains[d.chainID].displayName} />
                                <Text ml="10px">{Chains[d.chainID].displayName}</Text>
                            </Flex>
                            <Flex>
                                <img src={getIcon(otoken.symbol)} width="20px" height="20px" alt={otoken.symbol} />
                                <Text ml="10px">{displayBalance(d.liquidity, otoken.decimals, get2WayTokenFixed(otoken.symbol))}</Text>
                            </Flex>
                            <Text textAlign="right">{displayBalance(d.reward, otoken.decimals, get2WayTokenFixed(otoken.symbol))}</Text>
                        </Grid>
                    ))}
                </LiquidityBox>
            }
        </Flex>
    )
}

export default AddLiquity
