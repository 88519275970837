import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import { Flex, Text, BoringInput } from 'components/Style'
import useQuery from 'hooks/useQuery'
import { PrincipleWrapper, InputBox, MobileStep, NumberIcon, Line } from '../../style'

const PrincipleWrapperPrgress = styled(PrincipleWrapper)`
    padding: 15px;
`
interface Props {
    txID: string
    token: string
    oToken: string
    multiAddres: string
}
const GuidePrgress:React.FC<Props> = ({ txID, token, oToken, multiAddres }) => {
    const { t } = useTranslation()
    const [txId, setTxId] = useState(txID)
    const [query, setQuery]= useState(false)
    const { confirmation, step, error, fetchCheck } = useQuery(token, multiAddres)
    const handleQuery = () => {
        fetchCheck(txId)
        setQuery(true)
    }
    useEffect(() => {
        if (txId) { handleQuery() }
    }, [])
    return (
        <>
            {!query ?
                <PrincipleWrapperPrgress style={{marginBottom: '15px'}}>
                    <Text textAlign="center">{t('completed_3', {token: oToken})}</Text>
                    <InputBox><BoringInput placeholder={t('enter_TXID')} onChange={e => setTxId(e.target.value)} /></InputBox>
                    <Flex><Button sx={{height: '50px'}} fullWidth onClick={handleQuery}>{t('view_progress')}</Button></Flex>
                </PrincipleWrapperPrgress> :
                <PrincipleWrapperPrgress style={{marginBottom: '15px'}}>
                    <MobileStep>
                        <Flex alignItems="center">
                            <NumberIcon actived={step >= 0}>1</NumberIcon>
                            <Text color={step >= 0 ? '#0068FF' : '#ffffff'} ml="10px">{t('confirmations(0/6)', {number:  confirmation >= 6 ? 6 : confirmation})}</Text>
                        </Flex>
                        <Line />
                        <Flex alignItems="center">
                            <NumberIcon actived={step >= 1}>2</NumberIcon>
                            <Text color={step >= 1 ? '#0068FF' : '#ffffff'} ml="10px">
                                {error ? t('mint_step_7_2_wrong') : t('mint_step_7_2_right')}
                            </Text>
                        </Flex>
                        <Line />
                        <Flex alignItems="center">
                            <NumberIcon actived={step >= 2}>3</NumberIcon>
                            <Text color={step >= 2 ? '#0068FF' : '#ffffff'} ml="10px">
                                {error ? t('mint_step_7_3_wrong', {type: token}) : t('mint_step_7_3_right')}
                            </Text>
                        </Flex>
                        <Line />
                        <Flex alignItems="center">
                            <NumberIcon actived={step >= 3}>4</NumberIcon>
                            <Text color={step >= 3 ? '#0068FF' : '#ffffff'} ml="10px">
                                {error ? t('mint_step_7_4_wrong') : t('mint_step_7_4_right')}
                            </Text>
                        </Flex>
                    </MobileStep>
                </PrincipleWrapperPrgress>
            }
            <PrincipleWrapperPrgress>
                <Text color="#ffffff" mb="4px">{t('mint_query_0')}</Text>
                <Flex flexDirection="column" alignContent="center">
                    <Flex>
                        <Text>1、</Text>
                        <Text>
                            {t('mint_query_1_1')}
                            {t('mint_query_1_2')}
                        </Text>
                    </Flex>
                    <Flex><Text>2、</Text> <Text>{t('mint_query_2')}</Text></Flex>
                    <Flex>
                        <Text>3、</Text>
                        <Text>{t('mint_query_3')}</Text>
                    </Flex>
                    <Flex><Text>4、</Text>
                        <Text>
                            {t('mint_query_4_1')}
                            {t('mint_query_4_2')}
                        </Text>
                    </Flex>
                </Flex>
            </PrincipleWrapperPrgress>
        </>
    )
}

export default GuidePrgress