import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const ShareIcon:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 20 20">
            <path fill={color} d="M16.0526 20H3.94737C1.7685 19.9971 0.00289937 18.2315 0 16.0526V3.94737C0.00289937 1.7685 1.7685 0.00289937 3.94737 0H10C10.436 0 10.7895 0.353459 10.7895 0.789474C10.7895 1.22549 10.436 1.57895 10 1.57895H3.94737C2.64053 1.58184 1.58184 2.64053 1.57895 3.94737V16.0526C1.58184 17.3595 2.64053 18.4182 3.94737 18.4211H16.0526C17.3595 18.4182 18.4182 17.3595 18.4211 16.0526V10C18.4211 9.56399 18.7745 9.21053 19.2105 9.21053C19.6465 9.21053 20 9.56399 20 10V16.0526C19.9971 18.2315 18.2315 19.9971 16.0526 20Z" />
            <path fill={color} d="M9.7369 11.0525C9.52771 11.0517 9.32725 10.9685 9.17901 10.8209C9.02986 10.6736 8.94592 10.4726 8.94592 10.263C8.94592 10.0534 9.02986 9.85244 9.17901 9.7051L18.6527 0.231418C18.9638 -0.0584335 19.4485 -0.0498809 19.7491 0.250763C20.0498 0.551408 20.0583 1.03614 19.7685 1.34721L10.2948 10.8209C10.1466 10.9685 9.9461 11.0517 9.7369 11.0525Z"/>
            <path fill={color} d="M19.2106 1.57895H15C14.564 1.57895 14.2106 1.22549 14.2106 0.789474C14.2106 0.353459 14.564 6.49712e-09 15 0H19.2106C19.6466 9.18831e-09 20 0.353459 20 0.789474C20 1.22549 19.6466 1.57895 19.2106 1.57895Z"/>
            <path fill={color} d="M19.2105 5.78947C18.7745 5.78947 18.421 5.43601 18.421 5V0.789474C18.421 0.353459 18.7745 0 19.2105 0C19.6465 0 20 0.353459 20 0.789474V5C20 5.43601 19.6465 5.78947 19.2105 5.78947Z"/>
        </SvgIcon>
    )
}

export default ShareIcon