import { CHAIN_ETHER, CHAIN_POLYGON, CHAIN_IOTEX } from '@w3u/chains'
import { Address } from 'modal/types'
import {
    MULTICALL_NETWORKS,
    TWOWAY_ADDRESSES,
    OUSDT_TOKENS,
    TWOWAY_TOKENS
} from 'config/constants'
import { TWOWAY_CHEF_ADDRESSES } from 'config/constants/twoway'
import { NATIVE_BRIDGE_ADDRESSES, NATIVE_BRIDGE_TOKENS } from 'config/constants/native_bridge'
import { BordingDAO_TOKEN, TOKEN_BORING_PPTOKEN } from 'config/constants/pow_bridge'
import { BoringTunnel_TOKEN, TOKEN_BORING, TOKEN_BOR,
    BoringFeePool_TOKEN, BoringBoringDAO_TOKEN } from 'config/constants/tunnel'
import { BridgeStatus } from 'modal/types'
import {ROUTER_ADDRESSES} from "../config/constants/router";

export const getAddress = (address: Address, chainId?: number): string => {
    const _chainId = chainId ?? CHAIN_ETHER
    return address[_chainId]
}

export const getTwowayAddress = (chainId?: number): string => {
    const _chainId = chainId ?? CHAIN_ETHER
    return TWOWAY_ADDRESSES[_chainId]
}

export const getRouterAddress = (chainId?: number): string => {
    const _chainId = chainId ?? CHAIN_ETHER
    return ROUTER_ADDRESSES[_chainId]
}

export const getMulticallAddress = (chainId?: number) => {
    return getAddress(MULTICALL_NETWORKS, chainId)
}

export const getTwowayChefAddress = (relayChainID?: number) => {
    return TWOWAY_CHEF_ADDRESSES[relayChainID ?? CHAIN_POLYGON]
}

export const getNativeBridgeAddress = (chainId?: number): string => {
    const _chainId = chainId ?? CHAIN_ETHER
    return _chainId !== CHAIN_IOTEX ? NATIVE_BRIDGE_ADDRESSES[_chainId] : BridgeStatus.notsupport
}

export const getBoringDAOAddress = (token: string, chainId?: number): string => {
    const _chainId = chainId ?? CHAIN_ETHER
    return BordingDAO_TOKEN(token)[_chainId]
}
export const getBoringTunnelAddress = (token: string, chainId?: number): string => {
    const _chainId = chainId ?? CHAIN_ETHER
    return BoringTunnel_TOKEN(token)[_chainId]
}

// Tunnnel
export const getBoringAddress = (chainId?: number) => {
    const _chainId = chainId ?? CHAIN_ETHER
    return TOKEN_BORING[_chainId]
}
export const getBorAddress = (chainId?: number) => {
    const _chainId = chainId ?? CHAIN_ETHER
    return TOKEN_BOR[_chainId]
}

export const getBoringFeePoolAddress = (token: string, chainId?: number) => {
    const _chainId = chainId ?? CHAIN_ETHER
    return BoringFeePool_TOKEN(token)[_chainId]
}
export const getBoringBoringDAOlAddress = (token: string) => {
    // const _chainId = chainId ?? CHAIN_ETHER
    // TODO Only support ETH Chain
    return BoringBoringDAO_TOKEN(token)[CHAIN_ETHER]
}
export const getBoringPPtokenAddress = (token: string) => {
    return TOKEN_BORING_PPTOKEN(token)[CHAIN_ETHER]
}
export const getOTokenByAddress = (address: string) => {
    return OUSDT_TOKENS.find(f => f.address.toLocaleLowerCase() === address.toLocaleLowerCase())
}

export const getTwowayTokenByAddress = (address: string) => {
    return TWOWAY_TOKENS.find(f => f.address.toLocaleLowerCase() === address.toLocaleLowerCase())
}

export const getNativeTokenByAddress = (address: string) => {
    return NATIVE_BRIDGE_TOKENS.find(f => f.address.toLocaleLowerCase() === address.toLocaleLowerCase())
}
