import { useState, useEffect, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { boringClient, oPortalClient } from 'apollo/client'
import { boringFarm, oportalFarm } from 'apollo/queries'
import { IFarmHistory } from 'modal/types'

export const useBoringFarm = () => {
    const { account } = useWeb3React()
    const [data, setData] = useState<IFarmHistory []>([])
    const fetchBoringFarmData = useCallback(async () => {
        try {
            if (!account) return
            const result = await boringClient.query({
                query: boringFarm(account),
                fetchPolicy: 'cache-first',
            })
            const data: IFarmHistory [] = result.data.boringFarmEntities
            setData(data)
        } catch (error) {
            console.error('Unable to fetch boring farm data:', error)
        }
    }, [account])
    useEffect(() => {
        if (account) {
            fetchBoringFarmData()
        }
    }, [account])

    return { data, fetchBoringFarmData }
}

export const useOportalFarm = () => {
    const { account } = useWeb3React()
    const [data, setData] = useState<IFarmHistory []>([])
    const fetchBoringFarmData = useCallback(async () => {
        try {
            if (!account) return
            const result = await oPortalClient.query({
                query: oportalFarm(account),
                fetchPolicy: 'cache-first',
            })
            const data: IFarmHistory [] = result.data.oportalFarmEntities
            setData(data)
        } catch (error) {
            console.error('Unable to fetchn Oportal farm data:', error)
        }
    }, [account])
    useEffect(() => {
        if (account) {
            fetchBoringFarmData()
        }
    }, [account])

    return { data, fetchBoringFarmData }
}