import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const LodingIcon:React.FC<IconProps> = ({ color = "#ffffff" }) => {
    return (
        <SvgIcon viewBox="0 0 12 12">
            <g opacity="0.8">
                <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M0 6C0 2.68653 2.68623 0 6 0C9.31377 0 12 2.68652 12 6C12 9.31406 9.31377 12 6 12C2.68623 12 0 9.31406 0 6ZM0.9 6C0.9 8.81659 3.18341 11.1 6 11.1C8.81661 11.1 11.1 8.81659 11.1 6C11.1 3.18339 8.81661 0.9 6 0.9C3.18341 0.9 0.9 3.18339 0.9 6Z" />
                <path fill={color} d="M9.4336 6.00059H6.44297V2.55059C6.44297 2.30157 6.24141 2.10059 5.99297 2.10059C5.74425 2.10059 5.54297 2.30157 5.54297 2.55059V6.45059C5.54297 6.69902 5.74425 6.90059 5.99297 6.90059C6.01689 6.90059 6.04035 6.89869 6.06324 6.89508C6.08616 6.89868 6.10965 6.90059 6.13359 6.90059H9.43359C9.68203 6.90059 9.88359 6.69902 9.88359 6.45059C9.8836 6.20157 9.68204 6.00059 9.4336 6.00059Z" />
            </g>
        </SvgIcon>
    )
}

export default LodingIcon
