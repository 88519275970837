import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Text } from 'components/Style'

interface Props {
  open: boolean
  onClose: () => void
  title: string
  context?: string
}

const AlertDialog: React.FC<Props> = ({ open, onClose, title, context }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{padding: "20px"}}
      >
        <Text textAlign="center" fontSize="18px" color="#ffffff">
          {title}
        </Text>
        {context && 
          <DialogContent>
            <DialogContentText>
              {context}
            </DialogContentText>
          </DialogContent>
        }
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  )
}

export default AlertDialog
