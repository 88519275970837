import {
  CHAIN_ARBITRUM,
  CHAIN_AURORA,
  CHAIN_AVALANCHE,
  CHAIN_AVALANCHE_TESTNET,
  CHAIN_BOBA,
  CHAIN_BSC,
  CHAIN_BSC_TESTNET,
  CHAIN_ETHER,
  CHAIN_FANTOM,
  CHAIN_FANTOM_TESTNET,
  CHAIN_HARMONY_0,
  CHAIN_HARMONY_0_TESTNET,
  CHAIN_HECO,
  CHAIN_HECO_TESTNET,
  CHAIN_METIS,
  CHAIN_OK,
  CHAIN_OK_TESTNET,
  CHAIN_OPTIMISM,
  CHAIN_POLYGON,
  CHAIN_POLYGON_MUMBAI,
  CHAIN_XDAI,
  CHAIN_XDAI_TESTNET,
  CHAIN_KCC,
  CHAIN_IOTEX,
  CHAIN_BLAST,
} from '@w3u/chains'

import AAVE from 'assets/images/aave.png'
import Add from 'assets/images/add.png'
import AMY from 'assets/images/amy.png'
import ArrowForward from 'assets/images/arrow-forward.png'
import Boring from 'assets/images/bor.0a18b81a.png'
import ArbitrumIcon from 'assets/images/chain/arbitrum.svg'
import Aurora from 'assets/images/chain/aurora.svg'
import AvalancheIcon from 'assets/images/chain/avalanche.svg'
import BOBA from 'assets/images/chain/boba.png'
import BSCIcon from 'assets/images/chain/bsc.png'
import EthereumIcon from 'assets/images/chain/ethereum-color.png'
import ETH from 'assets/images/chain/ethereum.png'
import FantomIcon from 'assets/images/chain/fantom.svg'
import HarmonyIcon from 'assets/images/chain/harmony.svg'
import HecoIcon from 'assets/images/chain/heco.png'
import Metis from 'assets/images/chain/metis.png'
import OKIcon from 'assets/images/chain/okc.svg'
import Optimism from 'assets/images/chain/optimism.svg'
import PolygonIcon from 'assets/images/chain/polygon.png'
import XDaiIcon from 'assets/images/chain/xdai.png'
import CRV from 'assets/images/crv.png'
import DiamondPrimary from 'assets/images/diamond-primary.png'
import Diamond from 'assets/images/diamond.png'
import FIN from 'assets/images/fin.png'
import Fire from 'assets/images/fire.png'
import IZI from 'assets/images/izi.png'
import LOL from 'assets/images/lol.png'
import LOWB from 'assets/images/lowb.png'
import NSure from 'assets/images/nsure.png'
import oUSDT from 'assets/images/oUSDT.png'
import OBTC from 'assets/images/obtc.png'
import BTC from 'assets/images/btc.png'
import OLTC from 'assets/images/oltc.png'
import LTC from 'assets/images/ltc.png'
import OPortal from 'assets/images/oportal.png'
import OUSDC from 'assets/images/ousdc.png'
import USDC from 'assets/images/usdc.png'
import USDT from 'assets/images/usdt.png'
import VEMP from 'assets/images/vemp.png'
import VIDY from 'assets/images/vidy.png'
import WSG from 'assets/images/wsg.png'
import XVEMP from 'assets/images/xvemp.jpg'
import ODOGE from 'assets/images/odoge.svg'
import DOGE from 'assets/images/doge.png'
import Tag from 'assets/images/tag.png'
import BNB from 'assets/images/bnb.png'
import KCC from 'assets/images/chain/kcc.svg'
import IOTEX from 'assets/images/chain/iotex.svg'
import Blast from 'assets/images/chain/blast.jpg'

import Uniswap from 'assets/images/eco/uniswap.svg'
import Huobi from 'assets/images/eco/huobi.svg'
import Sushi from 'assets/images/eco/sushi.svg'
import Dodo from 'assets/images/eco/dodo.svg'
import Bancor from 'assets/images/eco/bancor.svg'
import Pancake from 'assets/images/eco/pancake.svg'
import Wault from 'assets/images/eco/wault.svg'
import Hermes from 'assets/images/eco/hermes.svg'
import Mojitoswap from 'assets/images/eco/mojitoswap.svg'
import Kswap from 'assets/images/eco/kswap.svg'
import Oolongswap from 'assets/images/eco/oolongswap.svg'
import Bitmart from 'assets/images/eco/bitmart.svg'
import CEX from 'assets/images/eco/ex.svg'
import Gate from 'assets/images/eco/gate.svg'
import Mexc from 'assets/images/eco/mexc.svg'
import Biture from 'assets/images/eco/biture.svg'
import OKCIcon from 'assets/images/eco/okc.svg'
import Boabicon from 'assets/images/eco/boba.svg'
import Convex from 'assets/images/eco/convex.svg'
import Curve from 'assets/images/eco/curve.svg'
import Yearn from 'assets/images/eco/yearn.svg'
import Fortube from 'assets/images/eco/fortube.png'

import BtcWallet from 'assets/images/mint/btc_wallet.svg'
import EthWallet from 'assets/images/mint/eth_wallet.svg'
import {CHAIN_BTC, CHAIN_DOGE, CHAIN_LTC} from '../config/constants/origin'
import BTCIcon from 'assets/images/btc.png'
import LTCIcon from 'assets/images/ltc.png'
import DOGEIcon from 'assets/images/doge.png'
import CPIcon from 'assets/images/cp.png'
import WisgaIcon from 'assets/images/wisga.png'

export const getIcon = (symbol: string | undefined): string => {
  switch (symbol) {
    case 'LSD':
      return 'https://assets.coingecko.com/coins/images/29519/small/logo.png?1679448801'
    case 'ETHx':
      return 'https://assets.coingecko.com/coins/images/29780/small/ethx.png?1681284433'
    case 'WISTA':
      return WisgaIcon
    case 'CP':
      return CPIcon
    case 'ETH':
      return EthereumIcon
    case 'BSC':
      return BSCIcon
    case 'BORING':
      return Boring
    case 'BOR':
      return Boring
    case 'VIDY':
      return VIDY
    case 'Nsure':
      return NSure
    case 'oLTC':
      return OLTC
    case 'FIN':
      return FIN
    case 'oFIN':
      return FIN
    case 'USDT':
      return USDT
    case 'oBTC':
      return OBTC
    case 'BTC':
      return BTC
    case 'LTC':
      return LTC
    case 'DOGE':
      return DOGE
    case 'oportal':
      return OPortal
    case 'VEMP':
      return VEMP
    case 'xVEMP':
      return XVEMP
    case 'AMY':
      return AMY
    case 'AAVE':
      return AAVE
    case 'CRV':
      return CRV
    case 'WSG':
      return WSG
    case 'oUSDT':
      return oUSDT
    case 'oUSDC':
      return OUSDC
    case 'LOWB':
      return LOWB
    case 'USDC':
      return USDC
    case 'FIRE':
      return Fire
    case 'LOL':
      return LOL
    case 'oLOL':
      return LOL
    case 'oIZI':
      return IZI
    case 'IZI':
      return IZI
    case 'METIS':
    case 'oMETIS':
      return Metis
    case 'oDOGE':
      return ODOGE
    case 'DOGE':
      return DOGE
    case 'BNB':
      return BNB
    case 'oETH':
      return EthereumIcon
    default:
      return USDT
  }
}

export const getImage = (name: string): string => {
  switch (name) {
    case 'arrow-forward':
      // return boringAssetPrefix + 'arrow-forward.png'
      return ArrowForward
    case 'add':
      return Add
    case 'diamond':
      return Diamond
    case 'diamond-primary':
      return DiamondPrimary
    case 'tag':
      return Tag
    default:
      return ''
  }
}

export const getChainIcon = (chainID: number) => {
  switch (chainID) {
    case CHAIN_BLAST:
      return Blast
    case CHAIN_BTC:
      return BTCIcon
    case CHAIN_LTC:
      return LTCIcon
    case CHAIN_DOGE:
      return DOGEIcon
    case CHAIN_ETHER:
      return EthereumIcon
    case CHAIN_METIS:
      return Metis
    case CHAIN_BSC:
      return BSCIcon
    case CHAIN_BSC_TESTNET:
      return BSCIcon
    case CHAIN_OK:
      return OKIcon
    case CHAIN_OK_TESTNET:
      return OKIcon
    case CHAIN_HARMONY_0:
      return HarmonyIcon
    case CHAIN_HARMONY_0_TESTNET:
      return HarmonyIcon
    case CHAIN_AVALANCHE:
      return AvalancheIcon
    case CHAIN_AVALANCHE_TESTNET:
      return AvalancheIcon
    case CHAIN_FANTOM:
      return FantomIcon
    case CHAIN_FANTOM_TESTNET:
      return FantomIcon
    case CHAIN_HECO:
      return HecoIcon
    case CHAIN_HECO_TESTNET:
      return HecoIcon
    case CHAIN_POLYGON:
      return PolygonIcon
    case CHAIN_POLYGON_MUMBAI:
      return PolygonIcon
    case CHAIN_XDAI:
      return XDaiIcon
    case CHAIN_XDAI_TESTNET:
      return XDaiIcon
    case CHAIN_ARBITRUM:
      return ArbitrumIcon
    case CHAIN_OPTIMISM:
      return Optimism
    case CHAIN_BOBA:
      return BOBA
    case CHAIN_AURORA:
      return Aurora
    case CHAIN_KCC:
      return KCC
    case CHAIN_IOTEX:
      return IOTEX
    case -1:
      return CEX
    default:
      return EthereumIcon
  }
}

export const getEcoIcon = (name: string) => {
  switch (name) {
    case 'uniswap':
      return Uniswap
    case 'huobi':
      return Huobi
    case 'sushi':
      return Sushi
    case 'dodo':
      return Dodo
    case 'bancor':
      return Bancor
    case 'pancake':
      return Pancake
    case 'wault':
      return Wault
    case 'hermes':
      return Hermes
    case 'mojitoswap':
      return Mojitoswap
    case 'kswap':
      return Kswap
    case 'oolongswap':
      return Oolongswap
    case 'okc':
      return OKIcon
    case 'huobi':
      return HecoIcon
    case 'bitmart':
      return Bitmart
    case 'biture':
      return Biture
    case 'gate':
      return Gate
    case 'mexc':
      return Mexc
    case 'Boab':
      return Boabicon
    case 'convex':
      return Convex
    case 'curve':
      return Curve
    case 'yearn':
      return Yearn
    case 'fortube':
      return Fortube
    default:
      return Uniswap
  }
}
// import Convex from 'assets/images/eco/convex.svg'
// import Curve from 'assets/images/eco/curve.svg'
// import Yearn from 'assets/images/eco/yearn.svg'
// import Fortube from 'assets/images/eco/fortube.png'

export const getMintIcon = (name: string) => {
  switch (name) {
    case 'oBTC':
      return BtcWallet
    case 'oLTC':
      return BtcWallet
    case 'oDOGE':
      return BtcWallet
    case 'oETH':
      return EthWallet
  }
}
