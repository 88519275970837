import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const DownarrowIcon:React.FC<IconProps> = ({ color = "#ffffff" }) => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path fill={color} d="M9.5687 11.6431L5.44009 15.7717L5.42678 15.785L5.42049 15.7923L5.41324 15.7986L5.40501 15.8058L5.39872 15.8109L5.38953 15.8191L5.37912 15.8274L5.35735 15.8429L5.34284 15.8542L5.32832 15.8646L5.31284 15.875H5.30655L5.29736 15.8842H5.28187L5.27268 15.8956H5.26639L5.25405 15.9089C4.94536 16.0734 4.56482 16.0107 4.32508 15.7562L0.217036 11.6431C-0.0798001 11.3354 -0.071091 10.8453 0.236631 10.5484C0.536854 10.2589 1.01247 10.2591 1.31221 10.5489L4.11872 13.3554V0.774144C4.11872 0.346671 4.46539 0 4.89287 0C5.32034 0 5.66701 0.346671 5.66701 0.774144V13.3554L8.47449 10.5489C8.766 10.2361 9.25589 10.2189 9.5687 10.5102C9.8815 10.8017 9.89867 11.2916 9.6074 11.6044C9.59506 11.6177 9.582 11.6305 9.5687 11.6431Z"/>
        </SvgIcon>
    )
}

export default DownarrowIcon
