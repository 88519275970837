import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Typography, Box } from '@mui/material'
import styled from '@emotion/styled'
import { CHAIN_ETHER } from '@w3u/chains'
import { Flex, Text } from 'components/Style'
import useMultiAddress from 'hooks/useBook'
import { getImage } from 'utils/icons'
import Tab from './components/Tab'
import Remaint from './components/Remaint'
import GuideOne from './components/GuideOne'
import GuideTwo from './components/GuideTwo'
import GuideTree from './components/GuideTree'
import GuideQuery from './components/GuideQuery'
import GuideCalculator from './components/GuideCalculator'
import MobileMint from './MobileMint'

const MintWrapper = styled(Flex)`
    padding: 30px;
    border-radius: 15px;
    background: #00000066;
    justify-content: space-between;
    width: 100%;
`
const MintWrapperLeft = styled(Flex)`
    border-radius: 30px 0 0 30px;
    padding-right: 30px;
    flex-direction: column;
    flex: 1 1 auto;
`
const GuideBox = styled(Box)`
    background: #00000066;
    padding: 30px;
    border-radius: 16px;
    margin-top: 30px;
`
const TagBoxGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    margin-top: 30px;
`
const TagBox = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    background: #000000;
`

const Mint = () => {
    const [searchParams] = useSearchParams()
    const tokenByUrl =  searchParams.get('token')
    const { t } = useTranslation()
    const matches = useMediaQuery('(max-width:600px)')
    const [mintToken, setMintToken] = useState(tokenByUrl ? `o${tokenByUrl}` : 'oBTC')
    const [step, setStep] = useState(0)
    const [txID, setTxID] = useState('')
    const token = useMemo(() => mintToken.slice(1) ?? 'BTC', [mintToken])
    const GuideTitle = useMemo(() => {
        let title = t('mint_guide')
        if (step === 3) {
            title = t('mint_progress_query')
        } else if (step === 4) {
            title = t('mint_calaulator')
        }
        return title
    }, [step])
    const { multiAddress: multiAddresss } = useMultiAddress()
    const multiAddres = useMemo(() => multiAddresss[token], [multiAddresss, token])

    const handleMintToken = (t: string) => {
        setMintToken(t)
    }
    const handleNext = (i: number) => {
        setStep(i)
    }
    const handleConfirm = (i: number, txId: string) => {
        setStep(i)
        setTxID(txId)
    }
    useEffect(() => {
        if (step !== 2) {
            setTxID('')
        }
    }, [step])
    

    return (
        <>
        {matches ? <MobileMint /> :
            <MintWrapper>
                <MintWrapperLeft>
                    <Box><Tab defaultToken={mintToken} onToken={handleMintToken} /></Box>
                    <Typography fontSize="28px" mt="30px" fontFamily="Lexend-Medium, Lexend">
                        {GuideTitle}
                    </Typography>
                    <Typography fontSize="12px" fontFamily="Lexend-Medium, Lexend" sx={{opacity: 0.6}}>
                        {t('mint_guide_1')}
                    </Typography>
                    <GuideBox>
                        {step === 0 && <GuideOne oToken={mintToken} onNext={handleNext} />}
                        {step === 1 && <GuideTwo multiAddres={multiAddres} oToken={mintToken} onNext={handleNext} />}
                        {step === 2 && <GuideTree oToken={mintToken} onNext={handleConfirm} />}
                        {step === 3 && <GuideQuery txID={txID} multiAddres={multiAddres} oToken={mintToken} onNext={handleNext} />}
                        {step === 4 && <GuideCalculator oToken={mintToken} onNext={handleNext} />}
                    </GuideBox>
                    {
                        step === 4 && 
                        <TagBoxGrid>
                            <TagBox>
                                <img width="28px" height="28px" src={getImage('tag')} alt="Tag" />
                                <Flex ml="12px" flexDirection="column">
                                    {mintToken === 'oBTC' && <Text>{t('Network Fee: 0.003 BTC')}</Text>}
                                    {mintToken === 'oLTC' && <Text>{t('Network Fee: 0.8 LTC')}</Text>}
                                    {mintToken === 'oDOGE' && <Text>{t('Network Fee: 800 Doge')}</Text>}
                                    {/* <Text>{t('Network fee: around 0.3% (required by the multi-signers for the transaction fee of oBTC)')}</Text> */}
                                </Flex>
                            </TagBox>
                            <TagBox>
                                <img width="28px" height="28px" src={getImage('tag')} alt="Tag" />
                                <Flex ml="12px" flexDirection="column">
                                    <Text>{t('The calculated result is for estimation only. Actual cost will be subject to the casting time.')}</Text>
                                </Flex>
                            </TagBox>
                        </TagBoxGrid>
                    }
                </MintWrapperLeft>
                <Remaint step={step} multiAddres={multiAddres} oToken={mintToken} onNext={handleNext} />
            </MintWrapper>
        }
        </>
    )
}

export default Mint
