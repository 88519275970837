import { useMemo } from 'react';
import { Link } from 'react-router-dom'
import { Flex, Text } from 'components/Style'
import { Chains, CHAIN_ETHER, CHAIN_IOTEX, CHAIN_OK, CHAIN_POLYGON } from '@w3u/chains'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useWeb3React } from '@web3-react/core'
import Balance from 'components/Balance';
import { getIcon, getChainIcon } from 'utils/icons';
import { useTwowayLockBalances, useUserLiquidity } from 'hooks/useTwoway'
import { useTwowayChef } from 'hooks/useTwowayChef'
import { getBalanceNumber } from 'utils/formatBalance'
import { usePrice } from 'hooks/usePrice'
import { displayTwowayBoringAPY, displayTwowayOTokenAPY } from 'utils/calc'
import { setupNetwork } from 'utils/network'
import { TwowayChefInfo } from 'modal/twoway'
import MobileLiquidity from './Mobile'
import { WrapperLiquity, Gride, SubGride, GrideSkeleton, TokenImg, ChainList, ListText } from './style'

const SkeletonList = () => {
    return (
        <>
            {[1, 2, 3, 4].map(d => (
                <GrideSkeleton key={d}>
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                </GrideSkeleton>
            ))}
        </>
    )
}

const Liquity = () => {
    const { t } = useTranslation()
    const matches = useMediaQuery('(max-width:600px)')
    const { chainId } = useWeb3React()
    const boringPrice = usePrice('BORING')
    const { lockBalances, loading, fetchLockLiquidity } = useTwowayLockBalances()
    const { oTokenBalanes, fetchUserLiquidity } = useUserLiquidity()
    const { farms, fetchFarmChef } = useTwowayChef()

    const liqudityList = useMemo(() => {
        return lockBalances.map(b => {
            const oToken = oTokenBalanes.find(f => f.symbol === b.tokenName)
            const totalLiquidity = b.balances.reduce((total, current) => new BigNumber(current.balance).plus(total), new BigNumber(0))
            const farm: TwowayChefInfo | undefined = farms?.find(f => f.token.symbol === b.tokenName)
            let balances = b.balances
            if (b.tokenName === 'oIZI') {
                balances = b.balances.filter(f => [CHAIN_POLYGON, CHAIN_ETHER].indexOf(f.chainID) > -1)
            }
            if (b.tokenName === 'oIZI' || b.tokenName === 'oFIN') {
                balances = b.balances.filter(f => [CHAIN_POLYGON, CHAIN_ETHER, CHAIN_OK].indexOf(f.chainID) > -1)
            }
            if (b.tokenName === 'oETH') {
                balances = b.balances.filter(f => f.chainID !== CHAIN_IOTEX)
            }
            const bordingApr = farm ? displayTwowayBoringAPY(farm, boringPrice) : '0.00'
            const oTokenApr = farm ? displayTwowayOTokenAPY(farm) : '0.00'
            return {
                ...b, 
                balances,
                totalLiquidity,
                userBalance: oToken?.balance,
                decimals: oToken?.decimals,
                farmApr: new BigNumber(bordingApr).plus(oTokenApr).toFixed()
            }
        })
    }, [lockBalances, oTokenBalanes, farms, boringPrice])

    const changeChain = async (chainId: number) => {
        await setupNetwork(chainId)
            .catch((e: any) => console.error('Setup network: ', e))
    }
    return (
        <>
        {matches ? <MobileLiquidity datas={liqudityList} /> :
            <WrapperLiquity>
                <Text fontSize="18px" mb="20px">{t('liquidity')}</Text>
                <Box>
                    <Box mb="20px" padding="10px" marginRight="16px">
                        <Gride>
                            <Text marginLeft="-10px">{t('Token')}</Text>
                            <Text>{t('chain')}</Text>
                            <Text textAlign="right">{t('total_liquidity')}</Text>
                            <Text textAlign="right">{t('your_liquidity')}</Text>
                            <Text textAlign="center">{t('farm_apy')}</Text>
                            {/* <Text>Detail</Text> */}
                        </Gride>
                    </Box>
                    {liqudityList.length ? liqudityList.map((token, i) => (
                        <Accordion
                            key={i}
                            sx={{
                                marginBottom: '20px',
                                borderRadius: '10px',
                                background: '#00000033 !important',
                                boxShadow: 'none',
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id={`${i}-header`}
                            >
                                <Gride>
                                    <TokenImg>
                                        <img src={getIcon(token.tokenName)} width="20px" height="20px" alt={token.tokenName} />
                                        <img src={getChainIcon(CHAIN_POLYGON)} width="16px" height="16px" alt={Chains[CHAIN_POLYGON].displayName} />
                                        <ListText ml="10px">{token.tokenName}</ListText>
                                    </TokenImg>
                                    <ChainList>
                                        {token.balances.map(chain => (<img key={chain.chainID} src={getChainIcon(chain.chainID)} width="16px" height="16px" alt="BOR" />))}
                                    </ChainList>
                                    <Balance textAlign="right" color="#ffffff" value={getBalanceNumber(token.totalLiquidity, token.decimals)} />
                                    <Balance textAlign="right" color="#ffffff" value={getBalanceNumber(new BigNumber(token.userBalance ?? '0'), token.decimals)} />
                                    <ListText textAlign="center">{token.farmApr}%</ListText>
                                    <ListText textAlign="right" pr="6px">{t('detail')}</ListText>
                                </Gride>
                            </AccordionSummary>
                            <AccordionDetails sx={{marginRight: '18px', padding: '0'}}>
                                {token.balances.map(d => (
                                    <SubGride key={d.chainID}>
                                        <Flex alignItems="center" sx={{gridColumnStart: 2}}>
                                            <img src={getChainIcon(d.chainID)} width="20px" height="20px" alt={Chains[d.chainID].displayName} />
                                            <ListText ml="6px">{Chains[d.chainID].displayName}</ListText>
                                        </Flex>
                                        <Balance textAlign="right" color="#ffffff" value={getBalanceNumber(new BigNumber(d.balance), token.decimals)} />
                                        <ListText textAlign="right"></ListText>
                                        <ListText textAlign="center">{token.farmApr}%</ListText>
                                        {d.chainID === chainId ?
                                            <Flex justifyContent='space-between'>
                                                <Link to={`/liquidity/add?token=${token.tokenName}`}><Button size='small'>{t('add')}</Button></Link>
                                                <Link to={`/liquidity/remove?token=${token.tokenName}`}><Button size='small'>{t('remove')}</Button></Link>
                                                <Link to='/farm/boring'><Button size='small'>{t('go_farm')}</Button></Link>
                                            </Flex> :
                                            <Flex>
                                                <Button onClick={() => changeChain(d.chainID)} fullWidth size='small' variant="outlined">
                                                    {t('switch')}
                                                </Button>
                                            </Flex>
                                        }
                                    </SubGride>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    )): <SkeletonList />
                }
                </Box>
            </WrapperLiquity>
        }
        </>
    )
}

export default Liquity
