import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const ErrorIcon:React.FC<IconProps> = ({ color = "#ffffff" }) => {
    return (
        <SvgIcon viewBox="0 0 12 13">
            <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M0.121863 7.22184C0.694969 10.0471 3.17465 12.0805 6.0574 12.0892C6.86998 12.0915 7.67467 11.9297 8.42327 11.6137C11.4296 10.2564 12.8036 6.74809 11.5187 3.7101C10.2338 0.672111 6.75941 -0.785516 3.69152 0.426294C1.04005 1.55766 -0.451244 4.39663 0.121863 7.22184ZM0.933318 6.01404C0.933318 3.19639 3.22786 0.889958 6.05739 0.889958C8.87504 0.889958 11.1815 3.1845 11.1815 6.01404C11.1815 8.84357 8.88693 11.1381 6.05739 11.1381C3.22786 11.1381 0.933318 8.83168 0.933318 6.01404Z" />
            <path fill={color} d="M3.78725 8.76041C3.59699 8.75773 3.42664 8.64191 3.35419 8.46597C3.28174 8.29002 3.32115 8.08783 3.45436 7.95197L7.984 3.42233C8.17113 3.2352 8.47453 3.2352 8.66166 3.42233C8.84879 3.60946 8.84879 3.91286 8.66166 4.09999L4.13202 8.61774C4.04133 8.71028 3.91681 8.76181 3.78725 8.76041Z" />
            <path fill={color} d="M8.31538 8.76034C8.18995 8.75856 8.0703 8.70728 7.9825 8.61768L3.45286 4.08804C3.33181 3.96699 3.28453 3.79055 3.32884 3.62519C3.37315 3.45983 3.50231 3.33067 3.66767 3.28636C3.83303 3.24205 4.00947 3.28933 4.13052 3.41038L8.64827 7.9519C8.78148 8.08777 8.82088 8.28996 8.74844 8.4659C8.67599 8.64185 8.50564 8.75767 8.31538 8.76034Z" />
        </SvgIcon>
    )
}

export default ErrorIcon