import {useWeb3React} from '@web3-react/core'
import {useCallback, useEffect, useState} from 'react'
import {getRouterSteps} from '../utils/api'
import {RouterStepInterface} from '../interfaces/router'
import {useInterval} from './useInterval'

export const useRouterTransactions = () => {
  const {account} = useWeb3React()
  const [txs, setTxs] = useState<RouterStepInterface[][]>([])

  const fetch = useCallback(async () => {
    try {
      const res = await getRouterSteps(account)
      const data = res.data
      console.log(data)
      setTxs(data)
    } catch (e) {
      console.error(e)
    }
  }, [account])

  useInterval(
    () => {
      if (account) {
        fetch()
      }
    },
    5000,
    true
  )

  return txs
}
