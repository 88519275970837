import React, { useState } from 'react'
import { Flex, Text, BoringInput } from 'components/Style'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import styled from '@emotion/styled'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MyButton from 'components/Button'
import { BackIcon } from 'components/Svg'

const ViewBox = styled(Box)`
    /* position: relative; */
`
const InputBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    border-radius: 20px;
    background: #000000;
`

const Back = styled(Flex)`
    margin-top: 20px;
    cursor: pointer;
`

const StyledInput = styled(BoringInput)`
    border: 1px solid #0068ff66;
    width: 500px;
    height: 44px;
    padding: 0 12px;
`
interface Props {
    oToken: string
    onNext: (step: number, txID: string) => void
}
const GuideTree:React.FC<Props> = ({ oToken, onNext }) => {
    const { t } = useTranslation()
    const [txID, setTxID] = useState('')
    // const token = useMemo(() => oToken.slice(1) ?? 'BTC', [oToken])
    const handleNext = (index: number) => {
        onNext && onNext(index, txID)
    }

    return (
        <ViewBox>
            <Flex mb="42px">
                <Text fontSize="14px">{t('completed_1')}</Text>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
                    <Flex mb="34px">
                        <CheckCircleIcon sx={{fontSize: '40px'}} />
                        <Text ml="22px" fontSize="24px">{t('completed')}</Text>
                    </Flex>
                    <Text mb="60px" fontSize="14px">{t('completed_2')}</Text>
                </Flex>
            <Flex justifyContent="center">
                <InputBox>
                    <Flex flexDirection="column" alignItems="center" mb="20px">
                        <Text mb="20px" fontSize="12px">{t('completed_3', {token: oToken})}</Text>
                        <StyledInput value={txID} onChange={(e) => setTxID(e.target.value)} placeholder={t('enter_TXID')} />
                    </Flex>
                    <Flex justifyContent="center">
                        <MyButton disabled={!txID} onClick={() => handleNext(3)}>{t('view_progress')}</MyButton>
                    </Flex>
                </InputBox>
            </Flex>
            <Back onClick={() => handleNext(1)}>
                <BackIcon />
                <Text ml="7px">{t('Back')}</Text>
            </Back>
        </ViewBox>
    )
}

export default GuideTree
