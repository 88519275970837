import {ethers} from 'ethers'
import {useMemo} from 'react'
import {useWeb3React} from '@web3-react/core'
import {CHAIN_ETHER, CHAIN_IOTEX} from '@w3u/chains'
import {getUrlProvider} from 'utils/providers'
import {
  getTwowayAddress,
  getMulticallAddress,
  getTwowayChefAddress,
  getBoringDAOAddress,
  getBoringTunnelAddress,
  getBoringBoringDAOlAddress,
  getBoringFeePoolAddress,
  getBorAddress,
  getBoringAddress,
  getRouterAddress,
} from 'utils/addressHelpers'
import {ORACLE_ADDRESS} from 'config/constants/pow_bridge'
import {OLD_ETH_CHEF_ADDRESS} from 'config/constants/chef'
import {useRelayChainID} from 'hooks'
import {NATIVE_BRIDGE_ADDRESSES} from 'config/constants/native_bridge'
import ERC20ABI from 'config/abi/ERC20.json'
import MultiCallABI from 'config/abi/Multicall.json'
import TwowayCenterABI from 'config/abi/TwowayCenter.json'
import RouterABI from 'config/abi/Entrance.json'
import OraclelABI from 'config/abi/Oracle.json'
import TwowayChefABI from 'config/abi/TwowayChef.json'
import NBridgeABI from 'config/abi/NBridge.json'
import BoringDAOABI from 'config/abi/BoringDAO.json'
import BoringTunnelABI from 'config/abi/Tunnel.json'
import FeePoolABI from 'config/abi/FeePool.json'
import BoringChefV2ABI from 'config/abi/BoringChefV2.json'
import BoringV2ABI from 'config/abi/BoringV2.json'

export const getSinger = (library: any, chainId?: number) => {
  return library ? library.getSigner() : getUrlProvider(chainId ?? CHAIN_ETHER)
}

export const getContract = (
  abi: any,
  address: string,
  chainId?: number,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  const signerOrProvider = signer ?? getUrlProvider(chainId ?? CHAIN_ETHER)
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getOracleContract = (chainId?: number) => {
  return getContract(OraclelABI, ORACLE_ADDRESS[chainId ?? CHAIN_ETHER] ?? ORACLE_ADDRESS[CHAIN_ETHER])
}

export const getMulticallContract = (chainId?: number) => {
  return getContract(MultiCallABI, getMulticallAddress(chainId), chainId)
}

export const useErc20Contract = (address: string, chainID?: number) => {
  const {chainId, library} = useWeb3React()
  return useMemo(
    () => getContract(ERC20ABI, address, chainID ?? chainId, getSinger(library, chainID ?? chainId)),
    [library, chainId, address]
  )
}

export const useTwowayContract = () => {
  const {chainId, library} = useWeb3React()
  return useMemo(
    () => getContract(TwowayCenterABI, getTwowayAddress(chainId), chainId, getSinger(library)),
    [chainId, library]
  )
}

export const useRouterContract = () => {
  const {chainId, library} = useWeb3React()
  return useMemo(
    () => getContract(RouterABI.abi, getRouterAddress(chainId), chainId, getSinger(library)),
    [chainId, library]
  )
}

export const useTwowayChefContract = () => {
  const {library} = useWeb3React()
  const relayChainID = useRelayChainID()
  return useMemo(
    () => getContract(TwowayChefABI, getTwowayChefAddress(relayChainID), relayChainID, getSinger(library)),
    [relayChainID, library]
  )
}

export const useNativeBridgeContract = () => {
  const {library, chainId} = useWeb3React()
  return useMemo(() => {
    const _chainId = chainId ?? CHAIN_ETHER
    const contractAddress = NATIVE_BRIDGE_ADDRESSES[_chainId]
    return contractAddress ? getContract(NBridgeABI, contractAddress, _chainId, getSinger(library)) : null
  }, [library, chainId])
}

export const getBordingDAOContract = (tokenName: string, chainId: number) => {
  return getContract(BoringDAOABI, getBoringDAOAddress(tokenName, chainId), chainId)
}

export const useBoringTunnelContract = (tokenName: string) => {
  const {library} = useWeb3React()
  return useMemo(
    () => getContract(BoringTunnelABI, getBoringTunnelAddress(tokenName, CHAIN_ETHER), CHAIN_ETHER, getSinger(library)),
    [library]
  )
}
export const useBoringBoringDAOContract = (tokenName: string) => {
  const {library} = useWeb3React()
  return useMemo(
    () => getContract(BoringDAOABI, getBoringBoringDAOlAddress(tokenName), CHAIN_ETHER, getSinger(library)),
    [library]
  )
}

export const useBoringFeePoolContract = (tokenName: string) => {
  const {library} = useWeb3React()
  return useMemo(
    () => getContract(FeePoolABI, getBoringFeePoolAddress(tokenName, CHAIN_ETHER), CHAIN_ETHER, getSinger(library)),
    [library, tokenName]
  )
}
export const useBoringChefContract = () => {
  const {library, chainId} = useWeb3React()
  return useMemo(
    () => getContract(BoringChefV2ABI, OLD_ETH_CHEF_ADDRESS, chainId ?? CHAIN_ETHER, getSinger(library)),
    [library, chainId]
  )
}
export const useBordingContract = () => {
  const {library} = useWeb3React()
  return useMemo(
    () => getContract(BoringV2ABI, getBoringAddress(CHAIN_ETHER), CHAIN_ETHER, getSinger(library)),
    [library]
  )
}
export const useBorContract = () => {
  const {library} = useWeb3React()
  return useMemo(() => getContract(BoringV2ABI, getBorAddress(CHAIN_ETHER), CHAIN_ETHER, getSinger(library)), [library])
}
