import React, {useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useSearchParams} from 'react-router-dom'
import {Typography, Box, Button} from '@mui/material'
import styled from '@emotion/styled'
import {useTranslation} from 'react-i18next'
import {useWeb3React} from '@web3-react/core'
import {CHAIN_ETHER, CHAIN_POLYGON} from '@w3u/chains'
import BigNumber from 'bignumber.js'
import {toast} from 'react-toastify'
import {Flex, Text, BoringInput} from 'components/Style'
import {getIcon} from 'utils/icons'
import ChainSwitcher from 'components/ChainSwitcher'
import TokenSwitcher from 'components/TokenSwitcher'
import ConnectWallet from 'components/ConnectWallet'
import FreeTooltips from 'components/Tooltip'
import MyButton, {OutlineButton} from 'components/Button'
import RemoveLiquidityNotice from 'components/Notice/RemoveLiquidityNotice'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import {get2WayTokenFixed, changeChain} from 'utils'
import {displayBalance} from 'utils/formatBalance'
import {useRelayChainID} from 'hooks'
import {useRelayTwowayAddress} from 'hooks'
import {useTokensBalance} from 'hooks/useTokenBalance'
import {useApprove} from 'hooks/useApprove'
import {
  useTwowayChainIds,
  useTwowayOTokens,
  useTwowayTokens,
  useTwowayLockBalance,
  useTwowayCalculateFee,
  useTwowayFixFees,
  useWithdraw,
} from 'hooks/useTwoway'
import {BridgeType} from 'modal/types'
const Wrapper = styled.div`
  background: #00000066;
  padding: 30px 20px;
  border-radius: 30px;
  width: 580px;
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 20px 15px;
  }
`
const InputBox = styled(Box)`
  background: #00000066;
  border-radius: 12px;
  border: 1px solid #0a214d;
  padding: 17px;
  width: 100%;
`
const InputBoxNoborder = styled(Box)`
  background: #00000066;
  border-radius: 12px;
  padding: 17px;
  width: 100%;
`
const BoringInfo = styled(Flex)`
  flex-direction: column;
  background: #00000066;
  border-radius: 0 0 30px 30px;
  padding: 30px 20px;
  width: 520px;
  @media screen and (max-width: 600px) {
    width: 90%;
    padding: 20px 15px;
  }
`

const RemoveLiquidity = () => {
  const {t} = useTranslation()
  const [searchParams] = useSearchParams()
  const defaultSymbol = searchParams.get('token')
  const history = useNavigate()
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState<string>('')
  const {account, chainId} = useWeb3React()
  const relayChainID = useRelayChainID()
  const relayAddress = useRelayTwowayAddress()
  const ids = useTwowayChainIds()
  const {onWithdraw} = useWithdraw()
  const [toID, setToID] = useState(ids[0])
  const oTokens = useTwowayOTokens()
  const tokens = useTwowayTokens(chainId)
  const queryOToken = oTokens.find((f) => f.symbol === defaultSymbol)
  const defaultOToken = defaultSymbol && queryOToken ? queryOToken : oTokens[0]
  const [oToken, setOToken] = useState(defaultOToken)
  const {balances} = useTokensBalance(
    oTokens.map((d) => ({address: d.address, symbol: d.symbol})),
    relayChainID
  )
  const balance = useMemo(() => balances.find((f) => f.address === oToken.address)?.balance || '0', [balances, oToken])
  const token = useMemo(() => {
    return tokens.find((f) => `o${f.symbol}` === oToken.symbol) ?? tokens[0]
  }, [tokens, oToken])
  const {
    allowance,
    loading: approveLoading,
    fetchApprove,
    onApprove,
  } = useApprove(oToken.address, relayAddress, relayChainID)
  const {lockBalance} = useTwowayLockBalance(token.symbol, toID)
  const {fixFee} = useTwowayFixFees(chainId ?? CHAIN_ETHER, toID, token)
  const {fee, calcFee, ratioFees, remainAmount} = useTwowayCalculateFee(
    token,
    account,
    toID,
    amount,
    lockBalance,
    BridgeType.oportal,
    fixFee
  )
  const amountError = useMemo(() => {
    const blanceBN = new BigNumber(balance).div(Math.pow(10, oToken.decimals))
    const lockBalanceBN = new BigNumber(lockBalance).div(Math.pow(10, 18))
    const amountBN = new BigNumber(amount)
    if (amountBN.gt(blanceBN) || amountBN.eq(0)) {
      return t('insufficient_balance')
    } else if (amountBN.gt(lockBalanceBN)) {
      return t('twoway_remove_liquidity_maximum_notice', {
        maximum_amount: `${displayBalance(lockBalance, 18, get2WayTokenFixed(token?.symbol))} ${token?.symbol}`,
      })
    } else if (amountBN.lte(displayBalance(fixFee))) {
      return t('twoway_remove_liquidity_minimum_notice', {
        minimum_amount: `${displayBalance(fixFee, 18, get2WayTokenFixed(token?.symbol))} ${token?.symbol}`,
      })
    }
    return null
  }, [oToken, token, balance, amount, lockBalance, fixFee])
  const handleChianChange = (id: number) => setToID(id)
  const handleConfirm = async () => {
    try {
      setLoading(true)
      const tx = await onWithdraw(amount, oToken, toID, account ?? '')
      toast(
        <RemoveLiquidityNotice
          toID={toID}
          txHash={tx.hash}
          amount={amount}
          symbol={oToken.symbol}
          tokenAddress={oToken.address}
          receivedAmount={displayBalance(remainAmount)}
        />
      )
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const handleApprove = async () => {
    try {
      setLoading(true)
      await onApprove()
      fetchApprove()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const handleToekenChange = (address: string) => {
    setOToken(oTokens.find((f) => f.address === address) ?? oTokens[0])
  }
  const getBack = () => {
    history(-1)
  }
  const handleMax = () => {
    setAmount(displayBalance(balance, oToken.decimals, get2WayTokenFixed(oToken?.symbol)))
  }
  const renderAction = () => {
    return allowance ? (
      <MyButton fullWidth loading={loading} disabled={!!amountError || !amount} onClick={handleConfirm}>
        {!amountError ? (
          t('confirm')
        ) : (
          <Text fontSize='18px' color='#ff0000'>
            {amountError}
          </Text>
        )}
      </MyButton>
    ) : (
      <MyButton fullWidth loading={loading} onClick={handleApprove}>
        {t('approve')}
      </MyButton>
    )
  }

  return (
    <Flex flexDirection='column' alignItems='center'>
      <Wrapper>
        <Typography mb='15px' fontSize='18px'>
          {t('remove_liquidity')}
        </Typography>
        <Flex onClick={getBack} sx={{cursor: 'pointer', mb: {md: '30px', xs: '15px'}}}>
          <KeyboardBackspaceIcon sx={{fontSize: '24px'}} />
        </Flex>
        <Flex alignItems='center' mb='15px'>
          <Text mr='15px'>{t('To')}</Text>
          <ChainSwitcher chainIDs={ids} onChange={handleChianChange} />
        </Flex>
        <InputBox sx={{mb: {md: '30px', xs: '15px'}}}>
          <Flex mb='10px' justifyContent='space-between'>
            <Text fontSize='12px'>{t('remove')}</Text>
            <Text fontSize='12px'>
              {t('balance')}: {displayBalance(balance, oToken.decimals)}
            </Text>
          </Flex>
          <Flex justifyContent='space-between' alignItems='center'>
            <Flex>
              <TokenSwitcher
                defaultTokenAddress={oToken.address}
                border={false}
                tokens={oTokens}
                chainID={relayChainID}
                onChange={handleToekenChange}
              />
              <BoringInput value={amount} onChange={(e) => setAmount(e.target.value)} />
            </Flex>
            <OutlineButton style={{height: '26px'}} onClick={handleMax}>
              MAX
            </OutlineButton>
          </Flex>
        </InputBox>
        <InputBoxNoborder sx={{mb: {md: '30px', xs: '15px'}}}>
          <Text mb='15px' fontSize='12px'>
            {t('estimated_received')}
          </Text>
          <Flex>
            <img src={getIcon(token.symbol)} width='20px' height='20px' alt={token.symbol} />
            <Text m='0 10px' fontSize='12px'>
              {token.symbol}
            </Text>
            <Text fontSize='12px'>{displayBalance(remainAmount)}</Text>
          </Flex>
        </InputBoxNoborder>
        <Flex justifyContent='center'>
          <Box sx={{minWidth: {md: '60%', xs: '100%'}}}>
            {account ? (
              chainId === CHAIN_POLYGON ? (
                renderAction()
              ) : (
                <Button onClick={() => changeChain(CHAIN_POLYGON)} fullWidth size='large' variant='outlined'>
                  {t('switch_to_chain', {chain: 'Polygon'})}
                </Button>
              )
            ) : (
              <ConnectWallet />
            )}
          </Box>
        </Flex>
      </Wrapper>
      <BoringInfo>
        <Flex justifyContent='space-between' sx={{mb: {md: '15px', xs: '8px'}}}>
          <Flex>
            <Text mr='12px'>{t('fee')}</Text>
            <FreeTooltips fixFee={fixFee} calcFee={calcFee} token={token} ratioFees={ratioFees} />
          </Flex>
          <Text>
            {displayBalance(fee)} {token.symbol}
          </Text>
        </Flex>
        <Flex justifyContent='space-between'>
          <Text>{t('total_liquidity')}</Text>
          <Text>
            {displayBalance(lockBalance)} {token.symbol}
          </Text>
        </Flex>
      </BoringInfo>
    </Flex>
  )
}

export default RemoveLiquidity
