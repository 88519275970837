import { Box } from '@mui/material'
import { Flex, Text } from 'components/Style'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getIcon, getChainIcon, getEcoIcon } from 'utils/icons'
import { Farms } from './config'

const Grid = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 15px;
    }
`

const EcoWrapper = styled.div`
    width: 100%;
    background: #00000066;
    border-radius: 20px;
    padding: 30px;
    > div:not(:last-child) {
        margin-bottom: 30px;
    }
    @media screen and (max-width: 600px) {
        background: transparent;
        padding: 15px 0;
        > div:not(:last-child) {
            margin-bottom: 15px;
        }
    }
`
const FarmBox = styled(Flex)`
    @media screen and (max-width: 600px) {
        padding: 15px;
        background: #00000066;
        border-radius: 20px;
    }
`

const ChainName = styled.div<{chainBackground: string}>`
    background: ${({ chainBackground }) => chainBackground};
    padding: 16px;
    width: 60px;
    height: 60px;
    border-radius: 12px 0 0 12px;
    @media screen and (max-width: 600px) {
        display: none;
    }
`
const ChainNameInner = styled(Box)`
    display: none;
    /* margin-right: 12px; */
    background-color: #3e5bf24d;
    padding: 6px;
    border-radius: 10px;
    @media screen and (max-width: 600px) {
        display: inline-flex;
        align-items: center;
    }
`
const ChainList = styled.div`
    flex: 1 1 auto;
    background: #00000066;
    padding: 20px;
    border-radius: 0 12px 12px 12px;
    @media screen and (max-width: 600px) {
        padding: 0;
        background: transparent;
    }
`

const ChainSwap = styled(Flex)<{borderColor: string, background: string}>`
    justify-content: space-between;
    border: 1px solid ${({ borderColor }) => borderColor};
    padding: 17px 14px;
    border-radius: 12px;
    background: ${({ background }) => background};
`
const ChainSwapFarm = styled(ChainSwap)`
    flex-direction: column;
`
const GoButton = styled.div<{background: string, color: string}>`
    border-radius: 24px;
    padding: 2px 16px;
    background: ${({ background }) => background};
    color: ${({ color }) => color};
`

const ExtenalFarm = () => {
    const { t } = useTranslation()
    return (
        <EcoWrapper>
            <Text mb="15px !important" fontSize="18px">{t('externel_farm')}</Text>
            {Farms.map((swap, i) => (
                <FarmBox key={i}>
                    <ChainName chainBackground={swap.chainBackground}>
                        <img src={getChainIcon(swap.chain)} width="28px"  height="28px" alt="BOR" />
                    </ChainName>
                    <ChainList>
                        {swap.projects.map((project, j) => (
                            <>
                                <ChainNameInner sx={{marginBottom: '15px'}}><img src={getChainIcon(swap.chain)} width="28px"  height="28px" alt="BOR" /></ChainNameInner>
                                <Grid key={`grid${j}`}>
                                    {project.metas.map((meta, k) => (
                                        <ChainSwapFarm key={`farm${k}`} borderColor={meta.borderColor} background={meta.background}>
                                            <Flex justifyContent="space-between" mb="20px">
                                                <Flex alignItems="center">
                                                    <img src={getEcoIcon(meta.icon)} width="30px"  height="30px" alt="BOR" />
                                                    <Text ml="10px">{meta.name}</Text>
                                                </Flex>
                                                <a target="_blank" href={meta.link}>
                                                    <GoButton background={ meta.buttonColor} color={meta.color}>Go</GoButton>
                                                </a>
                                            </Flex>
                                            <Flex>
                                                <Text>{meta.actionText}</Text>
                                                <Flex alignItems="center" m={meta.deposit.length > 0 ? '0 10px' : '0 4px 0 0'}>
                                                    {meta.deposit.map(d => (<img style={{margin: '0 5px'}} width="20px"  height="20px" src={getIcon(d)} alt={d} />))}
                                                </Flex>
                                                <Text mr="12px">{meta.orderText}</Text>
                                                {meta.earn.map(d => <img style={{margin: '0 5px'}} src={getIcon(d)} width="20px" height="20px" alt={d} />)}
                                            </Flex>
                                        </ChainSwapFarm>
                                    ))}
                                </Grid>
                            </>
                        ))}
                    </ChainList>
                </FarmBox>
            ))}
        </EcoWrapper>
    )
}

export default ExtenalFarm
