import * as React from 'react'
import { styled as style } from '@mui/material/styles'
import { useWeb3React } from '@web3-react/core'
import { CHAIN_ETHER } from '@w3u/chains'
import { getIcon } from 'utils/icons'
import { Menu, MenuItem, Typography } from '@mui/material'
import { MenuProps } from '@mui/material/Menu'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import Balance from 'components/Balance'
import { Flex } from 'components/Style'
import Copy from 'components/Copy'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { NATIVE_BRIDGE_TOKENS } from 'config/constants/native_bridge'
import { useTokenBalance } from 'hooks/useTokenBalance'
import { getBalanceNumber } from 'utils/formatBalance'
import { getSmallAddress } from 'utils'
import {isBool} from "react-toastify/dist/utils";

interface StyledMenuProps extends MenuProps {
  isBottom?: boolean
}

const StyledMenu = style(({isBottom, ...props}: StyledMenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: isBottom ? 'top' : 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: isBottom ? 'bottom' : 'top',
      horizontal: 'right',
    }}
    PaperProps={{
      sx: {
        marginTop: isBottom ? '-10px' : '10px'
      }
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '12px',
    minWidth: 180,
    background: '#00000066',
    [theme.breakpoints.down('md')]: {
      background: '#000000',
    },
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
      },
      'svg': {
        width: '14px',
        height: '14px',
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  },
}))

const BoringBox = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  background: #00000066;
  border-radius: 6px;
  cursor: pointer;
`

export default function BoringBalance({isBottom}: {isBottom?: boolean}) {
  const { chainId } = useWeb3React()
  const _chainId = chainId ?? CHAIN_ETHER
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation()
  const borToken = React.useMemo(() => NATIVE_BRIDGE_TOKENS.filter(f => f.symbol === 'BORING').find(f => f.chainID === _chainId) ?? NATIVE_BRIDGE_TOKENS.filter(f => f.symbol === 'BORING')[0], [chainId])
  const { balance } = useTokenBalance(borToken.address)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <BoringBox onClick={handleClick}>
        <img width="16px" style={{marginRight: '4px'}} src={getIcon('BOR')} />
        <Balance value={getBalanceNumber(balance, borToken.decimals)} duration={0} />
        <KeyboardArrowDownIcon style={{marginLeft: '4px'}} />
      </BoringBox>
      <StyledMenu
        isBottom={isBottom}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <Flex minWidth={300} alignItems="center" justifyContent="space-between">
            <Typography >{t('BORING Balance')}</Typography>
            <Balance fontSize="14px" value={getBalanceNumber(balance, borToken.decimals)} duration={0} unit=" BORING" />
          </Flex>
        </MenuItem>
        <MenuItem disableRipple>
          <Flex minWidth={300} alignItems="center" justifyContent="space-between">
            <Typography fontSize="14px">{t('BORING Contract')}</Typography>
            <Copy onAfter={() => setAnchorEl(null)} marginRight="0px" color='#ffffff' name={getSmallAddress(borToken.address)} value={borToken.address} />
          </Flex>
        </MenuItem>
      </StyledMenu>
    </div>
  )
}
