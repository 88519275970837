import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { Flex, Text, BoringInput } from 'components/Style'
import { getIcon } from 'utils/icons'
import { BackIcon } from 'components/Svg'
// import QRCode from 'qrcode'
import { ComplateIcon } from 'components/Svg'

import { PrincipleWrapper, ComplateBox, InputBox } from '../../style'

const PrincipleWrapperTree = styled(PrincipleWrapper)`
    padding: 18px 15px;
`

interface Props {
    oToken: string
    onPve: (t: number) => void
    onNext: (t: number, txID: string) => void
}
const GuideTree:React.FC<Props> = ({ oToken, onPve, onNext }) => {
    const { t } = useTranslation()
    const [txID, setTxID] = useState('')
    const handleNextQuery = () => {
        onNext(4, txID)
    }
    return (
        <>
            <PrincipleWrapperTree>
                <Flex sx={{cursor: 'pointer', marginBottom: '18px'}} 
                    alignItems="center" onClick={() => onPve(1)}>
                    <Flex alignItems="center" >
                        <BackIcon />
                        <Text ml="4px" fontSize="20px" color="#ffffff">{t('BACK')}</Text>
                    </Flex>
                    <Text ml="45px">{t('Completed')}</Text>
                </Flex>
                <ComplateBox>
                    <ComplateIcon />
                </ComplateBox>
                <Text textAlign="center" color="#ffffff" mb="20px">{t('completed_1')}</Text>
                <Text textAlign="center" padding="0 20px" color="#A1A8C7">{t('completed_2')}</Text>
            </PrincipleWrapperTree>
            <PrincipleWrapperTree>
                <Text textAlign="center">{t('completed_3', {token: oToken})}</Text>
                <InputBox><BoringInput value={txID} onChange={(e) => setTxID(e.target.value)} placeholder={t('enter_TXID')} /></InputBox>
                <Flex><Button sx={{height: '50px'}} fullWidth onClick={handleNextQuery}>{t('view_progress')}</Button></Flex>
            </PrincipleWrapperTree>
        </>
    )
}

export default GuideTree