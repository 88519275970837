import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const MediunIcon:React.FC<IconProps> = () => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 3.5816 12.4184 0 8 0C3.5816 0 0 3.5816 0 8C0 12.4184 3.5816 16 8 16C12.4184 16 16 12.4184 16 8ZM8.74352 8C8.74352 6.14403 7.25138 4.64 5.41146 4.64C3.57214 4.64 2.08 6.14403 2.08 8C2.08 9.85597 3.57155 11.36 5.41146 11.36C7.25138 11.36 8.74352 9.85597 8.74352 8ZM10.786 4.8C11.7061 4.80031 12.4521 6.21624 12.4521 7.96263C12.4521 9.70981 11.706 11.1253 10.7863 11.1253C9.86607 11.1253 9.12 9.70922 9.12 7.96263C9.12 6.21624 9.86591 4.80031 10.786 4.8ZM13.9723 7.95389C13.9723 6.38921 13.7101 5.12 13.3862 5.12C13.0628 5.12 12.8 6.38921 12.8 7.95389C12.8 9.51856 13.0628 10.7878 13.3862 10.7878C13.7096 10.7878 13.9723 9.51915 13.9723 7.95389Z" fill="white"/>
        </SvgIcon>
    )
}

export default MediunIcon
