import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const DefiplusIcon:React.FC<IconProps> = () => {
    return (
        <SvgIcon viewBox="0 0 60 60">
            <g id="1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="2" transform="translate(-390.000000, -1411.000000)">
                    <g id="3" transform="translate(390.000000, 1411.000000)">
                        <circle id="4" fill="#FFFFFF" cx="30" cy="30" r="30"></circle>
                        <g id="download" transform="translate(13.000000, 10.000000)" fill="#181325" fill-rule="nonzero">
                            <path d="M25.8781046,29.8290062 C26.1150597,29.3722723 26.5529725,29.0909091 27.0268826,29.0909091 L31.1549253,29.0909091 C31.6288354,29.0909091 32.0667618,29.3722723 32.3037169,29.8290062 L34.3677383,33.8073499 C34.6046933,34.2641291 34.6046933,34.8267951 34.3677383,35.2835743 L32.3037169,39.2618123 C32.0667618,39.7185914 31.6288354,40 31.1549253,40 L27.0268826,40 C26.5529725,40 26.1150597,39.7185914 25.8781046,39.2618123 L23.8140819,35.2835743 C23.5771242,34.8267951 23.5771242,34.2641291 23.8140819,33.8073499 L25.8781046,29.8290062 Z" id="路径"></path>
                            <path d="M25.8780957,17.1017245 C26.1150502,16.6449868 26.5529755,16.3636364 27.0268301,16.3636364 L31.1549711,16.3636364 C31.6288258,16.3636364 32.0667103,16.6449868 32.3037055,17.1017245 L34.3677082,21.0801013 C34.6047033,21.5368389 34.6047033,22.099555 34.3677082,22.5562926 L32.3037055,26.5346845 C32.0667103,26.9914674 31.6288258,27.2727273 31.1549711,27.2727273 L27.0268301,27.2727273 C26.5529755,27.2727273 26.1150502,26.9914674 25.8780957,26.5346845 L23.8140795,22.5562926 C23.577125,22.099555 23.577125,21.5368389 23.8140795,21.0801013 L25.8780957,17.1017245 Z" id="路径"></path>
                            <path d="M14.0599223,23.4653206 C14.2968776,23.0085345 14.7347909,22.7272727 15.2087559,22.7272727 L19.3367767,22.7272727 C19.8106331,22.7272727 20.2485192,23.0085345 20.4855153,23.4653206 L22.5495257,27.4437703 C22.7865217,27.9004053 22.7865217,28.463231 22.5495257,28.9198661 L20.4855153,32.8983158 C20.2485192,33.3551018 19.8106331,33.6363636 19.3367767,33.6363636 L15.2087559,33.6363636 C14.7347909,33.6363636 14.2968776,33.3551018 14.0599223,32.8983158 L11.9958983,28.9198661 C11.758943,28.463231 11.758943,27.9004053 11.9958983,27.4437703 L14.0599223,23.4653206 Z" id="路径"></path>
                            <path d="M25.8781301,4.37445421 C26.1149914,3.91772241 26.5530151,3.63636364 27.0268734,3.63636364 L31.1549108,3.63636364 C31.6289049,3.63636364 32.0667928,3.91772241 32.3037899,4.37445421 L34.3678086,8.35281549 C34.6046699,8.80955183 34.6046699,9.37226635 34.3678086,9.82900269 L32.3037899,13.8073685 C32.0667928,14.2641048 31.6289049,14.5454545 31.1549108,14.5454545 L27.0268734,14.5454545 C26.5530151,14.5454545 26.1149914,14.2641048 25.8781301,13.8073685 L23.8141114,9.82900269 C23.5771144,9.37226635 23.5771144,8.80955183 23.8141114,8.35281549 L25.8781301,4.37445421 Z" id="路径"></path>
                            <path d="M14.0599274,10.738085 C14.2969222,10.2813644 14.7348061,10 15.2086599,10 L19.3366589,10 C19.8106485,10 20.2485324,10.2813644 20.4855272,10.738085 L22.5495266,14.7164151 C22.7865214,15.1731961 22.7865214,15.7358646 22.5495266,16.1926456 L20.4855272,20.1710512 C20.2485324,20.6276812 19.8106485,20.9090909 19.3366589,20.9090909 L15.2086599,20.9090909 C14.7348061,20.9090909 14.2969222,20.6276812 14.0599274,20.1710512 L11.9959279,16.1926456 C11.7589331,15.7358646 11.7589331,15.1731961 11.9959279,14.7164151 L14.0599274,10.738085 Z" id="路径"></path>
                            <path d="M2.24174556,17.1018251 C2.47874037,16.6450453 2.91662425,16.3636364 3.39061387,16.3636364 L7.51861278,16.3636364 C7.99246666,16.3636364 8.43035054,16.6450453 8.66734535,17.1018251 L10.7313448,21.0800686 C10.9683396,21.5368484 10.9683396,22.0995153 10.7313448,22.556295 L8.66734535,26.5346896 C8.43035054,26.9913183 7.99246666,27.2727273 7.51861278,27.2727273 L3.39061387,27.2727273 C2.91662425,27.2727273 2.47874037,26.9913183 2.24174556,26.5346896 L0.177746106,22.556295 C-0.059248702,22.0995153 -0.059248702,21.5368484 0.177746106,21.0800686 L2.24174556,17.1018251 Z" id="路径"></path>
                            <path d="M11.6206915,8.24131898 C11.8840119,7.63350224 11.8840119,6.88462682 11.6206915,6.27678998 L9.32701219,0.982269125 C9.06369181,0.374438311 8.57705367,0 8.05039783,0 L3.46305423,0 C2.93639839,0 2.44976024,0.374440322 2.18643986,0.982271136 L0.909090909,3.65004708 L6.34751125,3.65004708 C6.8741671,3.65004708 7.36080524,4.02447474 7.62412562,4.63231159 L10.3433381,10.9090909 L11.6206915,8.24131898 Z" id="路径"></path>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}

export default DefiplusIcon
