import React, {useState} from 'react'
import styled from '@emotion/styled'
import {useWeb3React} from '@web3-react/core'
import {Chains, CHAIN_POLYGON} from '@w3u/chains'
import {useTranslation} from 'react-i18next'
import {Flex, Text} from 'components/Style'
// import Grid from '@mui/material/Grid'
import {useTwowayChefAddress, useRelayChainID} from 'hooks'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import {getIcon} from 'utils/icons'
import {TwowayChefInfo} from 'modal/twoway'
import {displayBalance} from 'utils/formatBalance'
import {setupNetwork} from 'utils/network'
import {useApprove} from 'hooks/useApprove'
import {displayTwowayBoringAPY, displayTwowayOTokenAPY} from 'utils/calc'
import {useDeposit, useWithdraw, useClaim} from 'hooks/useTwowayChef'

import Deposit from './components/Deposit'
import Withdraw from './components/Withdraw'

const FarmWrapper = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 20px 15px;
  background-color: #00000066;
  margin-bottom: 15px;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr) 1.5fr;
  padding: 20px 0;
  align-items: center;
`
interface FarmItemProps {
  chainId: number
  farm: TwowayChefInfo
  boringPrice: string
  onRefresh: () => void
}
interface Props {
  farmName: string
  farms?: TwowayChefInfo[]
  boringPrice: string
  onRefresh: () => void
}
const SkeletonList = () => {
  return (
    <>
      {[1, 2, 3, 4].map((d) => (
        <Grid key={d}>
          <Skeleton variant='text' width='300px' height={40} />
        </Grid>
      ))}
    </>
  )
}
const FarmItem: React.FC<FarmItemProps> = ({chainId, farm, boringPrice, onRefresh}) => {
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  const [openWithdraw, setOpenWithdraw] = useState(false)
  const chefAddress = useTwowayChefAddress()
  const relayChainID = useRelayChainID()
  const {allowance, loading: apvLoading, onApprove} = useApprove(farm.token.address, chefAddress, relayChainID)
  const [loading, setLoading] = useState(apvLoading)
  const {onDeposit} = useDeposit()
  const {onWithdraw} = useWithdraw()
  const {onClaim} = useClaim()
  const changeChain = async (chainId: number) => {
    await setupNetwork(chainId).catch((e: any) => console.error('Setup network: ', e))
  }
  const handleApprove = async () => {
    try {
      setLoading(true)
      await onApprove()
      onRefresh()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const handleDeposit = async (amount: string) => {
    try {
      setLoading(true)
      await onDeposit(farm.token.pid, amount)
      setOpen(false)
      onRefresh()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const handleWithdraw = async (amount: string) => {
    try {
      setLoading(true)
      await onWithdraw(farm.token.pid, amount)
      setOpenWithdraw(false)
      onRefresh()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const handleClaim = async () => {
    try {
      setLoading(true)
      await onClaim(farm.token.pid)
      onRefresh()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const renderAction = () => {
    return allowance ? (
      <Flex width='100%' justifyContent='space-between'>
        <Button style={{padding: '6px 8px', marginRight: '4px'}} onClick={() => setOpen(true)}>
          {t('deposit')}
        </Button>
        <Button style={{padding: '6px 8px'}} onClick={() => setOpenWithdraw(true)}>
          {t('withdraw')}
        </Button>
      </Flex>
    ) : (
      <Flex justifyContent='flex-end'>
        <Button disabled={loading} onClick={handleApprove}>
          {t('approve')}
        </Button>
      </Flex>
    )
  }
  return (
    <>
      <Accordion
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            width: '100%',
            margin: 0,
            padding: '15px 0',
            border: 'none',
            boxShadow: 'none',
            borderTop: '1px solid #000000',
          }}
        >
          <Flex>
            <img src={getIcon(farm.token.symbol)} width='20px' height='20px' alt={farm.token.symbol} />
            <Typography ml='10px' color='#ffffff'>
              {farm.token.symbol}
            </Typography>
          </Flex>
        </AccordionSummary>
        <AccordionDetails sx={{padding: '0'}}>
          <Grid>
            <Text color='#ffffffb3' fontSize='12px'>
              {t('APY')}
            </Text>
            <Flex flexDirection='column' justifyContent='center'>
              <Flex>
                <img src={getIcon('BORING')} alt='Boring' width={18} height={18} /> &nbsp;
                <Text color='#ffffffb3'>{displayTwowayBoringAPY(farm, boringPrice)}%</Text>
              </Flex>
              <Flex>
                <img src={getIcon(farm.token.symbol)} alt={farm.token.symbol} width={18} height={18} /> &nbsp;
                <Text color='#ffffffb3'>{displayTwowayOTokenAPY(farm)}%</Text>
              </Flex>
            </Flex>
          </Grid>
          <Grid>
            <Text color='#ffffffb3' fontSize='12px'>
              {t('Total Staked')}
            </Text>
            <Text color='#ffffffb3' fontSize='12px'>
              {displayBalance(farm.totalStaked)}
            </Text>
          </Grid>
          <Grid>
            <Text color='#ffffffb3' fontSize='12px'>
              {t('Balance')}
            </Text>
            <Text color='#ffffffb3' fontSize='12px'>
              {displayBalance(farm.balance)}
            </Text>
            {/* <Flex justifyContent="flex-end"><ShareIcon color='#0068FF'/></Flex> */}
          </Grid>
          <Grid>
            <Text color='#ffffffb3' fontSize='12px'>
              {t('Your staked')}
            </Text>
            <Text color='#ffffffb3' fontSize='12px'>
              {displayBalance(farm.yourStaked)}
            </Text>
            <Flex justifyContent='space-evenly'>{chainId === CHAIN_POLYGON && renderAction()}</Flex>
          </Grid>
          <Grid>
            <Text color='#ffffffb3' fontSize='12px'>
              {t('Reward')}
            </Text>
            <Flex flexDirection='column'>
              <Flex>
                <img src={getIcon('BORING')} alt='Boring' width={18} height={18} /> &nbsp;
                <Text color='#ffffffb3'>{displayBalance(farm.earned[0])}</Text>
              </Flex>
              <Flex>
                <img src={getIcon(farm.token.symbol)} alt={farm.token.symbol} width={18} height={18} /> &nbsp;
                <Text color='#ffffffb3'>{displayBalance(farm.earned[1], farm.token.decimals)}</Text>
              </Flex>
            </Flex>
            <Flex justifyContent='space-evenly'>
              {chainId === CHAIN_POLYGON ? (
                <Flex justifyContent='flex-end'>
                  <Button fullWidth onClick={handleClaim}>
                    {t('Claim')}
                  </Button>
                </Flex>
              ) : (
                <Button onClick={() => changeChain(CHAIN_POLYGON)} fullWidth size='small' variant='outlined'>
                  {t('switch_to_chain', {chain: 'Polygon'})}
                </Button>
              )}
            </Flex>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Deposit loading={loading} farmInfo={farm} open={open} onClose={() => setOpen(false)} onConfrim={handleDeposit} />
      <Withdraw
        loading={loading}
        farmInfo={farm}
        open={openWithdraw}
        onClose={() => setOpenWithdraw(false)}
        onConfrim={handleWithdraw}
      />
    </>
  )
}
const MobileFarm: React.FC<Props> = ({farmName, farms, boringPrice, onRefresh}) => {
  const {t} = useTranslation()
  const {chainId} = useWeb3React()
  return (
    <FarmWrapper>
      <Flex flexDirection='column' mb='15px'>
        <Typography>{farmName}</Typography>
      </Flex>
      {farms?.length ? (
        farms.map((farm, i) => (
          <FarmItem
            key={i}
            farm={farm}
            chainId={chainId ?? CHAIN_POLYGON}
            boringPrice={boringPrice}
            onRefresh={onRefresh}
          />
        ))
      ) : (
        <SkeletonList />
      )}
    </FarmWrapper>
  )
}

export default MobileFarm
