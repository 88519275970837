import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const Back:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 16 11">
            <path fill={color} d="M4.62836 0L5.65673 1.02836L2.83636 3.84945H10.9222C13.6698 3.84945 15.9971 6.16073 15.9971 8.94255L16 10.3971H14.5498L14.5469 8.94255C14.5498 7.18255 12.9491 5.29964 10.9222 5.304C9.57091 5.30691 6.87564 5.30691 2.83636 5.304L5.65673 8.22764L4.62836 9.26545L0 4.57527L4.62836 0.000727273V0Z"/>
        </SvgIcon>
    )
}

export default Back