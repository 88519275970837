import { useCallback, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { CHAIN_ETHER } from '@w3u/chains'
import multicall from 'utils/muticall'
import { ADDRESS_BOOK, ADDRESS_BOOK_PARAM, POW_TOKEN } from 'config/constants/pow_bridge'
import BookABI from 'config/abi/Book.json'
import ParamBookABI from 'config/abi/ParamBook.json'

export const useNetworkFee = () => {
    const [networkFee, setNetworkFee] = useState<{[x:string]: string}>({'BTC': ''})
    const fetchNetworkFee = useCallback(async () => {
        const parmaBookAddress = ADDRESS_BOOK_PARAM[CHAIN_ETHER]
        const calls = POW_TOKEN.map(d => (
            {
                address: parmaBookAddress,
                name: 'params2',
                params: [ethers.utils.formatBytes32String(d), ethers.utils.formatBytes32String('network_fee')]
            }
        ))
        const result = await multicall(ParamBookABI, calls, CHAIN_ETHER)
        let fees:{[x:string]: string} = {}
        POW_TOKEN.forEach((f, i) => {
            fees[f] = result[i].toString()
        })
        setNetworkFee(fees)
    }, [])
    
    useEffect(() => {
        fetchNetworkFee()
    }, [fetchNetworkFee])

    return { networkFee, fetchNetworkFee }
}

const useMultiAddress = () => {
    const [multiAddress, setMultiAddress] = useState<{[x:string]: string}>({'BTC': ''})
    const fetchPowAddress = useCallback(async () => {
        const bookAddress = ADDRESS_BOOK[CHAIN_ETHER]
        const calls = POW_TOKEN.map(d => (
            {
                address: bookAddress,
                name: 'assetMultiSignAddress',
                params: [d]
            }
        ))
        const result = await multicall(BookABI, calls, CHAIN_ETHER)
        let address:{[x:string]: string} = {}
        POW_TOKEN.forEach((f, i) => {
            address[f] = result[i]
        })
        setMultiAddress(address)
    }, [])

    useEffect(() => {
        fetchPowAddress()
    }, [fetchPowAddress])
    
    return { multiAddress, fetchPowAddress } 
}

export default useMultiAddress