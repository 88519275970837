import {
  CHAIN_ARBITRUM,
  CHAIN_AURORA,
  CHAIN_AURORA_TESTNET,
  CHAIN_AVALANCHE,
  CHAIN_BOBA,
  CHAIN_BSC,
  CHAIN_BSC_TESTNET,
  CHAIN_ETHER,
  CHAIN_ETHER_KOVAN,
  CHAIN_FANTOM,
  CHAIN_HARMONY_0,
  CHAIN_HARMONY_0_TESTNET,
  CHAIN_HECO,
  CHAIN_METIS,
  CHAIN_OK,
  CHAIN_OPTIMISM,
  CHAIN_POLYGON,
  CHAIN_POLYGON_MUMBAI,
  CHAIN_XDAI,
  CHAIN_KCC,
  CHAIN_IOTEX,
} from '@w3u/chains'

import {NativeBridgeToken} from 'modal/token'

export const ALL_CHAINS = [
  CHAIN_ETHER,
  CHAIN_BSC,
  CHAIN_OK,
  CHAIN_POLYGON,
  // CHAIN_HARMONY_0,
  CHAIN_XDAI,
  CHAIN_AVALANCHE,
  CHAIN_FANTOM,
  // CHAIN_HECO,
  CHAIN_ARBITRUM,
  CHAIN_OPTIMISM,
  CHAIN_BOBA,
  CHAIN_AURORA,
  CHAIN_KCC,
]

export const PRODUCTION_NATIVE_TARGET_CHAINS: {[chainID: number]: number[]} = {
  [CHAIN_ETHER]: [...ALL_CHAINS.filter((chainID) => chainID !== CHAIN_ETHER), CHAIN_METIS],
  [CHAIN_BSC]: [...ALL_CHAINS.filter((chainID) => chainID !== CHAIN_BSC), CHAIN_METIS],
  [CHAIN_OK]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_OK),
  [CHAIN_POLYGON]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_POLYGON),
  [CHAIN_XDAI]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_XDAI),
  // [CHAIN_HECO]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_HECO),
  // [CHAIN_HARMONY_0]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_HARMONY_0),
  [CHAIN_FANTOM]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_FANTOM),
  [CHAIN_AVALANCHE]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_AVALANCHE),
  [CHAIN_ARBITRUM]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_ARBITRUM),
  [CHAIN_OPTIMISM]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_OPTIMISM),
  [CHAIN_BOBA]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_BOBA),
  [CHAIN_METIS]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_METIS),
  [CHAIN_AURORA]: [CHAIN_HARMONY_0],
}

export const NATIVE_TARGET_CHAINS: {[chainID: number]: number[]} = {
  [CHAIN_ETHER_KOVAN]: [CHAIN_BSC_TESTNET, CHAIN_POLYGON_MUMBAI],
  [CHAIN_BSC_TESTNET]: [CHAIN_ETHER_KOVAN, CHAIN_POLYGON_MUMBAI],
  [CHAIN_POLYGON_MUMBAI]: [CHAIN_ETHER_KOVAN, CHAIN_BSC_TESTNET],
  [CHAIN_AURORA_TESTNET]: [CHAIN_HARMONY_0_TESTNET],
  // [CHAIN_HARMONY_0_TESTNET]: [CHAIN_AURORA_TESTNET],
  [CHAIN_ETHER]: [...ALL_CHAINS.filter((chainID) => chainID !== CHAIN_ETHER), CHAIN_METIS],
  [CHAIN_BSC]: [...ALL_CHAINS.filter((chainID) => chainID !== CHAIN_BSC), CHAIN_METIS],
  [CHAIN_OK]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_OK),
  [CHAIN_POLYGON]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_POLYGON),
  [CHAIN_XDAI]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_XDAI),
  // [CHAIN_HECO]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_HECO),
  // [CHAIN_HARMONY_0]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_HARMONY_0),
  [CHAIN_FANTOM]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_FANTOM),
  [CHAIN_AVALANCHE]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_AVALANCHE),
  [CHAIN_ARBITRUM]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_ARBITRUM),
  [CHAIN_OPTIMISM]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_OPTIMISM),
  [CHAIN_BOBA]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_BOBA),
  [CHAIN_METIS]: ALL_CHAINS.filter((chainID) => chainID !== CHAIN_METIS),
  [CHAIN_AURORA]: [CHAIN_HARMONY_0],
}

export const NATIVE_BRIDGE_ADDRESSES: {[chainID: number]: string} = {
  [CHAIN_ETHER_KOVAN]: '0x14dcF56cbf4C437449FE3811228D2E853B23E55f',
  [CHAIN_BSC_TESTNET]: '0x7B8C0668F4B5f3d8D4695864041F1f0C31e2F86C',
  [CHAIN_POLYGON_MUMBAI]: '0x944d7466B3c246BBeFAB03a51FBfF6Ac6355570E',
  [CHAIN_AURORA_TESTNET]: '0x5A9AbDe26C31B0F38975901f84e558204c98f44D',
  // [CHAIN_HARMONY_0_TESTNET]: '0x9926bDc075434CDA3a32e7BA4FbC39e6174d286D',
  [CHAIN_ETHER]: '0xdBe43C732080271Fd7588aa5fe71bd07456D9173',
  [CHAIN_BSC]: '0xe7b388d44fA9069385E174efe6f10616B149Fe86',
  [CHAIN_OK]: '0xb9A9587eee2Da928f72A5846440E81C7426d595f',
  [CHAIN_POLYGON]: '0x64E4E7840bC20959c8BA984Db82fA0132D9b04dD',
  [CHAIN_XDAI]: '0xE3B59FD01c0155A98146a6E0Beb8376B751363fc',
  // [CHAIN_HECO]: '0xE3B59FD01c0155A98146a6E0Beb8376B751363fc',
  [CHAIN_FANTOM]: '0x77B3aa18D89ea24e4AF7BB9BC221D49FD5534c19',
  [CHAIN_AVALANCHE]: '0x2e220E3ab85fC4A46F770A34eF2CdDc8074E4689',
  // [CHAIN_HARMONY_0]: '0x216f332D17145871D1d5ff5fEB4b08513Ef7Cc21',
  [CHAIN_ARBITRUM]: '0x9d8bDAa1dDE16da55c86cCaF12C81647ff9858B8',
  [CHAIN_OPTIMISM]: '0x91854b4a3c8379d5baa39F1C0917332E58BfBc7B',
  [CHAIN_BOBA]: '0x225247882FD7396c62E29707DA0890151208161a',
  [CHAIN_METIS]: '0xD01a5051253007ae0b7123b50410E3B5A3f6cF95',
  [CHAIN_AURORA]: '0xB98863f0a0cFE367496bFa393dA4D408Bee0A5Bf',
  [CHAIN_KCC]: '0xF8BE629521CfdcC35E9439E9775d341A0f076023',
  [CHAIN_IOTEX]: '0xffffffffffffffffffffffffffffffffffffffff',
}

export const NATIVE_BRIDGE_TOKENS: NativeBridgeToken[] = [
  // {
  //   symbol: 'USDT',
  //   address: '0x55FB3193F6e42D6753691c8aA38800B2753FB7d0',
  //   chainID: CHAIN_ETHER_KOVAN,
  //   decimals: 18,
  // },
  // {
  //     symbol: 'USDT',
  //     address: '0xC2769060Ea91EC67bca1e6Bedb02e3017a7C0d32',
  //     chainID: CHAIN_BSC_TESTNET,
  //     decimals: 18,
  // },
  // {
  //   symbol: 'USDT',
  //   address: '0x6F4E99b40FdF51bADc6C31361e17D5E1Bb33A13e',
  //   chainID: CHAIN_POLYGON_MUMBAI,
  //   decimals: 18,
  // },
  {
    symbol: 'LSD',
    address: '0xfAC77A24E52B463bA9857d6b758ba41aE20e31FF',
    chainID: CHAIN_ETHER,
    decimals: 18,
  },
  {
    symbol: 'ETHx',
    address: '0x21eAD867C8c5181854f6f8Ce71f75b173d2Bc16A',
    chainID: CHAIN_ETHER,
    decimals: 18,
  },
  {
    symbol: 'LSD',
    address: '0xa6B2d37B171E626ee5227322FEaD4272682fbf89',
    chainID: CHAIN_OPTIMISM,
    decimals: 18,
  },
  {
    symbol: 'ETHx',
    address: '0x226D8BfB4da78Ddc5bd8Fd6C1532c58e88F9Fd34',
    chainID: CHAIN_OPTIMISM,
    decimals: 18,
  },
  {
    symbol: 'WISTA',
    address: '0xB7042C40De76CFc607aC05e68F9C28A778F0C8a6',
    chainID: CHAIN_POLYGON,
    decimals: 18,
  },
  {
    symbol: 'WISTA',
    address: '0x3720E3C827daB665383b32bC49766D2d24C1Ed35',
    chainID: CHAIN_BSC,
    decimals: 18,
  },
  {
    symbol: 'CP',
    address: '0x5BDC32663Ec75E85ff4abc2CAe7ae8B606A2CFCa',
    chainID: CHAIN_ETHER,
    decimals: 18,
  },
  {
    symbol: 'CP',
    address: '0xf9d3d8b25b95bcda979025b74fdfa7ac3f380f9f',
    chainID: CHAIN_POLYGON,
    decimals: 18,
  },
  {
    symbol: 'BORING',
    address: '0xBC19712FEB3a26080eBf6f2F7849b417FdD792CA',
    chainID: CHAIN_ETHER,
    decimals: 18,
  },
  {
    symbol: 'BORING',
    address: '0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F',
    chainID: CHAIN_BSC,
    decimals: 18,
  },
  {
    symbol: 'BORING',
    address: '0x5ce01aa1528a6a0c8edecc4cee909f0a24cf54de',
    chainID: CHAIN_OK,
    decimals: 18,
  },
  {
    symbol: 'BORING',
    address: '0xfC7b937D53fbcFE0881c3a3D99c9736d5887A006',
    chainID: CHAIN_XDAI,
    decimals: 18,
  },
  // {
  //   symbol: 'BORING',
  //   address: '0xfC7b937D53fbcFE0881c3a3D99c9736d5887A006',
  //   chainID: CHAIN_HECO,
  //   decimals: 18,
  // },
  {
    symbol: 'BORING',
    address: '0x50D0799ba241E10A6E9bAcE2936129f117Cb32af',
    chainID: CHAIN_POLYGON,
    decimals: 18,
  },
  // {
  //   symbol: 'BORING',
  //   address: '0xB238a595e9797E009eFD5f938C9918181662066C',
  //   chainID: CHAIN_FANTOM,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'BORING',
  //   address: '0x017ff87ab312301ade54f7cf9cc5aea28c9de024',
  //   chainID: CHAIN_HARMONY_0,
  //   decimals: 18,
  // },
  {
    symbol: 'BORING',
    address: '0x603f0d26DA2c245D892300A4c3c86b5AD95C96be',
    chainID: CHAIN_AVALANCHE,
    decimals: 18,
  },
  {
    symbol: 'BORING',
    address: '0xfC7b937D53fbcFE0881c3a3D99c9736d5887A006',
    chainID: CHAIN_ARBITRUM,
    decimals: 18,
  },
  {
    symbol: 'BORING',
    address: '0xbE4A5438ad89311d8c67882175D0fFcC65Dc9C03',
    chainID: CHAIN_OPTIMISM,
    decimals: 18,
  },
  {
    symbol: 'BORING',
    address: '0x017Ff87AB312301aDE54f7cf9Cc5AEA28C9De024',
    chainID: CHAIN_BOBA,
    decimals: 18,
  },
  // {
  //   symbol: 'BORING',
  //   address: '0x226D8BfB4da78Ddc5bd8Fd6C1532c58e88F9Fd34',
  //   chainID: CHAIN_METIS,
  //   decimals: 18,
  // },
  {
    symbol: 'BORING',
    address: '0x5D78bF8f79A66e43f5932c1Ae0b8fA6563F97f74',
    chainID: CHAIN_KCC,
    decimals: 18,
  },
  {
    symbol: 'oBTC',
    address: '0x8064d9Ae6cDf087b1bcd5BDf3531bD5d8C537a68',
    chainID: CHAIN_ETHER,
    decimals: 18,
  },
  {
    symbol: 'oBTC',
    address: '0xd595a0632B7dC0d92f3a80147C63F2f3498F8eE4',
    chainID: CHAIN_BSC,
    decimals: 18,
  },
  {
    symbol: 'oBTC',
    address: '0x6ef2968f539E9cBe5043F62dAdE97EAfd92EC53d',
    chainID: CHAIN_OK,
    decimals: 18,
  },
  {
    symbol: 'oBTC',
    address: '0x29414EC76d79Ff238E5E773322799d1c7Ca2443F',
    chainID: CHAIN_XDAI,
    decimals: 18,
  },
  // {
  //   symbol: 'oBTC',
  //   address: '0x29414EC76d79Ff238E5E773322799d1c7Ca2443F',
  //   chainID: CHAIN_HECO,
  //   decimals: 18,
  // },
  {
    symbol: 'oBTC',
    address: '0xd39A60AF2ae61D0D92A06d04F97c505b9a54EDAA',
    chainID: CHAIN_POLYGON,
    decimals: 18,
  },
  // {
  //   symbol: 'oBTC',
  //   address: '0xbc0090f48FDb8725C24B4327c69B99cDd39c2749',
  //   chainID: CHAIN_FANTOM,
  //   decimals: 18,
  // },
  {
    symbol: 'oBTC',
    address: '0x57c37416c853C2703bbbF7eABB382A9CeDB523d8',
    chainID: CHAIN_AVALANCHE,
    decimals: 18,
  },
  // {
  //   symbol: 'oBTC',
  //   address: '0x18d7ddc3583C03d7912593A07f353f0279668964',
  //   chainID: CHAIN_HARMONY_0,
  //   decimals: 18,
  // },
  {
    symbol: 'VEMP',
    address: '0xcFEB09C3c5F0f78aD72166D55f9e6E9A60e96eEC',
    chainID: CHAIN_ETHER,
    decimals: 18,
  },
  {
    symbol: 'VEMP',
    address: '0xeDF3ce4Dd6725650a8e9398e5C6398D061Fa7955',
    chainID: CHAIN_BSC,
    decimals: 18,
  },
  {
    symbol: 'VEMP',
    address: '0x2c9a1d0E1226939EdB7BBb68C43a080c28743C5C',
    chainID: CHAIN_OK,
    decimals: 18,
  },
  {
    symbol: 'VEMP',
    address: '0x526f1DC408cFe7FC5330aB9f1e78474ceFF2a5dD',
    chainID: CHAIN_FANTOM,
    decimals: 18,
  },
  // {
  //   symbol: 'VEMP',
  //   address: '0xe179a674CF3785be513E1620aEBB944e68147D3F',
  //   chainID: CHAIN_ETHER_KOVAN,
  //   decimals: 18,
  // },
  // {
  //     symbol: 'VEMP',
  //     address: '0x337c6fC30eEACe049017fc180D259e95eAe1c960',
  //     chainID: CHAIN_BSC_TESTNET,
  //     decimals: 18,
  // },
  // {
  //   symbol: 'xVEMP',
  //   address: '0x2cEa1A4cc4aEBd76e448Bb894345D294c53853f2',
  //   chainID: CHAIN_ETHER_KOVAN,
  //   decimals: 18,
  // },
  // {
  //     symbol: 'xVEMP',
  //     address: '0x4852931e56B6bBf1f5a26eF62d85B0C56595DCE5',
  //     chainID: CHAIN_BSC_TESTNET,
  //     decimals: 18,
  // },
  // {
  //   symbol: 'xVEMP',
  //   address: '0x4a4e2d4859af854d2a3fc6df295843d21e70cc78',
  //   chainID: CHAIN_ETHER,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'xVEMP',
  //   address: '0xAe9a0E6Bd6CB21cF36B773d47923980b410D3C85',
  //   chainID: CHAIN_BSC,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'xVEMP',
  //   address: '0x861afdBC2F3DF53F07d397EacfA443683653cA99',
  //   chainID: CHAIN_OK,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'FIN',
  //   address: '0x054f76beed60ab6dbeb23502178c52d6c5debe40',
  //   chainID: CHAIN_ETHER,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'FIN',
  //   address: '0x2B21237BA30deF78EC551a10C99d656F90Eb10fC',
  //   chainID: CHAIN_OK,
  //   decimals: 18,
  //   isWrapped: true,
  //   originAddress: '0x8D3573f24c0aa3819A2f5b02b2985dD82B487715',
  // },
  // {
  //   symbol: 'AMY',
  //   address: '0xb6225104d9434CC148570cDDEEf19B2e46dA350D',
  //   chainID: CHAIN_ETHER,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'AMY',
  //   address: '0x8fbd420956fdd301f4493500fd0bcaaa80f2389c',
  //   chainID: CHAIN_ARBITRUM,
  //   decimals: 18,
  // },
  {
    symbol: 'AAVE',
    address: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
    chainID: CHAIN_ETHER,
    decimals: 18,
  },
  {
    symbol: 'AAVE',
    address: '0x68D97B7A961a5239B9F911DA8dEb57F6eF6e5e28',
    chainID: CHAIN_METIS,
    decimals: 18,
  },
  {
    symbol: 'CRV',
    address: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    chainID: CHAIN_ETHER,
    decimals: 18,
  },
  {
    symbol: 'CRV',
    address: '0x87DD4a7Ad23B95cD9fF9C26B5cF325905CaF8663',
    chainID: CHAIN_METIS,
    decimals: 18,
  },
  {
    symbol: 'WSG',
    address: '0xA58950F05FeA2277d2608748412bf9F802eA4901',
    chainID: CHAIN_BSC,
    decimals: 18,
  },
  {
    symbol: 'WSG',
    address: '0x4F497F9D85A6fE135fFca99f0f253919fE827211',
    chainID: CHAIN_METIS,
    decimals: 18,
  },
  // {
  //   symbol: 'ONE',
  //   address: '0x731203A6E33001d76AF6da49096fC8753b9E4932',
  //   chainID: CHAIN_AURORA,
  //   decimals: 18,
  // },
  // {
  //     symbol: 'ONE',
  //     address: '0xb608b55b0F777e70F8e37a18F8Da6EC8AE667B33',
  //     chainID: CHAIN_AURORA_TESTNET,
  //     decimals: 18,
  // },
  // {
  //   symbol: 'ONE',
  //   address: '0x323B6b60B286f69D2cEf2277A71038FbDfd18F86',
  //   chainID: CHAIN_HARMONY_0,
  //   decimals: 18,
  // },
  // {
  //     symbol: 'ONE',
  //     address: '0xCc246259854B8c824371EFfF4eAe9A119fD48631',
  //     chainID: CHAIN_HARMONY_0_TESTNET,
  //     decimals: 18,
  // },
  // {
  //   symbol: 'LOWB',
  //   address: '0x843d4a358471547f51534e3e51fae91cb4dc3f28',
  //   chainID: CHAIN_BSC,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'LOWB',
  //   address: '0xd5B59f9F35F9a5bE75E7357798BF15A4E958B6B2',
  //   chainID: CHAIN_METIS,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'FIRE',
  //   address: '0x0f0Dd5E2c0e0c4A41F8908D73D36B8D142F6745a',
  //   chainID: CHAIN_ETHER,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'FIRE',
  //   address: '0x0f0Dd5E2c0e0c4A41F8908D73D36B8D142F6745a',
  //   chainID: CHAIN_BSC,
  //   decimals: 18,
  // },
]
