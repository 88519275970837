import { useCallback, useEffect, useState, useMemo } from "react"
import { useWeb3React } from '@web3-react/core'
import { CHAIN_ETHER } from '@w3u/chains'
import { getERC20Contract } from 'utils/contractHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { TokenBalance } from 'modal/token'
import multicall from 'utils/muticall'
import { isOriginToken } from 'utils/providers'
import Erc20ABI from 'config/abi/ERC20.json'
import useRefresh from "./useRefresh"

export const useAccountBalance = (address: string | null | undefined): string => {
    const checkedAddresses = useMemo(() => address, [address])
    const {library} = useWeb3React()
    const [balance, setBalance] = useState('')

    const handle = useCallback(async () => {
        try {
        const result = await library.getBalance(checkedAddresses)
        setBalance(result.toString())
        } catch (e) {
        console.log(`useAccountBalance: ${e}`)
        }
    }, [library, checkedAddresses])

    useEffect(() => {
        handle()
    }, [handle])

    return balance
}

export const useTokenBalance = (address: string, chainID?: number) => {
    const [balance, setBalance] = useState(BIG_ZERO)
    const [loading, setLoading] = useState(false)
    const { chainId, account } = useWeb3React()
    const fetchBalance = useCallback(async () => {
        try {
            const contract = getERC20Contract(address, chainID ?? chainId)
            setLoading(true)
            const tokenBalance = await contract.balanceOf(account)
            setBalance(tokenBalance.toString())
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }, [chainId, address])

    useEffect(() => {
        if (account) {
            fetchBalance()
        } else {
            setBalance(BIG_ZERO)
        }
    }, [account, fetchBalance])

    return { balance, loading, fetchBalance }
}

export const useTokensBalance = (tokens: {address: string, symbol: string}[], relayChainID?: number) => {
    const { slowRefresh }  = useRefresh()
    const [loading, setLoading] = useState(false)
    const { chainId, account } = useWeb3React()
    const allAddress = useMemo(() =>  tokens.map(d => d.address), [tokens])
    // const account = "0x382bDf9aa5eB51DAC1095033Cc4a53f38BA06A1D"
    const initBalances: TokenBalance[] = tokens.map(d => ({address: d.address, balance: ''}))
    const [balances, setBalances] = useState<TokenBalance[]>(initBalances)
    const originBalance = useAccountBalance(account)
    const fetchBalances = async () => {
        const _chainId = relayChainID ?? chainId ?? CHAIN_ETHER
        const calls = allAddress.map(token => ({
            address: token,
            name: 'balanceOf',
            params: [account]
        }))
        const clearBalances: TokenBalance[] = allAddress.map(d => ({address: d, balance: '0'}))
        try {
            setLoading(true)
            const resultBalances = await multicall(Erc20ABI, calls, _chainId)
            const bs = tokens.map((d, i) => {
                return {
                    address: d.address,
                    balance: isOriginToken(_chainId, d.symbol) ?  originBalance : resultBalances[i].toString()
                }
            })
            setBalances(bs)
            setLoading(false)
        } catch (error) {
            setBalances(clearBalances)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchBalances()
    }, [slowRefresh, chainId, originBalance, allAddress.toString()])

    return { balances, loading, fetchBalances }
}
