import { useCallback } from 'react'
import { useBoringBoringDAOContract } from 'hooks/useContract'
import { ethers } from 'ethers'

export const usePledge = (tokenName: string) => {
    const contract = useBoringBoringDAOContract(tokenName)
    const handlePledge = useCallback(async (assetType: string, amount: string) => {
        const tunnelKey = ethers.utils.formatBytes32String(assetType)
        const tx = await contract.pledge(tunnelKey, ethers.utils.parseEther(amount))
        const receipt = await tx.wait()
        return receipt
    }, [contract])
    return { onPledge: handlePledge }
}

export const useRedeem = (tokenName: string) => {
    const contract = useBoringBoringDAOContract(tokenName)
    const handleRedeem = useCallback(async (assetType: string, amount) => {
        const tunnelKey = ethers.utils.formatBytes32String(assetType)
        const tx = await contract.redeem(tunnelKey, ethers.utils.parseEther(amount))
        const receipt = await tx.wait()
        return receipt
    }, [contract])
    return { onRedeem: handleRedeem }
}