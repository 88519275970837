import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const TwitterIcon:React.FC<IconProps> = () => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path fillRule="evenodd" clip-rule="evenodd" fill="white" d="M8 0C12.4182 0 16 3.58182 16 8C16 12.4182 12.4182 16 8 16C3.58182 16 0 12.4182 0 8C0 3.58182 3.58182 0 8 0ZM4.11418 10.8698C3.97164 10.8698 3.83055 10.8604 3.69236 10.8429C4.47418 11.3796 5.40364 11.6924 6.40073 11.6916C9.65164 11.6931 11.4298 8.86327 11.4298 6.40945C11.4298 6.32945 11.4284 6.24945 11.4247 6.16945C11.7702 5.912 12.0698 5.58982 12.3069 5.22109C11.9898 5.36436 11.6495 5.45891 11.2916 5.49891C11.6567 5.27418 11.9375 4.91491 12.0691 4.48364C11.728 4.69091 11.3498 4.83927 10.9469 4.91636C10.6247 4.54982 10.1651 4.31636 9.65673 4.30764C8.68073 4.29164 7.88945 5.11636 7.88945 6.14982C7.88945 6.296 7.90473 6.43927 7.93527 6.57673C6.46691 6.48145 5.16436 5.71636 4.29236 4.56364C4.14036 4.84 4.05309 5.16291 4.05309 5.50909C4.05309 6.16364 4.36509 6.74545 4.83855 7.088C4.54836 7.07491 4.27636 6.98764 4.03782 6.84364V6.86764C4.03782 7.78182 4.64727 8.54909 5.456 8.728C5.30764 8.77018 5.152 8.792 4.99055 8.79127C4.87709 8.79055 4.76582 8.77745 4.65818 8.75491C4.88291 9.50545 5.53527 10.0524 6.30909 10.0705C5.70473 10.5724 4.94255 10.8713 4.11418 10.8698Z" />
        </SvgIcon>
    )
}

export default TwitterIcon
