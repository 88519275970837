import React, { useEffect, useState } from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';
import { createStyles, InputBase } from '@mui/material'
import { withStyles } from '@material-ui/styles'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import { Chains } from '@w3u/chains'
import { useWeb3React } from '@web3-react/core'
import { getAllSupportChains } from 'utils'
import { getChainIcon } from 'utils/icons'
import { setupNetwork } from 'utils/network'

export const BoringInput = withStyles(() =>
  createStyles({
    root: {
      borderRadius: '6px',
      color: '#FFFFFF',
      padding: '8px',
      fontSize: '12px',
      background: 'linear-gradient(270deg, #2C4BC2 0%, #627EEA 100%)',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
      },
      '& img': {
        width: '16px',
        height: '16px',
        marginRight: '5px',
      },
      '& svg': {
        color: '#FFFFFF'
      }
    },
  })
)(InputBase)

const StyeldImg = styled.div`
  display:flex;
  > img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`
interface Props {
  isMobile?: boolean
  isBottom?: boolean
}
const ChainSelect:React.FC<Props> = ({ isMobile, isBottom= false }): JSX.Element => {
    const {chainId} = useWeb3React()
    const [id, setID] = useState(chainId ?? 1)
    const allChains = getAllSupportChains()

    useEffect(() => setID(chainId ?? 1), [chainId])

    const change = async (e: SelectChangeEvent) => {
        await setupNetwork(e.target.value as unknown as number)
            .then(() => {
              setID(e.target.value as unknown as number)
              // window.location.reload()
            })
            .catch((e) => console.error('Setup network: ', e))
    }

    return (
      <Select
        IconComponent={ExpandMoreOutlinedIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: isBottom ? 'top' : 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: isBottom ? 'bottom' : 'top',
            horizontal: 'right',
          },
          elevation: 1,
            PaperProps: {
              sx: {
                  marginTop: isBottom ? '-10px' : '10px'
              }
            }
        }}
        onChange={change}
        value={id.toString()}
        input={<BoringInput />}
      >
        {allChains.map((chainID) => (
          <MenuItem key={chainID} value={chainID}>
            <StyeldImg>
              <img src={getChainIcon(chainID)} width='20px' height="20px" alt='chain' />
              {!isMobile && Chains[chainID].displayName}
            </StyeldImg>
          </MenuItem>
        ))}
      </Select>
    )
  }

  export default ChainSelect
