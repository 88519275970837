import {useEffect, useState} from 'react'
import {getRouterSteps} from '../utils/api'
import {RouterStepInterface} from '../interfaces/router'
import {useInterval} from './useInterval'
import {useWeb3React} from '@web3-react/core'

export const useRouterStep = (txHash: string) => {
  const {account, chainId} = useWeb3React()
  const [steps, setSteps] = useState<RouterStepInterface[]>([])

  useInterval(
    async () => {
      if (txHash) {
        const res = await getRouterSteps(account)
        const data = res.data
        if (data) {
          const filterData = data.filter(
            (ds: RouterStepInterface[]) => ds[0].origin_chain_id === chainId && ds[0].origin_txid === txHash
          )
          const finalData = filterData[0]
          if (!filterData || finalData.length === 0) {
            finalData.push({
              origin_chain_id: chainId,
              origin_txid: txHash,
            })
          } else {
            setSteps(filterData[0])
          }
        }
      }
    },
    5000,
    true
  )

  return steps
}
