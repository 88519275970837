import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const DiscordIcon:React.FC<IconProps> = () => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.96202 8.39595C4.92788 7.78367 5.40522 7.25522 6.04232 7.19996C6.36306 7.20601 6.66743 7.33688 6.88541 7.56247C7.1034 7.78807 7.21624 8.08896 7.19798 8.39595C7.21625 8.69836 7.10819 8.99531 6.89764 9.22122C6.68709 9.44713 6.39138 9.58341 6.07581 9.59996C5.42191 9.56123 4.92387 9.02285 4.96202 8.39595ZM8.96202 8.39595C8.92864 7.77211 9.42535 7.23874 10.0758 7.19996C10.3906 7.21446 10.6864 7.349 10.8971 7.57363C11.1079 7.79826 11.2162 8.09434 11.198 8.39595C11.2163 8.69836 11.1082 8.99531 10.8976 9.22122C10.6871 9.44713 10.3914 9.58341 10.0758 9.59996C9.42191 9.56123 8.92387 9.02285 8.96202 8.39595Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.608538 4.94004C1.84637 1.94985 4.76384 6.95858e-06 8.00011 5.78854e-06C10.1212 -0.00179938 12.156 0.839529 13.6565 2.33872C15.1569 3.83791 16 5.87201 16 7.99308C16.0027 11.2294 14.0554 14.1485 11.0663 15.3889C8.07712 16.6292 4.63511 15.9465 2.34575 13.6591C0.0563915 11.3716 -0.629292 7.93022 0.608538 4.94004ZM11.0049 12.6206C12.105 12.2852 13.1395 11.7638 14.0635 11.079C14.2899 8.60456 13.6102 6.13208 12.1509 4.12106C11.3615 3.76195 10.5308 3.50167 9.67766 3.34612C9.66491 3.33876 9.64919 3.33876 9.63644 3.34612C9.52051 3.55185 9.41593 3.76376 9.32317 3.98091C8.39687 3.84076 7.45476 3.84076 6.52846 3.98091C6.4357 3.76376 6.33112 3.55185 6.21519 3.34612C5.36204 3.50167 4.53136 3.76195 3.742 4.12106C2.34813 6.15455 1.72816 8.61979 1.99427 11.0707C2.91631 11.7538 3.94777 12.2751 5.04454 12.6124H5.08576C5.32251 12.2924 5.53209 11.9532 5.71231 11.5983V11.5489C5.3813 11.4218 5.06143 11.2673 4.75601 11.0872V11.0295L4.94562 10.8811C6.89344 11.7965 9.1478 11.7965 11.0956 10.8811C11.1551 10.9377 11.2184 10.9901 11.2852 11.0378V11.0955C10.9818 11.274 10.6648 11.4283 10.3372 11.5571V11.6066C10.5205 11.9597 10.7299 12.2987 10.9637 12.6206H11.0049Z" fill="white"/>
        </SvgIcon>
    )
}

export default DiscordIcon
