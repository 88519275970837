import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const GithubIcon:React.FC<IconProps> = () => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path d="M8 0.000417502C3.62862 -0.044003 0.0479462 3.46181 0 7.83314C0.0165952 11.2368 2.22547 14.2417 5.46909 15.2731C5.86909 15.3459 6.01455 15.1059 6.01455 14.895V13.5641C3.78909 14.0368 3.31636 12.5168 3.31636 12.5168C3.16822 12.0396 2.85326 11.6317 2.42909 11.3677C1.70182 10.8877 2.48727 10.895 2.48727 10.895C2.99657 10.963 3.4467 11.2605 3.70909 11.7022C4.17863 12.5164 5.21388 12.8044 6.03636 12.3495C6.07702 11.9512 6.25738 11.5802 6.54545 11.3022C4.77091 11.1059 2.90909 10.4368 2.90909 7.43314C2.89322 6.64964 3.18272 5.89068 3.71636 5.31678C3.47333 4.6441 3.50203 3.90319 3.79636 3.25133C3.79636 3.25133 4.47273 3.04042 5.97818 4.05133C7.28865 3.70212 8.66771 3.70212 9.97818 4.05133C11.5055 3.04042 12.16 3.25133 12.16 3.25133C12.4543 3.90319 12.483 4.6441 12.24 5.31678C12.786 5.88016 13.0912 6.63403 13.0909 7.4186C13.0909 10.4295 11.2145 11.0913 9.45455 11.2877C9.84476 11.6666 10.0449 12.2002 10 12.7422V14.8877C10 15.1422 10.1455 15.3459 10.5455 15.2659C13.7794 14.2282 15.98 11.2294 16 7.83314C15.9521 3.46181 12.3714 -0.044003 8 0.000417502" fill="white"/>
        </SvgIcon>
    )
}

export default GithubIcon
