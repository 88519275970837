export interface Address {
  [chainID: number]: string
}

export type MultiCallResponse<T> = T | null

export enum BridgeType {
  oportal = 'oportal',
  native = 'native',
  router = 'router',
}

export enum BridgeStatus {
  support = 'support',
  notsupport = 'not support',
}

export interface TransactionAllData {
  [tx: string]: IHistory[]
}
export interface TransactionData {
  tx: string
  currentBlockNumber: number
  blockNumber: number
  pastNumber: number
  gas: string
  status: number // 1: success, 0: fail, 2: waiting
}
export interface IHistory {
  id: number
  transaction: string // "0xcba4b7fc572983c0e395e960a2343c1c3fcdd6bc96af4e21e62767aca5b18942",
  event: string // "CrossIned",
  event_chain_id: number // 56,
  event_chain_name: string // "BSC",
  from: string // "0x4907b9ae38dF0Ad4727330f94766a8F2E4FCdF05",
  from_chain_id: number // 1088,
  from_chain_name: string // "METIS",
  from_token_address: string // "0xEA32A96608495e54156Ae48931A7c20f0dcc1a21",
  to: string // "0x4907b9ae38dF0Ad4727330f94766a8F2E4FCdF05",
  to_chain_id: number // 56,
  to_chain_name: string // "BSC",
  to_token_address: string // "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  amount: string // 2474.3961,
  block_time: string // "2022-05-05T05:39:28Z",
  status: number // 1,
  tx_id: string // "0x2d9110fbe7bf244ce6c3ccc0eed6eab5e9db9a92bbdb90294acb50e9a7839051",
  step: number // 3,
  steps: number
  first_step_transaction: string // "0xa832236b2473fb3ffe967516e41ef1f0212864c578075892ec6c27167db7f61c"
  transactionData?: TransactionData
  origin_token_address: string
  confirmed: number
  bridgeType: string
}

export interface IFarmHistory {
  amount: string
  eventName: string
  from: string
  id: string
  optName: string
  symbol: string
  timestamp: string
  to: string
  user: string
}
