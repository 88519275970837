import { useCallback, useEffect, useState } from 'react'
import { getPowChainReceived, getPowChainTxInfo } from 'utils/api'
import { useFinalMintEvents } from 'hooks/useMint'
import validator from 'multicoin-address-validator'

const checkOpReturn = (res: any) => {
    const outputs = res.data.outputs
    let ret = false
    let eth = ''
    for (const output of outputs) {
        if (output.address === 'nulldata' || output.type === 'nulldata') {
            let asm = output.script_asm
            eth = '0x' + asm.replace('OP_RETURN ', '')
            // @ts-ignore
            if (!validator.validate(eth, 'eth')) {
                console.error(`eth address is wrong eth address`)
            } else {
                ret = true
            }
        }
    }
    return { ethAddress: eth, ret }
}

const useQueryMint = (type: string, multiAddress: string) => {
    const [confirmation, setConfirmation] = useState(0)
    const [address, setAddress] = useState('')
    const [step, setStep] = useState(0)
    const [error, setError] = useState(false)
    const { fetchMint } = useFinalMintEvents()

    const fetchCheck = useCallback(async (txID) => {
        const result = await getPowChainTxInfo(type, txID)
        const txRes = result.data
        const data = txRes.data
        if (!data) return
        const c = data.confirmations ?? 0
        const ads = data.inputs ? data.inputs[0].address : ''
        setConfirmation(c)
        setAddress(ads)
        if (c < 6) return
        // 1. check OP_REETURN
        const {ethAddress, ret} = checkOpReturn(txRes)
        if (!ret) {
            const receivedRes = await getPowChainReceived(type, ads, txID)
            for (const tx of receivedRes.data.txs) {
                if (c - tx.confirmations < 24) {
                    try {
                        const txInRes = await getPowChainTxInfo(type, tx.txid)
                        for (const input of txInRes.data.inputs) {
                            if (input.address === multiAddress) {
                                setStep(2)
                            }
                        }
                    } catch (error) {
                        setError(true)
                    }
                }
            }
        } else {
            setStep(3)
        }
        // 2. check event
        const rest = await fetchMint(type, ethAddress, txID)
        if (rest) {
            setStep(3)
        }
    }, [type])

    // useEffect(() => {
    //     if (txID) {
    //         fetchCheck()
    //     }
    // }, [fetchCheck, txID])

    return { confirmation, address, step, error, fetchCheck }

}

export default useQueryMint