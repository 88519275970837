import {
    CHAIN_ARBITRUM,
    CHAIN_AVALANCHE,
    CHAIN_BOBA,
    CHAIN_BSC,
    CHAIN_ETHER,
    CHAIN_FANTOM,
    CHAIN_HARMONY_0,
    CHAIN_HECO,
    CHAIN_OK,
    CHAIN_OPTIMISM,
    CHAIN_POLYGON,
    CHAIN_XDAI,
  } from '@w3u/chains'
  import BigNumber from 'bignumber.js'

  import { TwowayChefInfo } from 'modal/twoway'

  export const getBlockTime = (chainID: number | undefined): number => {
    switch (chainID) {
      case CHAIN_ETHER:
        return 15
      case CHAIN_BSC:
        return 3
      case CHAIN_OK:
        return 4
      case CHAIN_POLYGON:
        return 2
      case CHAIN_XDAI:
        return 5
      case CHAIN_HECO:
        return 3
      case CHAIN_FANTOM:
        return 1
      case CHAIN_AVALANCHE:
        return 2
      case CHAIN_HARMONY_0:
        return 2
      case CHAIN_ARBITRUM:
        return 1
      case CHAIN_OPTIMISM:
        return 1
      case CHAIN_BOBA:
        return 1
      default:
        return 3
    }
  }

//   export const displayAPY = (poolInfo: PoolInfo, boringPrice: string): string => {
//     const result = calcAPY(poolInfo, boringPrice)
//     if (new BigNumber(result).eq(0)) {
//       return 'Finished'
//     }

//     return result + '%'
//   }

//   export const calcAPY = (poolInfo: PoolInfo, boringPrice: string): string => {
//     const rate = new BigNumber(poolInfo.point)
//       .div(poolInfo.totalPoint)
//       .multipliedBy(poolInfo.boringPerBlock)
//       .div(getBlockTime(poolInfo.token?.chainID))

//     const lockValue = new BigNumber(poolInfo.totalStaked).multipliedBy(10 ** 18)
//     const m = rate.multipliedBy(365 * 3600 * 24).multipliedBy(boringPrice)

//     if (lockValue.eq(0)) return '0.00'

//     const result = m.div(lockValue).multipliedBy(100)

//     let fixed = 2
//     if (result.gt(1000)) {
//       fixed = 0
//     }

//     return result.toFixed(fixed)
//   }

  export const displayTwowayBoringAPY = (poolInfo: TwowayChefInfo | undefined, boringPrice: string): string => {
    if (!poolInfo) return '0'
    const rate = new BigNumber(poolInfo.point).div(poolInfo.totalPoint).multipliedBy(poolInfo.rewardPerSecond[0])

    let lockValue = new BigNumber(poolInfo.totalStaked).multipliedBy(10 ** 18)
    const m = rate.multipliedBy(365 * 3600 * 24).multipliedBy(boringPrice)

    if (lockValue.eq(0)) return '0.00'

    if (poolInfo.token?.symbol === 'oETH') {
      lockValue = lockValue.multipliedBy(2800)
    }

    if (poolInfo.token?.symbol === 'oMETIS') {
      lockValue = lockValue.multipliedBy(150)
    }

    const result = m.div(lockValue).multipliedBy(100)

    let fixed = 2
    if (result.gt(1000)) {
      fixed = 0
    }

    return result.toFixed(fixed)
  }

  export const displayTwowayOTokenAPY = (poolInfo: TwowayChefInfo | undefined): string => {
    if (!poolInfo) return '0'
    const rate = new BigNumber(poolInfo.rewardPerSecond[1])

    const lockValue = new BigNumber(poolInfo.totalStaked)
    const m = rate.multipliedBy(365 * 3600 * 24)

    if (lockValue.eq(0)) return '0.00'

    const result = m.div(lockValue).multipliedBy(100)

    let fixed = 2
    if (result.gt(1000)) {
      fixed = 0
    }

    return result.toFixed(fixed)
  }
