import React, { useState, useEffect, useContext } from 'react'
import { Box } from '@mui/material'
import { Chains} from '@w3u/chains'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { getScanTxLink } from 'utils'
import { getNativeSteps } from 'utils/api'
import useRefresh from 'hooks/useRefresh'
import { TransactionContext } from 'contexts/TransactionContext'
import { TransactionAllData, BridgeType } from 'modal/types'
import { CrossNoticeStatus, CrossNoticeEdge, Flex, Text } from 'components/Style'

import { ViewIcon } from 'components/Svg'
import Arrow from 'assets/images/arrow.svg'
import Right from 'assets/images/right.svg'
import Time from 'assets/images/time.svg'
import Wrong from 'assets/images/wrong.svg'

const ViewBox = styled.div`
  margin-left: 8px;
  > svg {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
`

const NativeBridgeNotice = ({
  fromID,
  toID,
  txHash,
  amount,
  symbol,
  tokenAddress,
  receivedAmount,
}: {
  fromID: number
  toID: number
  txHash: string
  amount: string
  symbol: string
  tokenAddress: string
  receivedAmount: string
}) => {
  const { t } = useTranslation()
  const { targetOpen, changeTxHash, updateData } = useContext(TransactionContext)
  const [status, setStatus] = useState(0)
  const [secondHash, setSecondHash] = useState('')
  const [firstOpen, setFirstOpen] = useState(true)
  const { fastRefresh } = useRefresh()
  const fetchSteps = async () => {
    const res = await getNativeSteps({tx_id: txHash})
    const data = res.data
    // console.log(data)

    if (txHash && data) {
      const events = data.events
      let history:any[] = []
      if (events.length === 0) {
        const step1 = {
          transaction: txHash,
          event: "CrossOut",
          amount,
          first_step_transaction: txHash,
          from: "",
          event_chain_id: fromID,
          event_chain_name: Chains[fromID].displayName,
          from_chain_id: fromID,
          from_chain_name: Chains[fromID].displayName,
          origin_chain_id: fromID,
          origin_chain_name: Chains[fromID].displayName,
          origin_token_address: tokenAddress,
          origin_token_symbol: symbol,
          status: 0,
          step: 1,
          to: '',
          to_chain_id: toID,
          to_chain_name: Chains[toID].displayName,
          total_amount: 300,
          tx_id: txHash,
          bridgeType: BridgeType.native
        }
        const step2 = {
          ...step1,
          event: 'CrossIn',
          transaction: '',
          tx_id: '',
          step: 2,
          event_chain_id: toID,
          event_chain_name: Chains[toID].displayName
        }
        history = [step1, step2]
      } else if (events.length === 1) {
        const step1 = {
          ...events[0],
          event_chain_id: fromID,
          event_chain_name: Chains[fromID].displayName,
          bridgeType: BridgeType.native
        }
        const step2 = {
          ...events[0],
          event_chain_id: toID,
          event_chain_name: Chains[toID].displayName,
          transaction: '',
          status: 0,
          bridgeType: BridgeType.native
        }
        history = [step1, step2]
      } else if (events.length === 2) {
        const step1 = {
          ...events[0],
          event_chain_id: fromID,
          event_chain_name: Chains[fromID].displayName,
          bridgeType: BridgeType.native
        }
        const step2 = {
          ...events[1],
          event_chain_id: toID,
          event_chain_name: Chains[toID].displayName,
          bridgeType: BridgeType.native
        }
        history = [step1, step2]
      }
      const tsd: TransactionAllData = {
        [txHash]: history
      }
      setFirstOpen(false)
      updateData && updateData(tsd)
      if (firstOpen) {
        changeTxHash && changeTxHash(txHash, true)
        targetOpen && targetOpen()
      }
    }
    if (data.total_steps !== 0 && data.events.length === data.total_steps) {
      setStatus(1)
      setSecondHash(data.events[1].transaction)
    }
  }
  useEffect(() => {
    fetchSteps()
  }, [fastRefresh])

  const handleViewDetail = () => {
    if (txHash) {
      changeTxHash && changeTxHash(txHash, true)
      targetOpen && targetOpen()
    }
  }

  return (
    <>
      <Box sx={{display: 'flex'}}>
        <CrossNoticeEdge
          chainID={fromID}
          amount={amount}
          symbol={symbol}
          href={getScanTxLink(fromID, txHash)}
          desc={`From ${Chains[fromID].displayName}`}
        />
        <CrossNoticeStatus mx={2} width={46}>
          {status === 0 && <img src={Time} alt='Loading' />}
          {status === 1 && <img src={Right} alt='Done' />}
          {status === 2 && <img src={Wrong} alt='Wrong' />}
          <img src={Arrow} alt='arrow' />
        </CrossNoticeStatus>
        <CrossNoticeEdge
          chainID={toID}
          amount={receivedAmount}
          symbol={`${symbol}`}
          href={getScanTxLink(toID, secondHash)}
          desc={`To ${Chains[toID].displayName}`}
        />
      </Box>
      <Flex alignItems="center" mt="8px" onClick={handleViewDetail}>
        <Text color="#0068FF">{t('View detail')}</Text>
        <ViewBox><ViewIcon color="#0068FF" /></ViewBox>
      </Flex>
    </>
  )
}

export default NativeBridgeNotice
