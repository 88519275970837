import { useState } from 'react'
import { Flex, Text } from 'components/Style'
import styled from '@emotion/styled'

const TabWrapper = styled(Flex)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: #0068ff33;
    padding: 5px;
    margin-bottom: 30px;
    border-radius: 10px;
    @media screen and (max-width: 600px) {
        width: 100%;
        margin-bottom: 15px;
    }
`

const TabButton = styled.div<{actived: boolean}>`
    display: flex;
    justify-content: center;
    background: ${({ actived }) => actived ? 'linear-gradient(90deg, #0068FF 0%, #00A1FF 100%)' : '' } ;
    border-radius: 8px;
    padding: 10px 0;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
        /* background: linear-gradient(90deg, #0068FF 0%, #00A1FF 100%); */
    }
`

const DATA = ['BORING', 'UNBORING']

interface Props {
    onTab: (token: string) => void
}

const Tab: React.FC<Props> = ({ onTab }) => {
    const [token, setToken] = useState('BORING')
    const handleClick = (t: string) => {
        setToken(t)
        onTab(t)
    }

    return (
        <TabWrapper>
            {DATA.map(d => (
                <TabButton key={d} onClick={() => handleClick(d)} actived={token === d}>
                    <Text fontSize="14px">{d}</Text>
                </TabButton>
            ))}
        </TabWrapper>
    )
}

export default Tab
