import { useState } from 'react'
import { Flex, Text } from 'components/Style'
import styled from '@emotion/styled'
import { getIcon } from 'utils/icons'

const TabWrapper = styled(Flex)`
    display: inline-flex;
    background: #0068ff33;
    padding: 10px;
    border-radius: 10px;
    @media screen and (max-width: 600px) {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 5px;
    }
    > div:first-child {
        margin: 0 10px 0 0 !important;
    }
    > div:last-child {
        margin: 0 0 0 10px !important;
    }
`

const TabButton = styled.div<{actived: boolean}>`
    display: flex;
    background: ${({ actived }) => actived ? 'linear-gradient(90deg, #0068FF 0%, #00A1FF 100%)' : '' } ;
    border-radius: 8px;
    padding: 10px 25px;
    cursor: pointer;
    &:hover {
        background: linear-gradient(90deg, #0068FF 0%, #00A1FF 100%);
    }
    @media screen and (max-width: 600px) {
        padding: 10px 16px;
    }
`

const DATA = ['oBTC', 'oLTC', 'oDOGE']

interface Props {
    defaultToken: string
    onToken: (token: string) => void
}

const Tab: React.FC<Props> = ({ onToken, defaultToken }) => {
    const [token, setToken] = useState(defaultToken ?? 'oBTC')
    const handleClick = (t: string) => {
        setToken(t)
        onToken(t)
    }

    return (
        <TabWrapper>
            {DATA.map(d => (
                <TabButton key={d} onClick={() => handleClick(d)} actived={token === d}>
                    <img src={getIcon(d)} width="20px" height="20px" alt="Token" />
                    <Text ml="10px">{d}</Text>
                </TabButton>
            ))}
        </TabWrapper>
    )
}

export default Tab
