import {
    CHAIN_ARBITRUM,
    CHAIN_AVALANCHE,
    CHAIN_AVALANCHE_TESTNET,
    CHAIN_BOBA,
    CHAIN_BSC,
    CHAIN_BSC_TESTNET,
    CHAIN_ETHER,
    CHAIN_ETHER_KOVAN,
    CHAIN_FANTOM,
    CHAIN_FANTOM_TESTNET,
    CHAIN_HARMONY_0,
    CHAIN_HARMONY_0_TESTNET,
    CHAIN_HECO,
    CHAIN_HECO_TESTNET,
    CHAIN_OK,
    CHAIN_OK_TESTNET,
    CHAIN_OPTIMISM,
    CHAIN_POLYGON,
    CHAIN_POLYGON_MUMBAI,
    CHAIN_XDAI,
    CHAIN_XDAI_TESTNET,
  } from '@w3u/chains'
  
  import {PegToken} from 'modal/token'
  
  export const PEG_PROXY: {[chainID: number]: string} = {
    [CHAIN_ETHER]: '0xF8393Bc60CF8CfcD442BcD742a4Aa847f4B6B4ac',
    [CHAIN_BSC]: '0xf52d6823d9e2aff7548d9fe82eeadca6b1ed3062',
    [CHAIN_OK]: '0x39b107d2d810954507e7cd70403fda4ae12a192e',
    [CHAIN_POLYGON]: '0x54e2657015a9b5cc65dde59e515c71171312d319',
    [CHAIN_AVALANCHE]: '0x0f4c9320b9de4fa426d3e27d85c3452f52314c57',
    [CHAIN_HARMONY_0]: '0xe3b59fd01c0155a98146a6e0beb8376b751363fc',
    [CHAIN_HECO]: '0x8b645375a7a3bceaa01a6ecfd6e58d90b125b454',
    [CHAIN_FANTOM]: '0xbe4a5438ad89311d8c67882175d0ffcc65dc9c03',
    [CHAIN_XDAI]: '0x8b645375a7a3bceaa01a6ecfd6e58d90b125b454',
    [CHAIN_ETHER_KOVAN]: '0xF4809f0574E66aB07A0D2F4c229af0cD31A16FA6',
    [CHAIN_ARBITRUM]: '0x11E4BED429b239a1A0C594ADEB71b99e8Fa1011A',
    [CHAIN_OPTIMISM]: '0xD01a5051253007ae0b7123b50410E3B5A3f6cF95',
    [CHAIN_BOBA]: '0xa1C094Be9E6E3d5FA1815aF8c2c80fF19a4FEcdb',
    [CHAIN_BSC_TESTNET]: '0x6456cA9a734FBEB21BE8b7935837FF33325F4dBf',
    [CHAIN_OK_TESTNET]: '0x1D89E92f28f66DBE12a7F0b469ab55e77330bb1b',
    [CHAIN_POLYGON_MUMBAI]: '0xBDF99C3b6c23fF62049A721385893696514eed62',
    [CHAIN_AVALANCHE_TESTNET]: '0x019ecC465EeE4c2C12c9D27474eB494Cc58799e8',
    [CHAIN_HARMONY_0_TESTNET]: '0x8723ca4652cC6b0d7f916B524178F5ed7FB3534A',
    [CHAIN_HECO_TESTNET]: '0x7fbdB4E3657AA7877887BB445dcB57e58c674871',
    [CHAIN_FANTOM_TESTNET]: '0x40a7Db10d41b0e16F8E264294E47A4719c706EA2',
    [CHAIN_XDAI_TESTNET]: '0x25836668769f5017F29CA40967c5a2889a001550',
  }
  
  export const PEG_TOKENS: PegToken[] = [
    {
      symbol: 'USDT',
      address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      chainID: CHAIN_ETHER,
      decimals: 6,
      pair: '0xfd16ea1d1a5531b1829200f64e8c18f8e9364477',
      pid: 0,
    },
    {
      symbol: 'USDT',
      address: '0x382bB369d343125BfB2117af9c149795C6C65C50',
      chainID: CHAIN_OK,
      decimals: 18,
      pair: '0x232c6b4e2e0833b1e6e07c9a37898203e7dd7b24',
      pid: 0,
    },
    {
      symbol: 'USDT',
      address: '0x55d398326f99059ff775485246999027b3197955',
      chainID: CHAIN_BSC,
      decimals: 18,
      pair: '0x37e6812c17252534b8b30c8b05fb677c4a94c397',
      pid: 0,
    },
    {
      symbol: 'USDT',
      address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      chainID: CHAIN_POLYGON,
      decimals: 6,
      pair: '0xed6701d701841401b446f23757caa422d81691b0',
      pid: 0,
    },
    {
      symbol: 'USDT',
      address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
      chainID: CHAIN_AVALANCHE,
      decimals: 6,
      pair: '0x216f332d17145871d1d5ff5feb4b08513ef7cc21',
      pid: 0,
    },
    {
      symbol: 'USDT',
      address: '0xa71edc38d189767582c38a3145b5873052c3e47a',
      chainID: CHAIN_HECO,
      decimals: 18,
      pair: '0xe9fe1cefed8492cce0a72bc01c46002791644784',
      pid: 0,
    },
    {
      symbol: 'USDT',
      address: '0x3c2b8be99c50593081eaa2a724f0b8285f5aba8f',
      chainID: CHAIN_HARMONY_0,
      decimals: 6,
      pair: '0xb238a595e9797e009efd5f938c9918181662066c',
      pid: 0,
    },
    {
      symbol: 'USDT',
      address: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
      chainID: CHAIN_FANTOM,
      decimals: 6,
      pair: '0xe4af65953eeb4b868b7b7ff33808e22069b09916',
      pid: 0,
    },
    {
      symbol: 'USDT',
      address: '0x4ECaBa5870353805a9F068101A40E0f32ed605C6',
      chainID: CHAIN_XDAI,
      decimals: 6,
      pair: '0xe9fe1cefed8492cce0a72bc01c46002791644784',
      pid: 0,
    },
    {
      symbol: 'USDT',
      address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
      chainID: CHAIN_ARBITRUM,
      decimals: 6,
      pair: '0xe4aF65953eeB4b868B7b7ff33808E22069B09916',
      pid: 0,
    },
    {
      symbol: 'USDT',
      address: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
      chainID: CHAIN_OPTIMISM,
      decimals: 6,
      pair: '0x68D97B7A961a5239B9F911DA8dEb57F6eF6e5e28',
      pid: 0,
    },
    {
      symbol: 'USDT',
      address: '0x5DE1677344D3Cb0D7D465c10b72A8f60699C062d',
      chainID: CHAIN_BOBA,
      decimals: 6,
      pair: '0x364701C4276B800A7493C84785E8ECD97258AcDe',
      pid: 0,
    },
    // {
    //   symbol: 'USDT',
    //   address: '0x1D83BcDA708047898F20Cebb4AABF08008783f41',
    //   chainID: CHAIN_ETHER_KOVAN,
    //   decimals: 6,
    //   pair: '0x51d4615c849A80BAFb3EcF4817Fb9174eD4B7284',
    //   pid: 0,
    // },
    {
      symbol: 'USDT',
      address: '0xB36c3713A6D46C67f55F6F49Ae0c47a61901F015',
      chainID: CHAIN_BSC_TESTNET,
      decimals: 18,
      pair: '0x9d3f38c696ca08CDDC9C5D04B24D52beE5a81ea1',
      pid: 0,
    },
    {
      symbol: 'USDT',
      address: '0xCB7Bb6e911e79713A596731dc21D0a2EF24a4F74',
      chainID: CHAIN_POLYGON_MUMBAI,
      decimals: 6,
      pair: '0xa8E633C998b109eE76714b4CA8d7D652A68b3860',
    },
    {
      symbol: 'USDT',
      address: '0xbE64543d9dC5b530ee9bD6259D02d14613Aec9aB',
      chainID: CHAIN_OK_TESTNET,
      decimals: 18,
      pair: '0xC8E98D8855088F869a38dD00f4683c3Ea05d2c62',
    },
    {
      symbol: 'USDT',
      address: '0xb608b55b0F777e70F8e37a18F8Da6EC8AE667B33',
      chainID: CHAIN_AVALANCHE_TESTNET,
      decimals: 6,
      pair: '0xBE4Cb4bD2f6E1BAC5E45CE9162388d514375Dc28',
    },
    {
      symbol: 'USDT',
      address: '0xAe8234563e2B07E5cB89c6B0d81Fe54CF7667769',
      chainID: CHAIN_HECO_TESTNET,
      decimals: 18,
      pair: '0x5b9611a5f0dA88aC6D3146C784a7ff66de6E6f09',
    },
    {
      symbol: 'USDT',
      address: '0xbf49c0ffDEEC5bF1731674841B60E4B0855FE6ED',
      chainID: CHAIN_HARMONY_0_TESTNET,
      decimals: 6,
      pair: '0xa42ECFcFE2eA660D4cA88d6773a77eA41AB384a6',
    },
    {
      symbol: 'USDT',
      address: '0xbf49c0ffDEEC5bF1731674841B60E4B0855FE6ED',
      chainID: CHAIN_FANTOM_TESTNET,
      decimals: 6,
      pair: '0x031c7CaCCA128D830C0160e36074D296B6AD23AA',
    },
    {
      symbol: 'USDT',
      address: '0xbf49c0ffDEEC5bF1731674841B60E4B0855FE6ED',
      chainID: CHAIN_XDAI_TESTNET,
      decimals: 6,
      pair: '0xbBa69494e9812948349f31691C871A4aBA42D517',
    },
  ]
  