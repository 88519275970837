import styled from '@emotion/styled'

export const DialogTitle = styled.div`
    text-align: center;
    color: #FFFFFF;
    font-size: 14px;
    font-family: 'Lexend-Regular, Lexend';
    margin-bottom: 15px;
`
export const TokenBalance = styled.div`
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.23) 0%, rgba(0, 35, 55, 0.22) 50%, rgba(0, 161, 255, 0.15) 100%);
    border-radius: 10px;
    border: 1px solid rgba(0, 104, 255, 0.22);
    padding: 10px 20px;
    margin-bottom: 8px;
    color: #ffffff;
    font-weight: 500;
    > div {
        padding: 10px 0;
        align-items: center;
    }
    > div:first-child {
        border-bottom: 1px solid #000000;
    }
`
export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr 100px 60px;
    padding: 16px 0;
    /* border-bottom: 1px solid #000000; */
    color: #ffffff;
    font-weight: 500;
    @media screen and (max-width: 600px) {
        grid-template-columns: 120px 4fr 60px 40px;
    }
`
export const ChainStep = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    padding: 30px 0;
    color: #ffffff;
    font-weight: 500;
    border-top: 1px solid #000000;
    @media screen and (max-width: 600px) {
        grid-template-columns: 110px 4fr 60px 40px;
    }
`
