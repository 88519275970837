import styled from "@emotion/styled"
import { Text } from 'components/Style'

export const MobileWrapper = styled.div`
    width: 100%;
    padding: 20px 15px 15px;
    border-radius: 20px;
    background: #0000004d;
`
export const PrincipleWrapper = styled.div`
    border-radius: 20px;
    background: #0000004d;
    padding: 0;
`
export const FarmBox = styled.div`
    background: #8585854d;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
`

export const FarmBoxBottom = styled(FarmBox)`
    background: #3E5BF24d;
`
export const WrapperText = styled.div<{mb?: string, fullwidth?: boolean}>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    background: #0000004d;
    border-radius: 15px;
    margin-bottom: ${({ mb }) => mb ? mb : '0'};
    width: ${({ fullwidth }) => fullwidth ? '100%' : 'auto'};
`

export const WrapperTextSmail = styled(WrapperText)`
    padding: 5px 15px;
`
export const CopyAddress = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 17px 0;
    border: 1px solid #0068ff;
    border-radius: 9px;
    margin-bottom: 15px;
    cursor: pointer;
    @media screen and (max-width: 600px) {
        border: 1px solid #0068ff80;
    }
`
export const ViewWallet = styled.div`
    background: #0068ff4d;
    padding: 7px 15px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    margin: 15px 0;
`
export const ComplateBox = styled.div`
    display: flex;
    margin: 25px auto 12px;
    justify-content: center;
    > svg {
        width: 60px;
        height: 60px;
    }
`
export const InputBox = styled.div`
    border: 1px solid #0068FF;
    border-radius: 9px;
    padding: 8px 15px;
    margin: 15px 0;
`
export const CalcWrapper = styled.div<{context?: string}>`
    display: flex;
    justify-content: ${({context}) => context ? context :  'space-between'};
    align-items: center;
    border: 1px solid #0068FF;
    border-radius: 10px;
    padding: 15px;
    @media screen and (max-width: 600px) {
        border: none;
        background: #0000004d;
    }
`

export const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    background: #000000;
    border-radius: 10px;
    padding: 20px 15px;
    margin-bottom: 15px;
`
export const TotalSupply = styled.div`
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #000000 13.12%, rgba(0, 35, 55, 0.220736) 56.4%, rgba(0, 161, 255, 0.175033) 99.99%);
    border: 1px solid rgba(0, 104, 255, 0.479268);
    padding: 15px;
    border-radius: 20px;
`

export const TotalSupplyMobile = styled.div`
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #000000 0%, rgba(0, 35, 55, 0.22) 50%, rgba(0, 161, 255, 0.18) 100%);
    border: 1px solid #00a1ff2e;
    padding: 15px;
    border-radius: 10px;
`

export const StepGrid = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 1.8fr 1.5fr 1.8fr 1.5fr 1.8fr 1.5fr;
`
export const MobileStep = styled.div`
    display: flex;
    flex-direction: column;
`
export const SepBox = styled.div`
    position: relative;
    justify-content: center;
`

export const NumberIcon = styled.div<{actived?: boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 24px;
    background: ${({ actived }) => actived ? '#0068FF' : '#ffffff66'};
    @media screen and (max-width: 600px) {
        font-size: 12px;
        width: 20px;
        height: 20px;
    }
`
export const StepText = styled(Text)`
    position: absolute;
    top: calc(100% + 10px);
    white-space: nowrap;
`
export const Line = styled.div`
    width: 1px;
    height: 30px;
    margin: 4px 0; 
    border-right: 1px dashed #ffffff;
    margin-left: 10px;
` 