import React, { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core' 
import { CHAIN_ETHER, CHAIN_BSC } from '@w3u/chains'
import { Flex } from 'components/Style'
import { useTranslation } from 'react-i18next'
import { Typography, Tooltip } from '@mui/material'
import styled from '@emotion/styled'
import { getIcon, getMintIcon } from 'utils/icons'
import MyButton from 'components/Button'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import NashAddrssImg from 'assets/images/mint/nash_address.svg'
import NashContractImg from 'assets/images/mint/nash_contract.svg'
import EthImg from 'assets/images/mint/eth_wallet.svg'

const SetpBox = styled(Flex)<{variant: string, mb?: string, hidebgcolor?: boolean}>`
    justify-content: center;
    align-items: center;
    background: ${({ hidebgcolor }) => hidebgcolor ? '' : '#00000066'};
    padding: ${({ variant }) => variant === 'sm' ? '9px 20px' : '24px 0'};
    width: ${({ variant }) =>  variant === 'sm' ? '180px' : '100%'};
    margin-bottom: ${({ mb }) => mb ? mb : '0'};
    border-radius: 14px;
    > p {
        margin-left: 12px;
    }
`

const TradeTooltip = () => {
    const { t } = useTranslation()
    return (
        <Tooltip
            title={
                <React.Fragment>
                    <Flex alignItems="center">
                        <CloseIcon sx={{color: '#ff0000'}} />
                        <Typography ml="4px">{t('NO exchange address')}</Typography>
                    </Flex>
                    <Flex alignItems="flex-start" justifyContent="flex-start">
                        <CheckIcon sx={{color: '#2e7d32'}} />
                        <Typography>{t('Please choose a wallet that supports the OP_RETURN function(eg. imToken, Math Wallet or Bitpie)')}</Typography>
                    </Flex>
                </React.Fragment>
            }
        >
            <HelpOutlineIcon sx={{cursor: 'pointer'}} />
        </Tooltip>
    )
    
}

interface Props {
    oToken: string
    onNext: (step: number) => void
}
const GuideOne:React.FC<Props> = ({ oToken, onNext }) => {
    const { t } = useTranslation()
    const { chainId } = useWeb3React()
    const token = useMemo(() => oToken.slice(1) ?? 'BTC', [oToken])
    const handleNext = (index: number) => {
        onNext && onNext(index)
    }
    const ChainEther = useMemo(() => {
        let chainEther = 'ETH'
        switch (chainId) {
            case CHAIN_ETHER:
                chainEther = 'ETH'
                break;
            case CHAIN_BSC:
                chainEther = 'BNB'
                break;
            default:
                chainEther = 'ETH'
        }
        return chainEther
    }, [chainId])

    return (
        <>
            <Typography>{t('mechanism')}</Typography>
            <Flex mt="20px" mb="20px">
                <Flex flexDirection="column" justifyContent="space-between" alignItems="center" flex="3 3 0">
                    <SetpBox variant='sm' mb="10px">
                        <img src={getIcon(oToken)} width="24px" height="24px" alt={token} />
                        <Typography whiteSpace="nowrap">{t('chain_network', {chain: token})}</Typography>
                    </SetpBox>
                    <SetpBox variant='lg' mb="10px">
                        <img src={getMintIcon(oToken)} width="24px" height="24px" alt={token} />
                        <Flex alignItems="center">
                            <Typography fontSize="12px" m="0 6px">{t('your_chain_address', {chain: token})}</Typography>
                            <TradeTooltip />
                        </Flex>
                    </SetpBox>
                    <SetpBox variant='lg' mb="10px" height="76px" hidebgcolor>
                        <ArrowDownwardIcon />
                        <Typography fontSize="12px">{t('send')} {token}</Typography>
                    </SetpBox>
                    <SetpBox variant='lg'>
                        <img src={NashAddrssImg} width="24px" height="24px" alt="Token" />
                        <Typography fontSize="12px">{t('NEL_address')}</Typography>
                    </SetpBox>
                </Flex>
                <Flex justifyContent="center" alignItems="flex-end" pb="40px" flex="1 1 auto"><ArrowForwardIcon /></Flex>
                <Flex flexDirection="column" alignItems="center" flex="3 3 0">
                    <SetpBox variant='sm' mb="10px">
                        <img src={getIcon(ChainEther)} width="24px" height="24px" alt={ChainEther} />
                        <Typography>{t('chain_network', {chain: ChainEther})}</Typography>
                    </SetpBox>
                    <SetpBox variant='lg' mb="10px">
                        <img src={EthImg} width="24px" height="24px" alt="Token" />
                        <Typography fontSize="12px">{t('your_chain_address', {chain: ChainEther})}</Typography>
                    </SetpBox>
                    <SetpBox variant='lg' mb="10px" height="76px" hidebgcolor>
                        <ArrowUpwardIcon />
                        <Typography fontSize="12px">{t('receive_token', {otken: oToken})}</Typography>
                    </SetpBox>
                    <SetpBox variant='lg'>
                        <img src={NashContractImg} width="24px" height="24px" alt={ChainEther} />
                        <Typography fontSize="12px">{t('NEL_contract')}</Typography>
                    </SetpBox>
                </Flex>
            </Flex>
            <Flex justifyContent="center">
                <MyButton onClick={() => handleNext(1)}>{t('next')}</MyButton>
            </Flex>
        </>
    )
}

export default GuideOne
