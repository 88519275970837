import {Box} from '@mui/material'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import useMediaQuery from '@mui/material/useMediaQuery'
import ChainSelect from 'components/ChainSelect'
import ConnectWallet from 'components/ConnectWallet'
import BoringBalance from 'components/BoringBalance'
import { MenuIcon } from 'components/Svg'
import LogoImg from 'assets/images/BoringDAO.png'


const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 34px 24px 50px;
  @media screen and (max-width: 600px) {
    padding: 15px 20px;
  }
`

const StyleBox = styled(Box)`
  padding: 0 12px;
  display: flex;
`
interface MobileProps {
  onMenu: () => void
}
const Header:React.FC<MobileProps> = ({ onMenu }) => {
  const matches = useMediaQuery('(max-width:600px)')
  const handleMenu = () => onMenu()

  return (
    <HeaderWrapper>
      {matches ? 
      <>
        <Box
         onClick={handleMenu}
         sx={{
          cursor: 'pointer'
        }}><MenuIcon /></Box>
        <Link to="/"><img width="120px" height="30px" src={LogoImg} alt="logo" /></Link>
      </> :
      <>
        <Link to="/"><img width="120px" height="30px" src={LogoImg} alt="logo" /></Link>
        <StyleBox>
          <ChainSelect />
          <ConnectWallet variant='header' />
          <BoringBalance />
        </StyleBox>
      </>
      }
    </HeaderWrapper>
  )
}

export default Header
