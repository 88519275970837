export interface MenuItem {
  label: string
  icon: string
  href?: string
  target?: string
  hideLine?: boolean
  linkIcon?: string
}
export interface MenuItemSub {
  label: string
  href?: string
  target?: string
  hideLine?: boolean
}
export interface MenuItems extends MenuItem {
  items?: MenuItemSub[]
}

export const config: MenuItems[] = [
  // {
  //   label: 'home',
  //   icon: 'HomeIcon',
  //   href: '/',
  // },
  // {
  //   label: 'BridgeAggregator',
  //   icon: 'OportalIcon',
  //   href: '/router',
  // },
  {
    label: 'v2-oportal',
    icon: 'OportalIcon',
    target: '_blank',
    href: 'https://oportal.boringdao.com',
    hideLine: true,
    linkIcon: 'JumpIcon',
  },
  {
    label: 'oTunnel',
    icon: 'OTunnelIcon',
    items: [
      {
        label: 'mint',
        href: '/mint',
      },
      {
        label: 'burn',
        href: '/burn',
      },
      {
        label: 'pledge',
        href: '/pledge',
      },
    ],
  },
  {
    label: 'farm',
    icon: 'FarmIcon',
    items: [
      {
        label: 'oPortal_farm',
        href: '/farm/oportal',
      },
      {
        label: 'oTunnel_farm',
        href: '/farm/boring',
      },
      // {
      //     label: '(3,3)',
      //     href: 'https://33bond.boringdao.com/',
      //     target: '_blank'
      // },
      {
        label: 'externel_farm',
        href: '/externalfarm',
      },
    ],
  },
  {
    label: 'Boring',
    icon: 'BoringIcon',
    items: [
      {
        label: 'swap',
        href: '/swap',
      },
      {
        label: 'split',
        href: '/split',
      },
      {
        label: 'governance',
        target: '_blank',
        href: 'https://forum.boringdao.com',
      },
    ],
  },
  {
    label: 'V2',
    icon: 'V2Icon',
    target: '_blank',
    href: 'https://app-v2.boringdao.com',
    hideLine: true,
    linkIcon: 'JumpIcon',
  },
]

export const socials = [
  {
    label: 'PeckShield',
    icon: 'PeckshieidIcon',
    href: 'https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-BoringDAO-1.0-2020-89.pdf',
  },
  {
    label: 'Certik',
    icon: 'CertikIcon',
    href: 'https://github.com/BoringDAO/boringDAO-contract/blob/master/REP-BoringDAO-2021-05-29.pdf',
  },
  {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: 'https://twitter.com/TheBoringDAO',
  },
  {
    label: 'Mediun',
    icon: 'MediunIcon',
    href: 'https://theboringdao.medium.com',
  },
  {
    label: 'Discord',
    icon: 'DiscordIcon',
    href: 'https://discord.com/invite/zCQ2ddp',
  },
  {
    label: 'Telegeam',
    icon: 'TelegeamIcon',
    href: 'https://t.me/boringdao_official',
  },
  {
    label: 'Github',
    icon: 'GithubIcon',
    href: 'https://github.com/BoringDAO',
  },
  {
    label: 'DefiOulse',
    icon: 'DefiplusIcon',
    href: 'https://www.defipulse.com/projects/boringdao',
  },
]
