import React, {useEffect, useMemo, useState} from 'react'
import { MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import {CHAIN_ETHER, Chains} from '@w3u/chains'
import {useWeb3React} from '@web3-react/core'
import styled from '@emotion/styled'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import {getChainIcon} from 'utils/icons'
import {getChainName, setupNetwork} from 'utils/network'
import { BoringSelect, Text } from 'components/Style'

const StyleBoringSelect = styled(BoringSelect)<{border?: boolean}>`
  //background: #000000;
  border-radius: 8px !important;
  padding: 6px 10px;
  border: ${({ border }) => border ? '1px' : '0'} solid #0068ff66;
  padding-left: ${({ border }) => border ? '8px !important' : '0'};
`

const ChainSwitcher = ({
  chainIDs,
  onChange,
  changeNetwork,
  selectCurrentChain,
  defaultChain,
  fullWidth,
  border,
}: {
  chainIDs: number[]
  onChange?: (id: number) => void
  changeNetwork?: boolean
  selectCurrentChain?: boolean
  defaultChain?: any
  fullWidth?: boolean
  border?: boolean
}) => {
  const {chainId} = useWeb3React()
  const [id, setID] = useState<number>(selectCurrentChain || defaultChain ? defaultChain || chainId || CHAIN_ETHER : chainIDs[0])
  const sid = useMemo(() => {
    if (id) {return id.toString()} else {return '1'}
  }, [id])
  const change = async (e: SelectChangeEvent) => {
    if (!changeNetwork) return setID(e.target.value as unknown as number)
    await setupNetwork(e.target.value as unknown as number)
      .then((res) => {
        if (res) {
          setID(e.target.value as unknown as number)
        }
        // window.location.reload()
      })
      .catch((e: any) => console.error('Setup network: ', e))
  }
  const changeDefaultChain = async (id: number) => {
    if (!changeNetwork) return null
    await setupNetwork(id)
      .then((res) => {
        if (res) {
          setID(id)
        }
      })
      .catch((e: any) => console.error('Setup network: ', e))
  }

  useEffect(() => {
    if (changeNetwork) {
      setID(selectCurrentChain ? chainId ?? CHAIN_ETHER : chainIDs[0])
    }
  }, [chainId])

  useEffect(() => {
    if (!selectCurrentChain) {
      if (chainIDs.some(s => s === defaultChain)) {
        setID(defaultChain)
      } else {
        setID(chainIDs[0])
      }
    } else {
      if (chainIDs.some(s => s === defaultChain)) {
        setID(defaultChain)
        changeDefaultChain(defaultChain)
      }
    }
  }, [chainIDs, defaultChain])

  useEffect(() => onChange && onChange(id), [id])


  return (
    <Select
      style={{fontSize: '13px'}}
      sx={{borderRadius: '100px', height: '32px', width: fullWidth ? '100%' : 'auto'}}
      IconComponent={ExpandMoreOutlinedIcon}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        elevation: 10,
      }}
      onChange={change}
      value={sid}
      input={<StyleBoringSelect border={border} />}
    >
      {chainIDs.map((chain) => (
        <MenuItem key={chain} value={chain}>
          <img src={getChainIcon(chain)} width={20} alt='chain' />
          <Text>{getChainName(chain)}</Text>
        </MenuItem>
      ))}
    </Select>
  )
}

export default ChainSwitcher
