import React, {useMemo, useCallback, useEffect, useState} from 'react'
import {Flex, Text} from 'components/Style'
import {useTranslation} from 'react-i18next'
import {Box} from '@mui/material'
import styled from '@emotion/styled'
import copy from 'copy-to-clipboard'
import QRCode from 'qrcode'
import {toast} from 'react-toastify'
import {CopyIcon} from 'components/Svg'
import {getMinMintValue} from 'config/constants/pow_bridge'
import MyButton from 'components/Button'
import {BackIcon} from 'components/Svg'

const ViewBox = styled(Box)`
  position: relative;
`
const ViweBoxMiddle = styled(Flex)`
  margin-bottom: 10px;
  img {
    flex: 0 0 170px;
  }
  svg {
    width: 12px;
    height: 12px;
  }
  > div {
    flex: 0 0 50%;
    > div {
      line-height: 24px;
    }
  }
`
const QRImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px;
`

const Back = styled(Flex)`
  cursor: pointer;
`
interface Props {
  oToken: string
  multiAddres: string
  onNext: (step: number) => void
}
const GuideTwo: React.FC<Props> = ({oToken, multiAddres, onNext}) => {
  const {t} = useTranslation()
  const [qrImage, setQrImage] = useState('')
  const handleCopy = (link: any) => {
    const res = copy(multiAddres)
    if (res) {
      toast('Copied', {autoClose: 1000})
    }
  }
  const handleNext = (index: number) => {
    onNext && onNext(index)
  }

  const generateQR = useCallback(async () => {
    const qr = await QRCode.toDataURL(multiAddres, {margin: 2})
    setQrImage(qr)
  }, [multiAddres])

  useEffect(() => {
    if (multiAddres) {
      generateQR()
    }
  }, [generateQR])
  const minMintAmount = useMemo(() => getMinMintValue(oToken.slice(1)), [oToken])
  return (
    <ViewBox>
      <Flex mb='42px'>
        <Text fontSize='14px'>{t('demo')}</Text>
      </Flex>
      <ViweBoxMiddle>
        <img style={{width: '170px', borderRadius: '20px'}} width='170px' src='./images/iphone.gif' alt='view' />
        <Flex ml='30px' flexDirection='column' mt='30px'>
          <Flex>
            <Text>1、</Text>
            <Flex flexDirection='column'>
              <Text>{t('enter_the_NEL')}</Text>
              <Flex style={{cursor: 'pointer'}} alignItems='center' onClick={handleCopy}>
                <Text mr='6px'>{multiAddres}</Text>
                <CopyIcon color='#ffffff' />
              </Flex>
            </Flex>
          </Flex>
          <Flex>
            <Text>2、</Text>
            <Text mr='4px'>
              {t('enter_the_minting_amount')} {t('minimum_amount', {amount: minMintAmount})}
            </Text>
          </Flex>
          <Flex>
            <Text>3、</Text>
            <Text>{t('check_guide_3')}</Text>
          </Flex>
          <Flex>
            <Text>4、</Text>
            <Text>{t('check_guide_4')}</Text>
          </Flex>
        </Flex>
      </ViweBoxMiddle>
      <Flex justifyContent='center'>
        <MyButton onClick={() => handleNext(2)}>{t('Next')}</MyButton>
      </Flex>
      {qrImage && <QRImg src={qrImage} width='110px' height='110px' alt='QR' />}
      <Back onClick={() => handleNext(0)}>
        <BackIcon />
        <Text ml='7px'>{t('back')}</Text>
      </Back>
    </ViewBox>
  )
}

export default GuideTwo
