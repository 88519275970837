import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const ComplateIcon:React.FC<IconProps> = ({ color = "#ffffff" }) => {
    return (
        <SvgIcon viewBox="0 0 120 120">
            <path fill={color} d="M0 60C0 26.6667 27 0 60 0C93 0 120 27 120 60C120 93.3333 93 120 60 120C26.6667 120 0 93 0 60ZM49 88.3333L96.6667 40.6667C98 39.6667 98 37.6667 96.6667 36.6667L94.6667 34.6667C93.3333 33.3333 91.6667 33.3333 90.3333 34.6667L47.6667 77.3333L29.6667 56.6667C28.3333 55.6667 26.6667 55.6667 25.3333 56.6667L23.3333 58.6667C22 60 22 62 23.3333 63L41.6667 84.3333C41.7778 84.5556 41.8519 84.7778 41.9259 85C42.0741 85.4444 42.2222 85.8889 42.6667 86.3333L44.6667 88.3333C46 89.6667 47.6667 89.6667 49 88.3333Z" />
        </SvgIcon>
    )
}

export default ComplateIcon
