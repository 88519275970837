import React, { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { getTwowayHistory, getNativeHistory } from 'utils/api'
import { TransactionAllData, IHistory, BridgeType } from 'modal/types' 
import useRefresh from 'hooks/useRefresh'
import { CHAIN_POLYGON, Chains } from '@w3u/chains'

const INIT_DATA:TransactionAllData = {}

interface TransactionProps {
    data: TransactionAllData
    open: boolean
    txHash: string
    changeTxHash?: (tx: string, fromStep?: boolean) => void
    updateOpenStep?: () => void
    targetOpen?: () => void
    updateData?: (playload: TransactionAllData) => void
}

const TransactionContext = React.createContext<TransactionProps>({ data: INIT_DATA, txHash: '', open: false })

const TransactionContextProvider:React.FC = ({ children }) => {
    const { account } = useWeb3React()
    const [open, setOpen] = useState(false)
    const [data, setData] = useState<TransactionAllData>({})
    const [txHash, setTxhash] = useState('')
    const [fromStep, setFromStep] = useState(false)
    const { slowRefresh } = useRefresh()

    const fetchTwowayNativeHistory = async () => {
        const res: any = await getTwowayHistory({address: account, page: 1, page_size: 60})
        const resNative: any = await getNativeHistory({address: account, page: 1, page_size: 60})
        const data = res.data
        const nativeData = resNative.data
        let history:{[tx: string]: IHistory[]} = {}
        if (Array.isArray(data)) {
            data.map(d => {
                if (d.from_chain_id === CHAIN_POLYGON || d.to_chain_id === CHAIN_POLYGON) {
                    return { ...d, steps: 2}
                }
                return { ...d, steps: 3}
            }).forEach(f => {
                if (f.first_step_transaction in history) {
                    history[f.first_step_transaction].push({...f, bridgeType: BridgeType.oportal})
                } else {
                    history[f.first_step_transaction] = [{...f, bridgeType: BridgeType.oportal}]
                }
            })
        }
        if (Array.isArray(nativeData)) {
            nativeData.map(d => ({...d, steps: 2 })).forEach(f => {
                if (f.first_step_transaction in history) {
                    history[f.first_step_transaction].push({
                        ...f,
                        event_chain_id: f.step === 1 ? f.from_chain_id : f.to_chain_id,
                        bridgeType: BridgeType.native
                    })
                } else {
                    history[f.first_step_transaction] = [{
                        ...f,
                        event_chain_id: f.step === 1 ? f.from_chain_id : f.to_chain_id,
                        bridgeType: BridgeType.native
                    }]
                }
            })
        }
        Object.keys(history).forEach(key => {
            if (history[key].length < history[key][0].steps) {
                const firstStep = history[key][0]
                if (firstStep.steps - history[key].length === 2) {
                    history[key].push({
                        ...history[key][0],
                        step: 2,
                        status: 0,
                        transaction: '',
                        event_chain_id: CHAIN_POLYGON,
                        event_chain_name: Chains[CHAIN_POLYGON].displayName
                    })
                    history[key].push({
                        ...history[key][0],
                        step: 3,
                        status: 0,
                        transaction: '',
                        event_chain_id: firstStep.to_chain_id,
                        event_chain_name: Chains[firstStep.to_chain_id].displayName
                    })
                }
                if (firstStep.steps - history[key].length === 1) {
                    history[key].push({
                        ...history[key][1],
                        step: 3,
                        status: 0,
                        transaction: '',
                        event_chain_id: firstStep.to_chain_id,
                        event_chain_name: Chains[firstStep.to_chain_id].displayName
                    })
                }
            }
            history[key] = history[key].sort((a,b) => a.step - b.step)
        })
        if (!fromStep) {
            setData(history)
        }
    }

    useEffect(() => {
        if (account) {
            fetchTwowayNativeHistory()
        }
    }, [account, slowRefresh, fromStep])

    const targetOpen = () => {
        setOpen(!open)
    }
    const changeTxHash = (tx: string, fromStep?: boolean) => {
        setTxhash(tx)
        setFromStep(!!fromStep)
    }
    const updateData = (playload: TransactionAllData) => {
        setData({...data, ...playload})
    }

  return <TransactionContext.Provider value={{data, open, txHash, targetOpen, changeTxHash, updateData}}>{children}</TransactionContext.Provider>
}

export { TransactionContext, TransactionContextProvider }
