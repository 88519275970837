import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Flex } from 'components/Style'

export const SelectWrapper = styled.div`
    background: #00000066;
    padding: 30px;
    border-radius: 30px;
    width: 580px;
    @media screen and (max-width: 600px) {
        width: 100%;
        padding: 20px 15px;
    }
`

export const SelectBox = styled(Box)`
    background: #00000066;  
    border-radius: 10px;
    border: 1px solid #0A214D;
    padding: 15px;
    width: 100%;
`
export const ChianSelectBox = styled(Box)`
    background: #00000066;  
    border-radius: 10px;
    border: 1px solid #0A214D;
    padding: 10px 15px;
    width: 100%;
`
export const AssetGrid = styled(Flex)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`

export const Grid = styled(Flex)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
`