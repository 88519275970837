import {createStyles, Box, BoxProps, StyledComponentProps, InputBase, Button} from '@mui/material'
import {withStyles} from '@material-ui/styles'
import clsx from 'clsx'
import styled from '@emotion/styled'
import {useTranslation} from 'react-i18next'
import {getChainIcon} from 'utils/icons'
import LinkIcon from 'assets/images/link.svg'
import {Chains} from '@w3u/chains'
import {ShareIcon} from '../Svg'
export interface WrapperBoxProps extends BoxProps, StyledComponentProps {
  children?: React.ReactNode
  className?: string
}

export const Flex = ({children, className, ...other}: WrapperBoxProps): JSX.Element => {
  return (
    <Box display='flex' className={clsx(className)} {...other}>
      {children}
    </Box>
  )
}

export const Text = ({children, className, ...other}: WrapperBoxProps): JSX.Element => {
  return (
    <Box fontSize='12px' className={clsx(className)} {...other}>
      {children}
    </Box>
  )
}

export const CenterX = ({children, className, ...other}: WrapperBoxProps): JSX.Element => {
  return (
    <Box display='flex' flexDirection={'column'} alignItems='center' className={clsx(className)} {...other}>
      {children}
    </Box>
  )
}

export const CenterY = ({children, className, ...other}: WrapperBoxProps): JSX.Element => {
  return (
    <Box display='flex' alignItems='center' className={clsx(className)} {...other}>
      {children}
    </Box>
  )
}

export const Center = ({children, className, ...other}: WrapperBoxProps): JSX.Element => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center' width='100%' className={clsx(className)} {...other}>
      {children}
    </Box>
  )
}

export const BoringButtonLarge = styled(Button)`
  padding: 19px 100px;
`

export const BoringInput = withStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '6px',
      color: '#FFFFFF',
      '& svg': {
        color: '#FFFFFF',
      },
    },
  })
)(InputBase)
export const BoringInputBorder = withStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: '6px',
      border: '1px solid #0A214D',
      color: '#FFFFFF',
      '& svg': {
        color: '#FFFFFF',
      },
      '&::input-placeholder': {
        color: '#FFFFFF',
      },
    },
  })
)(InputBase)

export const BoringSelect = withStyles(() =>
  createStyles({
    root: {
      borderRadius: '6px',
      color: '#FFFFFF',
      padding: '8px 0',
      '& svg': {
        color: '#FFFFFF',
      },
      '& .MuiBox-root': {
        padding: '7px 10px',
      },
    },
  })
)(InputBase)

export const BoringSelectBorder = withStyles(() =>
  createStyles({
    root: {
      borderRadius: '6px',
      color: '#FFFFFF',
      border: '1px solid #0A214D',
      padding: '8px 12px',
      '& svg': {
        color: '#FFFFFF',
      },
      '& .MuiBox-root': {
        padding: '2px 10px 0',
      },
    },
  })
)(InputBase)

export const CrossNoticeStatus = styled(CenterX)`
  padding-top: 8px;
`

export const CrossNoticeEdge = ({
  chainID,
  amount,
  symbol,
  href,
  desc,
}: {
  chainID: number
  amount: string
  symbol: string
  href?: string
  desc: string
}) => {
  const {t} = useTranslation()
  return (
    <Box sx={{display: 'flex'}}>
      <CenterX>
        <img src={getChainIcon(chainID)} width={32} height={32} alt='chain' />
        <Text fontSize={12} color={'#ffffff66'}>
          {desc}
        </Text>
        <Box
          fontSize={12}
          mt={1}
          textAlign='center'
          sx={{
            position: 'relative',
            img: {
              position: 'absolute',
              bottom: '4px',
              right: '-16px',
            },
          }}
        >
          {amount} {symbol}
        </Box>
        {/* {href &&
          <a href={href} target={'_blank'} rel='noreferrer'>
            <Flex mt="10px" alignItems="center">
              <Text mr="4px">{t('look_details')}</Text>
              <img src={LinkIcon} alt='link' width={12} height={12} />
            </Flex>
          </a>
        } */}
      </CenterX>
    </Box>
  )
}
