import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const OTunnel:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 15 16">
            <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M7.758 2.667V4H9.052V5.333L5.172 9.333V0H0V1.333H2.586V16L14.223 2.667H7.758ZM13.551 15.985V14.928C13.269 14.971 12.88 14.992 12.383 14.993H10.142C10.249 14.727 10.401 14.486 10.599 14.273C10.827 14.028 11.145 13.761 11.55 13.473C12.271 12.977 12.718 12.649 12.894 12.489C13.273 12.132 13.463 11.705 13.463 11.209C13.463 10.659 13.26 10.227 12.855 9.913C12.449 9.599 11.898 9.44 11.199 9.44C10.387 9.44 9.778 9.678 9.366 10.152C9.099 10.462 8.916 10.889 8.815 11.433L9.958 11.568C10.007 11.243 10.089 10.998 10.207 10.833C10.41 10.519 10.749 10.36 11.223 10.36C11.565 10.36 11.834 10.438 12.031 10.592C12.23 10.752 12.328 10.968 12.328 11.24C12.328 11.588 12.008 11.98 11.367 12.416C10.744 12.833 10.304 13.155 10.048 13.385C9.53 13.854 9.165 14.376 8.952 14.953C8.856 15.214 8.786 15.556 8.744 15.977C8.979 15.95 9.322 15.936 9.776 15.936H12.343C12.775 15.936 13.178 15.952 13.551 15.985Z"/>
        </SvgIcon>
    )
}

export default OTunnel
