import React from 'react'
import {Box, Stack} from '@mui/material'
import {Chains} from '@w3u/chains'
import {useTranslation} from 'react-i18next'
import {Center, CenterX, CenterY, CrossNoticeEdge, CrossNoticeStatus, Text} from 'components/Style'
import Arrow from 'assets/images/arrow.svg'
import Right from 'assets/images/right.svg'
import Time from 'assets/images/time.svg'
import {useRouterStep} from '../../../hooks/useRouterStep'
import {PintActive, Point, PointOut, PointSuccess} from '../../../views/Transaction/step'
import {getChainIcon} from '../../../utils/icons'
import {ShareIcon} from '../../Svg'

const RouterNotice3 = ({
  fromID,
  toID,
  txHash,
  amount,
  symbol,
  tokenAddress,
  receivedAmount,
  nodeAmount,
}: {
  fromID: number
  toID: number
  txHash: string
  amount: string
  symbol: string
  tokenAddress: string
  receivedAmount: string
  nodeAmount: number
}) => {
  const {t} = useTranslation()

  const steps = useRouterStep(txHash)

  return (
    <>
      <Box sx={{display: 'flex'}}>
        <CenterX>
          <img src={getChainIcon(fromID)} width={32} height={32} alt='chain' />
          <Text fontSize={12} color={'#ffffff66'}>
            {Chains[fromID].displayName}
          </Text>
          <CenterY alignItems={'flex-start'} mt={1}>
            {amount} {symbol} &nbsp;
            <a target='_blank' href={`${Chains[fromID].explorer}/tx/${txHash}`}>
              <ShareIcon color='#0068FF' />
            </a>
          </CenterY>
        </CenterX>
        <Box mt={'16px'}>
          <Point />
        </Box>
        <CrossNoticeStatus mx={2} width={46}>
          {steps.length > 1 && <img src={Right} alt='time' />}
          {steps.length <= 1 && <img src={Time} alt='time' />}
          <img src={Arrow} alt='arrow' />
        </CrossNoticeStatus>
        <Box mt={'16px'}>
          {steps.length < 3 && <PintActive />}
          {steps.length === 3 && <PointSuccess />}
        </Box>
        <CrossNoticeStatus mx={2} width={46}>
          {steps.length < 3 && <img src={Time} alt='time' />}
          {steps.length === 3 && <img src={Right} alt='time' />}
          <img src={Arrow} alt='arrow' />
        </CrossNoticeStatus>

        <Box mt={'16px'}>
          <Point />
        </Box>
        <CenterX>
          <img src={getChainIcon(toID)} width={32} height={32} alt='chain' />
          <Text fontSize={12} color={'#ffffff66'}>
            {Chains[toID].displayName}
          </Text>
          <CenterY alignItems={'flex-start'} mt={1}>
            {steps.length === 3 && (
              <a target='_blank' href={`${Chains[toID].explorer}/tx/${steps[2].step_txid}`}>
                <ShareIcon color='#0068FF' />
              </a>
            )}
          </CenterY>
        </CenterX>
      </Box>
    </>
  )
}

const RouterNotice5 = ({
  fromID,
  toID,
  txHash,
  amount,
  symbol,
  tokenAddress,
  receivedAmount,
  nodeAmount,
}: {
  fromID: number
  toID: number
  txHash: string
  amount: string
  symbol: string
  tokenAddress: string
  receivedAmount: string
  nodeAmount: number
}) => {
  const {t} = useTranslation()

  const steps = useRouterStep(txHash)

  return (
    <>
      <Box sx={{display: 'flex'}}>
        <CenterX>
          <img src={getChainIcon(fromID)} width={32} height={32} alt='chain' />
          <Text fontSize={12} color={'#ffffff66'}>
            {Chains[fromID].displayName}
          </Text>
          <CenterY alignItems={'flex-start'} mt={1}>
            {amount} {symbol} &nbsp;
            <a target='_blank' href={`${Chains[fromID].explorer}/tx/${txHash}`}>
              <ShareIcon color='#0068FF' />
            </a>
          </CenterY>
        </CenterX>

        <Box mt={'16px'}>
          <Point />
        </Box>

        <CrossNoticeStatus mx={2} width={46}>
          {steps.length > 1 && <img src={Right} alt='time' />}
          {steps.length <= 1 && <img src={Time} alt='time' />}
          <img src={Arrow} alt='arrow' />
        </CrossNoticeStatus>
        <Box mt={'16px'}>
          {steps.length < 3 && <PintActive />}
          {steps.length >= 3 && <PointSuccess />}
        </Box>
        <CrossNoticeStatus mx={2} width={46}>
          {steps.length < 3 && <img src={Time} alt='time' />}
          {steps.length >= 3 && <img src={Right} alt='time' />}
          <img src={Arrow} alt='arrow' />
        </CrossNoticeStatus>

        <Box mt={'16px'}>
          {steps.length < 4 && <PintActive />}
          {steps.length >= 4 && <PointSuccess />}
        </Box>
        <CrossNoticeStatus mx={2} width={46}>
          {steps.length < 4 && <img src={Time} alt='time' />}
          {steps.length === 4 && <img src={Right} alt='time' />}
          <img src={Arrow} alt='arrow' />
        </CrossNoticeStatus>

        <Box mt={'16px'}>
          <Point />
        </Box>

        <CenterX>
          <img src={getChainIcon(toID)} width={32} height={32} alt='chain' />
          <Text fontSize={12} color={'#ffffff66'}>
            {Chains[toID].displayName}
          </Text>
          <CenterY alignItems={'flex-start'} mt={1}>
            {steps.length === 4 && (
              <a target='_blank' href={`${Chains[toID].explorer}/tx/${steps[3].step_txid}`}>
                <ShareIcon color='#0068FF' />
              </a>
            )}
          </CenterY>
        </CenterX>
      </Box>
    </>
  )
}

const RouterNotice = ({
  fromID,
  toID,
  txHash,
  amount,
  symbol,
  tokenAddress,
  receivedAmount,
  nodeAmount,
}: {
  fromID: number
  toID: number
  txHash: string
  amount: string
  symbol: string
  tokenAddress: string
  receivedAmount: string
  nodeAmount: number
}) => {
  const {t} = useTranslation()

  return (
    <>
      {nodeAmount === 1 ? (
        <RouterNotice3
          fromID={fromID}
          toID={toID}
          txHash={txHash}
          amount={amount}
          symbol={symbol}
          tokenAddress={tokenAddress}
          receivedAmount={receivedAmount}
          nodeAmount={nodeAmount}
        />
      ) : (
        <RouterNotice5
          fromID={fromID}
          toID={toID}
          txHash={txHash}
          amount={amount}
          symbol={symbol}
          tokenAddress={tokenAddress}
          receivedAmount={receivedAmount}
          nodeAmount={nodeAmount}
        />
      )}
    </>
  )
}

export default RouterNotice
