import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Typography, Button, Box } from '@mui/material'
import styled from '@emotion/styled'
import { Flex } from 'components/Style'
import Copy from 'components/Copy'
import { useTranslation } from 'react-i18next'
import { getIcon } from 'utils/icons'
// import RedeemIcon from '@mui/icons-material/Redeem'
import { Text } from 'components/Style'
import QRCode from 'qrcode'
import { useTunnelInfo, useTotalSubbly } from 'hooks/useTunnel'
import BigNumber from 'bignumber.js'
import Balance from 'components/Balance'
import { getBalanceNumber } from 'utils/formatBalance'

const RemaintWrapper = styled(Flex)`
    margin: -30px -30px -30px 0;
    padding: 30px;
    border-radius: 0 15px 15px 0;
    background: #000000;
    flex-direction: column;
    min-width: 370px;
`

const  RemaintBox = styled.div`
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding: 24px 20px;
    width: 100%;
    background: linear-gradient(90deg, #000000 13.12%, rgba(0, 35, 55, 0.220736) 56.4%, rgba(0, 161, 255, 0.175033) 99.99%);
    border-radius: 12px;
    border: 1px solid rgba(0, 104, 255);
    margin: 30px 0 40px;
    font-size: 20px;
`

const RemaintButton = styled(Button)`
    width: 100%;
    padding: 20px 0;
    border-radius: 10px;
    border: 1px solid #0068FF;
    color: #0068FF;
`
interface Props {
    step: number
    oToken: string
    multiAddres: string
    onNext: (step: number) => void
}
const Remaint:React.FC<Props>  = ({ step, oToken, multiAddres, onNext }) => {
    const { t } = useTranslation()
    const token = useMemo(() => oToken.slice(1) ?? 'BTC', [oToken])
    const [qrImage, setQrImage] = useState('')
    const { canIssue, pledgeRatio } = useTunnelInfo()
    const { total } = useTotalSubbly()
    const info = useMemo(() => {
        const issue = canIssue[token] ?? '0'
        const used = total[token]
        const remain = new BigNumber(issue).minus(used)
        return {issue, used, remain}
    }, [canIssue, pledgeRatio, total, token])
    const handleNext = (index: number) => {
        onNext && onNext(index)
    }
    const generateQR = useCallback(async () => {
        const qr = await QRCode.toDataURL(multiAddres, {margin: 1})
        setQrImage(qr)
    }, [multiAddres])

    useEffect(() => {
        if (multiAddres) {
            generateQR()
        }
    }, [generateQR])
    return (
        <RemaintWrapper>
            <Typography>{t('remaining_capacity')}</Typography>
            <RemaintBox>
                <img style={{marginRight: '10px'}} src={getIcon(token)} width="32px" height="32px" alt={token} />
                <Balance fontSize="20px" value={getBalanceNumber(info.remain)} unit={` ${oToken}`} />
                {/* <Typography ml="10px">{displayBalance(info.remain)} {oToken}</Typography> */}
            </RemaintBox>
            {step < 3 ?
                <Flex justifyContent="center" mb="30px">
                    {/* <Flex alignItems="center" mr="16px"><RedeemIcon /><Typography ml="8px" fontSize="12px">{t('You can get extra money by coining')}</Typography></Flex> */}
                    {/* <Flex alignItems="center">
                        <img src={getIcon('BOR')} width="12px" height="12px" alt="BOR" />
                        <Typography ml="8px" fontSize="12px">BORING奖励</Typography>
                    </Flex> */}
                </Flex> :
                <Flex flexDirection="column" alignItems="center" justifyContent="center" mb="30px">
                    <Text mb="20px" fontSize="24px">{t('mint')}</Text>
                    <img src={qrImage} width="160px" height="160px" style={{marginBottom: '20px'}} alt="qr" />
                    <Box mb="20px">
                        <Copy value={multiAddres} name={multiAddres} color="#ffffff" />
                    </Box>
                </Flex>
            }
            {step <= 2 && 
                <>
                    <RemaintButton onClick={() => handleNext(3)} variant='outlined' sx={{marginBottom: '30px'}}>{t('track_progress')}</RemaintButton>
                    <RemaintButton onClick={() => handleNext(4)} variant='outlined'>{t('mint_calaulator')}</RemaintButton>
                </>
            }
            {step === 3 &&
                <>
                    <RemaintButton onClick={() => handleNext(0)} sx={{marginBottom: '30px'}}>{t('check_guidance')}</RemaintButton>
                    <RemaintButton onClick={() => handleNext(4)} variant='outlined'>{t('mint_calaulator')}</RemaintButton>
                </>
            }
            {step === 4 &&
                <>
                    <RemaintButton onClick={() => handleNext(0)} sx={{marginBottom: '30px'}}>{t('check_guidance')}</RemaintButton>
                    <RemaintButton onClick={() => handleNext(3)} variant='outlined'>{t('track_progress')}</RemaintButton>
                </>
            }
        </RemaintWrapper>
    )
}

export default Remaint