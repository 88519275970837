import {useMemo, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import {CHAIN_BSC, CHAIN_ETHER} from '@w3u/chains'
import {Box, Button, Typography} from '@mui/material'
import styled from '@emotion/styled'
import {useTranslation} from 'react-i18next'
import {BoringInput, Flex, Text} from 'components/Style'
import {getIcon} from 'utils/icons'
import ConnectWallet from 'components/ConnectWallet'
import MyButton, {OutlineButton} from 'components/Button'
// import { useCheckSupportChain } from 'hooks/useChackChainId'
import {useTokenBalance} from 'hooks/useTokenBalance'
// import Balance from 'components/Balance'
import {displayBalance, getFullDisplayBalance} from 'utils/formatBalance'
import BigNumber from 'bignumber.js'
import {getBoringAddress} from 'utils/addressHelpers'
import {useToBor, useToBoring} from 'hooks/useBoring'
import {changeChain, formatNumber} from 'utils'
import {BIG_TEN} from 'utils/bigNumber'
import {DownarrowIcon} from 'components/Svg'
import {getContract, getSinger} from '../../hooks/useContract'
import WistaABI from '../../config/abi/TokenWISTA.json'
import {ethers} from 'ethers'

const BOR_BORING_RATIO = 10000

const BurnWrapper = styled.div`
  background: #00000066;
  padding: 30px 19px;
  border-radius: 30px;
  width: 580px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

const BurnBox = styled(Box)<{borderLine?: boolean}>`
  background: #00000066;
  border-radius: 12px;
  padding: 15px;
  width: 100%;
  border: ${({borderLine}) => (borderLine ? '1px' : '0')} solid #0068ff;
`

const Transfer = () => {
  const {library} = useWeb3React()
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')
  const {chainId, account} = useWeb3React()
  const oldWistaAddress = '0xeaE00113af330E6f67342B8298eFc40232cD9F09'
  const newWistaAddress = '0x3720E3C827daB665383b32bC49766D2d24C1Ed35'
  const {balance: oldBalance, fetchBalance: fetchBalanceBoring} = useTokenBalance(oldWistaAddress, CHAIN_BSC)
  const balance = oldBalance

  const contract = useMemo(() => getContract(WistaABI, newWistaAddress, CHAIN_BSC, getSinger(library)), [library])

  const amountError = useMemo(() => {
    try {
      if (value === '') return null
      const balanceN = new BigNumber(balance.toString()).div(BIG_TEN.pow(18))
      const amountN = new BigNumber(value)
      if (balanceN.lte(0)) {
        return t('balance_is_0')
      }
      if (amountN.gt(balanceN)) {
        return t('balance_is_insufficient')
      }
    } catch (error) {
      return null
    }
    return null
  }, [balance, value])
  const handleTransfer = async () => {
    try {
      setLoading(true)
      const tx = await contract?.burnOldTokenToGetNew()
      await tx.wait()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const renderAction = () => {
    return (
      <>
        <MyButton loading={loading} fullWidth onClick={handleTransfer}>
          {!amountError ? (
            t('claim')
          ) : (
            <Text fontSize='18px' color='#ff0000'>
              {amountError}
            </Text>
          )}
        </MyButton>
      </>
    )
  }
  return (
    <Flex flexDirection='column' alignItems='center' width={'100%'}>
      <BurnWrapper>
        <BurnBox borderLine>
          <Flex justifyContent='space-between' alignItems='center'>
            <Flex alignItems='center' flex='auto'>
              <img style={{marginRight: '12px'}} src={getIcon('WISTA')} width='20px' height='20px' />
              <Text mr='12px'>OLD-WISTA</Text>
              <BoringInput
                sx={{height: {md: '32px', sx: '26px'}}}
                placeholder='0.00'
                value={displayBalance(oldBalance?.toString())}
                onChange={(e) => setValue(e.target.value)}
              />
              {/* <Text color="#ffffff66">≈ $ {formatNumber(tokenValue)}</Text> */}
            </Flex>
          </Flex>
        </BurnBox>
        <Flex justifyContent='center' sx={{m: {md: '20px 0', xs: '10px 0'}}}>
          <Box>
            <DownarrowIcon />
          </Box>
        </Flex>
        <BurnBox mb='16px'>
          <Typography mb='8px' fontSize='12px'>
            {t('receive')}
          </Typography>
          <Flex alignItems='center'>
            <img style={{marginRight: '12px'}} src={getIcon('WISTA')} width='20px' height='20px' />
            <Text mr='12px'>WISTA</Text>
            <Text sx={{wordBreak: 'break-all'}}>{value}</Text>
          </Flex>
        </BurnBox>
        <Flex justifyContent='center'>
          <Box sx={{width: {md: '60%', xs: '100%'}}}>
            {account ? (
              chainId === CHAIN_BSC ? (
                renderAction()
              ) : (
                <Button onClick={() => changeChain(CHAIN_BSC)} fullWidth size='large' variant='outlined'>
                  Switch to BSC
                </Button>
              )
            ) : (
              <ConnectWallet />
            )}
          </Box>
        </Flex>
      </BurnWrapper>
    </Flex>
  )
}

export default Transfer
