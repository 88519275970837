import { useCallback, useState } from 'react'
import { getUrlProvider } from 'utils/providers'
import { TransactionData } from 'modal/types'
import BigNumber from 'bignumber.js'
import { BIG_TEN, BIG_ZERO } from 'utils/bigNumber'

const useWaitForTransaction = () => {
    const [data, setData] = useState<(TransactionData | undefined)[]>([])
    const fetchTransaction = async (txs: {hash: string, chainId: number}[]) => {
        const dataResult = await Promise.all(txs.map(async tx => {
            try {
                const provider = getUrlProvider(tx.chainId)
                const txInfo = await provider.getTransactionReceipt(tx.hash)
                // const txInfo = await provider.waitForTransaction(tx.hash)
                const gasPrice = await provider.getGasPrice()
                const blockNumber = await provider.getBlockNumber()
                const gas = new BigNumber(txInfo.effectiveGasPrice ? txInfo.effectiveGasPrice.toString() : gasPrice.toString())
                    .times(txInfo.gasUsed.toString())
                    .div(BIG_TEN.pow(18)) || BIG_ZERO
                const t:TransactionData = {
                    tx: tx.hash,
                    currentBlockNumber: 0,
                    blockNumber: txInfo.blockNumber,
                    pastNumber: txInfo.confirmations ? txInfo.confirmations : blockNumber - txInfo.blockNumber,
                    gas: gas.toFixed(6),
                    status: txInfo.status ?? 2
                }
                return t
            } catch (error) {
                return undefined
            }
        }))
        const updateData = dataResult.map((d,i) => d ? d : data[i])
        setData(updateData)
    }

    return { data, fetchTransaction }
}

export default useWaitForTransaction
