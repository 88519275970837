import React from 'react'
import styled from '@emotion/styled'
import {Box, Link, BoxProps, StyledComponentProps } from '@mui/material'
import { useLocation } from 'react-router-dom'
import {
  CHAIN_AURORA,
  CHAIN_AVALANCHE,
  CHAIN_BOBA,
  CHAIN_HARMONY_0,
  CHAIN_METIS,
  CHAIN_OK,
  CHAIN_POLYGON,
  CHAIN_XDAI,
} from '@w3u/chains'
import clsx from 'clsx'
import {useTranslation} from 'react-i18next'
import {getChainIcon} from 'utils/icons'
import Divider from '@mui/material/Divider'
import { Flex, Text } from 'components/Style'

const BackedWraper = styled(Box)`
  color:#A1A8C7;
  font-size: 12px;
  text-align: center;
  padding-top: 20px;
  margin: 0 auto;
  width: 440px;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
`

export interface WithBoxProps extends BoxProps, StyledComponentProps {
    children?: React.ReactNode
    className?: string
}

const Backed = ({className, ...other}: WithBoxProps): JSX.Element => {
  const {t} = useTranslation()
  const location = useLocation()
  const chains = [
    CHAIN_POLYGON,
    CHAIN_AVALANCHE,
    CHAIN_METIS,
    CHAIN_HARMONY_0,
    CHAIN_OK,
    CHAIN_XDAI,
    CHAIN_BOBA,
    CHAIN_AURORA,
  ]
  const bankRoute = ['/twoway', '/liquidity', '/data']
  return (
    <BackedWraper
      fontSize={12}
      className={clsx(className)}
      {...other}
    >
      {bankRoute.some(s => s.indexOf(location.pathname) > -1) &&
        <Box
          display='flex'
          justifyContent='center'
          alignContent="center"
          marginBottom='2px'
          sx={{
            '& > span': {
              marginRight: '3px',
            },
            '& > :not(style) + :not(style)': {
              ml: '2px',
            },
            '& > img:last-child': {
              marginRight: '10px',
            },
          }}
        >
          <span style={{whiteSpace: 'nowrap'}}>Backed by</span>
          {chains.map((chain) => (
            <img style={{margin: '1px 3px 0'}} key={chain} src={getChainIcon(chain)} width={14} height={14} alt='chain' />
          ))}
          <Divider style={{margin: '0 6px'}} orientation="vertical" variant="middle" flexItem />
          <Box>
            <Link
              style={{color: '#A1A8C7', whiteSpace: 'nowrap'}}
              target='_blank'
              rel='noopener'
              href='https://docs.google.com/forms/d/e/1FAIpQLSdNq8WIq8uafyrC1EpqXkug4iddzWtQou42sSlj14DyhFDnLw/viewform?usp=send_form'
            >
              {t('list_your_coin')}
            </Link>
          </Box>
        </Box>
      }
      <Flex justifyContent="center">Powered by BoringDAO <Text sx={{display: {md: 'block', xs: 'none'}}}>&nbsp;🌀&nbsp;Nash Equilibrium Lock(NEL) Technology</Text></Flex>
      <Box
        sx={{
          fontSize: '12px',
          '& > a': {
            color: '#A1A8C7',
            textDecoration: 'none',
          },
          '& > :not(style) + :not(style)': {
            ml: 2,
          },
        }}
      >
        {/* <Link target='_blank' rel='noopener' href='https://www.boringdao.com/'>
          {t('website')}
        </Link> */}
      </Box>
    </BackedWraper>
  )
}

export default Backed
