export interface TwowayToken {
  address: string
  symbol: string
  decimals: number
  chainID: number
  displayName?: string
}

export interface TwowayOToken extends TwowayToken {
  pid: number
}

export interface BoringChefToken extends TwowayToken {
  type: string
  name: string
  pid: number
  subSymbol?: string
  stakePriceSymbol?: string
}

export interface TwowayOTokenBalcnce extends TwowayOToken {
  balance: string
}
export interface TwowayTokenAddress {
  [chainID: number]: TwowayToken
}

export interface TokenBalance {
  address: string
  balance: string
}

export interface Token {
  address: string
  symbol: string
  decimals: number
  isWrapped?: boolean
  originAddress?: string
}
export interface PegToken {
  address: string
  symbol: string
  decimals: number
  chainID: number
  pair: string
  pid?: number
  isOld?: boolean
}

export interface NativeBridgeToken extends TwowayToken {
  isWrapped?: boolean
  originAddress?: string
}
 
export interface NativeBridgeToken {
  address: string
  symbol: string
  decimals: number
  chainID: number
  isWrapped?: boolean
  originAddress?: string
}

export interface TopToken extends NativeBridgeToken {
  isTwoway: boolean
}

export const generateTopTokenFromNative = (token: NativeBridgeToken): TopToken => {
  return {
    address: token.address,
    symbol: token.symbol,
    decimals: token.decimals,
    chainID: token.chainID,
    isWrapped: token.isWrapped,
    originAddress: token.originAddress,
    isTwoway: false
  }
}

export const generateTopTokenFromTwoway = (token: TwowayToken): TopToken => {
  return {
    address: token.address,
    symbol: token.symbol,
    decimals: token.decimals,
    chainID: token.chainID,
    isTwoway: true
  }
}
