import React from 'react'
import ReactDOM from 'react-dom'
import {Web3Provider} from '@ethersproject/providers'
import {ExternalProvider, JsonRpcFetchFunc} from '@ethersproject/providers/src.ts/web3-provider'
import {Web3ReactProvider} from '@web3-react/core'
import CssBaseline from '@mui/material/CssBaseline'
import {ThemeProvider} from '@mui/material'
import {bscTheme} from 'themes/bas'
import Web3ReactManager from 'components/Web3ReactManager'
import {RefreshContextProvider} from 'contexts/RefreshContext'
import {TransactionContextProvider} from 'contexts/TransactionContext'
import {ToastContainer} from 'react-toastify'
import TransactionModal from 'views/Transaction'
import 'react-toastify/dist/ReactToastify.css'

import './i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'
import ModalProvider from 'mui-modal-provider'

function getLibrary(provider: ExternalProvider | JsonRpcFetchFunc): Web3Provider {
  const library = new Web3Provider(provider, 'any')
  library.pollingInterval = 15000
  return library
}

if (window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = true
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ReactManager>
        <ThemeProvider theme={bscTheme}>
          <CssBaseline />
          <RefreshContextProvider>
            <TransactionContextProvider>
              <ToastContainer
                position='top-right'
                hideProgressBar={true}
                newestOnTop={false}
                pauseOnFocusLoss
                pauseOnHover
                closeOnClick={false}
                autoClose={30000000}
              />
              <ModalProvider>
                <App />
              </ModalProvider>
              <TransactionModal />
            </TransactionContextProvider>
          </RefreshContextProvider>
        </ThemeProvider>
      </Web3ReactManager>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
