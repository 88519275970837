import {
    CHAIN_ARBITRUM,
    CHAIN_ARBITRUM_TESTNET,
    CHAIN_AURORA,
    CHAIN_AURORA_TESTNET,
    CHAIN_AVALANCHE,
    CHAIN_AVALANCHE_TESTNET,
    CHAIN_BOBA,
    CHAIN_BSC,
    CHAIN_BSC_TESTNET,
    CHAIN_ETHER,
    CHAIN_ETHER_GOERLI,
    CHAIN_ETHER_KOVAN,
    CHAIN_ETHER_RINKEBY,
    CHAIN_ETHER_ROPSTEN,
    CHAIN_FANTOM,
    CHAIN_FANTOM_TESTNET,
    CHAIN_HARMONY_0,
    CHAIN_HARMONY_0_TESTNET,
    CHAIN_HECO,
    CHAIN_HECO_TESTNET,
    CHAIN_METIS,
    CHAIN_OK,
    CHAIN_OK_TESTNET,
    CHAIN_OPTIMISM,
    CHAIN_POLYGON,
    CHAIN_POLYGON_MUMBAI,
    CHAIN_XDAI,
    CHAIN_XDAI_TESTNET,
} from '@w3u/chains'

export const POW_TOKEN = ['BTC', 'LTC', 'DOGE']

export const BurnFee = '0.0001'
export const MinBTCMintValue = '0.1'
export const MinBTCBurnValue = '0.002'

export const MinLTCMintValue = '50'
export const MinLTCBurnValue = '10'

export const MinDOGEMintValue = '20000'
export const MinDOGEBurnValue = '18000'

export const getMinMintValue = (tokenName: string) => {
    // if (chainId === 97 || chainId === 56) {
    //   return tokenName === 'BTC' ? '0.01' : '10'
    // }
    return tokenName === 'BTC' ? MinBTCMintValue : tokenName === 'LTC' ? MinLTCMintValue : MinDOGEMintValue
}
export const getMinBurnValue = (tokenName: string) => {
    // if (chainId === 97 || chainId === 56) {
    //     return tokenName === 'BTC' ? '0.002' : '1'
    // }
    return tokenName === 'BTC' ? MinBTCBurnValue : tokenName === 'LTC' ? MinLTCBurnValue : MinDOGEBurnValue
}

export const ADDRESS_BOOK: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x1b0e954aa1b88791c8251e8328287f3feb96d719',
    [CHAIN_ETHER_ROPSTEN]: '0x90371aE783E8689eB76D4A01E65feBb50AC06B39',
    [CHAIN_BSC]: '0x864bFfBBa1d0B350711fE61ba690d24D3f37C976',
    [CHAIN_BSC_TESTNET]: '0xe47d46f0c3DD2EDDa83f3508645cbAf3a6FC2550'
}
export const ADDRESS_BOOK_PARAM: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0xbc91c117157d7c47135b13c97ec4a46fa1ca1ab1',
    [CHAIN_ETHER_ROPSTEN]: '0x727BE15ca0458DC8A70f7F342CF85820e80C688A',
    [CHAIN_BSC]: '0x6122611EFBf744ff4bbF31EFd2522b37Fc37C0c5',
    [CHAIN_BSC_TESTNET]: '0x797051A08a1753b3E054DDC1245AD812DAe9050F'
}
export const CONTRACT_ORACLE: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0xADee8b009d8e4c3D95bf415c7EEF53d8F9147716',
    [CHAIN_ETHER_ROPSTEN]: '0xF178D2EE54Dbfb22b06404667a2c2846EEc76414',
    [CHAIN_BSC]: '0x4D051416321c532dC775b4327051C4521483d987',
    [CHAIN_BSC_TESTNET]: '0x93eF5889DB8B3bbe9158ac97e49b5EE653907f1A',
    [CHAIN_POLYGON]: '0xAC1B9afAccC359b4d51D4b71Efae2425cDbB758b'
}

export const BoringDAO_BTC: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0xe3547ba476907cebe554ab2a1c9f64378fb14f3b',
    [CHAIN_ETHER_ROPSTEN]: '0xCB6138CB08B145750A51B7b7243E532e441C42F5',
    [CHAIN_BSC]: '0x8E3b62EDD67F14c1CcfAdE0EA302a228e8796D7C',
    [CHAIN_BSC_TESTNET]: '0x08DE288650B5DaE42a18Caef64720aABf0300151'
}
export const BoringDAO_LTC: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x58e25cCD2843caE992eFBC15C8D4C64f4f70809A',
    [CHAIN_ETHER_ROPSTEN]: '0x5b846eD0889Bf507D2A721d81e694aFE674bB731',
    [CHAIN_BSC]: '0x8E3b62EDD67F14c1CcfAdE0EA302a228e8796D7C',
    [CHAIN_BSC_TESTNET]: '0x08DE288650B5DaE42a18Caef64720aABf0300151'
}

export const BoringDAO_DOGE: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x58e25cCD2843caE992eFBC15C8D4C64f4f70809A',
    [CHAIN_ETHER_ROPSTEN]: '0x5b846eD0889Bf507D2A721d81e694aFE674bB731'
}

export const BordingDAO_TOKEN = (token: string) => {
    let tokens = BoringDAO_BTC
    switch (token) {
        case 'BTC':
            tokens = BoringDAO_BTC
            break;
        case 'LTC':
            tokens = BoringDAO_LTC
            break;
        case 'DOGE':
            tokens = BoringDAO_DOGE
            break;
        default:
            tokens = BoringDAO_BTC
    }
    return tokens
}

export const OBTC: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x8064d9ae6cdf087b1bcd5bdf3531bd5d8c537a68',
    [CHAIN_ETHER_ROPSTEN]: '0x04fF10c7AFc9747aE39b52422A7682a5df28c229',
    [CHAIN_BSC]: '0x0314fc7180dD2A4370A4039ccf11f2C1a4a9C33B',
    [CHAIN_BSC_TESTNET]: '0x03eD9220fa63F7c7Fc1aEaE44C992B559Ba5D62A',
    [CHAIN_POLYGON]: '0x90fB380DdEbAF872cc1F8d8e8C604Ff2f4697c19',
    [CHAIN_POLYGON_MUMBAI]: '0xFd164af933a49594c6f3F9B210c753c2e9b5c6A2'
}
export const OLTC: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x07C44B5Ac257C2255AA0933112c3b75A6BFf3Cb1',
    [CHAIN_ETHER_ROPSTEN]: '0x75Cd6127E42072ffFd51B6f2108755CEAa6e597E',
    [CHAIN_BSC]: '0xd4694898DDf455bA703446Bba52034d1212B24D9',
    [CHAIN_BSC_TESTNET]: '0x00c072D3B2a8f511Ee0e0Bf53400923495BEc6a0'
}
export const DOGE: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x9c306A78b1a904e83115c05Ac67c1Ef07C653651',
    [CHAIN_ETHER_ROPSTEN]: '0x54512E183456B2e3c240Dc9a773cD5c1010BF768'
}

export const OTOKEN = (token: string) => {
    let tokens = OBTC
    switch (token) {
        case 'BTC':
            tokens = OBTC
            break;
        case 'LTC':
            tokens = OLTC
            break;
        case 'DOGE':
            tokens = DOGE
            break;
        default:
            tokens = OBTC
    }
    return tokens
}

export const Tunnel_BTC: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x258a1eb6537ae84cf612f06b557b6d53f49cc9a1',
    [CHAIN_ETHER_ROPSTEN]: '0xd30dA60559B55EFa8A9B2ABBD019bAcAa9cd5001',
    [CHAIN_BSC]: '0xdd096DE00814f83c621EdEb837b26107470974Fb',
    [CHAIN_BSC_TESTNET]: '0xC576BB22c782295F0F135f0aba603EDEf8c1E29D'
}
export const Tunnel_LTC: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0xD7D997Dd57114E1e2d64ab8c0d767A0d6b2426F0',
    [CHAIN_ETHER_ROPSTEN]: '0x7d1e3De9Eed8Ff32f464E3a05b8F766F240958fC',
    [CHAIN_BSC]: '0x4502eea15ef70256e3903643e3191ea54f0588A6',
    [CHAIN_BSC_TESTNET]: '0xa589d847AE0c2cb09F05a6793A85fCA494BC16F5'
}
export const Tunnel_DOGE: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x22b1AC6B2d55ade358E5b7f4281ed1Dd2f6f0077',
    [CHAIN_ETHER_ROPSTEN]: '0x8B351152C4808CE1aF10838D624D7685Ad538768'
}
export const Tunnel_TOKEN = (token: string) => {
    let tokens = Tunnel_BTC
    switch (token) {
        case 'BTC':
            tokens = Tunnel_BTC
            break;
        case 'LTC':
            tokens = Tunnel_LTC
            break;
        case 'DOGE':
            tokens = Tunnel_DOGE
            break;
        default:
            tokens = Tunnel_BTC
    }
    return tokens
}

export const ORACLE_ADDRESS: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0xADee8b009d8e4c3D95bf415c7EEF53d8F9147716',
    [CHAIN_ETHER_ROPSTEN]: '0xF178D2EE54Dbfb22b06404667a2c2846EEc76414',
    [CHAIN_BSC]: '0x4D051416321c532dC775b4327051C4521483d987',
    [CHAIN_BSC_TESTNET]: '0x93eF5889DB8B3bbe9158ac97e49b5EE653907f1A',
    [CHAIN_POLYGON]: '0xAC1B9afAccC359b4d51D4b71Efae2425cDbB758b'
}

export const TOKEN_BORING_BTC_PPTOKEN: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x6C189Baa963060DAEEC77B7305b055216090bFC4',
}
export const TOKEN_BORING_LTC_PPTOKEN: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0x48c57b4E50Ae145E36d1b26D3470A6b9a68F3Ee9',
}
export const TOKEN_BORING_DOGE_PPTOKEN: { [chainID: number]: string } = {
    [CHAIN_ETHER]: '0xD221f7F04b2055E2f937063D415D6BcDCc1D70De'
}
export const TOKEN_BORING_PPTOKEN = (token: string) => {
    let tokens = TOKEN_BORING_BTC_PPTOKEN
    switch (token) {
        case 'BTC':
            tokens = TOKEN_BORING_BTC_PPTOKEN
            break;
        case 'LTC':
            tokens = TOKEN_BORING_LTC_PPTOKEN
            break;
        case 'DOGE':
            tokens = TOKEN_BORING_DOGE_PPTOKEN
            break;
        default:
            tokens = TOKEN_BORING_BTC_PPTOKEN
    }
    return tokens
}
