import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const TelegeamIcon:React.FC<IconProps> = () => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path fillRule="evenodd" clip-rule="evenodd" d="M8 16C12.4194 16 16 12.4194 16 8C16 3.58057 12.4194 0 8 0C3.58057 0 0 3.58057 0 8C0 12.4194 3.58057 16 8 16ZM12.7382 4.46667L11.1827 11.7993L11.1833 11.7986C11.0669 12.3174 10.7601 12.444 10.326 12.2009L7.95583 10.4551L6.81341 11.5555C6.68677 11.6828 6.58045 11.7885 6.33734 11.7885L6.50664 9.37564L10.8976 5.40796C11.0879 5.23867 10.8549 5.14318 10.601 5.31248L5.17257 8.73028L2.83422 8.00026C2.32632 7.8418 2.31548 7.49237 2.93986 7.24926L12.082 3.72582C12.5052 3.56735 12.8756 3.8213 12.7382 4.46667Z" fill="white"/>
        </SvgIcon>
    )
}

export default TelegeamIcon
