import {useCallback, useEffect, useState} from 'react'
import {useErc20Contract} from 'hooks/useContract'
import {getERC20Contract} from 'utils/contractHelpers'
import {useWeb3React} from '@web3-react/core'
import {MaxUint256} from '@ethersproject/constants'
import {BridgeType, BridgeStatus} from 'modal/types'
import {ethers} from 'ethers'
import useRefresh from './useRefresh'

export const useApprove = (
  address: string,
  spender: string,
  chainId: number,
  birdgeType?: BridgeType,
  nowBirdgeType?: BridgeType,
  input?: string
) => {
  const {slowRefresh} = useRefresh()
  const {account} = useWeb3React()
  const [allowance, setAllowance] = useState(false)
  const [loading, setLoading] = useState(false)
  try {
    const contract = useErc20Contract(address, chainId)
    const fetchAllowance = useCallback(async () => {
      try {
        if (spender === BridgeStatus.notsupport || !chainId) return
        if (address.includes('0000000000')) {
          setAllowance(true)
          return
        }
        const noSignerContract = getERC20Contract(address, chainId)
        const allowanceAmount = (await noSignerContract.allowance(account, spender)) as ethers.BigNumber
        setAllowance(allowanceAmount.gt(!!input ? input : 0))
      } catch (error) {
        // console.log('Fetch-Allowance', error)
      }
    }, [contract, account, address, spender, input])

    const handleApprove = useCallback(async () => {
      try {
        setLoading(true)
        const tx = await contract.approve(spender, MaxUint256)
        const receipt = await tx.wait()
        await fetchAllowance()
        setLoading(false)
        return receipt.status
      } catch (error) {
        setLoading(false)
      }
    }, [contract, address, spender, input])

    useEffect(() => {
      if (account && (!birdgeType || birdgeType === nowBirdgeType)) {
        fetchAllowance()
      }
    }, [account, address, spender, nowBirdgeType, slowRefresh, input])

    return {allowance, loading, fetchApprove: fetchAllowance, onApprove: handleApprove}
  } catch (error) {
    return {allowance, loading, fetchApprove: () => {}, onApprove: () => {}}
  }
}
