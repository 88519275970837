import {useMemo, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import {CHAIN_ETHER} from '@w3u/chains'
import {Typography, Box, Button} from '@mui/material'
import styled from '@emotion/styled'
import {useTranslation} from 'react-i18next'
import {Flex, BoringInput, Text} from 'components/Style'
import {getIcon} from 'utils/icons'
import ConnectWallet from 'components/ConnectWallet'
import MyButton, {OutlineButton} from 'components/Button'
// import { useCheckSupportChain } from 'hooks/useChackChainId'
import {useTokenBalance} from 'hooks/useTokenBalance'
// import Balance from 'components/Balance'
import {displayBalance, getFullDisplayBalance} from 'utils/formatBalance'
import BigNumber from 'bignumber.js'
import {getBorAddress, getBoringAddress} from 'utils/addressHelpers'
import {useApprove} from 'hooks/useApprove'
import {useToBoring, useToBor} from 'hooks/useBoring'
import {changeChain, formatNumber} from 'utils'
import {BIG_TEN} from 'utils/bigNumber'
import {usePrices} from 'hooks/usePrice'
import {DownarrowIcon} from 'components/Svg'
import {registerToken} from 'utils/network'
import Tab from './Tab'

const BOR_BORING_RATIO = 10000

const BurnWrapper = styled.div`
  background: #00000066;
  padding: 30px 19px;
  border-radius: 30px;
  width: 580px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`

const BurnBox = styled(Box)<{borderLine?: boolean}>`
  background: #00000066;
  border-radius: 12px;
  padding: 15px;
  width: 100%;
  border: ${({borderLine}) => (borderLine ? '1px' : '0')} solid #0068ff;
`

const Transfer = () => {
  // useCheckSupportChain(CHAIN_ETHER)
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('BORING')
  const [value, setValue] = useState('')
  const {chainId, account} = useWeb3React()
  const prices = usePrices(['BOR', 'BORING'])
  const borAddress = getBorAddress(CHAIN_ETHER)
  const bordingAddress = getBoringAddress(CHAIN_ETHER)
  const {allowance, onApprove, fetchApprove} = useApprove(borAddress, bordingAddress, CHAIN_ETHER)
  const {balance: boringBalance, fetchBalance: fetchBalanceBoring} = useTokenBalance(bordingAddress, CHAIN_ETHER)
  const {balance: borBalance, fetchBalance: fetchBalanceBor} = useTokenBalance(borAddress, CHAIN_ETHER)
  const {onToBoring} = useToBoring()
  const {onToBor} = useToBor()
  const tokenValue = useMemo(() => {
    if (!value) return '0.00'
    const t = type === 'BORING' ? 'BOR' : 'BORING'
    return prices && Number(value) >= 0 ? new BigNumber(prices[t]).times(value).div(BIG_TEN.pow(18)).toFixed(3) : '0.00'
  }, [prices, type, value])
  const balance = useMemo(() => (type === 'BORING' ? borBalance : boringBalance), [boringBalance, borBalance, type])
  const youGet = useMemo(() => {
    if (!value) return '0.00'
    return type === 'BORING'
      ? new BigNumber(value).times(BOR_BORING_RATIO).toFixed(3)
      : new BigNumber(value).div(BOR_BORING_RATIO).toFixed(3)
  }, [value, type])
  const amountError = useMemo(() => {
    try {
      if (value === '') return null
      const balanceN = new BigNumber(balance.toString()).div(BIG_TEN.pow(18))
      const amountN = new BigNumber(value)
      if (balanceN.lte(0)) {
        return t('balance_is_0')
      }
      if (amountN.gt(balanceN)) {
        return t('balance_is_insufficient')
      }
    } catch (error) {
      return null
    }
    return null
  }, [balance, value])
  const handleMax = () => setValue(getFullDisplayBalance(balance))
  const handleType = (t: string) => setType(t)
  const handleApprove = async () => {
    try {
      setLoading(true)
      await onApprove()
      fetchApprove()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const handleTransfer = async () => {
    try {
      setLoading(true)
      if (type === 'BORING') {
        console.log(value)
        await onToBoring(value)
        fetchBalanceBoring()
        fetchBalanceBor()
      } else if (type === 'UNBORING') {
        await onToBor(value)
        fetchBalanceBoring()
        fetchBalanceBor()
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const hendleRegister = async () => {
    let tokenInfo = {
      tokenAddress: borAddress,
      tokenSymbol: 'BOR',
      tokenDecimals: 18,
    }
    if (type === 'BORING') {
      tokenInfo = {
        tokenAddress: bordingAddress,
        tokenSymbol: 'BORING',
        tokenDecimals: 18,
      }
    }
    await registerToken(tokenInfo.tokenAddress, tokenInfo.tokenSymbol, tokenInfo.tokenDecimals)
  }

  const renderAction = () => {
    return (
      <>
        {type === 'UNBORING' || allowance ? (
          <MyButton
            loading={loading}
            disabled={!value || new BigNumber(value ?? 0).lte(0) || !!amountError}
            fullWidth
            onClick={handleTransfer}
          >
            {!amountError ? (
              t('confirm')
            ) : (
              <Text fontSize='18px' color='#ff0000'>
                {amountError}
              </Text>
            )}
          </MyButton>
        ) : (
          <MyButton loading={loading} disabled={loading} fullWidth onClick={handleApprove}>
            {t('approve')}
          </MyButton>
        )}
      </>
    )
  }
  return (
    <Flex flexDirection='column' alignItems='center' width={'100%'}>
      <BurnWrapper>
        <Tab onTab={handleType} />
        <BurnBox borderLine>
          <Flex justifyContent='flex-end' mb='8px'>
            <Text>
              {t('balance')}: {displayBalance(balance.toString())}
            </Text>
          </Flex>
          <Flex justifyContent='space-between' alignItems='center'>
            <Flex alignItems='center' flex='auto'>
              <img style={{marginRight: '12px'}} src={getIcon('BOR')} width='20px' height='20px' />
              <Text mr='12px'>{type === 'BORING' ? 'BOR' : 'BORING'}</Text>
              <BoringInput
                sx={{height: {md: '32px', sx: '26px'}}}
                placeholder='0.00'
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              {/* <Text color="#ffffff66">≈ $ {formatNumber(tokenValue)}</Text> */}
            </Flex>
            <OutlineButton style={{height: '26px'}} onClick={handleMax}>
              {t('MAX')}
            </OutlineButton>
          </Flex>
        </BurnBox>
        <Flex justifyContent='center' sx={{m: {md: '20px 0', xs: '10px 0'}}}>
          <Box>
            <DownarrowIcon />
          </Box>
        </Flex>
        <BurnBox mb='16px'>
          <Typography mb='8px' fontSize='12px'>
            {t('receive')}
          </Typography>
          <Flex alignItems='center'>
            <img style={{marginRight: '12px'}} src={getIcon('BOR')} width='20px' height='20px' />
            <Text mr='12px'>{type === 'BORING' ? 'BORING' : 'BOR'}</Text>
            <Text sx={{wordBreak: 'break-all'}}>{formatNumber(youGet)}</Text>
          </Flex>
        </BurnBox>
        <Flex justifyContent='flex-end' mb='30px' style={{cursor: 'pointer'}}>
          <Text color='#ffffff' onClick={hendleRegister}>
            {type === 'BORING' ? t('Add BORING to Metamask') : t('Add BOR to Metamask')}
          </Text>
        </Flex>
        <Flex justifyContent='center'>
          <Box sx={{width: {md: '60%', xs: '100%'}}}>
            {account ? (
              chainId === CHAIN_ETHER ? (
                renderAction()
              ) : (
                <Button onClick={() => changeChain(CHAIN_ETHER)} fullWidth size='large' variant='outlined'>
                  {t('switch_to_ethereum')}
                </Button>
              )
            ) : (
              <ConnectWallet />
            )}
          </Box>
        </Flex>
      </BurnWrapper>
    </Flex>
  )
}

export default Transfer
