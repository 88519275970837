import {useCallback} from 'react'
import {useBorContract, useBordingContract} from 'hooks/useContract'
import {ethers} from 'ethers'

export const useToBoring = () => {
  const boringContract = useBordingContract()
  const handleToBoring = useCallback(
    async (amount: string) => {
      console.log(amount)
      console.log(ethers.utils.parseEther(amount).toString())
      const tx = boringContract.toBoring(ethers.utils.parseEther(amount))
      const receipt = await tx.wait()
      return receipt
    },
    [boringContract]
  )

  return {onToBoring: handleToBoring}
}

export const useToBor = () => {
  const bordingContract = useBordingContract()
  const handleToBor = useCallback(
    async (amount: string) => {
      const tx = bordingContract.toBor(ethers.utils.parseEther(amount))
      const receipt = await tx.wait()
      return receipt
    },
    [bordingContract]
  )

  return {onToBor: handleToBor}
}
