import {useCallback, useEffect, useState} from 'react'
import BigNumber from 'bignumber.js'
import {BridgeType} from 'modal/types'
import {displayBalance} from '../utils/formatBalance'
import {BIG_TEN} from '../utils/bigNumber'

let indexO = {index: 0}

export const useRouterCalculateOutput = (
  bridgeType4: BridgeType,
  fromID: number,
  toID: number,
  tokenAddress: string,
  from: string | undefined | null,
  to: string | undefined | null,
  amount: string,
  inputDecimals: number,
  balance: string
) => {
  const [loading, setLoading] = useState(false)
  const [out, setOut] = useState('')
  const [fee, setFee] = useState('')
  const [prepare, setPrepare] = useState(false)
  const [nodeAmount, setNodeAmount] = useState(1)
  const [inputIsLow, setInputIsLow] = useState(false)

  const fetchOut = useCallback(
    async (recordIndex: number) => {
      setInputIsLow(false)
      if (bridgeType4 === BridgeType.native) {
        setPrepare(true)
        return
      }
      setPrepare(false)
      try {
        // const balanceN = new BigNumber(balance).div(BIG_TEN.pow(inputDecimals))
        // const amountN = new BigNumber(amount)
        // if (amountN.gt(balanceN)) {
        //   setFee('0.00')
        //   setOut('0.00')
        //   setLoading(false)
        //   return
        // }

        if (amount === '') {
          setFee('0.00')
          setOut('0.00')
          setLoading(false)
          return
        }
        if (amount !== '' && new BigNumber(amount).gt(0)) {
          console.log(amount)
          setLoading(true)
          setFee('0.00')
          setOut('0.00')
          fetch('https://router.boringdao-api.com/best_path', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              from_chain_id: fromID,
              to_chain_id: toID,
              token_address: tokenAddress,
              from_address: from,
              to_address: to,
              amount: new BigNumber(amount).multipliedBy(10 ** inputDecimals).toFixed(),
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              console.log(data)
              console.log('record', recordIndex, 'index', indexO.index)
              if (recordIndex === indexO.index) {
                const o = displayBalance(data.output, data.output_decimal)
                setNodeAmount(data.nodes.length)
                setOut(o)
                console.log('out', o)
                setFee(new BigNumber(amount).minus(new BigNumber(o?.replace(',', ''))).toString())
                setLoading(false)
                setPrepare(true)
                setInputIsLow(false)
              }
            })
            .catch((e) => {
              console.log('record', recordIndex, 'index', indexO.index)
              if (recordIndex === indexO.index) {
                setInputIsLow(true)
                setOut('0.00')
                console.error(e)
                setLoading(false)
              }
            })
        }
      } catch (error) {
        setLoading(false)
        console.log('fetchRouter-error', error)
      }
    },
    [fromID, toID, amount, from, to, tokenAddress]
  )

  useEffect(() => {
    indexO.index += 1
    fetchOut(indexO.index)
  }, [fetchOut])

  return {out, fee, loading, prepare, nodeAmount, inputIsLow}
}
