import * as React from 'react'
import { styled as style, alpha } from '@mui/material/styles'
import { getIcon, getChainIcon } from 'utils/icons'
import { Chains } from '@w3u/chains'
import { Menu, MenuItem, Typography } from '@mui/material'
import { MenuProps } from '@mui/material/Menu'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import Balance from 'components/Balance'
import { Flex, Text } from 'components/Style'
import { TwowayOToken } from 'modal/token'
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const StyledMenu = style((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    },
  },
}))

const FilterIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  background: #00000066;
  border-radius: 6px;
  border: 1px solid #0A214D;
  cursor: pointer;
  margin-left: 12px;
`

interface OtokenProos {
    otokens: TwowayOToken[]
    onConfirm: (address: string) => void
}

const FilterOToken:React.FC<OtokenProos> = ({ otokens, onConfirm }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation()
  const [tokenName, setTokeName] = React.useState(otokens[0].symbol)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  };
  const handleClose = (name: string) => {
    setAnchorEl(null)
    setTokeName(name)
    onConfirm(name)
  }
  const otoken = React.useMemo(() => {
      return otokens.find(f => f.symbol === tokenName)
  }, [otokens, tokenName])

  return (
    <div>
      <FilterIcon onClick={handleClick}>
        <img src={getIcon(otoken?.symbol)} width={18} height={18} />
        <Text ml="8px" color="#ffffff">{otoken?.symbol}</Text>
        <FilterAltIcon style={{marginLeft: '4px'}} />
      </FilterIcon>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {otokens.map(d =>
            <MenuItem onClick={() => handleClose(d.symbol)} disableRipple>
                <Flex alignItems="center" justifyContent="space-between">
                    <img width={18} height={18} src={getIcon(d.symbol)} alt={d.symbol} />
                    <Typography>{d.symbol}</Typography>
                </Flex>
            </MenuItem>
        )}
      </StyledMenu>
    </div>
  )
}

export default FilterOToken
