import React from 'react'
import styled from '@emotion/styled'
import CircularProgress from '@mui/material/CircularProgress'

export const OutlineButton = styled.div<{color?: string,mb?: string}>`
    display: flex;
    align-items: center;
    padding: 2px 6px;
    border: 1px solid ${({ color }) => color ? color : '#FFFFFF'};
    border-radius: 6px;
    color: ${({ color }) => color ? color : '#FFFFFF'};
    font-size: 12px;
    /* line-height: 20px;
    height: 20px; */
    margin-bottom: ${({ mb }) => mb ? mb : '0'};
    cursor: pointer;
    :hover {
        opacity: 0.8;
    }
`

const StyleSmallButton =  styled.div<{
    fullWidth?: boolean
    disabled?: boolean
    loading?: boolean
    padding?: string
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: ${({ padding }) => padding ? padding : '8px 10px'};
    width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};
    background: ${({ disabled }) => disabled ? '#444C65' : 'linear-gradient(90deg, #0068FF 0%, #00A1FF 100%);'};
    color: ${({ disabled }) => disabled ? 'rgba(255, 255, 255, 0.6)' : '#ffffff'};
    border-radius: 6px;
    font-size: 12px;
    cursor: ${({ disabled, loading }) => (disabled || loading) ? 'not-allowed' : 'pointer'};
    pointer-events: ${({ disabled, loading }) => (disabled || loading) ? 'none' : ''};
    :hover {
        opacity: ${({ disabled, loading }) => (disabled || loading) ? 1 : 0.8}
    }
`
const StyleButton =  styled.div<{
    fullWidth?: boolean
    disabled?: boolean
    loading?: boolean
}>`
    padding: 16px 8px;
    text-align: center;
    min-width: ${({ fullWidth }) => fullWidth ? 'auto' : '300px'};
    width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};
    background: ${({ disabled }) => disabled ? '#444C65' : 'linear-gradient(90deg, #0068FF 0%, #00A1FF 100%);'};
    color: ${({ disabled }) => disabled ? 'rgba(255, 255, 255, 0.6)' : '#ffffff'};
    border-radius: 12px;
    font-size: 18px;
    cursor: ${({ disabled, loading }) => (disabled || loading) ? 'not-allowed' : 'pointer'};
    pointer-events: ${({ disabled, loading }) => (disabled || loading) ? 'none' : ''};
    :hover {
        opacity: ${({ disabled, loading }) => (disabled || loading) ? 1 : 0.8}
    }
    @media screen and (max-width: 600px) {
        padding: 12px 8px;
    }
`
interface Props {
    fullWidth?: boolean
    disabled?: boolean
    loading?: boolean
    padding?: string
    onClick?: () => void
}

export const SmallButton:React.FC<Props> = ({ 
    children, 
    fullWidth,
    disabled,
    loading,
    padding,
    onClick,
    ...props 
}) => {
    return (
        <StyleSmallButton 
            onClick={onClick}
            fullWidth={fullWidth}
            disabled={disabled}
            loading={loading}
            padding={padding}
            {...props}
        >
            {loading ? <CircularProgress sx={{color: '#ffffff'}} size="18px" /> : children}
        </StyleSmallButton>
    )
}
const Button:React.FC<Props> = ({ 
    children, 
    fullWidth,
    disabled,
    loading,
    onClick,
    ...props 
}) => {
    return (
        <StyleButton 
            onClick={onClick}
            fullWidth={fullWidth}
            disabled={disabled}
            loading={loading}
            {...props}
        >
            {loading ? <CircularProgress sx={{color: '#ffffff'}} size="18px" /> : children}
        </StyleButton>
    )
}

export default Button