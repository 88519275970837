import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const GithubIcon:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 16 17">
            <path fill={color} d="M5.88171 1.10345L1.09741 5.88775C0.847719 6.13742 0.436946 6.13742 0.187255 5.88775C-0.0624182 5.63806 -0.0624182 5.22728 0.187255 4.97759L4.97155 0.193297C5.22124 -0.0563757 5.63201 -0.0563757 5.88171 0.193297C6.13138 0.442989 6.13138 0.845701 5.88171 1.10345Z" />
            <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M4.77832 15.4644C4.77832 15.8188 5.06828 16.1088 5.42268 16.1088C5.77707 16.1088 6.06703 15.8188 6.06703 15.4644V0.644362C6.06703 0.289957 5.77709 0 5.42268 0C5.06828 0 4.77832 0.289957 4.77832 0.644362V15.4644ZM14.9027 10.221L10.1184 15.0053C9.8687 15.263 9.8687 15.6658 10.1184 15.9154C10.3681 16.1651 10.7788 16.1651 11.0285 15.9154L15.8128 11.1312C16.0625 10.8815 16.0625 10.4707 15.8128 10.221C15.5631 9.97132 15.1524 9.97132 14.9027 10.221Z"/>
            <path fill={color} d="M10.577 0C10.9314 0 11.2213 0.289957 11.2213 0.644362V15.4644C11.2213 15.8188 10.9314 16.1088 10.577 16.1088C10.2226 16.1088 9.93262 15.8188 9.93262 15.4644V0.644362C9.93262 0.289957 10.2226 0 10.577 0Z"/>
        </SvgIcon>
    )
}

export default GithubIcon
