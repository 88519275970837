import React, {useEffect, useMemo, useState} from 'react'
import {Dialog, InputBase, Box} from '@mui/material'
import {BoringChefInfo} from 'modal/twoway'
import {useTranslation} from 'react-i18next'
import styled from '@emotion/styled'
import {Text, Flex} from 'components/Style'
import {getIcon} from 'utils/icons'
import {cleanBalance, displayBalance} from 'utils/formatBalance'
import BigNumber from 'bignumber.js'
import {formatNumber} from 'utils'
import Mybutton, {OutlineButton} from 'components/Button'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const DialogTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  font-family: 'Lexend-Regular, Lexend';
  font-size: 18px;
  margin-bottom: 34px;
  > svg {
    width: 18px;
    height: 18px;
  }
`

const InputWrapper = styled.div`
  display: flex;
  border: 1px solid #0068ff;
  padding: 27px 17px;
  border-radius: 12px;
  input {
    color: #ffffff;
    padding: 0 20px;
  }
`

interface DepositProps {
  farmInfo: BoringChefInfo
  open: boolean
  onClose: () => void
  onConfrim: (amount: string) => void
}

const DepositModal: React.FC<DepositProps> = ({farmInfo, open, onClose, onConfrim}) => {
  const {t} = useTranslation()
  const [amount, setAmount] = useState('')
  const balance = useMemo(() => displayBalance(farmInfo.balance ?? '0.00', farmInfo.token.decimals), [farmInfo])
  const [loading, setLoading] = useState(false)
  const handleClose = () => {
    setAmount('')
    onClose()
  }

  const handle = async () => {
    setLoading(true)
    await onConfrim(amount)
    setLoading(false)
  }

  return (
    <Dialog open={open} keepMounted onClose={handleClose} title={t('stake')} fullWidth={true}>
      <DialogTitle>
        <Text fontSize='18px'>{t('stake')}</Text>
        <Text sx={{cursor: 'pointer'}} onClick={handleClose}>
          <CloseOutlinedIcon style={{width: '18px'}} />
        </Text>
      </DialogTitle>
      <InputWrapper>
        <img src={getIcon(farmInfo.token.symbol)} width={28} height={28} />
        <InputBase fullWidth value={amount} onChange={(e) => setAmount(e.target.value)} />
        <OutlineButton onClick={() => setAmount(cleanBalance(balance))}>MAX</OutlineButton>
      </InputWrapper>
      <Flex mt='8px' justifyContent='flex-end'>
        <Text color='#ffffff'>
          {t('balance')}&nbsp;:&nbsp;{balance}
        </Text>
      </Flex>
      <Flex mt='20px' justifyContent='center'>
        <Box sx={{width: {md: '60%', xs: '100%'}}}>
          <Mybutton
            loading={loading}
            disabled={!amount || new BigNumber(amount).gt(balance)}
            fullWidth
            onClick={handle}
          >
            {t('stake')}
          </Mybutton>
        </Box>
      </Flex>
    </Dialog>
  )
}

export default DepositModal
