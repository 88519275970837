import {useTranslation} from 'react-i18next'
import React from 'react'
import {Flex, Text} from '../../components/Style'
import {getChainIcon} from '../../utils/icons'
import {Chains} from '@w3u/chains'
import {ShareIcon} from '../../components/Svg'
import {RouterStepInterface} from '../../interfaces/router'
import {ChainStep} from 'views/Transaction/style'
import {LongLine, PintActive, Point, PointOut, StepWrapper3} from '../../views/Transaction/step'

const RouterChainItem3 = ({steps}: {steps: RouterStepInterface[]}) => {
  const first = steps[0]

  return (
    <ChainStep key={first.origin_chain_id}>
      <Flex alignItems='center'>
        <img src={getChainIcon(first.origin_chain_id)} width='38px' height='38px' alt={first.node_name} />
        <Text ml='5px'>{Chains[first.origin_chain_id].displayName}</Text>
      </Flex>
      <StepWrapper3 style={{width: '100%', padding: '20px 0'}}>
        <Flex flexDirection='column' alignItems='flex-start'>
          <Flex width='100%' alignItems='center' mb='10px'>
            <Point style={{flex: '0 0 16px'}} />
            <LongLine style={{flex: 'auto'}} />
          </Flex>
          <Text>
            <a target='_blank' href={`${Chains[first.origin_chain_id].explorer}/tx/${first.origin_txid}`}>
              <ShareIcon color='#0068FF' />
            </a>
          </Text>
        </Flex>
        <Flex flexDirection='column' alignItems='flex-end'>
          <Flex width='100%' alignItems='center' mb='10px'>
            <LongLine style={{flex: 'auto'}} />
            {steps.length > 1 ? <Point style={{flex: '0 0 16px'}} /> : <PintActive />}
          </Flex>
          {/*<Text>{steps.length > 1 ? steps[1].node_name : ''}</Text>*/}
        </Flex>
        <Flex flexDirection='column' alignItems='flex-end'>
          <Flex width='100%' alignItems='center' mb='10px'>
            <LongLine style={{flex: 'auto'}} />
            {steps.length === 3 ? (
              <Point style={{flex: '0 0 16px'}} />
            ) : steps.length === 2 ? (
              <PintActive style={{flex: '0 0 16px'}} />
            ) : (
              <Point />
            )}
          </Flex>
          {steps.length === 3 && (
            <Text>
              <a
                target='_blank'
                href={`${Chains[first.target_chain_id].explorer}/tx/${steps[steps.length - 1].step_txid}`}
              >
                <ShareIcon color='#0068FF' />
              </a>
            </Text>
          )}
        </Flex>
      </StepWrapper3>
      <Flex alignItems='center' justifyContent='center'>
        <img src={getChainIcon(first.target_chain_id)} width='38px' height='38px' alt={first.node_name} />
        <Text ml='5px'>{Chains[first.target_chain_id].displayName}</Text>
      </Flex>
    </ChainStep>
  )
}

const RouterChainItem5 = ({steps}: {steps: RouterStepInterface[]}) => {
  const first = steps[0]

  return (
    <ChainStep key={first.origin_chain_id}>
      <Flex alignItems='center'>
        <img src={getChainIcon(first.origin_chain_id)} width='38px' height='38px' alt={first.node_name} />
        <Text ml='5px'>{Chains[first.origin_chain_id].displayName}</Text>
      </Flex>
      <StepWrapper3 style={{width: '100%', padding: '20px 0'}}>
        <Flex flexDirection='column' alignItems='flex-start'>
          <Flex width='100%' alignItems='center' mb='10px'>
            <Point style={{flex: '0 0 16px'}} />
            <LongLine style={{flex: 'auto'}} />
          </Flex>
          <Text>
            <a target='_blank' href={`${Chains[first.origin_chain_id].explorer}/tx/${first.origin_txid}`}>
              <ShareIcon color='#0068FF' />
            </a>
          </Text>
        </Flex>
        <Flex flexDirection='column' alignItems='flex-end'>
          <Flex width='100%' alignItems='center' mb='10px'>
            <LongLine style={{flex: 'auto'}} />
            {steps.length > 1 ? <Point style={{flex: '0 0 16px'}} /> : <PintActive />}
          </Flex>
          {/*<Text>{steps.length > 1 ? steps[1].node_name : ''}</Text>*/}
        </Flex>
        <Flex flexDirection='column' alignItems='flex-end'>
          <Flex width='100%' alignItems='center' mb='10px'>
            <LongLine style={{flex: 'auto'}} />
            {steps.length === 3 ? (
              <Point style={{flex: '0 0 16px'}} />
            ) : steps.length === 2 ? (
              <PintActive style={{flex: '0 0 16px'}} />
            ) : (
              <Point />
            )}
          </Flex>
          {steps.length === 3 && (
            <Text>
              <a
                target='_blank'
                href={`${Chains[first.target_chain_id].explorer}/tx/${steps[steps.length - 1].step_txid}`}
              >
                <ShareIcon color='#0068FF' />
              </a>
            </Text>
          )}
        </Flex>
      </StepWrapper3>
      <Flex alignItems='center' justifyContent='center'>
        <img src={getChainIcon(first.target_chain_id)} width='38px' height='38px' alt={first.node_name} />
        <Text ml='5px'>{Chains[first.target_chain_id].displayName}</Text>
      </Flex>
    </ChainStep>
  )
}

const RouterChainItem = ({steps}: {steps: RouterStepInterface[]}) => {
  const {t} = useTranslation()

  return <>{steps.length > 3 ? <RouterChainItem5 steps={steps} /> : <RouterChainItem3 steps={steps} />}</>
}

export default RouterChainItem
