import { useState } from 'react'
import { Button } from '@mui/material'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { useWeb3React } from '@web3-react/core'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import ConnectWalletModal from 'components/Modal/ConnectWallet'

const StyleButton = styled(Button)`
    background: #00000066;
    margin: 0 10px;
    padding: 5px 10px;
    &:hover {
        background: #00000066;
    }
`

interface IButton {
    variant?: string
    hideIcon?: boolean
}

const ConnectWallet:React.FC<IButton> = ({ variant, hideIcon, ...props }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const { account } = useWeb3React()

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClickClose = () => {
        setOpen(false)
    }

    return (
        <>
            {variant && variant === 'header' ? 
                <StyleButton size="small" onClick={handleClickOpen} {...props}>
                    {account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` :
                        <>{!hideIcon && <AccountBalanceWalletIcon style={{marginRight: '8px', color: '#0068FF'}} />}{t('connect_wallet')}</>
                    }
                </StyleButton> :
                <Button fullWidth size='large' onClick={handleClickOpen} {...props}>
                    {account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : t('connect_wallet')}
                </Button>
            }
            <ConnectWalletModal open={open} onClose={handleClickClose}/>
        </>
    )
}

export default ConnectWallet
