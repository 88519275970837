import {
    CHAIN_AAC,
    CHAIN_ARBITRUM, CHAIN_ARBITRUM_TESTNET, CHAIN_AURORA, CHAIN_AVALANCHE, CHAIN_BOBA,
    CHAIN_BSC, CHAIN_BSC_TESTNET, CHAIN_CONFLUX,
    CHAIN_ETHER, CHAIN_ETHER_KOVAN,
    CHAIN_FANTOM, CHAIN_HARMONY_0,
    CHAIN_HECO, CHAIN_HECO_TESTNET, CHAIN_IOTEX, CHAIN_KCC,
    CHAIN_METIS,
    CHAIN_OK, CHAIN_OPTIMISM,
    CHAIN_POLYGON, CHAIN_POLYGON_MUMBAI,
    CHAIN_XDAI
} from "@w3u/chains";

export const ROUTER_ADDRESSES: {[chainID: number]: string} = {
    [CHAIN_POLYGON]: '0x7898354Ee241536678075F8d71EE0f606b68dD56',
    [CHAIN_BSC]: '0xfdb5BFd28796263fA3066f9Cd5e8A584ffA10aB1',

    [CHAIN_ETHER]: '0xdDb6Be0b53d3d04198F5C77dfB53fBe7eDAB7c01',
    [CHAIN_OK]: '0xB6bA839E6C9760C9e2C5DF6a3f3621786479cf7F',
    [CHAIN_HECO]: '0x1783cCa495420797f52417d0b441b9b6ba0b73e7',
    [CHAIN_XDAI]: '0xD51Ab6A7C7300c314e55Abd0615e2fF7F635A7cE',
    [CHAIN_FANTOM]: '0x39788D3E34E6BFc52244359264eb5960661Dd5E0',
    [CHAIN_METIS]: '0x6eCCD646fF097a8F249D377D85ec21d19c7C02A9',
    [CHAIN_ARBITRUM]: '0xD09EeB57FDeE62ED387860623075174969B49C22',
    [CHAIN_AVALANCHE]: '0x65C56cBB035a3835A82C3740b822AecE74f47529',
    [CHAIN_HARMONY_0]: '0xffffffffffffffffffffffffffffffffffffffff',
    [CHAIN_OPTIMISM]: '0xEa5Fed6ADB6ea4C8A2e2622de720f9cEd0B22a17',
    [CHAIN_AURORA]: '0x81114Cfc4dCB4de333A270041F6a67CF27f83987',
    [CHAIN_KCC]: '0xDd5B602745C90Ee4C8f96d0309EA92Ee78Def5b8',
    [CHAIN_IOTEX]: '0xbE4A5438ad89311d8c67882175D0fFcC65Dc9C03',
    [CHAIN_BOBA]: '0xffffffffffffffffffffffffffffffffffffffff',
    [CHAIN_AAC]: '0x6B2B2349d5DA2D260F5819CBfF767Ae090E9a600',
    [CHAIN_CONFLUX]: '0x4F497F9D85A6fE135fFca99f0f253919fE827211'
}
