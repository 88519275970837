import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Flex, Text } from 'components/Style'
import styled from '@emotion/styled'
import useMediaQuery from '@mui/material/useMediaQuery'
import { CHAIN_POLYGON } from '@w3u/chains'
import { useWeb3React } from '@web3-react/core'
import { getIcon } from 'utils/icons'
import { Button, Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SmallButton } from 'components/Button'
import BigNumber from 'bignumber.js'
import Balance from 'components/Balance'
import { useTwowayChef } from 'hooks/useTwowayChef'
import { displayBalance, getBalanceNumber } from 'utils/formatBalance'
import { useTwowayChefAddress, useRelayChainID } from 'hooks'
import { usePrice } from 'hooks/usePrice'
import { useApprove } from 'hooks/useApprove'
import { useDeposit, useWithdraw, useClaim } from 'hooks/useTwowayChef'
import { TwowayChefInfo } from 'modal/twoway'
import { changeChain } from 'utils'
import { displayTwowayBoringAPY, displayTwowayOTokenAPY } from 'utils/calc'
import Deposit from './components/Deposit'
import Withdraw from './components/Withdraw'
import MobileFarm from './mobile'

const Grid = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 1fr 0.6fr repeat(4, 1fr) 3fr;
    grid-row-gap: 20px;
    height: 40px;
    &:not(:last-child) {
        margin-bottom: 20px;
    }
`

const EcoWrapper = styled.div`
    width: 100%;
    background: #00000066;
    border-radius: 30px;
    padding: 30px;
`

const TitleName = styled.div`
    background: #00000066;
    padding: 21px 16px;
    border-radius: 12px 0 0 12px;
    height: 60px;
    min-width: 111px;
`
const FarmBox = styled.div`
    flex: 1 1 auto;
    background: #00000066;
    padding: 20px;
    border-radius: 0 12px 12px 12px;
`
const ButtonGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    width: 100%;
`
const MobileWrapper =  styled.div`
    width: 100%;
`
interface FarmItemProps {
    farm: TwowayChefInfo
    boringPrice: string
    onRefresh: () => void
}
const SkeletonList = () => {
    return (
        <>
            {[1, 2, 3, 4].map(d => (
                <Grid key={d}>
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="text" height={40} />
                </Grid>
            ))}
        </>
    )
}
const FarmItem:React.FC<FarmItemProps> = ({ farm, boringPrice, onRefresh }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [openWithdraw, setOpenWithdraw] = useState(false)
    const { chainId } = useWeb3React()
    const relayChainID = useRelayChainID()
    const chefAddress = useTwowayChefAddress()
    const { allowance, loading: apvLoading, onApprove } = useApprove(farm.token.address, chefAddress, relayChainID)
    const [loading, setLoading] = useState(apvLoading)
    const [depositLoading, setDepositLoading] = useState(apvLoading)
    const [widthDrawLoading, setWidthDrawLoading] = useState(apvLoading)
    const [calimLoading, setClaimLoading] = useState(apvLoading)
    const { onDeposit } = useDeposit()
    const { onWithdraw } = useWithdraw()
    const { onClaim } = useClaim()
    const handleDeposit = async (amount: string) => {
        try {
            setDepositLoading(true)
            await onDeposit(farm.token.pid, amount)
            setOpen(false)
            onRefresh()
            setDepositLoading(false)
        } catch (error) {
            setDepositLoading(false)
        }
    }
    const handleWithdraw = async (amount: string) => {
        try {
            setWidthDrawLoading(true)
            await onWithdraw(farm.token.pid, amount)
            setOpenWithdraw(false)
            onRefresh()
            setWidthDrawLoading(false)
        } catch (error) {
            setWidthDrawLoading(false)
        }
    }
    const handleClaim = async () => {
        try {
            setClaimLoading(true)
            onClaim(farm.token.pid)
            onRefresh()
            setClaimLoading(false)
        } catch (error) {
            setClaimLoading(false)
        }
    }
    const handleApprove = async () => {
        try {
            setLoading(true)
            await onApprove()
            onRefresh()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    const renderAction = () => {
        const canClaim = new BigNumber(farm.earned[0]).gt(0) || new BigNumber(farm.earned[1]).gt(0)

        return allowance ?
        <ButtonGrid>
            <SmallButton loading={depositLoading} onClick={() => setOpen(true)}>{t('stake')}</SmallButton>
            <SmallButton disabled={new BigNumber(farm.yourStaked).eq(0)} loading={widthDrawLoading} onClick={() => setOpenWithdraw(true)}>{t('withdraw')}</SmallButton>
            <SmallButton disabled={!canClaim} loading={calimLoading} onClick={handleClaim}>{t('claim')}</SmallButton>
        </ButtonGrid> :
        <SmallButton loading={loading} fullWidth onClick={handleApprove}>{t('approve')}</SmallButton>
    }
    return (
        <>
            <Grid key={farm.token.pid}>
                <Flex alignItems="center">
                    <img src={getIcon(farm.token.symbol)} width="20px" height="20px" alt={farm.token.symbol} />
                    <Text ml="6px">{farm.token.symbol}</Text>
                </Flex>
                <Flex flexDirection="column" justifyContent="center">
                    {farm.token.symbol !== 'oFIN' && farm.token.symbol !== 'oIZI' &&
                        <Flex>
                            <img src={getIcon('BORING')} alt='Boring' width={18} height={18} /> &nbsp;
                            <Text>{displayTwowayBoringAPY(farm, boringPrice)}%</Text>
                        </Flex>
                    }
                    <Flex>
                        <img src={getIcon(farm.token.symbol)} alt={farm.token.symbol} width={18} height={18} /> &nbsp;
                        <Text>{displayTwowayOTokenAPY(farm)}%</Text>
                    </Flex>
                </Flex>
                <Flex alignItems="center" justifyContent="flex-end">
                    <Balance value={getBalanceNumber(new BigNumber(farm.totalStaked))} />
                </Flex>
                <Flex alignItems="center" justifyContent="flex-end">
                    <Text mr="6px">{displayBalance(farm.balance)}</Text>
                    <Link to={`/liquidity/add?token=${farm.token.symbol}`}><Text color="#0068FF" style={{cursor: 'pointer'}}>{t('Get')}</Text></Link>
                </Flex>
                <Flex alignItems="center" justifyContent="flex-end">
                    <Balance value={getBalanceNumber(new BigNumber(farm.yourStaked))} />
                </Flex>
                <Flex flexDirection="column" justifyContent="center">
                    <Flex justifyContent="flex-end">
                        <img src={getIcon('BORING')} alt='Boring' width={18} height={18} /> &nbsp;
                        <Text>{displayBalance(farm.earned[0])}</Text>
                    </Flex>
                    <Flex justifyContent="flex-end">
                        <img src={getIcon(farm.token.symbol)} alt={farm.token.symbol} width={18} height={18} /> &nbsp;
                        <Text>{displayBalance(farm.earned[1], farm.token.decimals)}</Text>
                    </Flex>
                </Flex>
                <Flex justifyContent="space-evenly">
                    {chainId === CHAIN_POLYGON ? renderAction() :
                        <Button onClick={() => changeChain(CHAIN_POLYGON)} fullWidth size='small' variant="outlined">
                            {t('switch_to_chain', {chain: 'Polygon'})}
                        </Button>
                    }
                </Flex>
            </Grid>
            <Deposit
                loading={depositLoading}
                farmInfo={farm}
                open={open}
                onClose={() => setOpen(false)}
                onConfrim={handleDeposit}
            />
            <Withdraw
                loading={widthDrawLoading}
                farmInfo={farm}
                open={openWithdraw}
                onClose={() => setOpenWithdraw(false)}
                onConfrim={handleWithdraw}
            />
        </>

    )
}

const Farm = () => {
    const { t } = useTranslation()
    const matches = useMediaQuery('(max-width:600px)')
    const boringPrice = usePrice('BORING')
    const { farms, fetchFarmChef } = useTwowayChef()
    const FarmName = ['TwowayV2 LP']
    return (
        <>
        {matches ? <MobileWrapper>
            <Text mb="15px" fontSize="16px" color="#ffffff">{t('oPortal_farm')}</Text>
            <MobileFarm farmName={FarmName[0]} boringPrice={boringPrice} farms={farms} onRefresh={fetchFarmChef} />
        </MobileWrapper> :
            <EcoWrapper>
                <Text mb="20px" fontSize="18px">{t('oPortal_farm')}</Text>
                <Flex alignItems="center">
                    <TitleName style={{visibility: 'hidden'}}>
                        <Text>{FarmName[0]}</Text>
                    </TitleName>
                    <Grid style={{width: '100%', padding: '0 20px'}}>
                        <Flex alignItems="center"><Text>{t('pool')}</Text></Flex>
                        <Flex alignItems="center"><Text>{t('APR')}</Text></Flex>
                        <Flex alignItems="center" justifyContent="flex-end"><Text>{t('total_staked')}</Text></Flex>
                        <Flex alignItems="center" justifyContent="flex-end"><Text>{t('balance')}</Text></Flex>
                        <Flex alignItems="center" justifyContent="flex-end"><Text>{t('your_staked')}</Text></Flex>
                        <Flex alignItems="center" justifyContent="flex-end"><Text>{t('reward')}</Text></Flex>
                    </Grid>
                </Flex>
                {FarmName.map((d, j) => (
                    <Flex key={j} mb="30px">
                        <TitleName>
                            <Text>{d}</Text>
                        </TitleName>
                        <FarmBox>
                            {farms ?
                                farms.filter(f => f).map((farm, i) => (<FarmItem key={i} farm={farm} boringPrice={boringPrice} onRefresh={fetchFarmChef} />)) :
                                <SkeletonList />
                            }
                        </FarmBox>
                    </Flex>
                ))}
            </EcoWrapper>
        }
        </>

    )
}

export default Farm
