import { useEffect, useState } from 'react'
import { MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Chains } from '@w3u/chains'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import {getIcon, getChainIcon} from 'utils/icons'
import { BoringSelect, Text } from 'components/Style'


const HomeTokenSwitcher = ({
  defaultTokenAddress,
  tokens,
  fullWidth,
  chainID,
  border = true,
  onChange
}: {
  defaultTokenAddress?: string
  tokens: string[]
  fullWidth?: boolean
  chainID?: number
  border?: boolean
  onChange: (tokenAddrss: string) => void
}) => {
  const [tokenAddress, setTokenAddress] = useState<string>(defaultTokenAddress ?? tokens[0])
  const switchToken = (e: SelectChangeEvent<string>) => {
    const address = e.target.value
    setTokenAddress(address)
    onChange(address)
  }

  useEffect(() => {
    setTokenAddress(tokens[0])
  }, [tokens])

  return (
    <Select
      style={{fontSize: '13px'}}
      sx={{mr: {md: 0, xs: 0}, height: {md: '32px', xs: '24px'}, borderRadius: '100px', width: fullWidth ? '100%' : 'auto'}}
      IconComponent={ExpandMoreOutlinedIcon}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        elevation: 1,
      }}
      onChange={switchToken}
      value={tokenAddress}
      input={<BoringSelect />}
    >
      {tokens.map((token) => (
        <MenuItem key={token} value={token}>
          <img src={getIcon(token)} width={20} alt='token' />
          {chainID && <img src={getChainIcon(chainID)} width={20} alt={Chains[chainID].displayName} /> }
          <Text lineHeight="20px">{token}</Text>
        </MenuItem>
      ))}
    </Select>
  )
}

export default HomeTokenSwitcher
