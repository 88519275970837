import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect, useState } from 'react'
import { CHAIN_ETHER } from '@w3u/chains'
import muticall, { Call } from 'utils/muticall'
import { POW_TOKEN, OTOKEN } from 'config/constants/pow_bridge'
import { getBoringTunnelAddress, getBoringAddress, getBoringFeePoolAddress } from 'utils/addressHelpers'
import { useBoringTunnelContract } from 'hooks/useContract'
import TunnelABI from 'config/abi/Tunnel.json'
import ERC20ABI from 'config/abi/ERC20.json'
import FeePoolABI from 'config/abi/FeePool.json'

/**
 * Pow Bridge Total Subbly
 * New Only Support Ethereum
 * @returns 
 */
export const useTotalSubbly = () => {
    const [total, setTotal] = useState<{[token: string]: string}>({'BTC': ''})
    // const { chainId } = useWeb3React()
    const fetchTotal = useCallback(async () => {
        // const _chainId = chainId ?? CHAIN_ETHER
        const calls = POW_TOKEN.map(d => {
            const tokenAddress =  OTOKEN(d)[CHAIN_ETHER]
            return {
                address: tokenAddress,
                name: 'totalSupply',
                params: []
            }
        })
        const result = await muticall(ERC20ABI, calls, CHAIN_ETHER)
        let tl:{[token: string]: string} = {}
        POW_TOKEN.map((d, i) => {
            tl[d] = result[i].toString()
        })
        setTotal(tl)
    }, [])

    useEffect(() => {
        fetchTotal()
    }, [fetchTotal])
    return { total, fetchTotal }
}

export const useTunnelInfo = () => {
    const [canIssue, setCanIssue] = useState<{[token: string]: string}>({'BTC': ''})
    const [pledgeRatio, setPledgeRatio] = useState<{[token: string]: string}>({'BTC': ''})
    const fetchTunnelInfo = useCallback(async () => {
        const calls = POW_TOKEN.map(d => {
            const tunnelAddress = getBoringTunnelAddress(d, CHAIN_ETHER)
            return {
                address: tunnelAddress,
                name: 'canIssueAmount',
                params: []
            }
        })
        const callsRatio = POW_TOKEN.map(d => {
            const tunnelAddress = getBoringTunnelAddress(d, CHAIN_ETHER)
            return {
                address: tunnelAddress,
                name: 'pledgeRatio',
                params: []
            }
        })
        const result = await muticall(TunnelABI, [...calls, ...callsRatio], CHAIN_ETHER)
        let issue:{[token: string]: string} = {}
        let ratio:{[token: string]: string} = {}
        POW_TOKEN.map((d, i) => {
            issue[d] = result[i].toString()
            ratio[d] =  result[i + POW_TOKEN.length].toString()
        })
        setCanIssue(issue)
        setPledgeRatio(ratio)
    }, [])

    useEffect(() => {
        fetchTunnelInfo()
    }, [fetchTunnelInfo])

    return { canIssue, pledgeRatio, fetchTunnelInfo }
}
interface PledgeData {
    totalPledage: string
    userPledage: string
    boringEarnd: string
    oTokenEarnd: string
    lockAmount: string
    unLockAmount: string
    userLockLength: string
    redeemLockTxLimit: string
}
/**
 * Tunnnel Pledage
 * New Only Support Ethereum
 * @returns 
 */
export const usePledgeData = () => {
    const [allPledgeData, setAllPledgeData] = useState<{[token:string]: PledgeData}>({
        'BTC': {
            totalPledage: '',
            userPledage: '',
            boringEarnd: '',
            oTokenEarnd: '',
            lockAmount: '',
            unLockAmount: '',
            userLockLength: '',
            redeemLockTxLimit: ''
        }
    })
    const [loading, setLoading] = useState(false)
    const { account } = useWeb3React()
    const fetchPledge = useCallback(async () => {
        try {
            setLoading(true)
            const boringAddress = getBoringAddress(CHAIN_ETHER)
            const defaultAccount = account ?? '0xffffffffffffffffffffffffffffffffffffffff'
            let calls: Call[] = []
            let userCalls: Call[] = []
            let userEardCalls: Call[] = []
            let userLockCalls: Call[] = []
            let plageCalls: Call[] = []
            let userLockLegthCalls: Call[] = []
            POW_TOKEN.forEach(token => {
                const boringTunnelAddress = getBoringTunnelAddress(token, CHAIN_ETHER)
                const boringFeePoolAddress = getBoringFeePoolAddress(token, CHAIN_ETHER)
                calls.push({
                    address: boringAddress,
                    name: 'balanceOf',
                    params: [boringTunnelAddress]
                })
                userCalls.push({
                    address: boringTunnelAddress,
                    name: 'borPledgeInfo',
                    params: [defaultAccount]
                })
                userLockLegthCalls.push({
                    address: boringTunnelAddress,
                    name: 'userLockLength',
                    params: [defaultAccount]
                })
                userEardCalls.push({
                    address: boringFeePoolAddress,
                    name: 'earned',
                    params: [defaultAccount]
                })
                userLockCalls.push({
                    address: boringTunnelAddress,
                    name: 'userLockAmount',
                    params: []
                })
                plageCalls.push({
                    address: boringTunnelAddress,
                    name: 'redeemLockTxLimit',
                    params: []
                })
            })
            const pledage = await muticall(ERC20ABI, calls, CHAIN_ETHER)
            const userPledage = await muticall(TunnelABI, [...userCalls, ...userLockCalls, ...userLockLegthCalls, ...plageCalls], CHAIN_ETHER)
            const earned = await muticall(FeePoolABI, userEardCalls, CHAIN_ETHER)
            const len = userPledage.length
            let lineNum = len / POW_TOKEN.length
            let res:any = [];
            let n = POW_TOKEN.length
            for (let i = 0; i < lineNum; i++) {
                let temp = userPledage.slice(i*n, i*n+n)
                res.push(temp);
            }
            let apd:{[token:string]: PledgeData} = {}
            POW_TOKEN.forEach((t,i) => {
                apd[t] = {
                    totalPledage: pledage[i][0].toString(),
                    userPledage: userPledage[i][0].toString(),
                    boringEarnd: earned[i][0].toString(),
                    oTokenEarnd:  earned[i][1].toString(),
                    lockAmount: res[1][i][0].toString(),
                    unLockAmount: res[1][i][1].toString(),
                    userLockLength: res[2][i][0].toString(),
                    redeemLockTxLimit: res[3][i][0].toString()
                }
            })
            setAllPledgeData(apd)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }, [account])

    useEffect(() => {
        fetchPledge()
    }, [fetchPledge])

    return { allPledgeData, loading, fetchPledge }
}

export const useWithdrawUnlock = (tokenName: string) => {
    const contract = useBoringTunnelContract(tokenName)
    const handleWithdrawUnlock = useCallback(async () => {
        const tx = await contract.withdrawUnlock()
        const receipt = await tx.wait()
        return receipt
    }, [contract])
    return { onWithdrawUnlock: handleWithdrawUnlock }
}
