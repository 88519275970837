import React, { useEffect, useRef } from 'react'
import CountUp from 'react-countup'
import { Text, WrapperBoxProps } from 'components/Style'

interface BalanceProps extends WrapperBoxProps {
  value: number
  duration?: number
  decimals?: number
  unit?: string
  isDisabled?: boolean
  prefix?: string
}

const Balance: React.FC<BalanceProps> = ({
  value,
  duration = 1,
  decimals = 2,
  isDisabled = false,
  unit,
  prefix,
  ...props
}) => {
  const previousValue = useRef(0)

  useEffect(() => {
    previousValue.current = value
  }, [value])

  // const showPrefix = Boolean(value && prefix)
  // const showUnit = Boolean(value && unit)
  return (
    <Text color={isDisabled ? 'textDisabled' : ''} {...props}>
      {/* {showPrefix && <span>{prefix}</span>} */}
      <span>{prefix}</span>
      <CountUp start={previousValue.current} end={value} decimals={decimals} duration={duration} separator="," />
      <span>{unit}</span>
      {/* {showUnit && } */}
    </Text>
  )
}

export default Balance
