import { useCallback, useEffect, useState } from 'react'
import { getOracleContract } from 'hooks/useContract'
import useRefresh from 'hooks/useRefresh'
import {ethers} from 'ethers'
import multicall from 'utils/muticall'
import { useWeb3React } from '@web3-react/core'
import { ORACLE_ADDRESS } from 'config/constants/pow_bridge'
import { CHAIN_ETHER } from '@w3u/chains'
import OracleABI from  'config/abi/Oracle.json'

export const usePrice = (tokenName: string): string => {
    const [price, setPrice] = useState<string>('0')
    const orcaleContract = getOracleContract(CHAIN_ETHER)
    const { slowRefresh } = useRefresh()

    const fetchPrice = useCallback(async () => {
        try {
            const result = await orcaleContract.getPrice(ethers.utils.formatBytes32String(tokenName))
            setPrice(result.toString())
        } catch (error) {
            setPrice(String(10 ** 17))
        }
    }, [orcaleContract])

    useEffect(() => {
        fetchPrice()
    }, [slowRefresh])

    return price
}

// Eethereum Oracle
export const usePrices = (tokenNames: string[]) => {
    const [price, setPrice] = useState<{[token:string]: string}>()
    // const { chainId } = useWeb3React()
    const { slowRefresh } = useRefresh()

    const fetchPrices = useCallback(async () => {
        try {
            const orcaleAddress = ORACLE_ADDRESS[CHAIN_ETHER]
            const calls = tokenNames.map(d => (
                {
                    address: orcaleAddress,
                    name: 'getPrice',
                    params: [ethers.utils.formatBytes32String(d)]
                }
            ))
            const result = await multicall(OracleABI, calls, CHAIN_ETHER)
            let prices:{[token:string]: string} = {}
            tokenNames.forEach((t,i) => {
                prices[t] = result[i].toString()
            })
            setPrice(prices)
        } catch (error) {
            setPrice({})
        }
    }, [])

    useEffect(() => {
        fetchPrices()
    }, [slowRefresh])

    return price
}
