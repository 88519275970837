import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Layout from 'components/Layout'
import Home from 'views/Home'
import Mint from 'views/PowBridge/Mint'
import Burn from 'views/PowBridge/Burn'
import Twoway from 'views/Twoway'
import Pledge from 'views/Pledge'
import Swap from 'views/Eco/Swap'
import ExternalFarm from 'views/Eco/ExternalFarm'
import FarmoPortal from 'views/FarmoPortal'
import FarmBoring from 'views/FarmBoring'
import Data from 'views/Data'
import Liquity from 'views/Liquidity'
import AddLiquity from 'views/Liquidity/add'
import RemoveLiquity from 'views/Liquidity/remove'
import Transfer from 'views/Transfer'
import RouterWay from './views/Router'
import BurnWista from './views/BurnWista'

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/mint' element={<Mint />} />
          <Route path='/burn' element={<Burn />} />
          <Route path='/twoway' element={<Twoway />} />
          <Route path='/router' element={<RouterWay />} />
          <Route path='/pledge' element={<Pledge />} />
          <Route path='/swap' element={<Swap />} />
          <Route path='/externalfarm' element={<ExternalFarm />} />
          <Route path='/farm/oportal' element={<FarmoPortal />} />
          <Route path='/farm/boring' element={<FarmBoring />} />
          <Route path='/data' element={<Data />} />
          <Route path='/liquidity' element={<Liquity />} />
          <Route path='/liquidity/add' element={<AddLiquity />} />
          <Route path='/liquidity/remove' element={<RemoveLiquity />} />
          <Route path='/split' element={<Transfer />} />
          <Route path='/claim_new_wista' element={<BurnWista />} />
        </Routes>
      </Layout>
    </Router>
  )
}

export default App
