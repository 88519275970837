import React, {useContext, useEffect, useMemo} from 'react'
import {Dialog, Button, Box} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {Flex, Text} from 'components/Style'
import {getIcon} from 'utils/icons'
// import Balance from 'components/Balance'
import {TransactionContext} from 'contexts/TransactionContext'
import {getTwowayTokenByAddress, getOTokenByAddress, getNativeTokenByAddress} from 'utils/addressHelpers'
import {usePrice} from 'hooks/usePrice'
import useRefresh from 'hooks/useRefresh'
import BigNumber from 'bignumber.js'
import {BIG_TEN} from 'utils/bigNumber'
import useWaitForTransaction from 'hooks/useWaitForTransaction'
import {registerToken} from 'utils/network'
import {TwowayToken} from 'modal/token'
import {BridgeType} from 'modal/types'
import CircularProgress from '@mui/material/CircularProgress'
import {get2WayTokenFixed} from 'utils'
import ChainItem from './ChainItem'
import {DialogTitle, TokenBalance, Grid} from './style'

const Transaction = () => {
  const {t} = useTranslation()
  const {slowRefresh} = useRefresh()
  const {data, open, txHash, targetOpen, changeTxHash} = useContext(TransactionContext)
  const {data: transactionData, fetchTransaction} = useWaitForTransaction()
  const chains = useMemo(() => (data && txHash ? data[txHash] : []), [data, txHash])
  const token = useMemo(() => {
    if (chains && chains.length && chains[0].bridgeType === BridgeType.oportal) {
      const tt = getTwowayTokenByAddress(chains[0].from_token_address)
      const ot = getOTokenByAddress(chains[0].from_token_address)
      return tt ?? ot
    } else if (chains && chains.length && chains[0].bridgeType === BridgeType.native) {
      const nt = getNativeTokenByAddress(chains[0].origin_token_address)
      return nt
    }
    return null
  }, [chains])
  const price = usePrice(token?.symbol ?? 'USDT')
  const amount = useMemo(() => (chains && chains[0] ? Number(chains[0].amount) : 0), [chains])
  const tokenPrice = useMemo(() => {
    if (token && price) {
      return new BigNumber(amount).times(price).div(BIG_TEN.pow(18)).toFixed(4)
    } else {
      return '0.00'
    }
  }, [token, price, amount])
  const txs = useMemo(
    () => (chains ? chains.map((d) => ({hash: d.transaction, chainId: d.event_chain_id})) : []),
    [chains]
  )
  useEffect(() => {
    if (txs.length) {
      fetchTransaction(txs)
    }
  }, [txs, slowRefresh])
  const finallDatas = useMemo(() => {
    if (chains) {
      return chains
        ? chains.map((d) => {
            const tx = transactionData.find((f) => f?.tx === d.transaction)
            return {...d, transactionData: tx}
          })
        : []
    }
  }, [chains, transactionData])

  const handleRegister = (token: TwowayToken | null | undefined) => {
    if (token) {
      registerToken(token.address, token.symbol, token.decimals)
    }
  }
  const handleClose = () => {
    targetOpen && targetOpen()
    changeTxHash && changeTxHash('', false)
  }
  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      keepMounted
      onClose={handleClose}
      sx={(theme) => ({
        '.MuiPaper-root': {
          padding: '30px',
          [theme.breakpoints.down('md')]: {
            padding: '10px',
            margin: '0 4px',
            width: 'calc(100% - 8px)',
          },
        },
      })}
    >
      <DialogTitle>{t('Transaction Status')}</DialogTitle>
      <TokenBalance>
        <Flex justifyContent='space-between'>
          <Text fontSize='14px' color='#ffffff'>
            {t('Token')}
          </Text>
          <Flex flexDirection='column'>
            <Flex alignItems='center' justifyContent='flex-end' mb='5px'>
              <img src={getIcon(token?.symbol)} width='38px' height='38px' alt={token?.symbol} />
              <Text fontSize='14px' ml='5px'>
                {token?.symbol}
              </Text>
            </Flex>
            <Text style={{cursor: 'pointer'}} onClick={() => handleRegister(token)}>
              {t('Add Token to Metamask')}
            </Text>
          </Flex>
        </Flex>
        <Flex justifyContent='space-between'>
          <Text fontSize='14px'>{t('Amount')}</Text>
          <Flex>
            <Text>
              {amount.toFixed(get2WayTokenFixed(token?.symbol))} {token?.symbol}
            </Text>
            <Text ml='10px'>~US${tokenPrice}</Text>
          </Flex>
        </Flex>
      </TokenBalance>
      <Grid>
        <Text fontSize='16px'>{t('Network')}</Text>
        <Text style={{visibility: 'hidden'}}>{t('Chain')}</Text>
        {/* <Text textAlign="center" fontSize="16px">{t('Timer')}</Text> */}
        <Text textAlign='right' fontSize='16px'>
          {t('Gas Fee')}
        </Text>
        <Text style={{visibility: 'hidden'}}>-</Text>
      </Grid>
      {finallDatas && finallDatas.length ? (
        finallDatas.map((d, i) => <ChainItem key={i} chians={finallDatas} chain={d} allSteps={finallDatas.length} />)
      ) : (
        <Flex width='100%' justifyContent='center' padding='30px 0 40px'>
          <CircularProgress sx={{color: '#ffffff'}} size='18px' />
        </Flex>
      )}
      <Flex justifyContent='center'>
        <Box width='50%'>
          <Button onClick={targetOpen} fullWidth size='large'>
            {t('Close')}
          </Button>
        </Box>
      </Flex>
    </Dialog>
  )
}

export default Transaction
