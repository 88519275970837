import { useEffect, useState } from 'react'
import { MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { Chains } from '@w3u/chains'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import {getIcon, getChainIcon} from 'utils/icons'
// import { setupNetwork } from 'utils/network'
import { BoringSelect, BoringSelectBorder, Text } from 'components/Style'
import { TwowayToken } from 'modal/token'


const TokenSwitcher = ({
  defaultTokenAddress,
  tokens,
  fullWidth,
  chainID,
  border = true,
  onChange
}: {
  defaultTokenAddress?: string
  tokens: TwowayToken[]
  fullWidth?: boolean
  chainID?: number
  border?: boolean
  onChange: (tokenAddrss: string) => void
}) => {
  const [tokenAddress, setTokenAddress] = useState<string>(defaultTokenAddress ?? tokens[0].address)
  const switchToken = (e: SelectChangeEvent<string>) => {
    const address = e.target.value
    setTokenAddress(address)
    onChange(address)
  }
  useEffect(() => {
    if (defaultTokenAddress) {
      setTokenAddress(defaultTokenAddress)
    }
  }, [defaultTokenAddress])

  useEffect(() => {
    if (tokens.some(s => s.address.toLocaleLowerCase() === tokenAddress.toLocaleLowerCase())) {
      setTokenAddress(tokenAddress)
    } else {
      setTokenAddress(tokens[0].address)
    }
  }, [tokens, tokenAddress])
  
  return (
    <Select
      style={{fontSize: '13px'}}
      sx={{mr: {md: 3, xs: 0}, borderRadius: '100px', height: '32px', width: fullWidth ? '100%' : 'auto'}}
      IconComponent={ExpandMoreOutlinedIcon}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        elevation: 1,
      }}
      onChange={switchToken}
      value={tokenAddress}
      input={border ? <BoringSelectBorder /> : <BoringSelect />}
    >
      {tokens.map((token) => (
        <MenuItem key={token.address} value={token.address}>
          <img style={{marginRight: '0'}} src={getIcon(token.symbol)} width={20} alt='token' />
          {chainID && <img style={{marginLeft: '-6px'}} src={getChainIcon(chainID)} width={16} alt={Chains[chainID].displayName} /> }
          <Text ml="4px" lineHeight="20px">{token.symbol}</Text>
        </MenuItem>
      ))}
    </Select>
  )
}

export default TokenSwitcher
