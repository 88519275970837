import { useMemo } from 'react'
import { Flex, Text } from 'components/Style'
import { IHistory } from 'modal/types'
import { getChainIcon } from 'utils/icons'
import { Chains, CHAIN_OPTIMISM, CHAIN_BOBA } from '@w3u/chains'
import { useTranslation } from 'react-i18next'
import { ShareIcon } from 'components/Svg'
import Skeleton from '@mui/material/Skeleton'
import { CONFIRM_BLOCK_BUMBER } from 'config/constants/twoway'
import { ChainStep } from './style'
import Step from './step'

interface Props {
    chians: IHistory[]
    chain: IHistory
    allSteps: number
}
// const showTime = (val: number) => {
//     if(val<60) {
//       return val + "s";
//     }else{
//       const min_total = Math.floor(val / 60)
//       const sec = Math.floor(val % 60)
//       if(min_total<60){
//         return min_total + "m" + sec + 's';
//       }else{
//         const hour_total = Math.floor(min_total / 60)
//         const min = Math.floor(min_total % 60)
//         return hour_total + "h" + min + "m" + sec + 's';
//       }
//     }
//   }

const ChainItem:React.FC<Props> = ({ chians, chain, allSteps }) => {
    const { t } = useTranslation()
    const  confrimBolckNumber = useMemo(() => CONFIRM_BLOCK_BUMBER[chain.event_chain_id ?? 1], [chain])
    // const  pearBlockTime = useMemo(() => PEAR_BLOCK_TIME[chain.event_chain_id ?? 1], [chain])
    const finalNumber = useMemo(() => {
        if (!chain.transactionData) {
            return 0
        }
        if (chain.transactionData) {
            return chain.transactionData.pastNumber <= confrimBolckNumber ? chain.transactionData.pastNumber : confrimBolckNumber
        }
        return 0
    }, [chain])
    // const timer = useMemo(() => {
    //     if (!chain.transactionData) {
    //         return ''
    //     }
    //     if (chain.transactionData) {
    //         if (chain.transactionData.pastNumber > 0 && chain.transactionData.pastNumber <= confrimBolckNumber) {
    //             return showTime(pearBlockTime * chain.transactionData.pastNumber)
    //         }
    //         if (chain.transactionData.pastNumber > confrimBolckNumber) {
    //             return showTime(pearBlockTime * confrimBolckNumber / 1000)
    //         }
    //     }
    //     return ''
    // }, [chain, confrimBolckNumber, pearBlockTime])
    // const isConfirm = useMemo(() => chain && chain.confirmed === 1, [chain])
    return (
        <ChainStep key={chain.id}>
            <Flex alignItems="center">
                <img src={getChainIcon(chain.event_chain_id)} width="38px" height="38px" alt={chain.event_chain_name} />
                <Text ml="5px">{Chains[chain.event_chain_id].displayName}</Text>
            </Flex>
            <Step
                chians={chians}
                step={chain.step}
                allSteps={allSteps}
                chainId={chain.event_chain_id}
                status={chain.status}
                finalNumber={finalNumber}
                confirmNumber={confrimBolckNumber}
            />
            {/* <Flex alignItems="center" justifyContent="center">
                <Text>5 - 10m</Text>

            </Flex> */}
            <Flex flexDirection="column" justifyContent="center" alignItems="flex-end">
                {chain.event_chain_id === CHAIN_OPTIMISM || chain.event_chain_id === CHAIN_BOBA ? t('Not yet') :
                    chain?.transactionData ?
                    <>
                        <Text>{chain?.transactionData?.gas}</Text>
                        <Text>{Chains[chain.event_chain_id].symbol}</Text>
                        {/* <Text>~US$6.51</Text> */}
                    </> : <Skeleton width="60px" height="40px" />
                }
            </Flex>
            <Flex justifyContent="center" alignItems="center">
                {chain.transaction !== '' ?
                    <a target="_blank" href={`${Chains[chain.event_chain_id].explorer}/tx/${chain.transaction}`}><ShareIcon color="#0068FF" /></a> :
                    <ShareIcon color="#ffffff66"/>
                }
            </Flex>
        </ChainStep>
    )
}

export default ChainItem
