import { useCallback } from 'react'
import { useBoringBoringDAOContract } from 'hooks/useContract'
import { ethers } from 'ethers'
// import { toast } from 'react-toastify'

 const useBurn = (tokenName: string) => {
    const tunnelContract  = useBoringBoringDAOContract(tokenName)
    const handleBurn = useCallback(async (assetType: string, assetAddress: string, amount: string) => {
        try {
            const tunnelKey = ethers.utils.formatBytes32String(assetType)
            const tx = await tunnelContract.burnBToken(tunnelKey, ethers.utils.parseEther(amount).toString(), assetAddress)
            const receipt = await tx.wait()
            return receipt
        } catch (error) {
            // toast('Transfer amount exceeds balance', {autoClose: 1000})
        }
    }, [tunnelContract])

    return { onBurn: handleBurn }
}

export default useBurn