import React from 'react'
import styled from '@emotion/styled'
import ChainSelect from 'components/ChainSelect'
import ConnectWallet from 'components/ConnectWallet'
import BoringBalance from 'components/BoringBalance'

const BottomBarWrapper = styled.div`
    position: sticky;
    display: flex;
    justify-content: space-around;
    background-color: #0000004d;
    backdrop-filter: blur(7px);
    bottom: 0;
    border-radius: 27px 27px 0px 0px;
    padding: 15px;
`

const BottomBar = () => {
    return (
        <BottomBarWrapper>
            <ChainSelect isBottom={true} />
            <ConnectWallet variant='header' hideIcon />
            <BoringBalance isBottom={true} />
        </BottomBarWrapper>
    )
}

export default BottomBar
