import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const CertikIcon:React.FC<IconProps> = () => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <circle fill="white" cx="8" cy="8" r="8" />
            <path fill="#171C3E" fill-rule="evenodd" clip-rule="evenodd" d="M8.18468 2.2507L8.1514 2.24359C8.10674 2.23705 8.06038 2.23932 8.01686 2.25127L4.49739 3.19848L4.46695 3.20815C4.33895 3.25708 4.27154 3.38053 4.31534 3.49658L4.327 3.52247C4.38446 3.63084 4.5301 3.68773 4.6672 3.65075L8.10276 2.7263L11.6185 3.65132L11.6504 3.65815C11.7884 3.68005 11.9266 3.61121 11.9693 3.49516C12.0153 3.36972 11.9329 3.23688 11.7849 3.19791L8.18468 2.2507ZM3.19405 3.66213L3.22249 3.66412L3.2498 3.66896L3.27113 3.67465L3.29389 3.68289L3.32233 3.69626L3.33741 3.70537L3.36187 3.72272L3.3852 3.74348L3.40625 3.76709L3.42331 3.7907L3.44009 3.82L5.4062 7.77382H10.4034L12.5857 3.79923L12.6107 3.76396L12.6315 3.74149L12.6605 3.71674L12.6864 3.69968L12.7228 3.68176L12.7518 3.67209L12.7774 3.6664L12.7936 3.66384L12.8158 3.66241L12.8309 3.66213L12.8636 3.66497L12.8866 3.66924L12.9187 3.67863L12.9395 3.68716L12.9734 3.70565L12.9873 3.71532L13.0138 3.73694L13.0359 3.7597L13.0536 3.78274L13.0701 3.80947L13.0832 3.8365L13.0968 3.87632L13.1025 3.90419L13.1065 3.94288C13.1867 6.44318 12.9071 8.39079 12.2588 9.788C11.606 11.1952 10.2358 12.5488 8.15425 13.8596C8.11705 13.8832 8.07471 13.8976 8.0308 13.9014L7.98898 13.9022C7.93769 13.9023 7.88742 13.8879 7.84391 13.8607C5.73445 12.5497 4.35033 11.196 3.69695 9.788C3.06548 8.42578 2.80208 6.54245 2.89964 4.13431L2.9096 3.92183L2.91244 3.89993L2.92012 3.86779L2.93378 3.83081L2.94174 3.81488L2.95028 3.79895L2.96165 3.7816L2.97986 3.75884L3.00887 3.72954L3.03447 3.7102L3.06633 3.69143L3.08283 3.68346L3.10814 3.67465L3.12806 3.66896L3.15422 3.6644L3.17698 3.66213H3.19405ZM10.2754 8.49774H5.72421L8.02767 12.7645L10.2754 8.49774ZM4.24195 9.51436C3.71686 8.42862 3.45062 6.95148 3.44863 5.08437L7.4309 12.7645C5.83258 11.6932 4.77074 10.6075 4.24195 9.51436ZM12.551 5.08437L8.56869 12.7645C10.2774 11.6978 11.2135 10.5315 11.7289 9.45918C12.2395 8.39932 12.5194 6.90199 12.551 5.08437Z" />
        </SvgIcon>
    )
}

export default CertikIcon
