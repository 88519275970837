import React, { useState, useMemo } from 'react'
import { Dialog, DialogContent, DialogActions, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { BoringInput, Flex, Text } from 'components/Style'
import MyButton, { OutlineButton } from 'components/Button'
import BigNumber from 'bignumber.js'
import { formatNumber } from 'utils'
import { getIcon } from 'utils/icons'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const InputWrapper = styled(Flex)`
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    border: 1px solid #0068FF;
    border-radius: 6px;
`

const DialogTitle = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    font-family: 'Lexend-Regular, Lexend';
    font-size: 18px;
    margin-bottom: 34px;
    > svg {
        width: 18px;
        height: 18px;
    }
`

interface ModalProps {
    open: boolean
    maxAmount: string
    onClose: () => void
    onConfirm: (amount: string) => void
}

const RedeemModal:React.FC<ModalProps> = ({ open, onClose, maxAmount, onConfirm }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [amount, setAmount] = useState('')
    const balance = useMemo(() => new BigNumber(maxAmount).toString(), [maxAmount])
    const amountError = useMemo(() => {
        const amountBN = new BigNumber(amount)
        const balanceBN = new BigNumber(balance)
        if (balanceBN.lte(0)) {
            return t('balance_is_0')
        }
        if (amountBN.gt(balanceBN)) {
            return t('balance_is_insufficient')
        }
        return null
    }, [amount, balance])
    const handleMax = () => {
        setAmount(balance)
    }
    const handleConfirm = async () => {
        try {
            setLoading(true)
            await onConfirm(amount)
            onClose()
            setAmount('')
            setLoading(false)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    return (
        <Dialog
            open={open}
            keepMounted
            onClose={onClose}
            fullWidth={true}
        >
            <DialogTitle>
                <Text fontSize="18px">{t('Redeem')}</Text>
                <Text sx={{cursor: 'pointer'}} onClick={onClose}><CloseOutlinedIcon style={{width: '18px'}} /></Text>
            </DialogTitle>
            <DialogContent>
                <InputWrapper>
                    <Flex alignItems="center" width="100%">
                        <img src={getIcon('BOR')} alt="BOR" width="20px" height="20px" />
                        <BoringInput style={{flex: 'auto', marginLeft: '12px'}} value={amount} onChange={e => setAmount(e.target.value)} />
                    </Flex>
                    <OutlineButton onClick={handleMax}>MAX</OutlineButton>
                </InputWrapper>
                <Flex mt="8px" justifyContent="flex-end">
                    <Text>{t('balance')}: {formatNumber(balance)}</Text>
                </Flex>
            </DialogContent>
            <DialogActions>
                 <Flex width="100%" justifyContent="center">
                    <Box width="50%">
                        <MyButton
                            loading={loading}
                            disabled={!amount || !!amountError}
                            fullWidth
                            onClick={handleConfirm}>
                                {!amountError ? t('Redeem') :
                                    <Text fontSize="18px" color="#ff0000">{amountError}</Text>
                                }
                        </MyButton>
                    </Box>
                </Flex>
            </DialogActions>
        </Dialog>
    )
}

export default RedeemModal
