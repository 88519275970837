import { useWeb3React } from '@web3-react/core'
import { useCallback, useEffect, useState } from 'react'
import multicall from 'utils/muticall'
import { CHAIN_ETHER } from '@w3u/chains'
import { OLD_ETH_CHEF_ADDRESS } from 'config/constants/chef'
import { BoringChefToken } from 'modal/token'
import { BoringChefInfo } from 'modal/twoway'
import { useBoringChefContract } from 'hooks/useContract'
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'
import { BIG_TEN } from 'utils/bigNumber'

import ChefABI from 'config/abi/BoringChefV2.json'
import ERC20ABI from 'config/abi/ERC20.json'

const BORING_FARM: BoringChefToken[] = [
    {
  
        address: '0x6C189Baa963060DAEEC77B7305b055216090bFC4',
        name: 'PP Token(oBTC)',
        symbol: 'BORING',
        subSymbol: 'BTC',
        decimals: 18,
        chainID: 1,
        pid: 0,
        type: 'Manifest Destiny'
    },
    {
        address: '0x48c57b4E50Ae145E36d1b26D3470A6b9a68F3Ee9',
        name: 'PP Token(oLTC)',
        subSymbol: 'LTC',
        symbol: 'BORING',
        decimals: 18,
        chainID: 1,
        pid: 6,
        type: 'Manifest Destiny'
    },
    {
        address: '0xD221f7F04b2055E2f937063D415D6BcDCc1D70De',
        name: 'PP Token(oDoge)',
        subSymbol: 'DOGE',
        symbol: 'BORING',
        decimals: 18,
        chainID: 1,
        pid: 8,
        type: 'Manifest Destiny'
    },
    {
        address: '0x8064d9ae6cdf087b1bcd5bdf3531bd5d8c537a68',
        name: 'BTC',
        symbol: 'BTC',
        stakePriceSymbol: 'BTC',
        decimals: 18,
        chainID: 1,
        pid: 1,
        type: 'Wild Wild West'
    },
    {
        address: '0x07C44B5Ac257C2255AA0933112c3b75A6BFf3Cb1',
        name: 'LTC',
        symbol: 'LTC',
        decimals: 18,
        chainID: 1,
        pid: 7,
        type: 'Wild Wild West'
    },
    {
        address: '0x9c306A78b1a904e83115c05Ac67c1Ef07C653651',
        name: 'DOGE',
        symbol: 'DOGE',
        decimals: 18,
        chainID: 1,
        pid: 9,
        type: 'Wild Wild West'
    },
    {
        address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        name: 'WETH',
        symbol: 'ETH',
        decimals: 18,
        chainID: 1,
        pid: 2,
        type: 'Satellite City'
    },
    {
        address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        name: 'USDT',
        symbol: 'USDT',
        decimals: 6,
        chainID: 1,
        pid: 3,
        type: 'Satellite City'
    },
    {
        address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        name: 'USDC',
        symbol: 'USDC',
        decimals: 6,
        chainID: 1,
        pid: 4,
        type: 'Satellite City'
    },
    {
        address: '0x6b175474e89094c44da98b954eedeac495271d0f',
        name: 'DAI',
        symbol: 'DAI',
        decimals: 18,
        chainID: 1,
        pid: 5,
        type: 'Satellite City'
    },
    {
        address: '0xBC19712FEB3a26080eBf6f2F7849b417FdD792CA',
        name: 'BORING',
        symbol: 'BORING',
        decimals: 18,
        chainID: 1,
        pid: 12,
        type: 'Satellite City'
    }
]

/**
 * Get Boring Farm Info
 * New Only support Ethereum
 * @returns 
 */
export const useBoringChef = () => {
    const [chefs, setChefs] = useState<BoringChefInfo []>([])
    const [loading, setLoading] = useState(false)
    const { account } = useWeb3React()
    const fetchFarmChef = useCallback(async () => {
        const defaultAccount = account ?? '0xffffffffffffffffffffffffffffffffffffffff'
        setLoading(true)
        const data = await Promise.all(
            BORING_FARM.map(async token => {
                const chefAddress = OLD_ETH_CHEF_ADDRESS
                const tokenAddress = token.address
                const calls = [
                    {
                        address: tokenAddress,
                        name: 'balanceOf',
                        params: [defaultAccount]
                    },
                    {
                        address: tokenAddress,
                        name: 'balanceOf',
                        params: [chefAddress]
                    },
                    {
                        address: tokenAddress,
                        name: 'allowance',
                        params: [defaultAccount, chefAddress]
                    },
                    // {
                    //     address: tokenAddress,
                    //     name: 'totalSupply',
                    //     params: []
                    // }
                ]
                const chefCalls = [
                    {
                        address: chefAddress,
                        name: 'userInfo',
                        params: [token?.pid, defaultAccount]
                    },
                    {
                        address: chefAddress,
                        name: 'poolInfo',
                        params: [token?.pid]
                    },
                    {
                        address: chefAddress,
                        name: 'totalAllocPoint',
                        params: []
                    },
                    {
                        address: chefAddress,
                        name: 'pendingBoring',
                        params: [token?.pid, defaultAccount]
                    },
                    {
                        address: chefAddress,
                        name: 'boringPerBlock',
                        params: []
                    }
                ]
                try {
                    const pairs = await multicall(ERC20ABI, calls, CHAIN_ETHER)
                    const chefs = await multicall(ChefABI, chefCalls, CHAIN_ETHER)
                    const chef: BoringChefInfo = {
                        token,
                        totalStaked: pairs[1][0].toString(),
                        yourStaked: chefs[0][0].toString(),
                        point: chefs[1][1].toString(),
                        totalPoint: chefs[2][0].toString(),
                        boringPerBlock: chefs[4][0].toString(),
                        balance: pairs[0][0].toString(),
                        allowance: pairs[2][0].toString(),
                        reward: chefs[3][0].toString(),
                        loading: true
                    }
                    return chef
                } catch (error) {
                    return {
                        token,
                        totalStaked: '0',
                        yourStaked: '0',
                        point: '0',
                        totalPoint: '0',
                        boringPerBlock: '0',
                        balance: '0',
                        allowance: '0',
                        reward: '0',
                        loading: false
                    }
                }
            })
        )
        setChefs(data)
        setLoading(false)
    }, [account])
    useEffect(() => {
        if (fetchFarmChef) {
            fetchFarmChef()
        }
    }, [fetchFarmChef])

    return { chefs, loading, fetchFarmChef }
}

export const useDeposit = () => {
    const chefContract = useBoringChefContract()
    const handleDeposit = useCallback(async (pid: number, amount: string, decimals: number) => {
        const value = new BigNumber(amount).multipliedBy(BIG_TEN.pow(decimals)).toFixed()
        const tx = await chefContract.deposit(pid, value)
        const receipt = await tx.wait()
        return receipt.status
    }, [chefContract])

    return { onDeposit: handleDeposit }
}

export const useWithdraw = () => {
    const chefContract = useBoringChefContract()
    const handleWithdraw = useCallback(async (pid: number, amount: string, decimals: number) => {
        const value = new BigNumber(amount).multipliedBy(BIG_TEN.pow(decimals)).toFixed()
        const tx = await chefContract.withdraw(pid, value)
        const receipt = await tx.wait()
        return receipt.status
    }, [chefContract])

    return { onWithdraw: handleWithdraw }
}

export const useClaim = () => {
    const chefContract = useBoringChefContract()
    const handleClaim = useCallback(async (pid: number) => {
        const tx = await chefContract.withdraw(pid, 0)
        const receipt = await tx.wait()
        return receipt.status
    }, [chefContract])

    return { onClaim: handleClaim }
}