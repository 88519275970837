import { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useRelayChainID } from 'hooks'
import { useTwowayOTokens } from 'hooks/useTwoway'
import { useTwowayChefContract } from 'hooks/useContract'
import { ethers } from 'ethers'
import multicall from 'utils/muticall'
import { getTwowayChefAddress } from 'utils/addressHelpers'
import { TwowayChefInfo } from 'modal/twoway'
import ERC20ABI from 'config/abi/ERC20.json'
import TwowayChefABI from 'config/abi/TwowayChef.json'

export const useTwowayChef = () => {
    const [farms, setFarms] = useState<TwowayChefInfo[]>([])
    const { account } = useWeb3React()
    const relayChainID = useRelayChainID()
    const oTokens = useTwowayOTokens()
    const fetchFarmChef = useCallback(async () => {
        if (oTokens.length) {
            const defaultAccount = account ?? '0xffffffffffffffffffffffffffffffffffffffff'
            const twowayChefAddress = getTwowayChefAddress(relayChainID)
            const data: TwowayChefInfo[] | any [] = await Promise.all(
                oTokens.map(async otoken => {
                    const calls = [
                        {
                            address: otoken.address,
                            name: 'balanceOf',
                            params: [defaultAccount]
                        },
                        {
                            address: otoken.address,
                            name: 'balanceOf',
                            params: [twowayChefAddress]
                        },
                        {
                            address: otoken.address,
                            name: 'allowance',
                            params: [defaultAccount, twowayChefAddress]
                        }
                    ]
                    const chefCalls = [
                        {
                            address: twowayChefAddress,
                            name: 'userInfo',
                            params: [otoken.pid, defaultAccount]
                        },
                        {
                            address: twowayChefAddress,
                            name: 'poolInfo',
                            params: [otoken.pid]
                        },
                        {
                            address: twowayChefAddress,
                            name: 'totalAllocPoint',
                            params: []
                        },
                        {
                            address: twowayChefAddress,
                            name: 'perSecond',
                            params: [otoken.pid]
                        },
                        {
                            address: twowayChefAddress,
                            name: 'earned',
                            params: [otoken.pid, defaultAccount]
                        }
                    ]
                    try {
                        const tokenResults = await multicall(ERC20ABI, calls, relayChainID)
                        const chefResults = await multicall(TwowayChefABI, chefCalls, relayChainID)
                        const chef: TwowayChefInfo = {
                            token: otoken,
                            totalStaked: tokenResults[1][0].toString(),
                            yourStaked: chefResults[0][0].toString(),
                            point: chefResults[1][1].toString(),
                            totalPoint: chefResults[2][0].toString(),
                            rewardPerSecond: [chefResults[3][0].toString(), chefResults[3][1].toString()],
                            balance: tokenResults[0][0].toString(),
                            allowance: tokenResults[2][0].toString(),
                            earned: [chefResults[4][0].toString(), chefResults[4][1].toString()],
                        }
                        return chef
                    } catch (error) {
                        return null
                    }
                })
            )
            setFarms(data.filter(d => d))
        }
    }, [relayChainID, oTokens])

    useEffect(() => {
        if (fetchFarmChef) {
            fetchFarmChef()
        }
    }, [fetchFarmChef])

    return { farms, fetchFarmChef }
}

export const useDeposit = () => {
    const chefContract = useTwowayChefContract()
    const handleDeposit = useCallback(async (pid: number, amount: string) => {
        const tx = await chefContract.deposit(pid, ethers.utils.parseEther(amount))
        const receipt = await tx.wait()
        return receipt.status
    }, [chefContract])

    return { onDeposit: handleDeposit }
}

export const useWithdraw = () => {
    const chefContract = useTwowayChefContract()
    const handleWithdraw = useCallback(async (pid: number, amount: string) => {
        const tx = await chefContract.withdraw(pid, ethers.utils.parseEther(amount))
        const receipt = await tx.wait()
        return receipt.status
    }, [chefContract])

    return { onWithdraw: handleWithdraw }
}

export const useClaim = () => {
    const chefContract = useTwowayChefContract()
    const handleClaim = useCallback(async (pid: number) => {
        const tx = await chefContract.deposit(pid, 0)
        const receipt = await tx.wait()
        return receipt.status
    }, [chefContract])

    return { onClaim: handleClaim }
}
