import {TwowayToken, TwowayOToken} from 'modal/token'
import {IBoringFarmName} from 'modal/twoway'
import {
  CHAIN_ARBITRUM,
  CHAIN_AURORA,
  CHAIN_AVALANCHE,
  CHAIN_AVALANCHE_TESTNET,
  CHAIN_BOBA,
  CHAIN_BSC,
  CHAIN_BSC_TESTNET,
  CHAIN_ETHER,
  CHAIN_ETHER_KOVAN,
  CHAIN_FANTOM,
  CHAIN_FANTOM_TESTNET,
  CHAIN_HARMONY_0,
  CHAIN_HARMONY_0_TESTNET,
  CHAIN_HECO,
  CHAIN_HECO_TESTNET,
  CHAIN_METIS,
  CHAIN_OK,
  CHAIN_OK_TESTNET,
  CHAIN_OPTIMISM,
  CHAIN_POLYGON,
  CHAIN_POLYGON_MUMBAI,
  CHAIN_XDAI,
  CHAIN_XDAI_TESTNET,
  CHAIN_ARBITRUM_TESTNET,
  CHAIN_KCC,
  CHAIN_IOTEX,
  CHAIN_BLAST,
} from '@w3u/chains'

export const TWOWAY_ADDRESSES: {[chainID: number]: string} = {
  [CHAIN_ETHER]: '0xecaD1ab3464ECcC7536af6aFeE414dF873495616',
  [CHAIN_BSC]: '0x0075Ac0C662a5B655060d1519fF739D834078705',
  [CHAIN_OK]: '0xf82B64169f3F43706F423c3246a10D190D12B576',
  // [CHAIN_HECO]: '0x66D6A03394dC9D155F3704e14C6c0F03076B40D1',
  [CHAIN_POLYGON]: '0x6dc551088AFAf828b95a9c9Ad590AeFE797c8e87',
  [CHAIN_XDAI]: '0x805675c662CF99B0156C8D1D76EAB86a830d9E8D',
  [CHAIN_FANTOM]: '0x66D6A03394dC9D155F3704e14C6c0F03076B40D1',
  [CHAIN_METIS]: '0x57E05346A576A3f75972aC0E7c9d9f46765B346E',
  [CHAIN_ARBITRUM]: '0x2e220E3ab85fC4A46F770A34eF2CdDc8074E4689',
  [CHAIN_ARBITRUM_TESTNET]: '0x3caf319515A122E98Da710304f31F237a9b304C7',
  [CHAIN_AVALANCHE]: '0x12f3a5af0a82af48af89FA5BAcf6307e1b226903',
  // [CHAIN_HARMONY_0]: '0x7F942c3f0F61dE5133447B8A4974969EA36a7f19',
  [CHAIN_OPTIMISM]: '0x29414EC76d79Ff238E5E773322799d1c7Ca2443F',
  [CHAIN_ETHER_KOVAN]: '0x15624E9d0442D1039C335BC1aeB5F6A876a9bb72',
  [CHAIN_BSC_TESTNET]: '0xFaf3aD46dA9670910e340073FFd31aA4D04B2f5a',
  [CHAIN_POLYGON_MUMBAI]: '0xCbb3f8292CbE44efb8A059eA2406bD36F5ab1652',
  [CHAIN_AURORA]: '0x68D97B7A961a5239B9F911DA8dEb57F6eF6e5e28',
  [CHAIN_ETHER_KOVAN]: '0x79149445532A5847F12b136448a5f1AC25d85C92',
  [CHAIN_KCC]: '0x87DD4a7Ad23B95cD9fF9C26B5cF325905CaF8663',
  [CHAIN_IOTEX]: '0x87DD4a7Ad23B95cD9fF9C26B5cF325905CaF8663',
  [CHAIN_BOBA]: '0xffffffffffffffffffffffffffffffffffffffff',
  [CHAIN_BLAST]: '0x87DD4a7Ad23B95cD9fF9C26B5cF325905CaF8663',
}

export const FilterTokens = process.env.REACT_APP_NODE_ENV === 'production' ? ['METIS'] : []

export const OUSDT_TOKENS: TwowayOToken[] = [
  {
    symbol: 'oUSDT',
    address: '0x56700FB81834fDCe92616f7E7c4b8C8bb120957d',
    chainID: CHAIN_POLYGON_MUMBAI,
    decimals: 18,
    pid: 0,
  },
  {
    symbol: 'oUSDT',
    address: '0x413cfE1C41F98879365D665CACB7E79a60001fEE',
    chainID: CHAIN_POLYGON,
    decimals: 18,
    pid: 0,
  },
  {
    symbol: 'oUSDC',
    address: '0x8DE93f998b6b0ddA780Ee12B97dde1F2fADd3B1d',
    chainID: CHAIN_POLYGON,
    decimals: 18,
    pid: 1,
  },
  // {
  //   symbol: 'oLOL',
  //   address: '0x0D9ceFE09299C8c3Cbe1F2706fbcEdBc35d73052',
  //   chainID: CHAIN_POLYGON,
  //   decimals: 18,
  //   pid: 2,
  // },
  {
    symbol: 'oIZI',
    address: '0x79904eC9De1FCCF7ca50fE3f8430e922d53e07e6',
    chainID: CHAIN_POLYGON,
    decimals: 18,
    pid: 3,
  },
  {
    symbol: 'oETH',
    address: '0x5E4e79E5Ea6515c95eA4433b8C985a0f5fAE442b',
    chainID: CHAIN_POLYGON_MUMBAI,
    decimals: 18,
    pid: 0,
  },
  {
    symbol: 'oETH',
    address: '0x0caD6ef3383C4349c68f072076BCDE7B7623576B',
    chainID: CHAIN_POLYGON,
    decimals: 18,
    pid: 6,
  },
  {
    symbol: 'oMETIS',
    address: '0x746158B125447C909291cf3B271C929D156cBAd2',
    chainID: CHAIN_POLYGON,
    decimals: 18,
    pid: 5,
  },
  {
    symbol: 'oFIN',
    address: '0x4D98192A8A078D0937c8FCa144C07e741364BdcA',
    chainID: CHAIN_POLYGON,
    decimals: 18,
    pid: 4,
  },
]

export const TWOWAY_TOKENS: TwowayToken[] = [
  {
    symbol: 'ETH',
    address: '0x4300000000000000000000000000000000000004',
    chainID: CHAIN_BLAST,
    decimals: 18,
  },
  {
    symbol: 'USDC',
    address: '0x4300000000000000000000000000000000000003',
    chainID: CHAIN_BLAST,
    decimals: 18,
  },
  {
    symbol: 'USDT',
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    chainID: CHAIN_ETHER,
    decimals: 6,
  },
  {
    symbol: 'USDT',
    address: '0x382bB369d343125BfB2117af9c149795C6C65C50',
    chainID: CHAIN_OK,
    decimals: 18,
  },
  {
    symbol: 'USDT',
    address: '0x55d398326f99059ff775485246999027b3197955',
    chainID: CHAIN_BSC,
    decimals: 18,
  },
  {
    symbol: 'USDT',
    address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    chainID: CHAIN_POLYGON,
    decimals: 6,
  },
  {
    symbol: 'USDT',
    displayName: 'USDT.e',
    address: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
    chainID: CHAIN_AVALANCHE,
    decimals: 6,
  },
  // {
  //   symbol: 'USDT',
  //   address: '0xa71edc38d189767582c38a3145b5873052c3e47a',
  //   chainID: CHAIN_HECO,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'USDT',
  //   address: '0x3c2b8be99c50593081eaa2a724f0b8285f5aba8f',
  //   chainID: CHAIN_HARMONY_0,
  //   decimals: 6,
  // },
  // {
  //   symbol: 'USDT',
  //   address: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
  //   chainID: CHAIN_FANTOM,
  //   decimals: 6,
  // },
  {
    symbol: 'USDT',
    address: '0x4ECaBa5870353805a9F068101A40E0f32ed605C6',
    chainID: CHAIN_XDAI,
    decimals: 6,
  },
  {
    symbol: 'USDT',
    address: '0xbB06DCA3AE6887fAbF931640f67cab3e3a16F4dC',
    chainID: CHAIN_METIS,
    decimals: 6,
  },
  {
    symbol: 'USDT',
    address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
    chainID: CHAIN_ARBITRUM,
    decimals: 6,
  },
  {
    symbol: 'USDT',
    address: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
    chainID: CHAIN_OPTIMISM,
    decimals: 6,
  },
  // {
  //   symbol: 'USDT',
  //   address: '0x5DE1677344D3Cb0D7D465c10b72A8f60699C062d',
  //   chainID: CHAIN_BOBA,
  //   decimals: 6,
  // },
  // {
  //   symbol: 'USDT',
  //   address: '0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
  //   chainID: CHAIN_AURORA,
  //   decimals: 6,
  // },
  // {
  //   symbol: 'USDT',
  //   address: '0x35D50cbc648c533A5DA29f4899955bd116fC738C',
  //   chainID: CHAIN_ETHER_KOVAN,
  //   decimals: 6,
  // },
  // {
  //   symbol: 'USDT',
  //   address: '0x17b0C74E06407b06CE10B86021ceC571aDc8Dfb8',
  //   chainID: CHAIN_BSC_TESTNET,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'USDT',
  //   address: '0x800f9a236Dfb7206D29Fa15fE4110c75148696BB',
  //   chainID: CHAIN_POLYGON_MUMBAI,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'USDT',
  //   address: '0xbE64543d9dC5b530ee9bD6259D02d14613Aec9aB',
  //   chainID: CHAIN_OK_TESTNET,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'USDT',
  //   address: '0xb608b55b0F777e70F8e37a18F8Da6EC8AE667B33',
  //   chainID: CHAIN_AVALANCHE_TESTNET,
  //   decimals: 6,
  // },
  // {
  //   symbol: 'USDT',
  //   address: '0xAe8234563e2B07E5cB89c6B0d81Fe54CF7667769',
  //   chainID: CHAIN_HECO_TESTNET,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'USDT',
  //   address: '0xbf49c0ffDEEC5bF1731674841B60E4B0855FE6ED',
  //   chainID: CHAIN_HARMONY_0_TESTNET,
  //   decimals: 6,
  // },
  // {
  //   symbol: 'USDT',
  //   address: '0xbf49c0ffDEEC5bF1731674841B60E4B0855FE6ED',
  //   chainID: CHAIN_FANTOM_TESTNET,
  //   decimals: 6,
  // },
  // {
  //   symbol: 'USDT',
  //   address: '0xbf49c0ffDEEC5bF1731674841B60E4B0855FE6ED',
  //   chainID: CHAIN_XDAI_TESTNET,
  //   decimals: 6,
  // },
  {
    symbol: 'USDT',
    address: '0x0039f574eE5cC39bdD162E9A88e3EB1f111bAF48',
    chainID: CHAIN_KCC,
    decimals: 18,
  },
  // {
  //   symbol: 'USDT',
  //   address: '0x6fbcdc1169b5130c59e72e51ed68a84841c98cd1',
  //   chainID: CHAIN_IOTEX,
  //   decimals: 6,
  // },
  // {
  //   symbol: 'USDC',
  //   address: '0x3b2bf2b523f54c4e454f08aa286d03115aff326c',
  //   chainID: CHAIN_IOTEX,
  //   decimals: 6,
  // },
  {
    symbol: 'USDC',
    address: '0x980a5AfEf3D17aD98635F6C5aebCBAedEd3c3430',
    chainID: CHAIN_KCC,
    decimals: 18,
  },
  {
    symbol: 'USDC',
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    chainID: CHAIN_ETHER,
    decimals: 6,
  },
  {
    symbol: 'USDC',
    address: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
    chainID: CHAIN_OPTIMISM,
    decimals: 6,
  },
  {
    symbol: 'USDC',
    address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    chainID: CHAIN_BSC,
    decimals: 18,
  },
  {
    symbol: 'USDC',
    address: '0xc946daf81b08146b1c7a8da2a851ddf2b3eaaf85',
    chainID: CHAIN_OK,
    decimals: 18,
  },
  {
    symbol: 'USDC',
    address: '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83',
    chainID: CHAIN_XDAI,
    decimals: 6,
  },
  // {
  //   symbol: 'USDC',
  //   address: '0x9362bbef4b8313a8aa9f0c9808b80577aa26b73b',
  //   chainID: CHAIN_HECO,
  //   decimals: 6,
  // },
  {
    symbol: 'USDC',
    address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    chainID: CHAIN_POLYGON,
    decimals: 6,
  },
  // {
  //   symbol: 'USDC',
  //   address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
  //   chainID: CHAIN_FANTOM,
  //   decimals: 6,
  // },
  {
    symbol: 'USDC',
    address: '0xEA32A96608495e54156Ae48931A7c20f0dcc1a21',
    chainID: CHAIN_METIS,
    decimals: 6,
  },
  {
    symbol: 'USDC',
    address: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
    chainID: CHAIN_ARBITRUM,
    decimals: 6,
  },
  {
    symbol: 'USDC',
    displayName: 'USDC.e',
    address: '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664',
    chainID: CHAIN_AVALANCHE,
    decimals: 6,
  },
  // {
  //   symbol: 'USDC',
  //   address: '0x985458e523db3d53125813ed68c274899e9dfab4',
  //   chainID: CHAIN_HARMONY_0,
  //   decimals: 6,
  // },
  // {
  //   symbol: 'USDC',
  //   address: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',
  //   chainID: CHAIN_AURORA,
  //   decimals: 6,
  // },
  // {
  //   symbol: 'LOL',
  //   address: '0x55E3f23B563769426f8Aa4059e9977689c74B47E',
  //   chainID: CHAIN_BSC,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'LOL',
  //   address: '0x55E3f23B563769426f8Aa4059e9977689c74B47E',
  //   chainID: CHAIN_METIS,
  //   decimals: 18,
  // },
  {
    symbol: 'IZI',
    address: '0x9ad37205d608b8b219e6a2573f922094cec5c200',
    chainID: CHAIN_ETHER,
    decimals: 18,
  },
  {
    symbol: 'IZI',
    address: '0x60d01ec2d5e98ac51c8b4cf84dfcce98d527c747',
    chainID: CHAIN_POLYGON,
    decimals: 18,
  },
  // {
  //   symbol: 'ETH',
  //   address: '0xF3A6679B266899042276804930B3bFBaf807F15b',
  //   chainID: CHAIN_ETHER_KOVAN,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'ETH',
  //   address: '0x58586604Bc3206B04c6a38F97A0430CaE0326C56',
  //   chainID: CHAIN_POLYGON_MUMBAI,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'ETH',
  //   address: '0xCE2984f9D4F47c6575dE5dF2c9FCD286A57D8975',
  //   chainID: CHAIN_ARBITRUM_TESTNET,
  //   decimals: 18,
  // },
  {
    symbol: 'ETH',
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    chainID: CHAIN_ETHER,
    decimals: 18,
  },
  {
    symbol: 'ETH',
    address: '0x4200000000000000000000000000000000000006',
    chainID: CHAIN_OPTIMISM,
    decimals: 18,
  },
  {
    symbol: 'ETH',
    address: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    chainID: CHAIN_BSC,
    decimals: 18,
  },
  {
    symbol: 'ETH',
    address: '0xEF71CA2EE68F45B9Ad6F72fbdb33d707b872315C',
    chainID: CHAIN_OK,
    decimals: 18,
  },
  {
    symbol: 'ETH',
    address: '0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1',
    chainID: CHAIN_XDAI,
    decimals: 18,
  },
  // {
  //   symbol: 'ETH',
  //   address: '0x64FF637fB478863B7468bc97D30a5bF3A428a1fD',
  //   chainID: CHAIN_HECO,
  //   decimals: 18,
  // },
  {
    symbol: 'ETH',
    address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    chainID: CHAIN_POLYGON,
    decimals: 18,
  },
  // {
  //   symbol: 'ETH',
  //   address: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
  //   chainID: CHAIN_FANTOM,
  //   decimals: 18,
  // },
  {
    symbol: 'ETH',
    address: '0x420000000000000000000000000000000000000A',
    chainID: CHAIN_METIS,
    decimals: 18,
  },
  {
    symbol: 'ETH',
    address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    chainID: CHAIN_ARBITRUM,
    decimals: 18,
  },
  {
    symbol: 'ETH',
    address: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
    chainID: CHAIN_AVALANCHE,
    decimals: 18,
  },
  // {
  //   symbol: 'ETH',
  //   address: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
  //   chainID: CHAIN_AURORA,
  //   decimals: 18,
  // },
  // {
  //   symbol: 'ETH',
  //   address: '0x6983d1e6def3690c4d616b13597a09e6193ea013',
  //   chainID: CHAIN_HARMONY_0,
  //   decimals: 18,
  // },
  {
    symbol: 'ETH',
    address: '0xf55aF137A98607F7ED2eFEfA4cd2DfE70E4253b1',
    chainID: CHAIN_KCC,
    decimals: 18,
  },
  {
    symbol: 'FIN',
    address: '0x054f76beed60ab6dbeb23502178c52d6c5debe40',
    chainID: CHAIN_ETHER,
    decimals: 18,
  },
  {
    symbol: 'FIN',
    address: '0x576c990a8a3e7217122e9973b2230a3be9678e94',
    chainID: CHAIN_POLYGON,
    decimals: 18,
  },
  {
    symbol: 'FIN',
    address: '0x8d3573f24c0aa3819a2f5b02b2985dd82b487715',
    chainID: CHAIN_OK,
    decimals: 18,
  },
]

export const PRODUCTION_TWOWAY_CHAIN_IDS: number[] = [
  CHAIN_BSC,
  CHAIN_POLYGON,
  CHAIN_ETHER,
  CHAIN_OK,
  // CHAIN_HECO,
  CHAIN_XDAI,
  CHAIN_FANTOM,
  CHAIN_METIS,
  CHAIN_ARBITRUM,
  CHAIN_AVALANCHE,
  CHAIN_HARMONY_0,
  CHAIN_OPTIMISM,
  CHAIN_AURORA,
  CHAIN_KCC,
  CHAIN_BOBA,
  CHAIN_IOTEX,
]

export const TWOWAY_CHAIN_IDS: number[] = [CHAIN_ETHER_KOVAN, CHAIN_POLYGON_MUMBAI, CHAIN_ARBITRUM_TESTNET]

export const TWOWAY_CHEF_ADDRESSES: {[chainID: number]: string} = {
  [CHAIN_POLYGON_MUMBAI]: '0x797051A08a1753b3E054DDC1245AD812DAe9050F',
  [CHAIN_POLYGON]: '0xB225cC2207FECC82806042cEB386eF2b23E2464c',
}

export const BoringFarmName: IBoringFarmName[] = [
  {
    name: 'Manifest Destiny',
    href: '/pledge',
  },
  {
    name: 'Wild Wild West',
    href: '/mint',
  },
  {
    name: 'Satellite City',
    href: '',
  },
]

export const CONFIRM_BLOCK_BUMBER: {[chainID: number]: number} = {
  [CHAIN_ETHER]: 15,
  [CHAIN_BSC]: 30,
  [CHAIN_OK]: 5,
  [CHAIN_HECO]: 10,
  [CHAIN_POLYGON]: 80,
  [CHAIN_XDAI]: 100,
  [CHAIN_FANTOM]: 90,
  [CHAIN_METIS]: 15,
  [CHAIN_ARBITRUM]: 30,
  [CHAIN_AVALANCHE]: 30,
  [CHAIN_HARMONY_0]: 35,
  [CHAIN_OPTIMISM]: 30,
  [CHAIN_AURORA]: 25,
  [CHAIN_KCC]: 321,
  [CHAIN_BOBA]: 10,
  [CHAIN_IOTEX]: 60,
}

export const PEAR_BLOCK_TIME: {[chainID: number]: number} = {
  [CHAIN_ETHER]: 10000,
  [CHAIN_BSC]: 3000,
  [CHAIN_OK]: 3000,
  [CHAIN_HECO]: 3000,
  [CHAIN_POLYGON]: 3000,
  [CHAIN_XDAI]: 3000,
  [CHAIN_FANTOM]: 3000,
  [CHAIN_METIS]: 3000,
  [CHAIN_ARBITRUM]: 3000,
  [CHAIN_ARBITRUM_TESTNET]: 3000,
  [CHAIN_AVALANCHE]: 3000,
  [CHAIN_HARMONY_0]: 3000,
  [CHAIN_OPTIMISM]: 3000,
  [CHAIN_ETHER_KOVAN]: 3000,
  [CHAIN_BSC_TESTNET]: 3000,
  [CHAIN_POLYGON_MUMBAI]: 3000,
  [CHAIN_AURORA]: 3000,
  [CHAIN_KCC]: 3000,
  [CHAIN_IOTEX]: 3000,
}
