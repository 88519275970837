import React, {useEffect, useMemo, useState} from 'react'
import {useWeb3React} from '@web3-react/core'
import {CHAIN_ETHER} from '@w3u/chains'
import {Box, Button, Typography} from '@mui/material'
import styled from '@emotion/styled'
import {useTranslation} from 'react-i18next'
import {BoringInput, Flex, Text} from 'components/Style'
import {getIcon} from 'utils/icons'
import validator from 'multicoin-address-validator'
import OTokenSwitcher from 'components/OTokenSwitcher'
import ConnectWallet from 'components/ConnectWallet'
import {BurnFee, OTOKEN, POW_TOKEN} from 'config/constants/pow_bridge'
import {useTokenBalance, useTokensBalance} from 'hooks/useTokenBalance'
import Balance from 'components/Balance'
import MyButton, {OutlineButton} from 'components/Button'
import {DownarrowIcon} from 'components/Svg'
import {getBalanceNumber} from 'utils/formatBalance'
import BigNumber from 'bignumber.js'
import {BoringTunnel_TOKEN, TOKEN_BORING} from 'config/constants/tunnel'
import {BIG_ZERO} from 'utils/bigNumber'
import {changeChain, formatNumber} from 'utils'
import {usePrice, usePrices} from 'hooks/usePrice'
import {useApprove} from 'hooks/useApprove'
import useBurn from 'hooks/useBurn'
import {NATIVE_BRIDGE_TOKENS} from "../../config/constants/native_bridge";

const BurnWrapper = styled.div`
    background: #00000066;
    padding: 30px;
    border-radius: 15px;
    width: 580px;
    @media screen and (max-width: 600px) {
        width: 100%;
        padding: 20px 15px;
        border-radius: 20px;
    }
`

const BurnBox = styled(Box)`
    background: #00000066;
    border-radius: 12px;
    padding: 17px;
    width: 100%;
`
const BurnBoxBorder = styled(Box)`
    background: #00000066;
    border: 1px solid #0068ff66;
    border-radius: 12px;
    padding: 15px;
    width: 100%;
`

const BoringInfo = styled(Flex)`
    justify-content: space-between;
    background: #0000008c;
    border-radius: 0 0 10px 10px;
    padding: 30px 20px;
    width: 520px;
    @media screen and (max-width: 600px) {
        width: 90%;
        padding: 15px;
    }
`

const Burn = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [token, setToken] = useState<string>('BTC')
    const [toAddress, setToAddress] = useState('')
    const [error, setError] = useState<string | null>('')
    const [value, setValue] = useState('')
    const { chainId, account } = useWeb3React()
    const price = usePrice('BORING')
    const prices = usePrices(POW_TOKEN)
    const { onBurn } = useBurn(token)
    const boringAddress = TOKEN_BORING[CHAIN_ETHER]
    const { balance: boringBalance } = useTokenBalance(boringAddress)
    const tunnelAddress = useMemo(() => BoringTunnel_TOKEN(token)[CHAIN_ETHER], [token])
    const oTokens = useMemo(() => POW_TOKEN.map(d => ({address: OTOKEN(d)[CHAIN_ETHER], symbol: d})), [])
    const { balances, fetchBalances } = useTokensBalance(oTokens.map(d => ({address: d.address, symbol: d.symbol})), CHAIN_ETHER)
    const oToken = useMemo(() => oTokens.find(f => f.symbol === token) ?? oTokens[0], [oTokens, token])
    const balance = useMemo(() => {
        if (oToken && balances.length) {
            const bl = balances.find(f => f.address.toLocaleLowerCase() === oToken?.address.toLocaleLowerCase())
            return new BigNumber(bl?.balance ?? 0)
        }
        return new BigNumber(0)
    }, [balances, oToken])
    const oTokenPrice = useMemo(() => prices ? prices[token] : '0', [prices, token])
    const { allowance, onApprove, fetchApprove } = useApprove(boringAddress, tunnelAddress, CHAIN_ETHER)
    const boringFee = useMemo(() => {
        return price ? new BigNumber(oTokenPrice).div(price)
                .multipliedBy('0.002')
                .multipliedBy(value).toNumber() : 0.00
    }, [oTokenPrice, price, value])
    const handleChange = (t: string) => setToken(t.substring(1))
    const yourGet = useMemo(() => {
        return Number(value) > 0 ? new BigNumber(value).minus(BurnFee) : BIG_ZERO
    }, [value])
    useEffect(() => {
        if (value === '') return setError('')
        const balanceN = new BigNumber(balance.toString())
        // POW BTC LTC DOGE decimal 18
        const amountN = new BigNumber(value).multipliedBy(10 ** 18)
        const boringFeeN = new BigNumber(boringFee).multipliedBy(10 ** 18)
        if (balanceN.lte(0)) {
            setError(t('balance_is_0'))
            return
        }

        if (amountN.gt(balanceN)) {
            setError(t('balance_is_insufficient'))
            return
        }

        if (boringFeeN.gt(boringBalance)) {
            setError(t('insufficient_balance_of_fee'))
            return
        }

        setError('')
    }, [balance, value])
    const canBurn = useMemo(() => !!toAddress && new BigNumber(value ?? 0).gt(0) && !error, [toAddress, value])
    const handleMax = () => setValue(new BigNumber(balance).dividedBy(Math.pow(10, 18)).toString())
    const handleBurn = async () => {
        try {
            if (!validator.validate(toAddress, token)) {
                setError(`Invalid ${token} address`)
                return
            }
            setLoading(true)
            await onBurn(oToken.symbol, toAddress, value)
            fetchBalances()
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    const handleApprove = async () => {
        try {
            setLoading(true)
            await onApprove()
            fetchApprove()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    const renderAction = () => {
        return (
            <>
                {allowance ?
                    <MyButton loading={loading} disabled={!canBurn} fullWidth onClick={handleBurn}>
                        {!error ? t('burn') :
                            <Text fontSize="18px" color="#ff0000">{error}</Text>
                        }
                    </MyButton> :
                    <MyButton loading={loading} fullWidth onClick={handleApprove}>{t('approve')}</MyButton>
                }
            </>
        )
    }
    return (
        <Flex flexDirection="column" alignItems="center" width="100%">
            <BurnWrapper >
                <Typography mb="15px">{t('burn')}</Typography>
                <BurnBoxBorder sx={{mb: '20px', xs: '15px'}}>
                    <Text mb="10px" fontSize="12px">{t('send')}</Text>
                    <BoringInput style={{height: '20px'}} value={toAddress} onChange={e => setToAddress(e.target.value)} placeholder={t('enter_your_receive_address')} />
                </BurnBoxBorder>
                <BurnBoxBorder  sx={{mb: {md: '0', xs: '15px'}}}>
                    <Flex justifyContent="space-between" mb="10px">
                        <Text fontSize="12px">{t('burn')}</Text>
                        <Balance prefix={`${t('balance')}: `} value={getBalanceNumber(balance)} decimals={4} />
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Flex alignItems="center">
                            <OTokenSwitcher width={20} isOToken border={false} onChange={handleChange} />
                            <BoringInput style={{height: '20px'}} value={value} onChange={e => setValue(e.target.value)} />
                        </Flex>
                        <OutlineButton onClick={handleMax}>{t('max')}</OutlineButton>
                    </Flex>
                </BurnBoxBorder>
                <Flex sx={(theme) => ({
                        p: "10px 0",
                        [theme.breakpoints.down('md')]: {
                            display: 'none'
                        }
                    })}justifyContent="center">
                    <DownarrowIcon />
                </Flex>
                <BurnBox mb="15px">
                    <Typography mb="8px" fontSize="12px">{t('receive')}</Typography>
                    <Flex alignItems="center">
                        <img style={{marginRight: '12px'}} src={getIcon(token)} width="20px" height="20px" />
                        <Text>{formatNumber(yourGet.toFixed(4), 4)}</Text>
                    </Flex>
                </BurnBox>
                <Flex justifyContent="center">
                    <Box sx={{width: {md: '60%', xs: '100%'}}}>
                        {account ? chainId === CHAIN_ETHER ? renderAction() :
                            <Button onClick={() => changeChain(CHAIN_ETHER)} fullWidth size='large' variant="outlined">
                                {t('switch_to_ethereum')}
                            </Button> :
                            <ConnectWallet />
                        }
                    </Box>
                </Flex>
            </BurnWrapper>
            <BoringInfo>
                <Flex>
                    <Typography mr="10px">{t('Fee')}</Typography>
                    {/* <Flex alignItems="center">
                        <img src={getIcon('BOR')} width="20px" height="20px" alt="BOR" />
                        <Typography ml="8px">{t('BORING')}</Typography>
                    </Flex> */}
                </Flex>
                <Balance fontSize='1rem' value={boringFee} unit=" BORING" />
            </BoringInfo>
        </Flex>
    )
}

export default Burn
