import { useEffect, useState, useMemo } from 'react'
import { MenuItem } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import {getIcon} from 'utils/icons'
import { BoringSelect, BoringSelectBorder, Text } from 'components/Style'
import { POW_TOKEN } from 'config/constants/pow_bridge'

const TokenSwitcher = ({
  isOToken,
  fullWidth,
  border = true,
  width = 28,
  onChange
}: {
  isOToken?: boolean
  fullWidth?: boolean
  border?: boolean
  width?: number
  onChange: (tokenName: string) => void
}) => {
  const [tokenName, setTokenName] = useState<string>(isOToken ? 'oBTC' : 'BTC')
  const switchToken = (e: SelectChangeEvent<string>) => {
    const address = e.target.value
    setTokenName(address)
    onChange(address)
  }
  const tokens = isOToken ? POW_TOKEN.map(d => `o${d}`) : POW_TOKEN

  return (
    <Select
      style={{fontSize: '13px'}}
      sx={{
          mr: {md: 3, xs: 0},
          borderRadius: '100px',
          height: {md: '32px', xs: '15px'},
          width: fullWidth ? '100%' : 'auto'
        }}
      IconComponent={ExpandMoreOutlinedIcon}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        elevation: 1,
      }}
      onChange={switchToken}
      value={tokenName}
      input={border ? <BoringSelectBorder /> : <BoringSelect />}
    >
      {tokens.map((token, i) => (
        <MenuItem key={i} value={token}>
          <img src={getIcon(token)} width={width} alt={token} />
          <Text>{token}</Text>
        </MenuItem>
      ))}
    </Select>
  )
}

export default TokenSwitcher
