import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const Oportal:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 16 12">
<           path fill={color} fillRule="evenodd" clipRule="evenodd" d="M16 4.28571V3.08571C15.3714 2.85714 14.8 2.57143 14.2857 2.22857V0H13.1429V2.22857C11.7714 3.14286 9.94286 3.71429 8 3.71429C6.05714 3.71429 4.22857 3.14286 2.85714 2.22857V0H1.71429V2.22857C1.2 2.57143 0.628571 2.85714 0 3.08571V4.28571C0.628571 4.05714 1.2 3.82857 1.71429 3.54286V6.57143H0V7.71429H1.71429V10.5714H2.85714V7.71429H13.1429V10.5714H14.2857V7.71429H16V6.57143H14.2857V3.54286C14.8 3.82857 15.3714 4.11429 16 4.28571ZM4.57143 6.57143H2.85714V3.54286C3.37143 3.82857 3.94286 4.11429 4.57143 4.28571V6.57143ZM5.71429 6.57143H7.42857V4.85714C6.85714 4.8 6.28571 4.74286 5.71429 4.62857V6.57143ZM10.2857 6.57143H8.57143V4.85714C9.14286 4.8 9.71429 4.74286 10.2857 4.62857V6.57143ZM11.4286 6.57143H13.1429V3.54286C12.6286 3.82857 12.0571 4.05714 11.4286 4.28571V6.57143Z"/>
        </SvgIcon>
    )
}

export default Oportal
