import { useMemo, useState } from 'react'
import { Flex, Text } from 'components/Style'
import Skeleton from '@mui/material/Skeleton'
import styled from '@emotion/styled'
import { getChainIcon, getIcon } from 'utils/icons'
import { Chains } from '@w3u/chains'
// import {ellipseAddress} from '@w3u/dapp'
import { useTranslation } from 'react-i18next'
// import moment from 'moment'
import { displayBalance } from 'utils/formatBalance'
import { useTwowayOTokens, useTwowayLockBalances } from 'hooks/useTwoway'
// import { useAccountRecord } from 'hooks/useAccountRecord'
// import { getTwowayTokenSymbolByAddress } from 'utils'
import FilterOToken from './FilterOtoken'


const Grid = styled.div<{columns: string}>`
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: ${({ columns }) => columns };
    grid-row-gap: 20px;
`

const GridHead = styled(Grid)`
    padding: 10px 0;
`

const GridList = styled(Grid)`
    padding: 30px 0;
    border-bottom: 1px solid #000000;
    /* &:not(:last-child) {
        margin-bottom: 20px;
    } */
`

const DataWrapper = styled.div`
    width: 100%;
    background: #00000066;
    border-radius: 30px;
    padding: 30px;
    margin-bottom: 20px;
`

const FarmBox = styled.div`
    flex: 1 1 auto;
    border-radius: 0 12px 12px 12px;
    border-radius: 30px;
`
const SkeletonList = () => {
    return (
        <>
            {[1, 2, 3].map(d => (
                <GridList key={d} columns='repeat(2, 3fr) 1fr'>
                    <Skeleton variant="text" width={160} height={40} />
                    <Skeleton variant="text" width={160} height={40} />
                    <Skeleton variant="text" width={160} height={40} />
                </GridList>
            ))}
        </>
    )
}
const Data = () => {
    const { t } = useTranslation()
    // const { records } = useAccountRecord()
    // console.log("records", records)
    const { lockBalances } = useTwowayLockBalances()
    const oTokens = useTwowayOTokens()
    const [symbol, setSymbol] = useState<string>(oTokens[0].symbol)
    const otoken = useMemo(() => {
        return oTokens.find(f => f.symbol === symbol) ?? oTokens[0]
    }, [oTokens])
    const filterList = useMemo(() => {
        return lockBalances.find(f => f.tokenName === symbol)
    }, [lockBalances, symbol])
    return (
        <Flex width="100%" flexDirection="column">
            <DataWrapper>
                <Flex mb="30px" alignContent="center">
                    <Text fontSize="18px">{t('Total liquidity')}</Text>
                </Flex>
                <GridHead columns='repeat(2, 3fr) 1fr'>
                    <Flex alignItems="center">
                        <Text>{t('To')}</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Text>{t('Token')}</Text>
                        <FilterOToken otokens={oTokens} onConfirm={symbol => setSymbol(symbol)} />
                    </Flex>
                    <Flex alignItems="center">
                        <Text>{t('Liqidity')}</Text>
                        {/* <FilterAltIcon /> */}
                    </Flex>
                </GridHead>
                <FarmBox>
                    {filterList ? filterList?.balances.map(d => (
                        <GridList key={`${d.chainID}-${symbol}`} columns='repeat(2, 3fr) 1fr'>
                            <Flex alignItems="center">
                                <img src={getChainIcon(d.chainID)} width="20px" height="20px" alt={Chains[d.chainID].displayName} />
                                <Text ml="10px">{Chains[d.chainID].displayName}</Text>
                            </Flex>
                            <Flex alignItems="center">
                                <img src={getIcon(symbol)} width="20px" height="20px" alt={symbol} />
                                <Text ml="10px">{symbol}</Text>
                            </Flex>
                            <Flex alignItems="center"><Text>{displayBalance(d.balance, otoken.decimals)}</Text></Flex>
                        </GridList>
                    )) : <SkeletonList />
                }
                </FarmBox>
            </DataWrapper>
            {/* <DataWrapper>
                <Text mb="30px" fontSize="18px">{t('交易记录')}</Text>
                <GridHead columns='repeat(7, 1fr) 1.5fr'>
                    <Flex alignItems="center">
                        <Text>Name</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Text>Token</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Text>From Chain</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Text>To Chain</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Text>From</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Text>To</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Text>Amount</Text>
                    </Flex>
                    <Flex justifyContent="flex-end">
                        <Text>Time</Text>
                    </Flex>
                </GridHead>
                <FarmBox>
                    {records.map(d => (
                        <GridList columns='repeat(7, 1fr) 1.5fr'>
                            <Flex><Text>{t('Cross Chain')}</Text></Flex>
                            <Flex alignItems="center">
                                <img src={getIcon(getTwowayTokenSymbolByAddress(d.fromToken))} width="20px" height="20px" alt={d.optName} />
                                <Text ml="10px">{getTwowayTokenSymbolByAddress(d.fromToken)}</Text>
                            </Flex>
                            <Flex alignItems="center">
                                <img src={getChainIcon(Number(d?.fromChainId))} width="20px" height="20px" alt={Chains[Number(d?.fromChainId)].displayName} />
                                <Text ml="10px">{Chains[Number(d?.fromChainId)].displayName}</Text>
                            </Flex>
                            <Flex alignItems="center">
                                <img src={getChainIcon(Number(d?.toChainId))} width="20px" height="20px" alt={Chains[Number(d?.toChainId)].displayName} />
                                <Text ml="10px">{Chains[Number(d?.toChainId)].displayName}</Text>
                            </Flex>
                            <Flex alignItems="center">
                                <Text ml="10px">{ellipseAddress(d?.from)}</Text>
                            </Flex>
                            <Flex alignItems="center">
                                <Text ml="10px">{ellipseAddress(d?.to)}</Text>
                            </Flex>
                            <Flex><Text>{d.amount}</Text></Flex>
                            <Flex justifyContent="flex-end"><Text>{moment(d.timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')}</Text></Flex>
                        </GridList>
                    ))}
                </FarmBox>
            </DataWrapper> */}
        </Flex>
    )
}

export default Data
