import { useWeb3React } from "@web3-react/core"
import { useCallback, useEffect, useState } from "react"
import { setupNetwork } from 'utils/network'

export const useCheckSupportChain = (supportChainId: number) => {
    const changeChain = useCallback(async () => {
        if (supportChainId) {
            await setupNetwork(supportChainId)
        }
    }, [supportChainId])
    useEffect(() => {
        changeChain()
    }, [changeChain, supportChainId])
}

const useCheckFromChainId = (chainID: string | number | undefined | null) => {
    const { chainId } = useWeb3React()
    const changeChain = useCallback(async () => {
        if (!chainID || chainID === chainId) {
            return null
        }
        // if (chainId && chainID && Number(chainID) !== chainId) {
        //     await setupNetwork(Number(chainID))
        //     // window.location.reload()
        // }
    }, [chainId, chainID])
    useEffect(() => {
        changeChain()
    }, [changeChain, chainID])

    // return { operate }
}

export default useCheckFromChainId