import React, { useMemo, useState } from 'react'
import { Flex, Text, BoringInput } from 'components/Style'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { getIcon } from 'utils/icons'
import { useNetworkFee } from 'hooks/useBook'
import BigNumber from 'bignumber.js'
import { displayBalance } from 'utils/formatBalance'
import { usePrices } from 'hooks/usePrice'

const StyledInput = styled(BoringInput)`
    flex: 1 1 100%;
    height: 44px;
    padding: 12px;
    border: 1px solid #0068FF;
    background-color: #000000;
`
const AmountBox = styled.div`
    flex: 1 1 100%;
    height: 44px;
    background-color: #000000;
    padding: 12px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
`

interface Props {
    oToken: string
    onNext?: (step: number) => void
}
const GuideCalculator:React.FC<Props> = ({ oToken }) => {
    const { t } = useTranslation()
    const [value, setValue] = useState('0')
    const prices = usePrices(['BTC', 'LTC', 'DOGE'])
    const { networkFee } = useNetworkFee()
    const tokenName = useMemo(() => oToken.slice(1), [oToken])
    const oTokenName = oToken
    const yourGet = useMemo(() => {
        const valueBN = new BigNumber(value).multipliedBy(Math.pow(10, 18))
        const fee = networkFee[tokenName]
        return valueBN.gt(0) && fee ? valueBN.multipliedBy(new BigNumber('0.998')).minus(new BigNumber(fee)).toString() : '0'
    }, [networkFee, tokenName, value])
    const getValuePrice = useMemo(() => {
        if (prices && tokenName) {
            const yourGetAccount = new BigNumber(yourGet).div(Math.pow(10, 18))
            return value ? displayBalance(yourGetAccount.times(prices[tokenName]).toString()) : '0.00'
        }
        return '0.00'
    }, [prices, tokenName, value])

    return (
        <Box>
           <Flex mb="34px" alignItems="center">
                <Flex alignItems="center" p="8px 0" minWidth="120px">
                    <img width="28px" height="28px" src={getIcon(tokenName)} alt={tokenName} />
                    <Text ml="20px">{tokenName}</Text>
                </Flex>
                <StyledInput value={value} onChange={e => setValue(e.target.value)} placeholder="0.00" />
            </Flex>
            <Flex mb="34px" alignItems="center">
                <Flex alignItems="center" p="8px 0" minWidth="120px">
                    <img width="28px" height="28px" src={getIcon(oTokenName)} alt={oTokenName} />
                    <Text ml="20px">{oTokenName}</Text>
                </Flex>
                <AmountBox>
                    <Text>{displayBalance(yourGet)}</Text>
                    <Text>≈ $ {getValuePrice}</Text>
                </AmountBox>
            </Flex>
            {/* <Flex mb="34px" alignItems="center">
                <Flex alignItems="center" p="7px 12px" minWidth="137px">
                    <img width="28px" height="28px" src={getIcon('BOR')} alt="BOR" />
                    <Text ml="20px">BOR</Text>
                </Flex>
                <AmountBox>
                    <Text>0.0000</Text>
                    <Text>≈ $ 0.00</Text>
                </AmountBox>
            </Flex> */}
            {/* <TagBoxGrid>
                <TagBox>
                    <img width="28px" height="28px" src={getImage('tag')} alt="Tag" />
                    <Flex ml="12px" flexDirection="column">
                        <Text>{t('Minting fee: 0.2%.')}</Text>
                        <Text>{t('Network fee: around 0.3% (required by the multi-signers for the transaction fee of oBTC)')}</Text>
                    </Flex>
                </TagBox>
                <TagBox>
                    <img width="28px" height="28px" src={getImage('tag')} alt="Tag" />
                    <Flex ml="12px" flexDirection="column">
                        <Text>{t('The calculated result is for estimation only. Actual cost will be subject to the casting time.')}</Text>
                    </Flex>
                </TagBox>
            </TagBoxGrid> */}
        </Box>
    )
}

export default GuideCalculator
