import React, {useEffect, useState, useContext} from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CHAIN_POLYGON, Chains } from '@w3u/chains'
import styled from '@emotion/styled'
import { Flex, Text } from 'components/Style'
import useRefresh from 'hooks/useRefresh' 
// import { getScanTxLink } from 'utils'
import { getTwowaySteps } from 'utils/api'
import { TransactionContext } from 'contexts/TransactionContext'
import { TransactionAllData, BridgeType } from 'modal/types'
import { ViewIcon } from 'components/Svg'
// import Arrow from 'assets/images/arrow.svg'
import Right from 'assets/images/right.svg'
import Time from 'assets/images/time.svg'
import Wrong from 'assets/images/wrong.svg'

const ViewBox = styled.div`
  margin-left: 8px;
  > svg {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
`

const AddLiquidityNotice = ({
  fromID,
  txHash,
  amount,
  symbol,
  tokenAddress,
  receivedAmount,
}: {
  fromID: number
  txHash: string
  amount: string
  symbol: string
  tokenAddress: string
  receivedAmount: string
}) => {
  const { t } = useTranslation()
  const { targetOpen, changeTxHash, updateData } = useContext(TransactionContext)
  const [firstOpen, setFirstOpen] = useState(true)
  const [status, setStatus] = useState(0)
  const [secondHash, setSecondHash] = useState('')
  const { fastRefresh } = useRefresh()

  const handleViewDetail = () => {
    if (txHash) {
      changeTxHash && changeTxHash(txHash, true)
      targetOpen && targetOpen()
    }
  }

  const fetchSteps = async () => {
    const res = await getTwowaySteps({tx_id: txHash})
    const data = res.data
    if (txHash && data) {
      const events = data.events
      let history:any[] = []
      if (fromID === CHAIN_POLYGON) {
        if (events.length === 0) {
          const step1 = {
            transaction: txHash,
            event: 'Deposited',
            event_chain_id: fromID,
            event_chain_name: Chains[fromID].displayName,
            from: '',
            from_chain_id: fromID,
            from_chain_name: Chains[fromID].displayName,
            from_token_address: tokenAddress,
            to: '',
            to_chain_id: fromID,
            to_chain_name: Chains[fromID].displayName,
            to_token_address: '',
            amount,
            block_time: '',
            status: 0,
            tx_id: txHash,
            step: 1,
            first_step_transaction: txHash,
            bridgeType: BridgeType.oportal
          }
          history =[step1]
        } else if (events.length === 1) {
          history = [{...events[0],  bridgeType: BridgeType.oportal}]
        }
      } else {
        if (events.length === 0) {
          const step1 = {
            transaction: txHash,
            event: 'Deposited',
            event_chain_id: fromID,
            event_chain_name: Chains[fromID].displayName,
            from: '',
            from_chain_id: fromID,
            from_chain_name: Chains[fromID].displayName,
            from_token_address: tokenAddress,
            to: '',
            to_chain_id: CHAIN_POLYGON,
            to_chain_name: Chains[CHAIN_POLYGON].displayName,
            to_token_address: '',
            amount,
            block_time: '',
            status: 0,
            tx_id: txHash,
            step: 1,
            first_step_transaction: txHash,
            bridgeType: BridgeType.oportal
          }
          const step2 = {
            ...step1,
            transaction: '',
            event: 'Issued',
            event_chain_id: CHAIN_POLYGON,
            event_chain_name: Chains[CHAIN_POLYGON].displayName,
            tx_id: '',
            status: 0,
            step: 2,
            bridgeType: BridgeType.oportal
          }
          history = [step1, step2]
        } else if (events.length === 1) {
          const step2 = {
            ...events[0],
            transaction: '',
            event: 'Issued',
            event_chain_id: CHAIN_POLYGON,
            event_chain_name: Chains[CHAIN_POLYGON].displayName,
            status: 0,
            step: 2,
            bridgeType: BridgeType.oportal
          }
          history = [{ ...events[0], bridgeType: BridgeType.oportal }, step2]
        } else if (events.length === 2) {
          history = [{...events[0], bridgeType: BridgeType.oportal}, {...events[1], bridgeType: BridgeType.oportal}]
        }
      }
      const tsd: TransactionAllData = {
        [txHash]: history.sort((a, b) => a.step - b.step)
      }
      setFirstOpen(false)
      updateData && updateData(tsd)
      if (firstOpen) {
        changeTxHash && changeTxHash(txHash, true)
        targetOpen && targetOpen()
      }
    }
    if (data.total_steps !== 0 && data.events.length === data.total_steps) {
      setStatus(1)
      setSecondHash(data.events[1].transaction)
    }
  }
  
  useEffect(() => {
    fetchSteps()
  }, [fastRefresh])

  return (
    <>
      <Flex>
        {/* <CrossNoticeEdge
          chainID={fromID}
          amount={amount}
          symbol={symbol}
          href={getScanTxLink(fromID, txHash)}
          desc={`From ${Chains[fromID].displayName}`}
        /> */}
        <Text>{t('Add Liquidity', {amount: `${receivedAmount} ${symbol}`})}</Text>
        <Box ml="10px">
          {status === 0 && <img src={Time} alt='Loading' />}
          {status === 1 && <img src={Right} alt='Done' />}
          {status === 2 && <img src={Wrong} alt='Wrong' />}
        </Box>
        {/* <CrossNoticeStatus>
          <img src={Arrow} alt='arrow' />
        </CrossNoticeStatus> */}
        {/* <CrossNoticeEdge
          chainID={CHAIN_POLYGON}
          amount={receivedAmount}
          symbol={`o${symbol}`}
          href={getScanTxLink(CHAIN_POLYGON, secondHash)}
          desc={'On Polygon'}
        /> */}
      </Flex>
      <Flex alignItems="center" mt="8px" onClick={handleViewDetail}>
        <Text color="#0068FF">{t('View detail')}</Text>
        <ViewBox><ViewIcon color="#0068FF" /></ViewBox>
      </Flex>
    </>
  )
}

export default AddLiquidityNotice
