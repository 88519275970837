import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const TagIcon:React.FC<IconProps> = ({  color= "#ffffff" }) => {
    return (
        <SvgIcon viewBox="0 0 40 40">
            <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M0 19.9999C0 31.0231 8.95422 39.9998 20.0001 39.9998C31.0231 39.9998 40 31.0456 40 19.9999C40 8.97665 31.0456 0 20.0001 0C8.97678 0 0 8.95409 0 19.9999ZM9.19292 17.1408C9.1649 18.6885 10.0404 20.7565 11.1101 21.8261L19.6318 30.3478C20.7166 31.4326 22.4311 31.4219 23.4952 30.3577L30.4196 23.4333C31.4647 22.3882 31.4796 20.639 30.4101 19.5694L21.8884 11.0479C20.8035 9.96309 18.742 9.11925 17.2499 9.12918C17.2499 9.12918 11.9559 8.91084 10.4645 10.4023C8.97305 11.8937 9.19292 17.1408 9.19292 17.1408ZM13.6461 17.999C12.4269 16.78 12.4269 14.8032 13.6461 13.584C13.8058 13.4245 13.9783 13.2857 14.1605 13.1679C15.3699 12.3856 17.0016 12.5245 18.0611 13.584C19.2803 14.8031 19.2803 16.7799 18.0611 17.999C16.8421 19.2182 14.8653 19.218 13.6461 17.999ZM16.707 16.6449C17.1781 16.1736 17.1781 15.4095 16.707 14.9384C16.2357 14.467 15.4716 14.467 15.0005 14.9384C14.5292 15.4096 14.5292 16.1737 15.0005 16.6449C15.4717 17.1161 16.2358 17.1161 16.707 16.6449ZM20.5163 12.8078C19.836 12.1274 18.3506 11.5149 17.3295 11.5197V11.5197H17.3297C17.1996 11.5143 16.9477 11.509 16.6083 11.511C16.0393 11.5141 15.4497 11.5376 14.8773 11.5874C14.2948 11.6384 13.7655 11.714 13.3156 11.8147C12.726 11.9467 12.3427 12.1114 12.2579 12.1959C12.1737 12.2802 12.0118 12.6531 11.8807 13.2278C11.7782 13.6775 11.7015 14.2085 11.6502 14.7939C11.6003 15.3611 11.5769 15.9448 11.574 16.5084C11.5722 16.8443 11.5776 17.0936 11.5842 17.3306C11.5672 18.2769 12.1843 19.7689 12.8697 20.4547L20.9447 28.5298C21.2589 28.8436 21.7341 28.8436 22.0391 28.5389L28.6009 21.9772C28.8885 21.6891 28.8894 21.1808 28.5914 20.8828L20.5163 12.8078ZM20 36.857C29.2968 36.857 36.8572 29.3026 36.8572 19.9998C36.8572 10.703 29.3026 3.14276 20 3.14276C10.7032 3.14276 3.14296 10.6971 3.14296 19.9998C3.14296 29.2964 10.6973 36.857 20 36.857Z"/>
        </SvgIcon>
    )
}

export default TagIcon