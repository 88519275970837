import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const CopyIcon:React.FC<IconProps> = ({ color = "#ffffff" }) => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path fill={color} d="M2.39857 0V1.47971H7.47017L0 8.94988L1.05012 10L8.52029 2.52983V7.60143H10V0H2.39857Z" />
        </SvgIcon>
    )
}

export default CopyIcon
