import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const OTunnel:React.FC<IconProps> = ({ color = '#ffffff'}) => {
    return (
        <SvgIcon viewBox="0 0 16 16">
            <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 5.89499 0.813008 3.97987 2.14215 2.5515V8.2861C2.14215 10.0975 3.18029 11.5585 3.32069 11.7561C3.33112 11.7708 3.3366 11.7785 3.33632 11.7789C5.01712 13.9936 7.45419 14.0959 8.09453 14.0959C8.73487 14.0959 9.63523 13.8612 9.63384 13.8594C11.9759 13.0945 12.8127 11.6509 13.2176 10.9524C13.2355 10.9215 13.2526 10.892 13.2689 10.8641C13.654 10.2065 13.9107 9.09518 13.9107 7.98655C13.9107 6.87792 13.1831 4.71331 11.6213 3.54707C10.58 2.76957 9.44448 2.36912 8.2146 2.34569H6.69379V3.26765C7.707 3.27286 8.21394 3.27286 8.2146 3.26765C9.97475 3.34827 11.3513 4.17478 12.3442 5.74718C13.1942 7.35991 13.2263 8.93022 12.4405 10.4581C11.2618 12.7499 9.04838 13.1714 8.01449 13.1714C6.98061 13.1714 5.4844 12.7234 4.34696 11.5217C3.58867 10.7206 3.16566 9.64204 3.07793 8.2861V1.69295C3.4932 1.36841 3.94134 1.08399 4.41653 0.845514V8.26142C4.44675 8.93714 4.60917 9.53279 4.90379 10.0484C5.34571 10.8218 6.31462 11.7823 8.07405 11.8206C9.247 11.8462 10.2275 11.3058 11.0157 10.1995C11.4118 9.56341 11.6099 8.91738 11.6099 8.26142C11.6099 7.27747 11.1315 6.32903 10.665 5.84093C10.1986 5.35282 9.34943 4.67628 8.07405 4.67628H6.66967V5.59447H8.07405C8.69269 5.61887 9.20804 5.80222 9.6201 6.14452C10.2382 6.65796 10.665 7.35597 10.665 8.26142C10.665 8.86505 10.4263 9.4607 9.94877 10.0484C9.351 10.6126 8.72609 10.8947 8.07405 10.8947C7.09598 10.8947 5.43436 10.2781 5.34571 8.26142V0.450852C6.17629 0.158829 7.0696 0 8 0C12.4183 0 16 3.58172 16 8ZM9.46777 8.1719C9.46777 8.94601 8.84023 9.57355 8.06612 9.57355C7.34565 9.57355 6.75215 9.02998 6.67335 8.33058H6.66446V8.1719V6.79669H7.79375C7.88185 6.77934 7.97292 6.77025 8.06612 6.77025C8.84023 6.77025 9.46777 7.39779 9.46777 8.1719Z"/>
        </SvgIcon>
    )
}

export default OTunnel
