import BigNumber from 'bignumber.js'
import { TWOWAY_TOKENS } from 'config/constants/twoway'
import { PEG_TOKENS } from 'config/constants/pegToken'
import { PegToken } from 'modal/token'
import {
  CHAIN_ARBITRUM,
  CHAIN_ARBITRUM_TESTNET,
  CHAIN_AURORA,
  CHAIN_AURORA_TESTNET,
  CHAIN_AVALANCHE,
  CHAIN_BOBA,
  CHAIN_BSC,
  CHAIN_BSC_TESTNET,
  CHAIN_ETHER,
  CHAIN_ETHER_KOVAN,
  CHAIN_FANTOM,
  CHAIN_HARMONY_0,
  CHAIN_HARMONY_0_TESTNET,
  CHAIN_HECO,
  CHAIN_KCC,
  CHAIN_METIS,
  CHAIN_OASIS,
  CHAIN_OK,
  CHAIN_OK_TESTNET,
  CHAIN_OPTIMISM,
  CHAIN_POLYGON,
  CHAIN_POLYGON_MUMBAI,
  CHAIN_XDAI,
} from '@w3u/chains'
import { setupNetwork } from './network'
import {useMemo} from "react";
import {CHAIN_BTC, CHAIN_DOGE, CHAIN_LTC} from "../config/constants/origin";
import {NATIVE_BRIDGE_TOKENS} from "../config/constants/native_bridge";

export const isProduction = (): boolean => process.env.REACT_APP_NODE_ENV === 'production'

export const getTwowayFeeStatus = (amount: string) => {
    const amountN = new BigNumber(amount)
    if (amountN.gt(500000 * 10 ** 18)) {
        return ['high', '0.05%']
    }

    if (amountN.lt(100000 * 10 ** 18)) {
        return ['low', '1%']
    }

    return ['sufficient', '0.3%']
}

export const get2WayTokenFixed = (tokenName: string | undefined): number => {
  switch (tokenName) {
    case 'ETH':
      return 4
    case 'oETH':
      return 4
    default:
      return 2
  }
}

const twowayLimit: { [name: string]: { [index: number]: string } } = {
    USDT: {
        0: '500k',
        1: '100k',
    },
    USDC: {
        0: '500k',
        1: '100k',
    },
    IZI: {
        0: '500k',
        1: '100k',
    },
    ETH: {
        0: '200',
        1: '40',
    },
    METIS: {
        0: '3000',
        1: '500',
    },
}

export const getTwoWayLimit = (tokenName: string | undefined, index: number) => {
  if (index === 0 || index === 1) {
    try {
      return twowayLimit[tokenName ?? 'USDT'][index]
    } catch (error) {
      return ''
    }
  }
  return '500k'
}

export const getTwowayTokenSymbolByAddress = (address: string): string | undefined => {
    const token = TWOWAY_TOKENS.find((token) => token.address === address)
    return token?.symbol
}

export const getPegToken = (chainID: number): PegToken | undefined => {
    return PEG_TOKENS.find((t) => t.chainID === chainID)
}
export const getSmallAddress = (address: string) => {
    return `${address.substring(0, 4)}...${address.substring(address.length - 4)}`
}

export const getScanTxLink = (id: number, txHash: string) => {
  switch (id) {
    case CHAIN_ETHER:
      return 'https://etherscan.io/tx/' + txHash
    case CHAIN_ETHER_KOVAN:
      return 'https://kovan.etherscan.io/tx/' + txHash
    case CHAIN_BSC:
      return 'https://bscscan.com/tx/' + txHash
    case CHAIN_OK:
      return 'https://www.oklink.com/en/oec/tx/' + txHash
    case CHAIN_POLYGON:
      return 'https://polygonscan.com/tx/' + txHash
    case CHAIN_POLYGON_MUMBAI:
      return 'https://explorer-mumbai.maticvigil.com/tx/' + txHash
    case CHAIN_HECO:
      return 'https://hecoinfo.com/tx/' + txHash
    case CHAIN_HARMONY_0:
      return 'https://explorer.harmony.one/tx/' + txHash
    case CHAIN_HARMONY_0_TESTNET:
      return 'https://explorer.pops.one/tx/' + txHash
    case CHAIN_AVALANCHE:
      return 'https://snowtrace.io/tx/' + txHash
    case CHAIN_FANTOM:
      return 'https://ftmscan.com/tx/' + txHash
    case CHAIN_XDAI:
      return 'https://blockscout.com/xdai/mainnet/tx/' + txHash
    case CHAIN_ARBITRUM:
      return 'https://arbiscan.io/tx/' + txHash
    case CHAIN_OPTIMISM:
      return 'https://optimistic.etherscan.io/tx/' + txHash
    case CHAIN_BOBA:
      return 'https://blockexplorer.boba.network/tx/' + txHash
    case CHAIN_METIS:
      return 'https://andromeda-explorer.metis.io/tx/' + txHash
    case CHAIN_OASIS:
      return 'https://explorer.emerald.oasis.dev/tx/' + txHash
    case CHAIN_AURORA:
      return 'https://explorer.mainnet.aurora.dev/tx/' + txHash
    case CHAIN_AURORA_TESTNET:
      return 'https://explorer.testnet.aurora.dev/tx/' + txHash
    case CHAIN_KCC:
      return 'https://explorer.kcc.io/en/tx/' + txHash
    default:
      return 'https://etherscan.io/tx/' + txHash
  }
}

export const changeChain = async (chainId: number) => {
  await setupNetwork(chainId)
    // .then(() => window.location.reload())
    .catch((e: any) => console.error('Setup network: ', e))
}

export const formatNumber = (num: string, fixed?: number) => {
  let _num = num
  if (!num || num === 'NaN') {
    _num = '0'
  }
  return new BigNumber(_num).toFormat(fixed !== undefined ? fixed : 2, BigNumber.ROUND_DOWN)
}

export const isOriginToken = (symbol: string): boolean => {
    return symbol === 'BTC' || symbol === 'LTC' || symbol === 'DOGE';
}

export const isOriginChainId = (chainId: number): boolean => {
    return chainId === CHAIN_BTC || chainId === CHAIN_LTC || chainId === CHAIN_DOGE;

}

export const isOOriginToken = (symbol: string): boolean => {
    return symbol === 'oBTC' || symbol === 'oLTC' || symbol === 'oDOGE'
}

export const getOriginChainId = (symbol: string): number[] => {
    if (symbol === 'oBTC') return [CHAIN_BTC]
    if (symbol === 'oLTC') return [CHAIN_LTC]
    if (symbol === 'oDOGE') return [CHAIN_DOGE]

    return [CHAIN_ETHER]
}

export const getRelatedChains = (symbol: string): number[] => {
    if (symbol === 'BTC') return [CHAIN_BTC]
    if (symbol === 'LTC') return [CHAIN_LTC]
    if (symbol === 'DOGE') return [CHAIN_DOGE]
    if (symbol === 'oLTC') return [CHAIN_ETHER]
    if (symbol === 'oDOGE') return [CHAIN_ETHER]

    const t1 = TWOWAY_TOKENS.filter(t => t.symbol === symbol)
    if (t1 && t1.length > 0) {
        return  t1.map(t => t.chainID)
    }

    const t2 = NATIVE_BRIDGE_TOKENS.filter(t => t.symbol === symbol)

    return t2.map(t => t.chainID)
}
export const getAllSupportChains = (): number[] => {
  const twowayChains = TWOWAY_TOKENS.map(t => t.chainID)
  const fitlerTwowayChains = twowayChains.filter((f, i) => twowayChains.indexOf(f) === i)
  const nativeChains = NATIVE_BRIDGE_TOKENS.map(t => t.chainID)
  const filterNativeChains = nativeChains.filter((f, i) => nativeChains.indexOf(f) === i)
  const allChains = [...fitlerTwowayChains, ...filterNativeChains]
  return allChains.filter((f, i) => allChains.indexOf(f) === i)
}
export const getTwowayChains = (): number[] => {
  const chains = new Set(TWOWAY_TOKENS.map(t => t.chainID))
  return [...Array.from(chains.values())]
}
export const getOnlySupportNativeChains = (): number[] => {
  const twowayChains =  TWOWAY_TOKENS.map(t => t.chainID)
  const nativeChains = NATIVE_BRIDGE_TOKENS.map(t => t.chainID)
  const filterArray = nativeChains.filter(f => !twowayChains.some(s => s === f))
  const sets = new Set(filterArray)
  return [...Array.from(sets.values())]
}
export const getOnlySupportTwowayChains = (): number[] => {
  const twowayChains = TWOWAY_TOKENS.map(t => t.chainID)
  const nativeChains = NATIVE_BRIDGE_TOKENS.map(t => t.chainID)
  const filterArray = twowayChains.filter(f => !nativeChains.some(s => s === f))
  const sets = new Set(filterArray)
  return [...Array.from(sets.values())]
}
