import { useCallback } from 'react'
import { useBoringFeePoolContract } from 'hooks/useContract'

export const useFeePoolClaim = (tokenName: string) => {
    const contract = useBoringFeePoolContract(tokenName)
    const handleClaimFee = useCallback(async () => {
        const tx = await contract.claimFee()
        const receipt = await tx.wait()
        return receipt
    }, [contract])
    return { onClaimFee: handleClaimFee }
}