import {createTheme} from '@mui/material'
import { url } from 'inspector'

export const bscTheme = createTheme({
  spacing: 5,
  palette: {
    primary: {
      main: '#0068FF',
    },
    secondary: {
      main: '#FD7C25',
    },
  },
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 10000
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'rgba(0, 0, 0, 0)',
        }
      }
    },
    MuiSkeleton:{
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(80, 80, 90, 0.4)'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: '#ffffff'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: '#ffffff'
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: '#ffffff'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#FFFFFF',
          fontSize: '18px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        },
      },
      styleOverrides: {
        outlined: {
          padding: 0,
          paddingRight: '20px !important'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#f1f2f6',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          fontSize: '13px',
          textTransform: 'capitalize',
          color: '#ffffff !important',
        },
        contained: {
          background: 'linear-gradient(90deg, #0068FF 0%, #00A1FF 100%)',
          borderRadius: '10px',
          '&:hover': {
            backgroundColor: '#f1f2f2',
          },
        },
        outlined: {
          color: '#0068FF !important',
        },
        sizeSmall: {
          borderRadius: 5,
          padding: '4px 5px',
        },
        sizeLarge: {
          height: '60px',
          fontSize: '18px',
          fontWeight: 400,
          borderRadius: 10,
        },
        // containedSecondary: {
        //   backgroundColor: '#0068FF',
        //   color: '#fff',
        //   '&:hover': {
        //     backgroundColor: '#00D9A7 !important',
        //   },
        //   '&:active': {
        //     backgroundColor: '#00D9A7 !important',
        //   },
        // },
        // containedPrimary: {
        //   backgroundColor: '#0068FF',
        //   color: '#fff',
        //   '&:hover': {
        //     backgroundColor: '#0068FF',
        //   },
        // },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'none',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          // padding: '5px 0',
          boxShadow: '0px 1px 10px 0px rgba(186, 192, 204, 0.41)',
          // marginTop: '10px',
        },
        list: {
          padding: '8px 0',
          maxHeight: '300px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          padding: '12px 24px',
          fontSize: '14px !important',
          borderRadius: '4px',
          color: '#FFFFFF',
          '& a': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          },
          '& img': {
            marginRight: '10px',
          },
          '&:hover': {
            background: '#0068FF',
          },
          '&$selected': {
            background: '#red',
            '&:hover': {
              background: '#0068ff33',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#0D131B',
          padding: '15px',
        },
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#A1A8C7',
          minWidth: '60px !important',
          '&:hover': {
            color: '#0068FF',
          },
          '&$selected': {
            color: '#0068FF',
          },
          '&:focus': {
            color: '#0068FF',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 5px 43px 0px rgba(228, 232, 245, 0.92)',
          borderRadius: '16px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          display: 'flex',
          alignItems: 'center',
          '&::placeholder': {
            color: '#ffffff !important',
            fontSize: '12px',
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Lexend", "Roboto", "Helvetica", "Arial", sans-serif !important',
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        labelContainer: {
          color: '#fffffff'
        }
      }
    },
    MuiCssBaseline:  ({
      styleOverrides: {
        html: {
          WebkitFontSmoothing: 'auto',
        },
        body: {
          overflow: 'inherit !important',
          // background: `url('./images/boring-bg.jpg') no-repeat`,
          backgroundSize: '100% 100%',
          backgroundColor: '#13172F',
          minHeight: '100vh',
          paddingRight: '0 !important',
          color: '#FFFFFF',
          fontSize: '14px',
          fontFamily: '"Lexend", "Roboto", "Helvetica", "Arial", sans-serif !important',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            display: 'none',
          },
        },
        '*': {
          '-ms-overflow-style': 'none',
          'scrollbar-width:': 'none',
        },
        button: {
          textTransform: 'capitalize !important',
        },
        a: {
          textDecoration: 'none',
        },
        '.MuiDialog-root': {
          background: '#000000cc',
        },
        '.MuiAccordion-root:before': {
          height: '0 !important',
          background: 'transparent'
        },
        '.Toastify__toast-container': {
          minWidth: '260px',
          margin: '82px auto 0',
          width: 'auto !important',
          fontSize: '13px',
          '@media only screen and (max-width: 600px)': {
            width: '80% !important',
            right: 0,
          }
        },
        '.Toastify__toast': {
          borderRadius: '12px',
          padding: '8px 10px',
          color: '#fff',
          position: 'left',
          minHeight: '80px',
          background: '#00000066',
          border: '1px solid rgba(0,104,255,1)',
          '& svg': {
            marginRight: '5px',
          },
          '@media only screen and (max-width: 600px)': {
            background: '#000000',
          }
        },
        '.Toastify__close-button': {
          color: '#fff',
        },
        '.Toastify__toast--info': {
          background: '#fff',
        },
      },
    }),
  },
})
