import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Button } from '@mui/material'
import copy from 'copy-to-clipboard'
import { Flex, Text } from 'components/Style'
import { getMinMintValue } from 'config/constants/pow_bridge'
import { toast } from 'react-toastify'
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { BackIcon } from 'components/Svg'
import QRCode from 'qrcode'
import { CopyIcon } from 'components/Svg'
import { PrincipleWrapper, CopyAddress, ViewWallet } from '../../style'

const PrincipleWrapperTwo = styled(PrincipleWrapper)`
    padding: 18px 15px;
`

interface Props {
    oToken: string
    multiAddres: string
    onPve: (t: number) => void
    onNext: (t: number) => void
}
const GuideTwo:React.FC<Props> = ({ oToken, multiAddres, onPve, onNext }) => {
    const { t } = useTranslation()
    const [qrImage, setQrImage] = useState('')
    const generateQR = useCallback(async () => {
        const qr = await QRCode.toDataURL(multiAddres, {margin: 1})
        setQrImage(qr)
    }, [multiAddres])

    const handleCopy = (link: any) => {
        const res = copy(link)
        if (res) {
            toast('Copied', {autoClose: 1000})
        }
    }

    useEffect(() => {
        if (multiAddres) {
            generateQR()
        }
    }, [generateQR])
    const minMintAmount = useMemo(() => getMinMintValue(oToken.slice(1)), [oToken])
    return (
        <PrincipleWrapperTwo>
            <Flex sx={{cursor: 'pointer', marginBottom: '18px'}} 
                alignItems="center" onClick={() => onPve(0)}>
                <BackIcon />
                <Text ml="4px" fontSize="20px" color="#ffffff">{t('BACK')}</Text>
                <Text ml="calc(50% - 90px)" color="#ffffff">{t('Demo')}</Text>
            </Flex>
            <Flex justifyContent="center" mb="50px">
                <img style={{borderRadius: '12px'}} width="200px" src='./images/iphone.gif' alt="view" />
            </Flex>
            <Flex mb="15px" flexDirection="column" alignContent="center">
                <Flex mb="15px"><Text>1、</Text><Text>{t('enter_the_NEL')}</Text></Flex>
                <Flex justifyContent="center" mb="15px">
                    <img src={qrImage} width="150px" height="150px" alt="QR" />
                </Flex>
                <CopyAddress onClick={() => handleCopy(multiAddres)}>
                    <Text mr="10px">{multiAddres}</Text>
                    <CopyIcon />
                </CopyAddress>
                <Flex><Text>2、</Text><Text mr="4px">{t('enter_the_minting_amount')} {t('minimum_amount', {amount: minMintAmount})}</Text></Flex>
                <Flex>
                    <Text>3、</Text>
                    <Text>{t('check_guide_3')}</Text>
                </Flex>
                <Flex><Text>4、</Text><Text>{t('check_guide_4')}</Text></Flex>
            </Flex>
            {/* <Flex justifyContent="center">
                <ViewWallet>
                    <Text>查看钱包具体示例</Text>
                    <ArrowForwardIosIcon />
                </ViewWallet>
            </Flex> */}
            <Flex width="100%" padding="0 15px">
                <Button sx={{height: '50px'}} fullWidth onClick={() => onNext(2)}>{t('Next')}</Button>
            </Flex>
        </PrincipleWrapperTwo>
    )
}

export default GuideTwo