import React from 'react'
import { Input } from '@mui/material'
import copy from 'copy-to-clipboard'
import { Flex, Text } from 'components/Style'
import { CopyIcon } from 'components/Svg'
import { toast } from 'react-toastify'

interface CopyProps {
    name: string
    value: string
    color?: string
    marginRight?: string
    onAfter?: () => void
}

const Copy: React.FC<CopyProps> = ({ name, value, onAfter, color = '#0068FF', marginRight }) => {
    function handleCopy(link: any) {
        const res = copy(link)
        if (res) {
            toast('Copied', {autoClose: 1000})
            onAfter && onAfter()
        }
    }
    return (
        <Flex alignItems="center" sx={{cursor: 'pointer'}} onClick={() => {handleCopy(value)}}>
            <Input value={value} disabled sx={{display: 'none'}} />
            <Text fontSize="14px" color={color} mr="6px">{name}</Text>
            <CopyIcon color={color} />
        </Flex>
    )
}

export default Copy
