import React, { useMemo, useState } from 'react'
import { Box, Dialog, InputBase } from '@mui/material'
import { TwowayChefInfo } from 'modal/twoway'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Text, Flex } from 'components/Style'
import { getIcon } from 'utils/icons'
import { getFullDisplayBalanceString } from 'utils/formatBalance'
import MyButton, { OutlineButton } from 'components/Button'
import BigNumber from 'bignumber.js'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

const DialogTitle = styled.div`
    display:flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    font-family: 'Lexend-Regular, Lexend';
    font-size: 18px;
    margin-bottom: 34px;
    > svg {
        width: 18px;
        height: 18px;
    }
`

const InputWrapper = styled.div`
    display: flex;
    border: 1px solid #0068FF;
    padding: 27px 17px;
    border-radius: 12px;
    input {
        color: #ffffff;
        padding: 0 20px;
    }

`

interface DepositProps {
    farmInfo: TwowayChefInfo
    open: boolean
    loading: boolean
    onClose: () => void
    onConfrim: (amount: string) => void
}

const DepositModal:React.FC<DepositProps> = ({ farmInfo, open, loading, onClose, onConfrim }) => {
    const { t } = useTranslation()
    const [amount, setAmount] = useState('')
    const balance = useMemo(() => getFullDisplayBalanceString(farmInfo.balance, farmInfo.token.decimals), [farmInfo])
    const can = useMemo(() => new BigNumber(amount).gt(0) && new BigNumber(amount).lte(balance), [amount])

    const handleClose = () => {
        setAmount('')
        onClose()
    }

    return (
        <Dialog open={open} keepMounted onClose={handleClose}
            title={t('deposit')}
            fullWidth={true}
        >
            <DialogTitle>
                <Text fontSize="18px">{t('stake')}</Text>
                <Text sx={{cursor: 'pointer'}} onClick={handleClose}><CloseOutlinedIcon style={{width: '18px'}} /></Text>
            </DialogTitle>
            <InputWrapper>
                <img src={getIcon(farmInfo.token.symbol)} width={28} height={28} />
                <InputBase fullWidth value={amount} onChange={e => setAmount(e.target.value)} />
                <OutlineButton onClick={() => setAmount(balance)}>MAX</OutlineButton>
            </InputWrapper>
            <Flex mt='8px' justifyContent="flex-end">
                <Text color="#ffffff">{t('balance')}&nbsp;:&nbsp;{balance}</Text>
            </Flex>
            <Flex mt="20px" justifyContent="center">
                <Box sx={{width: {md: '60%', xs: '100%'}}}>
                    <MyButton loading={loading} disabled={!can} fullWidth onClick={() => onConfrim(amount)}>{t('deposit')}</MyButton>
                </Box>
            </Flex>
        </Dialog>
    )

}

export default DepositModal
