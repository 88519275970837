import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const CopyIcon:React.FC<IconProps> = ({ color = "#ffffff" }) => {
    return (
        <SvgIcon viewBox="0 0 12 12">
            <path fill={color} d="M10.0563 9.25295C9.70627 9.25295 9.42254 8.96921 9.42254 8.61921C9.42254 8.2692 9.70627 7.98547 10.0563 7.98547C10.3947 7.98547 10.6691 7.71111 10.6691 7.37268V1.88026C10.6691 1.54183 10.3947 1.26748 10.0563 1.26748H4.56386C4.22543 1.26748 3.95108 1.54183 3.95108 1.88026C3.95108 2.23027 3.66734 2.51401 3.31733 2.51401C2.96733 2.51401 2.68359 2.23027 2.68359 1.88026C2.6859 0.84278 3.52637 0.00230502 4.56386 0H10.0563C11.0938 0.00230502 11.9342 0.84278 11.9365 1.88026V7.37268C11.9342 8.41017 11.0938 9.25064 10.0563 9.25295Z"/>
            <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M1.98589 12H7.24611C8.3451 12 9.25295 11.1123 9.232 10.0133V4.73211C9.23267 4.20521 9.02365 3.69971 8.65108 3.32713C8.27851 2.95456 7.773 2.74555 7.24611 2.74622H1.98589C1.45885 2.74555 0.953219 2.95468 0.580624 3.32743C0.208028 3.70018 -0.000885785 4.20591 5.6644e-06 4.73295V10.0141C-0.000662843 10.541 0.208349 11.0465 0.580921 11.4191C0.953492 11.7917 1.459 12.0007 1.98589 12ZM1.47616 4.22237C1.61123 4.0873 1.79488 4.01212 1.98589 4.0137H7.24611C7.43712 4.01212 7.62077 4.0873 7.75584 4.22237C7.89091 4.35745 7.9661 4.54109 7.96452 4.73211V10.0133C7.9661 10.2043 7.89091 10.3879 7.75584 10.523C7.62077 10.6581 7.43712 10.7333 7.24611 10.7317H1.98589C1.79488 10.7333 1.61123 10.6581 1.47616 10.523C1.34109 10.3879 1.26591 10.2043 1.26749 10.0133V4.73211C1.26591 4.54109 1.34109 4.35745 1.47616 4.22237Z"/>
        </SvgIcon>
    )
}

export default CopyIcon
