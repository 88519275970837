import React from 'react'
import { SvgIcon } from '@mui/material'
import { IconProps } from '../type'

const CrossIcon:React.FC<IconProps> = ({ color = "#ffffff" }) => {
    return (
        <SvgIcon viewBox="0 0 12 12">
            <path fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M0 5.99996C0 9.30855 2.69147 12 5.99996 12C9.30853 12 12 9.30853 12 5.99996C12 2.69145 9.30855 0 5.99996 0C2.69145 0 0 2.69147 0 5.99996ZM0.861 5.99995C0.861 3.16628 3.16629 0.860986 5.99996 0.860986C8.83325 0.860986 11.139 3.16628 11.139 5.99995C11.139 8.83325 8.83327 11.139 5.99996 11.139C3.16629 11.139 0.861 8.83325 0.861 5.99995Z" />
            <path fill={color} d="M8.8784 4.40864C8.7104 4.24194 8.43867 4.24363 8.27246 4.41204L5.14816 7.57059L3.76512 6.14949C3.59974 5.97933 3.32841 5.97632 3.15918 6.14092C2.98941 6.30593 2.98557 6.57763 3.15061 6.74689L4.83833 8.48134C4.83919 8.48258 4.84087 8.48258 4.84173 8.48388C4.84259 8.48474 4.84303 8.48644 4.84389 8.48728C4.8709 8.51391 4.90431 8.53019 4.93603 8.54817C4.95277 8.5572 4.96604 8.57218 4.98317 8.57904C5.03503 8.60048 5.0903 8.61115 5.14515 8.61115C5.19956 8.61115 5.25403 8.60049 5.30543 8.5799C5.32217 8.57303 5.335 8.55929 5.35088 8.55073C5.38299 8.53273 5.41647 8.51692 5.44388 8.48991C5.44472 8.48905 5.44519 8.48728 5.44642 8.48644C5.44728 8.48518 5.44858 8.48473 5.44989 8.48388L8.88142 5.01502C9.04817 4.84618 9.0464 4.57492 8.8784 4.40864Z" />
        </SvgIcon>
    )
}

export default CrossIcon
