import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useWeb3React } from '@web3-react/core' 
import { CHAIN_ETHER, CHAIN_BSC } from '@w3u/chains'
import { Button } from '@mui/material'
import { Flex, Text } from 'components/Style'
import { getIcon, getMintIcon } from 'utils/icons'
import { DownarrowIcon } from 'components/Svg'
import NashAddrssImg from 'assets/images/mint/nash_address.svg'
import NashContractImg from 'assets/images/mint/nash_contract.svg'
import EthImg from 'assets/images/mint/eth_wallet.svg'
import { PrincipleWrapper, FarmBox, FarmBoxBottom, WrapperText, WrapperTextSmail } from '../../style'

interface Props {
    oToken: string
    token: string
    onNext: (t: number) => void
}
const GuideOne:React.FC<Props> = ({ token, oToken, onNext }) => {
    const { t } = useTranslation()
    const { chainId } = useWeb3React()
    const ChainEther = useMemo(() => {
        let chainEther = 'ETH'
        switch (chainId) {
            case CHAIN_ETHER:
                chainEther = 'ETH'
                break;
            case CHAIN_BSC:
                chainEther = 'BNB'
                break;
            default:
                chainEther = 'ETH'
        }
        return chainEther
    }, [chainId])
    return (
        <PrincipleWrapper>
            <Text padding="15px 0" fontSize="14px" textAlign="center">{t('Mechanism')}</Text>
            <FarmBox>
                <WrapperTextSmail mb="15px">
                    <img src={getIcon(token)} width="20px" height="20px" alt={token} />
                    <Text color="#ffffff" ml="10px">{t('chain_network', {chain: token})}</Text>
                </WrapperTextSmail>
                <WrapperText mb="15px" fullwidth>
                    <img src={getMintIcon(oToken)} width="20px" height="20px" alt={token} />
                    <Text color="#ffffff" ml="10px">{t('your_chain_address', {chain: token})}</Text>
                </WrapperText>
                <Text color="#ffffff" mb="10px" fontWeight="500">Send {token}</Text>
                <Flex mb="15px"><DownarrowIcon /></Flex>
                <WrapperText mb="8px" fullwidth>
                    <img src={NashAddrssImg} width="20px" height="20px" alt="Token" />
                    <Text color="#ffffff" ml="10px">{t('NEL_address')}</Text>
                </WrapperText>
            </FarmBox>
            <FarmBoxBottom>
                <Flex mt="-20px" mb="15px">
                    <DownarrowIcon />
                </Flex>
                <WrapperText mb="15px" fullwidth>
                    <img src={NashContractImg} width="20px" height="20px" alt="Token" />
                    <Text color="#ffffff" ml="10px">{t('NEL_contract')}</Text>
                </WrapperText>
                <Flex mb="15px" flexDirection="column" alignItems="center">
                    <Text mb="10px">{t('Receive oBTC asmingting rewards', {token: oToken })}</Text>
                    <DownarrowIcon />
                </Flex>
                <WrapperText mb="15px" fullwidth>
                    <img src={EthImg} width="20px" height="20px" alt="Token" />
                    <Text color="#ffffff" ml="10px">{t('your_chain_address', {chain: ChainEther})}</Text>
                </WrapperText>
                <WrapperTextSmail>
                    <img src={getIcon('ETH')} width="20px" height="20px" alt="ETH" />
                    <Text color="#ffffff" ml="10px">{t('chain_network', {chain: ChainEther})}</Text>
                </WrapperTextSmail>
            </FarmBoxBottom>
            <Flex width="100%" padding="15px">
                <Button sx={{height: '50px'}} fullWidth onClick={() => onNext(1)}>{t('next')}</Button>
            </Flex>
        </PrincipleWrapper>
    )
}

export default GuideOne