import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { CHAIN_ETHER } from '@w3u/chains'
import { ethers } from 'ethers'
import { getBordingDAOContract } from 'hooks/useContract'

export const useFinalMintEvents = () => {
    const { chainId } = useWeb3React()
    const fetchMint = useCallback(async (token: string, ethAddress: string, txID: string) => {
      // const _chainId = chainId ?? CHAIN_ETHER
      const bordingContract = getBordingDAOContract(token, CHAIN_ETHER)
      const logs = await bordingContract.getPastEvents('allEvents', {fromBlock: 10409912})
      const successes = {}
      const fails = {}
      const abiCoder = new ethers.utils.AbiCoder()
      console.log("Event-logs", logs)
      let done = false
      for (const log of logs) {
          try {
            if (log.event === 'ApproveMintSuccess') {
              const res = abiCoder.decode(log.raw.data, log.raw.topics)
              // @ts-ignore
              successes[res._txid] = {
                to: res.to,
                amount: res._amount
              }
            } else if (log.event === 'NotEnoughPledgeValue') {
              const res = abiCoder.decode(log.raw.data, log.raw.topics)
              // @ts-ignore
              fails[res._txid] = {
                to: res.to,
                amount: res._amount
              }
            }
          } catch (e) {
            console.error(e)
          }
      }
      for (let key of Object.keys(fails)) {
        // @ts-ignore
        const addr = fails[key]?.to
        if (addr.toLowerCase() === ethAddress.toLowerCase()) {
          if (key === txID) {
            done = true
          }
        }
      }
  
      for (let key of Object.keys(successes)) {
        // @ts-ignore
        const addr = successes[key]?.to
        if (addr.toLowerCase() === ethAddress.toLowerCase()) {
          if (key === txID) {
            done = true
          }
        }
      }
      return done
    }, [chainId])

    return { fetchMint }
}