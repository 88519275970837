import React, {useEffect, useMemo, useState} from 'react'
import styled from '@emotion/styled'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from 'components/Header'
import Footer from 'components/Footer'
import Container from '@mui/material/Container'

// import Sidebar from './Sideber'
import Menu from './Menu'
import MobileMenu from './MobileMenu'
import BottomBar from './BottomBar'
import AlertModal from '../Modal/Alert'
import {useTranslation} from 'react-i18next'
import {getAllSupportChains} from '../../utils'
import {useWeb3React} from '@web3-react/core'
import {useLocation} from 'react-router-dom'
import {CHAIN_BLAST} from '@w3u/chains'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background: url('./images/boring-bg.jpg') no-repeat;
  background-size: 100% 100%;
  @media screen and (max-width: 600px) {
    background: url('./images/boring-mobile.jpg') no-repeat;
    background-size: 100% 100%;
  }
`

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
  flex: auto;
`

const Inner = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
  padding: 30px 15px;
  @media screen and (max-width: 600px) {
    padding: 0 15px;
    align-items: flex-start;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: calc(100vh - 84px);
  overflow: scroll;
  > div:nth-of-type(1) {
    flex: 1 1 auto;
  }
  @media screen and (max-width: 600px) {
    height: calc(100vh - 130px);
  }
`

interface Props {
  children: React.ReactNode
}

const Layout: React.FC<Props> = ({children}): JSX.Element => {
  const {t} = useTranslation()
  const [alertOpen, setAlertOpen] = useState(true)
  const [open, setOpen] = useState(false)

  const matches = useMediaQuery('(max-width:600px)')
  const handleMobileMenu = () => setOpen(true)

  const location = useLocation()
  const {chainId} = useWeb3React()
  const allIds = useMemo(() => getAllSupportChains(), [])

  useEffect(() => {
    setAlertOpen(!allIds.some((s) => s === chainId))
  }, [chainId, allIds, location.pathname])

  return (
    <Wrapper>
      <Header onMenu={handleMobileMenu} />
      <MobileMenu open={open} onClose={() => setOpen(false)} />
      <BodyWrapper>
        {!matches && <Menu />}
        <Content>
          <Inner>{children}</Inner>
          <Footer />
          <AlertModal
            open={alertOpen}
            onClose={() => setAlertOpen(false)}
            title={t('Wrong Network')}
            context={t('Please switch to support network')}
          />
        </Content>
      </BodyWrapper>
      {matches && <BottomBar />}
    </Wrapper>
  )
}

export default Layout
