import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import { IconProps } from 'components/Svg/type'
import * as IconModule from 'components/Svg' 
import LanguageSelect from 'components/LanguageSelect'
import { Flex } from 'components/Style'

const Icons = (IconModule as unknown) as { [key: string]: React.FC<IconProps> };

import { config, MenuItems } from './config'

const StyleListButton = styled(ListItemButton)<{hideborder: boolean}>`
    border-bottom: ${({hideborder}) => hideborder ? '0' : '1px' } solid #51576E;
    padding: 12px 16px;
`
const StyleListItemIcon = styled(ListItemIcon)`
    min-width: 16px;
    margin-right: 10px;
`

const SubListItemText = styled(ListItemText)`
    color: #777C8B;
    font-size: 14px;
    &:hover {
        color: #ffffff;
    }
`
interface CollapMenuProps {
    menu: MenuItems
    hideborder?: boolean
    onClose?: () => void
}
const CollapseList:React.FC<CollapMenuProps> = ({ menu, hideborder, onClose }) => {
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const handleClick = () => {
        setOpen(!open)
    }
    const pushRoute = (route: string | undefined, target: string | undefined) => {
        if (target && route) {
            window.open(route)
        } else {
            navigate(route ?? '/')
            onClose && onClose()
        }
    }
    const actived = menu.items?.some(s => s.href === location.pathname)
    const Icon = Icons[menu.icon]
    const iconElement = <Icon color={actived? '#ffffff' : '#ffffff80'} />
    return (
        <>
            <StyleListButton onClick={handleClick} hideborder={!!menu.hideLine || !!hideborder}>
                <StyleListItemIcon>
                    {iconElement}
                </StyleListItemIcon>
                <ListItemText primary={t(menu.label)} style={{color: actived? '#ffffff' : '#ffffff80'}} />
                {open ? <ExpandLess style={{color: actived? '#ffffff' : '#ffffff80'}} /> : <ExpandMore style={{color: actived? '#ffffff' : '#ffffff80'}} />}
            </StyleListButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {menu.items && menu.items.map(m => (
                        <ListItemButton key={m.label} sx={{ pl: 3}} onClick={() => pushRoute(m.href, m.target)}>
                            <SubListItemText primary={t(m.label)} style={{color: location.pathname === m.href ? '#ffffff' : '#ffffff80'}} />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </>
    )
}
interface MenuPorps {
    hideborder?: boolean
    onClose?: () => void
}
const Menu:React.FC<MenuPorps> = ({ hideborder, onClose }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const pushRoute = (route: string | undefined, target: string | undefined) => {
        if (target && route) {
            window.open(route)
        } else {
            navigate(route ?? '/')
            onClose && onClose()
        }
    }
    return (
        <Flex
            sx={(theme) => ({
                minWidth: 234,
                p: '0 0 0 34px',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 'calc(100vh - 84px)',
                [theme.breakpoints.down('md')]: {
                    p: '0',
                },
            })}
        >
            <List
                sx={{
                    width: '100%',
                    maxWidth: 280,
                    padding: '0',
                    marginTop: '-12px',
                    height: 'calc(100% - 53px)',
                    overflow: 'scroll',
                }}
                component="nav"
            >
                {config.map(d => {
                    const Icon = Icons[d.icon]
                    const LinkIcon = d.linkIcon ? Icons[d.linkIcon] : null
                    const iconElement = <Icon color={location.pathname === d.href ? '#ffffff' : '#ffffff80'} />
                    const linkElement = LinkIcon && <LinkIcon color='#ffffff' />
                    return d.items ? <CollapseList key={d.label} menu={d} hideborder={hideborder} onClose={onClose} /> :
                    <StyleListButton key={d.label} onClick={() => pushRoute(d.href, d.target)} hideborder={d.hideLine || !!hideborder}>
                        <Flex width="100%" alignItems="center" justifyContent="space-between">
                            <Flex alignItems="center">
                                <StyleListItemIcon>
                                    {iconElement}
                                </StyleListItemIcon>
                                <ListItemText primary={t(d.label)} style={{color: location.pathname === d.href ? '#ffffff' : '#ffffff80'}} />
                            </Flex>
                            <Flex sx={{
                                marginTop: '10px',
                                'svg': {
                                    width: '18px',
                                    height: '18px'
                                }
                            }}>{linkElement}</Flex>
                        </Flex>
                    </StyleListButton>
                })}
            </List>
            <Flex mb="30px">
                <LanguageSelect />
            </Flex>
        </Flex>
        
    );
}

export default Menu
