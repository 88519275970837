import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Flex, Text, BoringInput } from 'components/Style'
// import OTokenSwitcher from 'components/OTokenSwitcher'
import copy from 'copy-to-clipboard'
import Balacne from 'components/Balance'
import { getIcon } from 'utils/icons'
import { DownarrowIcon } from 'components/Svg'
import { toast } from 'react-toastify'
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import QRCode from 'qrcode'
import BigNumber from 'bignumber.js'
import { displayBalance, getBalanceNumber } from 'utils/formatBalance'
import { TagIcon, CopyIcon} from 'components/Svg'
import { useNetworkFee } from 'hooks/useBook'
import { usePrices } from 'hooks/usePrice'
import { useTunnelInfo, useTotalSubbly } from 'hooks/useTunnel'

import { PrincipleWrapper, CalcWrapper, TextWrapper, TotalSupplyMobile, CopyAddress } from '../../style'

const PrincipleWrapperPrgress = styled(PrincipleWrapper)`
    padding: 15px;
`
interface Props {
    step: number
    oToken: string
    multiAddres: string
    onPve: (t: number) => void
    onNext: (t: number) => void
}
const GuideCalc:React.FC<Props> = ({ oToken, multiAddres, onPve, onNext }) => {
    const { t } = useTranslation()
    const [value, setValue] = useState('')
    const prices = usePrices(['BTC', 'LTC', 'DOGE'])
    const tokenName = useMemo(() => oToken.slice(1), [oToken])
    const token = useMemo(() => oToken.slice(1) ?? 'BTC', [oToken])
    const [qrImage, setQrImage] = useState('')
    const { networkFee } = useNetworkFee()
    const { canIssue, pledgeRatio } = useTunnelInfo()
    const { total } = useTotalSubbly()
    const info = useMemo(() => {
        const issue = canIssue[token] ?? '0'
        const used = total[token]
        const remain = new BigNumber(issue).minus(used)
        return {issue, used, remain}
    }, [canIssue, pledgeRatio, total, token])
    const yourGet = useMemo(() => {
        const valueBN = new BigNumber(value).multipliedBy(Math.pow(10, 18))
        const fee = networkFee[tokenName]
        return valueBN.gt(0) && fee ? valueBN.multipliedBy(new BigNumber('0.998')).minus(new BigNumber(fee)).toString() : '0'
    }, [networkFee, tokenName, value])
    const getValuePrice = useMemo(() => {
        if (prices && tokenName) {
            return value ? displayBalance(new BigNumber(value).times(prices[tokenName]).toString()) : '0.00'
        }
        return '0.00'
    }, [prices, tokenName, value])
    const getYourGetValuePrice = useMemo(() => {
        if (prices && tokenName) {
            const yourGetAccount = new BigNumber(yourGet).div(Math.pow(10, 18))
            return yourGet ? displayBalance(yourGetAccount.times(prices[tokenName]).toString()) : '0.00'
        }
        return '0.00'
    }, [prices, tokenName, yourGet])
    const generateQR = useCallback(async () => {
        const qr = await QRCode.toDataURL(multiAddres, {margin: 1})
        setQrImage(qr)
    }, [multiAddres])
    const handleCopy = (link: any) => {
        const res = copy(link)
        if (res) {
            toast('Copied', {autoClose: 1000})
        }
    }
    useEffect(() => {
        if (multiAddres) {
            generateQR()
        }
    }, [generateQR])
    return (
        <>
            <PrincipleWrapperPrgress style={{marginBottom: '15px'}}>
                <CalcWrapper>
                    <Flex alignItems="center">
                        <Flex alignItems="center" mr="12px">
                            <img width="15px" height="15px" src={getIcon(tokenName)} alt={tokenName} />
                            <Text ml="5px">{tokenName}</Text>
                        </Flex>
                        <BoringInput style={{height: '16px'}} value={value} onChange={e => setValue(e.target.value)} />
                    </Flex>
                    <Text>≈${getValuePrice}</Text>
                </CalcWrapper>
                <Flex m="15px 0" justifyContent="center"><DownarrowIcon /></Flex>
                <CalcWrapper>
                    <Flex alignItems="center">
                        <img src={getIcon(tokenName)} width="15px" height="15px" alt={tokenName} />
                        <Text ml="5px">{displayBalance(yourGet)}</Text>
                    </Flex>
                    <Text>≈${getYourGetValuePrice}</Text>
                </CalcWrapper>
            </PrincipleWrapperPrgress>
            <TextWrapper>
                <TagIcon />
                <Flex ml="12px" flexDirection="column">
                    {oToken === 'oBTC' && <Text>{t('Network Fee: 0.003 BTC')}</Text>}
                    {oToken === 'oLTC' && <Text>{t('Network Fee: 0.8 LTC')}</Text>}
                    {oToken === 'oDOGE' && <Text>{t('Network Fee: 800 Doge')}</Text>}
                </Flex>
            </TextWrapper>
            <TextWrapper>
                <TagIcon />
                <Flex ml="12px" flexDirection="column">
                    <Text>{t('The calculated result is for estimation only. Actual cost will be subject to the casting time.')}</Text>
                </Flex>
            </TextWrapper>
            <PrincipleWrapperPrgress>
                <Text color="#ffffff" mb="15px">{t('remaining_capacity')}</Text>
                <TotalSupplyMobile>
                    <img style={{marginRight: '12px'}} src={getIcon(tokenName)} width="20px" height="20px" alt={tokenName} />
                    <Balacne value={getBalanceNumber(info.remain)} unit={` o${tokenName}`} />
                </TotalSupplyMobile>
                <Flex flexDirection="column" alignItems="center" m="15px auto">
                    <Text fontSize="16px" mb="15px">Mint</Text>
                    <img src={qrImage} width="60%" alt="QR" />
                </Flex>
                <CopyAddress onClick={() => handleCopy(multiAddres)}>
                    <Text mr="12px">{multiAddres}</Text>
                    <CopyIcon />
                </CopyAddress>
            </PrincipleWrapperPrgress>
        </>
    )
}

export default GuideCalc