import {useMemo, useState} from 'react'
import {CHAIN_ETHER} from '@w3u/chains'
import {useWeb3React} from '@web3-react/core'
import {useSearchParams} from 'react-router-dom'
import styled from '@emotion/styled'
import {getIcon} from 'utils/icons'
import {useTranslation} from 'react-i18next'
import {Button, Typography, Skeleton, Box} from '@mui/material'
import {toast} from 'react-toastify'
import Balance from 'components/Balance'
import ToastContent from 'components/ToastContent'
import {Flex, Text} from 'components/Style'
import ConnectWallet from 'components/ConnectWallet'
import MyButton, {SmallButton} from 'components/Button'
import {usePledgeData} from 'hooks/useTunnel'
import {getBalanceNumber} from 'utils/formatBalance'
import {changeChain} from 'utils'
import BigNumber from 'bignumber.js'
import {getBoringBoringDAOlAddress, getBoringAddress, getBoringPPtokenAddress} from 'utils/addressHelpers'
import {useApprove} from 'hooks/useApprove'
import {useTokenBalance} from 'hooks/useTokenBalance'
import {useFeePoolClaim} from 'hooks/useFeePool'
import {useWithdrawUnlock} from 'hooks/useTunnel'
import {usePledge, useRedeem} from 'hooks/useBoringBoringDAO'
import {useCheckSupportChain} from 'hooks/useChackChainId'

import Tab from './components/Tab'
import PledgeModal from './components/PledgeModal'
import RedeemModal from './components/RedeemModal'

const PledgeWrapper = styled.div`
  background: #00000033;
  padding: 30px;
  border-radius: 15px;
  max-width: 580px;
  @media screen and (max-width: 600px) {
    padding: 15px;
    border-radius: 20px;
  }
`
const BalanceBox = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #000000 0%, rgba(0, 35, 55, 0.22) 50%, rgba(0, 161, 255, 0.18) 100%);
  border-radius: 10px;
  border: 1px solid rgba(0, 104, 255, 0.6);
  padding: 10px 15px;
  margin-bottom: 15px;
  @media screen and (max-width: 600px) {
    border-radius: 10px;
  }
`

const PledgeFlex = styled(Flex)`
  flex-direction: column;
  padding: 15px;
  border-radius: 18px;
  background: #00000066;
  max-width: 580px;
  margin-top: 15px;
`
const PledgeFlexFoot = styled(Flex)`
  flex-direction: column;
  padding: 30px 20px;
  border-radius: 10px;
  background: #0000004d;
  margin-top: 15px;
  max-width: 580px;
`

const Grid = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  width: 100%;
`

const RewardGrid = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  width: 100%;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const Pledge = () => {
  useCheckSupportChain(CHAIN_ETHER)
  const [searchParams] = useSearchParams()
  const tokenByUrl = searchParams.get('token')
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [openPledge, setOpenPledge] = useState(false)
  const [openRedeem, setOpenRedeem] = useState(false)
  const {chainId, account} = useWeb3React()
  const [token, setToken] = useState<string>(tokenByUrl || 'BTC')
  const oToken = useMemo(() => `o${token}`, [token])
  const {allPledgeData, loading: dataLoading, fetchPledge} = usePledgeData()
  const pledgeData = useMemo(() => allPledgeData[token], [allPledgeData, token])
  const totalPledage = useMemo(
    () => (pledgeData ? getBalanceNumber(new BigNumber(pledgeData.totalPledage)) : 0),
    [pledgeData]
  )
  const userPledage = useMemo(
    () => (pledgeData ? getBalanceNumber(new BigNumber(pledgeData.userPledage)) : 0),
    [pledgeData]
  )
  const boringEarnd = useMemo(
    () => (pledgeData ? getBalanceNumber(new BigNumber(pledgeData.boringEarnd)) : 0),
    [pledgeData]
  )
  const oTokenEarnd = useMemo(
    () => (pledgeData ? getBalanceNumber(new BigNumber(pledgeData.oTokenEarnd)) : 0),
    [pledgeData]
  )
  const userLock = useMemo(
    () => (pledgeData ? getBalanceNumber(new BigNumber(pledgeData.lockAmount)) : 0),
    [pledgeData]
  )
  const userUnlock = useMemo(
    () => (pledgeData ? getBalanceNumber(new BigNumber(pledgeData.unLockAmount)) : 0),
    [pledgeData]
  )
  const userLockLength = useMemo(
    () => (pledgeData ? getBalanceNumber(new BigNumber(pledgeData.userLockLength)) : 0),
    [pledgeData]
  )
  const redeemLockTxLimit = useMemo(
    () => (pledgeData ? getBalanceNumber(new BigNumber(pledgeData.redeemLockTxLimit)) : 0),
    [pledgeData]
  )
  const boringPPTokenAddress = getBoringPPtokenAddress(token)
  const boringBoringDAOAddress = getBoringBoringDAOlAddress(token)
  const bordingAddress = getBoringAddress()
  const {balance} = useTokenBalance(bordingAddress)
  const {balance: ppTokenBalance} = useTokenBalance(boringPPTokenAddress)
  const {allowance, onApprove} = useApprove(bordingAddress, boringBoringDAOAddress, CHAIN_ETHER)
  const {onClaimFee} = useFeePoolClaim(token)
  const {onWithdrawUnlock} = useWithdrawUnlock(token)
  const {onPledge} = usePledge(token)
  const {onRedeem} = useRedeem(token)

  const handleToken = (t: string) => {
    setToken(t)
  }
  const handlePledge = async (amount: string) => {
    try {
      setLoading(true)
      await onPledge(token, amount)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const handleRedeem = async (amount: string) => {
    try {
      if (new BigNumber(userLockLength).gte(redeemLockTxLimit)) {
        toast(<ToastContent status={false} content={`Redeem count is greater than limit`} />, {
          autoClose: 2000,
        })
        return
      }
      const amountBN = new BigNumber(amount).multipliedBy(Math.pow(10, 18))
      if (amountBN.gt(ppTokenBalance)) {
        toast(<ToastContent status={false} content={`You don't have enough PP Token to redeem`} />, {
          autoClose: 2000,
        })
        return
      }
      setLoading(true)
      await onRedeem(token, amount)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const handleApprove = async () => {
    try {
      setLoading(true)
      await onApprove()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const handleWithdrawUnlock = async () => {
    try {
      setLoading(true)
      await onWithdrawUnlock()
      fetchPledge()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const handleCliam = async () => {
    try {
      setLoading(true)
      await onClaimFee()
      fetchPledge()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const renderAction = () => {
    return (
      <>
        {allowance ? (
          userPledage > 0 ? (
            <Grid>
              <MyButton fullWidth onClick={() => setOpenPledge(true)}>
                {t('Pledge')}
              </MyButton>
              <MyButton disabled={new BigNumber(ppTokenBalance).lte(0)} fullWidth onClick={() => setOpenRedeem(true)}>
                {t('Redeem')}
              </MyButton>
            </Grid>
          ) : (
            <Box sx={{width: '60%'}}>
              <MyButton fullWidth onClick={() => setOpenPledge(true)}>
                {t('Pledge')}
              </MyButton>
            </Box>
          )
        ) : (
          <MyButton loading={loading} fullWidth onClick={handleApprove}>
            {t('approve')}
          </MyButton>
        )}
      </>
    )
  }
  return (
    <Flex flexDirection='column'>
      <PledgeWrapper>
        <Tab defaultToken={token} onToken={handleToken} />
        <BalanceBox>
          <img src={getIcon('BOR')} width='40px' height='40px' alt='BOR' />
          <Flex flexDirection='column' ml='20px'>
            <Text mb='4px'>{t('Total Pledged')}</Text>
            {dataLoading ? (
              <Skeleton width={180} height={30} />
            ) : (
              <Balance fontSize='20px' decimals={0} value={totalPledage} />
            )}
          </Flex>
        </BalanceBox>
        <PledgeFlex>
          <Text mb='15px'>{t('Your Pledged Assets')}</Text>
          <Flex
            flexDirection='column'
            sx={{
              padding: '15px',
              background: '#00000099',
              marginBottom: '15px',
              borderRadius: '10px',
            }}
          >
            <Text mb='17px'>{t('Total Amount Pledged')}</Text>
            <Flex>
              <img src={getIcon('BOR')} width='20px' height='20px' alt='BOR' />
              {dataLoading ? (
                <Skeleton width={60} height={12} />
              ) : (
                <Balance ml='10px' value={userPledage} unit={` BORING`} />
              )}
            </Flex>
          </Flex>

          <Flex
            flexDirection='column'
            sx={{
              padding: '15px',
              background: '#00000099',
              marginBottom: '15px',
              borderRadius: '10px',
            }}
          >
            <Text mb='17px'>
              PPToken(o{token}) {t('balance')}
            </Text>
            <Flex>
              <img src={getIcon('BOR')} width='20px' height='20px' alt='BOR' />
              {dataLoading ? (
                <Skeleton width={60} height={12} />
              ) : (
                <Balance ml='10px' value={getBalanceNumber(new BigNumber(ppTokenBalance))} unit={` BORING`} />
              )}
            </Flex>
          </Flex>

          <Flex justifyContent='center'>
            <Flex justifyContent='center' sx={{width: {md: allowance ? '100%' : '60%', xs: '100%'}}}>
              {account ? (
                chainId === CHAIN_ETHER ? (
                  renderAction()
                ) : (
                  <Button onClick={() => changeChain(CHAIN_ETHER)} fullWidth size='large' variant='outlined'>
                    {t('Switch to Ethereum')}
                  </Button>
                )
              ) : (
                <ConnectWallet />
              )}
            </Flex>
          </Flex>
        </PledgeFlex>
        {new BigNumber(userLock).gt(0) && (
          <PledgeFlex>
            <Text mb='20px'>{t('Assets to be claimes')}</Text>
            <RewardGrid>
              <Flex
                flexDirection='column'
                sx={{
                  padding: '16px',
                  background: '#00000099',
                  borderRadius: '12px',
                  marginBottom: '15px',
                }}
              >
                <Text mb='17px'>{t('Vesting(Locked for 24 hrs)')}</Text>
                <Flex alignItems='center'>
                  <img src={getIcon('BOR')} width='20px' height='20px' alt='BOR' />
                  <Flex ml='8px' alignItems='center'>
                    <Text mr='10px'>BORING</Text>
                    <Balance value={userUnlock} />
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                flexDirection='column'
                sx={{
                  padding: '16px',
                  background: '#00000066',
                  marginBottom: '15px',
                  borderRadius: '12px',
                }}
              >
                <Text mb='17px'>{t('Claimable')}</Text>
                <Flex alignItems='center'>
                  <img src={getIcon('BOR')} width='20px' height='20px' alt={oToken} />
                  <Flex ml='8px' alignItems='center'>
                    <Text mr='10px'>BORING</Text>
                    <Balance value={userLock} />
                  </Flex>
                </Flex>
              </Flex>
            </RewardGrid>
            <Flex justifyContent='center'>
              <MyButton loading={loading} onClick={handleWithdrawUnlock}>
                {t('Withdraw')}
              </MyButton>
            </Flex>
          </PledgeFlex>
        )}
        {new BigNumber(oTokenEarnd).gt(0) && (
          <PledgeFlex>
            <Text mb='20px'>{t('Rewards')}</Text>
            <RewardGrid>
              <Flex
                flexDirection='column'
                sx={{
                  padding: '16px',
                  background: '#00000099',
                  borderRadius: '12px',
                }}
              >
                <Text mb='17px'>{t('Claimable')}</Text>
                <Flex alignItems='center'>
                  <img src={getIcon('BOR')} width='20px' height='20px' alt='BOR' />
                  <Flex ml='8px' alignItems='center'>
                    <Text mr='10px'>BORING</Text>
                    <Balance value={boringEarnd} />
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                flexDirection='column'
                sx={{
                  padding: '16px',
                  background: '#00000099',
                  borderRadius: '12px',
                }}
              >
                <Text mb='17px'>{t('Claimable')}</Text>
                <Flex alignItems='center'>
                  <img src={getIcon(oToken)} width='20px' height='20px' alt={oToken} />
                  <Flex ml='8px' alignItems='center'>
                    <Text mr='10px'>{oToken}</Text>
                    <Balance value={oTokenEarnd} />
                  </Flex>
                </Flex>
              </Flex>
            </RewardGrid>

            <Flex justifyContent='center' mt='15px'>
              <Box sx={{width: {md: '60%', xs: '100%'}}}>
                <MyButton loading={loading} onClick={handleCliam}>
                  {t('Claim')}
                </MyButton>
              </Box>
            </Flex>
          </PledgeFlex>
        )}
      </PledgeWrapper>
      <PledgeFlexFoot>
        <Text fontSize='14px' mb='5px'>
          {t('tunnel_operator')}
        </Text>
        <Typography fontSize='12px'>{t('tunnel_desc_1')}</Typography>
        <Typography fontSize='12px' mb='10px'>
          {t('tunnel_desc_1_1')}
        </Typography>
        <Text fontSize='14px' mb='5px'>
          {t('tunnel_desc_2')}
        </Text>
        <Typography fontSize='12px'>{t('tunnel_desc_2_1', {map_type: oToken})}</Typography>
        <Typography fontSize='12px' mb='10px'>
          {t('tunnel_desc_2_2', {map_type: oToken})}
        </Typography>
        <Text fontSize='14px' mb='5px'>
          PP Token
        </Text>
        <Typography fontSize='12px'>{t('tunnel_desc_3_1')}</Typography>
        <Typography fontSize='12px'>{t('tunnel_desc_3_2')}</Typography>
      </PledgeFlexFoot>
      <PledgeModal
        open={openPledge}
        onClose={() => setOpenPledge(false)}
        onConfirm={handlePledge}
        maxAmount={balance.toString()}
      />
      <RedeemModal
        open={openRedeem}
        onClose={() => setOpenRedeem(false)}
        onConfirm={handleRedeem}
        maxAmount={new BigNumber(userPledage).toString()}
      />
    </Flex>
  )
}

export default Pledge
