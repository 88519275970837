import React from 'react'
import {useTranslation} from 'react-i18next'
import styled from '@emotion/styled'
import {Flex, Text} from 'components/Style'
import {IHistory} from 'modal/types'
import {Chains} from '@w3u/chains'
import useMediaQuery from '@mui/material/useMediaQuery'

export const StepWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-content: space-between;
  color: #ffffff;
  font-weight: 500;
  padding: 20px 0;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 12px;
  }
`
export const StepWrapper3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  justify-content: space-between;
  color: #ffffff;
  font-weight: 500;
  padding: 20px 0;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    padding: 0 12px;
  }
`
export const PointOut = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #ffffff;
`
export const BasePoint = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #ffffff;
`
export const Point = styled(BasePoint)`
  background: #0068ff;
`

export const PointSuccess = styled(BasePoint)`
  background: #3affa3;
`

const FailPoint = styled(BasePoint)`
  background: #ff0000;
`

// const PointNext = styled(BasePoint)`
//     background: #3AFFA3;
// `
export const PintActive = styled.div`
  @keyframes scaleout {
    0% {
      transform: scale(0.5);
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }
  position: relative;
  width: 16px;
  height: 16px;
  background: #3affa3;
  border: 2px solid #ffffff;
  border-radius: 50%;
  &:after {
    background: #3affa3;
  }
  &:before {
    background: rgb(58, 255, 163, 0.2);
  }
  &:before,
  &:after {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -15px;
    margin-top: -15px;
    border-radius: 50%;
    animation: scaleout 1.5s ease-out 0s infinite;
  }
`
const Spoit = styled.div<{ml: string}>`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #ffffff;
  margin-left: ${({ml}) => (ml ? ml : '')};
`
export const ShortLine = styled.div`
  width: 10px;
  height: 2px;
  background: #ffffff;
  margin: 0 5px;
`
export const LongLine = styled.div<{mt?: string}>`
  width: 100%;
  height: 2px;
  margin-top: ${({mt}) => (mt ? mt : '0')};
  background: #ffffff;
`
const MiddleBox = styled.div`
  position: relative;
`
const FirstPoint = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 20%;
  top: 0;
`
const SecondPoint = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 65%;
  top: 0;
`
interface Props {
  chians: IHistory[]
  allSteps?: number
  step: number
  status: number
  chainId: number
  finalNumber: number
  confirmNumber: number
}

// isConfirm 0 上链没有确认 1 链上已确认
const Step: React.FC<Props> = ({chians, step, chainId, status, finalNumber, confirmNumber}) => {
  const {t} = useTranslation()
  const matches = useMediaQuery('(max-width:600px)')

  const renderStep = () => {
    if (!status) {
      if (finalNumber === 0) {
        return (
          <>
            {step === 1 ? (
              <Text fontWeight='500'>{t('Waiting for', {chain: Chains[chainId].displayName})}</Text>
            ) : (
              <Text fontWeight='500'>
                {t('Waiting for', {chain: Chains[chians[step - 2].event_chain_id].displayName})}
              </Text>
            )}

            <StepWrapper style={{width: '100%', padding: '20px 0'}}>
              <Flex flexDirection='column' alignItems='flex-start'>
                <Flex width='100%' alignItems='center' mb='10px'>
                  <PointOut style={{flex: '0 0 16px'}} />
                  <LongLine style={{flex: 'auto'}} />
                </Flex>
                <Text>{t('Start')}</Text>
              </Flex>
              {!matches && (
                <MiddleBox>
                  <LongLine mt='7px' />
                </MiddleBox>
              )}
              <Flex flexDirection='column' alignItems='flex-end'>
                <Flex width='100%' alignItems='center' mb='10px'>
                  <LongLine style={{flex: 'auto'}} />
                  <PointOut style={{flex: '0 0 16px'}} />
                </Flex>
                <Text>{t('Final')}</Text>
              </Flex>
            </StepWrapper>
          </>
        )
      }
      return (
        <>
          <Text fontWeight='500'>{t('Waiting for confirmation')}</Text>
          <StepWrapper style={{width: '100%'}}>
            <Flex flexDirection='column' alignItems='flex-start'>
              <Flex width='100%' alignItems='center' mb='10px'>
                <Point style={{flex: '0 0 16px'}} />
                <LongLine style={{flex: 'auto'}} />
              </Flex>
              <Text>{t('Start')}</Text>
            </Flex>
            {!matches && (
              <MiddleBox>
                <LongLine mt='7px' />
                <FirstPoint>
                  <Flex alignItems='center' mb='10px'>
                    <Point />
                    {/* <Spoit ml="5px" />
                                        <Spoit ml="5px" /> */}
                  </Flex>
                  {finalNumber - 1 > 0 && (
                    <Text>
                      {finalNumber - 1}/{confirmNumber}
                    </Text>
                  )}
                </FirstPoint>
                <SecondPoint>
                  <Flex alignItems='center' mb='10px'>
                    {/* <PointNext /> */}
                    <PintActive />
                    {/* <Spoit ml="5px" /> */}
                  </Flex>
                  {finalNumber && (
                    <Text>
                      {finalNumber}/{confirmNumber}
                    </Text>
                  )}
                </SecondPoint>
              </MiddleBox>
            )}
            <Flex width='100%' flexDirection='column' alignItems='flex-end'>
              <Flex width='100%' style={{flex: 'auto'}} alignItems='center' mb='10px'>
                <Flex width='100%' justifyContent='space-evenly'>
                  <ShortLine />
                  <ShortLine />
                  <ShortLine />
                </Flex>
                {!matches ? (
                  <PointOut style={{flex: '0 0 16px'}} />
                ) : (
                  <SecondPoint>
                    <Flex alignItems='center' mb='10px'>
                      <PintActive />
                      <Spoit ml='5px' />
                    </Flex>
                    {finalNumber && (
                      <Text>
                        {finalNumber}/{confirmNumber}
                      </Text>
                    )}
                  </SecondPoint>
                )}
              </Flex>
              <Text>{t('Final')}</Text>
            </Flex>
          </StepWrapper>
        </>
      )
    }
    return (
      <>
        <Text fontWeight='500'>{t('Confirmed')}</Text>
        <StepWrapper style={{width: '100%', padding: '20px 0'}}>
          <Flex flexDirection='column' alignItems='flex-start'>
            <Flex width='100%' alignItems='center' mb='10px'>
              <Point style={{flex: '0 0 16px'}} />
              <LongLine style={{flex: 'auto'}} />
            </Flex>
            <Text>{t('Start')}</Text>
          </Flex>
          {!matches && (
            <MiddleBox>
              <LongLine mt='7px' />
              <FirstPoint>
                <Flex alignItems='center' mb='10px'>
                  <Point />
                  {/* <Spoit ml="5px" />
                                    <Spoit ml="5px" /> */}
                </Flex>
                <Text>
                  {confirmNumber - 1}/{confirmNumber}
                </Text>
              </FirstPoint>
              <SecondPoint>
                <Flex alignItems='center' mb='10px'>
                  <Point />
                  {/* <Spoit ml="5px" /> */}
                </Flex>
                {confirmNumber && (
                  <Text>
                    {confirmNumber}/{confirmNumber}
                  </Text>
                )}
              </SecondPoint>
            </MiddleBox>
          )}
          <Flex flexDirection='column' alignItems='flex-end'>
            <Flex width='100%' alignItems='center' mb='10px'>
              <LongLine style={{flex: 'auto'}} />

              <Point style={{flex: '0 0 16px'}} />
            </Flex>
            <Text>{t('Final')}</Text>
          </Flex>
        </StepWrapper>
      </>
    )
  }
  return (
    <Flex sx={{padding: {md: '0', xs: '0 6px'}}} flexDirection='column' alignItems='center' justifyContent='center'>
      {renderStep()}
    </Flex>
  )
}

export default Step
