import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    PRODUCTION_NATIVE_TARGET_CHAINS,
    NATIVE_TARGET_CHAINS,
} from 'config/constants/native_bridge'
import { useWeb3React } from '@web3-react/core'
import { CHAIN_ETHER, CHAIN_METIS } from '@w3u/chains'
import { isProduction } from 'utils'
import { ethers } from 'ethers'
import BigNumber from 'bignumber.js'
import { useNativeBridgeContract } from 'hooks/useContract'
import { ALL_CHAINS, NATIVE_BRIDGE_TOKENS } from 'config/constants/native_bridge'
import { NativeBridgeToken } from 'modal/token'
import { isOriginToken } from 'utils/providers'
import { BridgeType, BridgeStatus } from 'modal/types'
import { BIG_TEN } from 'utils/bigNumber'

export const useNativeTargetChains = () => {
    const { chainId } = useWeb3React()
    const _chainId = chainId ?? CHAIN_ETHER
    return useMemo(() => isProduction() ? PRODUCTION_NATIVE_TARGET_CHAINS[_chainId] : NATIVE_TARGET_CHAINS[_chainId], [_chainId])
}

export const getNativeFromChains = () => {
    return [...ALL_CHAINS, CHAIN_METIS]
}

export const useNativeBridgeTokens = (chainId: number | undefined): NativeBridgeToken[] => {
    return useMemo(() => NATIVE_BRIDGE_TOKENS.filter((token) => token.chainID === chainId), [chainId])
}

export const useCommonNativeBridgeTokens = (
    fromChainID: number | undefined,
    toChainID: number | undefined
): NativeBridgeToken[] => {
    const fromTokensTemp = useNativeBridgeTokens(fromChainID)
    const toTokens = useNativeBridgeTokens(toChainID)
    // const fromTokens = fromTokensTemp.filter((token) => !(fromChainID === CHAIN_ETHER && token.symbol.includes('xVEMP')))
    return useMemo(() => fromTokensTemp.filter((token) => toTokens.some((t) => t.symbol === token.symbol)), [
        fromChainID,
        toChainID,
    ])
}

export const userNativeFixFee  = (tokenAddress: string, targetChainID: string, bridgeType: BridgeType) => {
    const nativeBridgeContract = useNativeBridgeContract()
    const [nativeFixFee, setNativeFixFee] = useState('0')
    const fetchNativeFixFee = useCallback(async () => {
        if (nativeBridgeContract && bridgeType === BridgeType.native) {
            const fixFeeResult = await nativeBridgeContract.fixFees(tokenAddress, targetChainID)
            setNativeFixFee(fixFeeResult.toString())
        }
    }, [nativeBridgeContract, tokenAddress, targetChainID, bridgeType])
    useEffect(() => {
        fetchNativeFixFee()
    }, [fetchNativeFixFee])

    return { nativeFixFee, fetchNativeFixFee }
}

export const useNativeCalculateFee = (tokenAddress: string, targetChainID: string, amount: string, token: NativeBridgeToken, bridgeType: BridgeType, nativeFixFee: string) => {
    const nativeBridgeContract = useNativeBridgeContract()
    const [nativeFee, setNativeFee] = useState(['0', '0'])
    const fetchCalcFee = useCallback(async () => {
        try {
            const isCanCalc = new BigNumber(amount).gte(new BigNumber(nativeFixFee).div(BIG_TEN.pow(token.decimals)))
            if (isCanCalc && nativeBridgeContract && amount !== '' && new BigNumber(amount).gte(0) && 
                bridgeType === BridgeType.native) {
                const result = await nativeBridgeContract.calculateFee(tokenAddress, targetChainID, ethers.utils.parseUnits(amount, token.decimals))
                const fixedAmount = result[0].toString()
                const ratioAmount = result[1].toString()
                const remainAmount = result[2].toString()
                setNativeFee([new BigNumber(fixedAmount).plus(ratioAmount).toString(), remainAmount])
            } else {
                setNativeFee(['0', '0'])
            }
        } catch (error) {
            setNativeFee(['0', '0'])
        }
    }, [nativeBridgeContract, tokenAddress, targetChainID, amount, bridgeType])

    useEffect(() => {
        fetchCalcFee()
    }, [fetchCalcFee])

    return { nativeFee, fetchCalcFee }
}

export const useNativeLock = () => {
    const { chainId } = useWeb3React()
    const nativeBridgeContract = useNativeBridgeContract()
    const handleLock = useCallback(async (token: NativeBridgeToken, finalAddress: string, targetChainID: string | number, amount: string) => {
        if (!nativeBridgeContract) return
        let estimateArgs = {}
        if (isOriginToken(chainId, token.symbol)) {
            estimateArgs = {
                value: ethers.utils.parseEther(amount),
            }
        }
        const gas = await nativeBridgeContract?.estimateGas
            .crossOut(token.address, targetChainID, finalAddress, ethers.utils.parseEther(amount), estimateArgs)
            .catch((e) => {
                console.error(e)
            })
        let args: any = {
            gasLimit: gas,
        }
        if (isOriginToken(chainId, token.symbol)) {
            args = {
                value: ethers.utils.parseEther(amount),
                gasLimit: gas,
            }
        }
        const tx = await nativeBridgeContract.crossOut(token.address, targetChainID, finalAddress, ethers.utils.parseEther(amount), args)
        return tx
    }, [nativeBridgeContract, chainId])

    return { onNativeLock: handleLock }
}