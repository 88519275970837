import React, { useEffect, useState, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Tabs, Tab } from '@mui/material'
import { Text } from 'components/Style'
import useMultiAddress from 'hooks/useBook'
import GuideOne from './components/Mobile/GuiedOne'
import GuideTwo from './components/Mobile/GuideTwo'
import GuideTree from './components/Mobile/GuideTree'
import GuideCalc from './components/Mobile/GuideCalc'
import GuideProgress from './components/Mobile/GuideProgress'
import { MobileWrapper } from './style'
import MyTab from './components/Tab'

const MobileMint = () => {
    const [searchParams] = useSearchParams()
    const tokenByUrl =  searchParams.get('token')
    const { t } = useTranslation()
    const [mintToken, setMintToken] = useState(tokenByUrl ? `o${tokenByUrl}` : 'oBTC')
    const [tab, setTab] = useState(0)
    const [step, setStep] = useState(0)
    const [txID, setTxID] = useState('')
    const token = useMemo(() => mintToken.slice(1) ?? 'BTC', [mintToken])
    const { multiAddress: multiAddresss } = useMultiAddress()
    const multiAddres = useMemo(() => multiAddresss[token], [multiAddresss, token])
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        const num = Number(newValue)
        setTab(num)
        setStep(num === 0 ? num : num + 2)
    }
    const handleStep = (t: number) => setStep(t)
    useEffect(() => setTab(step <= 2 ? 0 : step - 2), [step])
    const handleStepQuery = (s: number, t: string) => {
        setStep(s)
        setTxID(t)
    }
    return (
        <MobileWrapper>
            <MyTab defaultToken={mintToken} onToken={(t: string) =>  setMintToken(t)} />
            <Tabs sx={{
                marginTop: '10px',
                width: '100%',
                '.MuiTabs-flexContainer': {
                    justifyContent:"space-between"
                },
                '.MuiButtonBase-root':{
                    padding: '8px 0',
                    fontSize: '16px'
                },
                '.MuiTab-root:hover': {
                    color: '#ffffff'
                },
                '.Mui-selected': {
                    color: '#ffffff !important',
                    borderBottom: '1px solid #ffffff'
                }
            }} value={tab} onChange={handleChange}aria-label="wrapped label tabs example">
                <Tab value={0} label={t('mint_guide')} wrapped/>
                <Tab value={1} label={t('Calculator')} />
                <Tab value={2} label={t('track_progress')} />
            </Tabs>
            <Text color="#ffffff99"  m="10px 0 15px">
                {t('mint_guide_1')}
            </Text>
            {step === 0 && <GuideOne oToken={mintToken} token={token} onNext={handleStep} />}
            {step === 1 && <GuideTwo oToken={mintToken} multiAddres={multiAddres} onPve={handleStep} onNext={handleStep} />}
            {step === 2 && <GuideTree oToken={mintToken} onPve={handleStep} onNext={handleStepQuery} />}
            {step === 3 && <GuideCalc oToken={mintToken} step={step} multiAddres={multiAddres} onPve={handleStep} onNext={handleStep} />}
            {step === 4 && <GuideProgress oToken={mintToken} token={token} txID={txID} multiAddres={multiAddres} />}
        </MobileWrapper>
    )
}

export default MobileMint