import React, {useEffect, useMemo, useState} from 'react'
import {Box, Button, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {Flex, Text} from 'components/Style'
import {useWeb3React} from '@web3-react/core'
import {useAllTokenSymbols, useCommonTokens} from 'hooks/twoway'

import {AssetGrid, ChianSelectBox, Grid, SelectBox, SelectWrapper} from './style'
import {useNavigate} from 'react-router-dom'
import HomeTokenSwitcher from '../../components/HomeTokenSwitcher'
import {getOriginChainId, getRelatedChains, isOOriginToken, isOriginChainId, isOriginToken} from '../../utils'
import {CHAIN_ETHER} from '@w3u/chains'
import ConnectWallet from '../../components/ConnectWallet'
import ChainSwitcher from '../../components/ChainSwitcher'

const Home = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {account} = useWeb3React()

  const symbols = useAllTokenSymbols()
  const [symbol, setSymbol] = useState(symbols && symbols[0])

  const fromChainIDs = useMemo(() => getRelatedChains(symbol), [symbol])

  const [fromID, setFromID] = useState(fromChainIDs[0])
  const toChainIDs = useMemo(() => {
    if (isOriginToken(symbol)) return [CHAIN_ETHER]
    const ids = fromChainIDs.filter((chainID) => chainID !== fromID)
    if (isOOriginToken(symbol)) return [...ids, ...getOriginChainId(symbol)]
    return ids
  }, [fromID, fromChainIDs])
  const [toID, setToID] = useState(toChainIDs[0])
  const tokens = useCommonTokens(fromID, toID)
  const token = useMemo(() => tokens.find((token) => token.symbol === symbol), [symbol])

  useEffect(() => {
    setToID(toChainIDs[0])
  }, [toChainIDs])

  const link = useMemo(() => {
    if (isOriginToken(symbol)) return `/mint?token=${symbol}`
    if (isOOriginToken(symbol) && isOriginChainId(toID)) return `/burn?token=${symbol}`
    const token = tokens.find((f) => f.symbol === symbol) ?? tokens[0]
    const type = token?.isTwoway ? 'router' : 'router'
    return `/${type}?token=${token.address}&from_chain_id=${fromID}&to_chain_id=${toID}`
  }, [token, toID, fromID])

  const handleFromChange = (id: any) => setFromID(id)
  const handleToChange = (id: any) => setToID(id)
  const handleTokenChange = (symbol: string) => setSymbol(symbol)

  return (
    <Flex flexDirection='column' alignItems='center' width='100%'>
      <SelectWrapper>
        <Flex sx={{mb: {md: '15px', xs: '8px'}}} justifyContent='space-between'>
          <Box
            sx={{
              background: '#00000066',
              px: '16px',
              py: '9px',
              borderRadius: '6px',
            }}
          >
            <Text fontSize='12px'>{t('asset')}</Text>
          </Box>
        </Flex>
        <AssetGrid>
          <SelectBox sx={{mb: {md: '20px', xs: '10px'}}}>
            <Flex justifyContent='space-between'>
              <HomeTokenSwitcher border={false} fullWidth tokens={symbols} onChange={handleTokenChange} />
            </Flex>
          </SelectBox>
        </AssetGrid>
        <Flex alignItems='center' sx={{mb: {md: '15px', xs: '8px'}}}>
          <Box
            sx={{
              background: '#00000066',
              px: '16px',
              py: '9px',
              borderRadius: '6px',
            }}
          >
            <Text>{t('chain')}</Text>
          </Box>
        </Flex>
        <Grid
          sx={(theme) => ({
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
              gridTemplateColumns: '1fr',
              gridRowGap: '10px',
              marginBottom: '15px',
            },
          })}
        >
          <ChianSelectBox>
            <Flex mb='10px' justifyContent='space-between'>
              <Text fontSize='12px'>{t('from')}</Text>
            </Flex>
            <Flex justifyContent='space-between'>
              <ChainSwitcher fullWidth chainIDs={fromChainIDs} onChange={handleFromChange} />
            </Flex>
          </ChianSelectBox>
          <ChianSelectBox>
            <Flex mb='10px' justifyContent='space-between'>
              <Text fontSize='12px'>{t('to')}</Text>
            </Flex>
            <Flex justifyContent='space-between'>
              <ChainSwitcher fullWidth chainIDs={toChainIDs} onChange={handleToChange} />
            </Flex>
          </ChianSelectBox>
        </Grid>
        {account ? (
          <Button sx={{height: {md: '60px', xs: '50px'}, fontSize: '18px'}} fullWidth onClick={() => navigate(link)}>
            {t('next')}
          </Button>
        ) : (
          <ConnectWallet />
        )}
        {/*<Button fullWidth size='large'>{t('Connect Wallet')}</Button>*/}
      </SelectWrapper>
    </Flex>
  )
}

export default Home
